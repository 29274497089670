export const ariaSelected = (rootItemId, sections, selectedNames) => {
  let selected = false;
  try {
    if (selectedNames) {
      sections.forEach((section) => {
        if (selectedNames[section]?.includes(rootItemId)) {
          const partsOfName = selectedNames[section].split("_");
          if (partsOfName[2] === rootItemId) {
            selected = true;
          }
        }
      });
    }
  } catch (e) {
    console.error("ariaSelected", e);
  }
  return selected;
};
