import { Fragment, useState } from "react";

const PsTreeGrid = ({ cmpState, onrowaction, cmp }) => {
  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const handleExpandCollapseClick = (recordId) => {
    setExpandedRowIds((prev) => {
      if (prev.includes(recordId)) {
        return prev.filter((id) => id !== recordId);
      } else {
        return [...prev, recordId];
      }
    });
  };

  const expandedRows = (inputs) => {
    return inputs.map((input) => (
      <tr
        aria-level="2"
        aria-posinset="1"
        aria-selected="false"
        aria-setsize="1"
        className="slds-hint-parent"
        key={input.id}
      >
        <th className="slds-tree__item" data-label="Name" scope="row">
          <button
            className="slds-button slds-button_icon slds-button_icon-x-small slds-m-right_x-small slds-is-disabled"
            aria-hidden="true"
            tabIndex="-1"
            title=""
          >
            <svg
              className="slds-button__icon slds-button__icon_small"
              aria-hidden="true"
            >
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevronright"></use>
            </svg>
            <span className="slds-assistive-text">{input.name}</span>
          </button>

          <div className="slds-truncate" title={input.name}>
            {input.name}
          </div>
        </th>
        <td data-label="Order" role="gridcell">
          <div className="slds-truncate" title={input.argOrder}>
            {input.argOrder}
          </div>
        </td>
        <td data-label="Field" role="gridcell">
          <div className="slds-truncate" title="View Details">
            <a
              href="#"
              tabIndex="-1"
              onClick={() => onrowaction("viewKey", input)}
            >
              {input.keyName}
            </a>
          </div>
        </td>
        <td data-label="Object" role="gridcell">
          <div className="slds-truncate" title="View Details">
            <a
              href="#"
              tabIndex="-1"
              onClick={() => onrowaction("viewContainer", input)}
            >
              {input.containerName}
            </a>
          </div>
        </td>
        <td data-label="Source" role="gridcell">
          <div className="slds-truncate" title="View Details">
            <a
              href="#"
              tabIndex="-1"
              onClick={() => onrowaction("viewSource", input)}
            >
              {input.sourceName}
            </a>
          </div>
        </td>
        <td
          data-label="# Distinct"
          role="gridcell"
          style={{ width: "3.25rem" }}
        >
          {input.robustDistinct}
        </td>
      </tr>
    ));
  };

  const dataTableColumns = cmpState.dataTableColumns.map((column) => {
    return (
      <th
        aria-sort="none"
        className="slds-has-button-menu slds-is-resizable slds-is-sortable"
        scope="col"
        key={column.label}
        style={{ width: column.width }}
      >
        <div className="slds-th__action slds-text-link_reset">
          <div className="slds-grid slds-grid_vertical-align-center slds-has-flexi-truncate">
            <span className="slds-truncate" title={column.label}>
              {column.label}
            </span>
          </div>
        </div>
      </th>
    );
  });

  const dataTableRecords = cmpState.dataTableRecords.map((record) => {
    const expanded = expandedRowIds.includes(record.id);
    return (
      <Fragment key={record.id}>
        <tr
          aria-expanded={expanded}
          aria-level="1"
          aria-posinset="2"
          aria-selected="true"
          aria-setsize="4"
        >
          <th className="slds-tree__item" data-label="Name" scope="row">
            {cmp.name === "PsChain" && (
              <button
                className="slds-button slds-button_icon slds-button_icon-x-small slds-m-right_x-small"
                aria-hidden="true"
                tabIndex="-1"
                title={`${expanded ? "Collapse" : "Expand"} ${record.name}`}
                onClick={() => handleExpandCollapseClick(record.id)}
              >
                <svg
                  className="slds-button__icon slds-button__icon_small"
                  aria-hidden="true"
                >
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevronright"></use>
                </svg>
                <span className="slds-assistive-text">
                  Expand {record.name}
                </span>
              </button>
            )}
            <div className="slds-truncate" title={record.name}>
              {record.name}
            </div>
          </th>

          <td data-label="Order" role="gridcell">
            <div className="slds-truncate" title={record.argOrder}>
              {record.argOrder}
            </div>
          </td>

          <td data-label="Field" role="gridcell">
            <div className="slds-truncate" title="View Details">
              <a
                href="#"
                tabIndex="-1"
                onClick={() => onrowaction("viewKey", record)}
              >
                {record.keyName}
              </a>
            </div>
          </td>

          <td data-label="Object" role="gridcell">
            <div className="slds-truncate" title="View Details">
              <a
                href="#"
                tabIndex="-1"
                onClick={() => onrowaction("viewContainer", record)}
              >
                {record.containerName}
              </a>
            </div>
          </td>

          <td data-label="Source" role="gridcell">
            {cmp.name === "PsChain" ? (
              <div className="slds-truncate" title={record.sourceName}>
                {record.sourceName}
              </div>
            ) : (
              <div className="slds-truncate" title="View Details">
                <a
                  href="#"
                  tabIndex="-1"
                  onClick={() => onrowaction("viewSource", record)}
                >
                  {record.sourceName}
                </a>
              </div>
            )}
          </td>

          <td
            data-label="# Distinct"
            role="gridcell"
            style={{ width: "3.25rem" }}
          >
            {record.robustDistinct}
          </td>
        </tr>
        {expanded && expandedRows(record.link.inputs)}
      </Fragment>
    );
  });

  return (
    <div>
      <table
        className="slds-table slds-table_bordered slds-table_edit slds-table_fixed-layout slds-table_resizable-cols slds-tree slds-table_tree"
        role="treegrid"
        aria-label="Example single select tree grid with selected row"
      >
        <thead>
          <tr className="slds-line-height_reset">{dataTableColumns}</tr>
        </thead>
        <tbody>{dataTableRecords}</tbody>
      </table>
    </div>
  );
};

export default PsTreeGrid;
