import React, { useState, useEffect } from "react";

import "./PsConnectorTile.css";

import Badge from '@salesforce/design-system-react/components/badge';
import Icon from '@salesforce/design-system-react/components/icon';
import Card from "@salesforce/design-system-react/components/card";
import Button from '@salesforce/design-system-react/components/button'; 

import Event from "../../helpers/event.js";


function PsConnectorTile(props) {

  const [cmpState,setCmpState] = useState({
    record: {},
    order: 0,
    mode : "init" //<!-- init, empty, view, error -->
  });

  // const parentFunctions = props.parentFunctions;
  var cmpWorking = {};

  useEffect(() => {
    cmpWorking = {...cmpState};
    init();
  },[]);

  useEffect(() => {
    cmpWorking = {...cmpState};

    //don't need this state? just do everything with props?

    // cmpWorking.record = props.record;
    // cmpWorking.order = props.record;
    // cmpWorking.record = props.record;

    // cmpWorking.parentId = props.parentId;
    // cmpWorking.queryFilter = props.queryFilter;
  },[props]);



  //afterScriptsLoaded
  const init = function (){

  }

  const handleSelect = function (){
    var record = props.record || {};

    var parentId = record.sourceId || (record.source || {}).id;
    var recordModule = 'pump';
    var recordObject = 'connector';
    var recordId = record.id;
    notifyNavigation(parentId, recordModule, recordObject, recordId);
  }

  const handleRefresh = function (){
    //TODO - application event, still need to build this

    // <aura:attribute name="origin" access="public" type="String"/>

    // var refreshEvent = $A.get('e.c:RefreshEvent');
    // refreshEvent.setParams({  data: { action: 'reload', origin: 'ConnectorTile'} });
    // refreshEvent.fire();
  }

  const notifyNavigation = function (parentId, recordModule, object, id){
    var event = Event.createEvent("navigation");
    event.parentId = parentId;
    event.module = module;
    event.obj = object;
    event.id = id;
    event.source = "record";

    props.childToParent(event);
  }


  return (
    <div className="slds-card_boundary grid-item">
      <Card
        id="recordGrid"
        heading={ <b>{props.record.name}</b>}
        footer={
          <div>
           <Button
              label="Manage"
              onClick={handleSelect}
              variant="brand"
            />

            {  props.record.runStatus === "Running" &&
                <Button
                  label="Check Status"
                  onClick={handleRefresh}
                  variant="brand"
                />
            }
          </div>         
        }
       >

        <div className="slds-p-horizontal_medium slds-p-bottom_small">
            <div className="source">
                Source: {props.record.sourceName}
            </div>

            <div className="slds-illustration slds-m-vertical_small pointer" aria-hidden="true" onClick={handleSelect}>
              {  props.record.connectorType.image &&                
                <img src={"/assets/images/connectors/" +  props.record.connectorType.image}  className="illustration-medium" alt={ props.record.connectorType.image} />
              }
              { ! props.record.connectorType.image &&
                <img src="/img/chatter/OpenRoad.svg" className="slds-illustration__svg illustration-medium" alt=""/>
              }
            </div>

            <div className="runStatus">
              {  props.record.runStatus === 'Running' &&
                <Badge
                  id="badge-base-example-inverse"
                  color="inverse"
                  content={ props.record.runStatus }
                  icon={
                    <Icon
                      category="utility"
                      name="spinner"
                      size="xx-small"
                      colorVariant="base"
                    />
                  }
                />
              }
            </div>    
        </div>

      </Card>
    </div>
  );
}

export default PsConnectorTile;