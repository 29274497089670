import { useState, useEffect } from "react";
import { Card } from "@salesforce/design-system-react";
import { useSearchParams } from "react-router-dom";

import "../Pages.css";
import ToastComponent from "../../components/toast-component";
import LoginForm from "./components/LoginForm";
import ResetForm from "./components/ResetForm";
import NewPasswordForm from "./components/NewPasswordForm";

const Login = ({ childProps: { userHasAuthenticated, user, setUser } }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const emptyToastState = { variant: "", details: "" };

  const [toastState, setToastState] = useState(emptyToastState);

  const [newUser, setNewUser] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [mode, setMode] = useState(""); //login, resetPassword, newPassword

  const closeToast = () => setToastState(emptyToastState);

  //IMPROVEMENT: autocomplete="new-password" / autocomplete="current-password"
  // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/

  useEffect(() => {
    onPageReferenceChange();
  }, [searchParams]);

  const onPageReferenceChange = () => {
    try {
      var mode = searchParams.get("mode") || "login";
      setMode(mode.toLowerCase());
    } catch (err) {
      console.error(err.stack);
    }
  };

  return (
    <div className="login-signup-container">
      <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
        {mode === "login" && (
          <LoginForm
            setToastState={setToastState}
            userHasAuthenticated={userHasAuthenticated}
            setUser={setUser}
            user={user}
            setMode={setMode}
            setNewUser={setNewUser}
          />
        )}

        {mode === "resetpassword" && (
          <ResetForm
            confirmationCode={confirmationCode}
            setConfirmationCode={setConfirmationCode}
            setToastState={setToastState}
            userHasAuthenticated={userHasAuthenticated}
            setUser={setUser}
            user={user}
            setMode={setMode}
          />
        )}

        {mode === "newpassword" && (
          <NewPasswordForm
            setToastState={setToastState}
            userHasAuthenticated={userHasAuthenticated}
            setUser={setUser}
            user={user}
            newUser={newUser}
          />
        )}
      </Card>
      {toastState.details ? (
        <ToastComponent
          close={closeToast}
          details={toastState.details}
          variant={toastState.variant}
        />
      ) : null}
    </div>
  );
};

export default Login;
