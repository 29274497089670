import { useEffect, useRef, useState } from "react";
import { Textarea } from "@salesforce/design-system-react";

import Record from "../../helpers/recordLayer";
import PsRecord from "../ps-record/PsRecord";
import ProgressBarComponent from "../ui/ProgressBarComponent";
import { formattedDateTime } from "../ps-container/components/Helper";
import EditButtonIcon from "../ui/EditButtonIcon";
import Statistics from "./components/Statistics";
import CheckAndCloseIcons from "./components/CheckAndCloseIcons";
import UndoButtonIcon from "../ui/UndoButtonIcon";
import PsNavigationInput from "../ps-navigation-input/PsNavigationInput";
import NameInputWithUndoIcon from "../ui/NameInputWithUndoIcon";
import { formattedNumber } from "../../helpers";

const PsKey = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Field",
    recordModule: "store",
    recordObject: "key",
    showDelete: false,

    //
    loading: false,
    mode: "init",
    recordId: props.recordId,
    parentId: props.parentId,
    record: {
      name: "",
      hasOverride: {
        name: false,
        dataTypeId: false,
        accept: false,
        dataTypeDetails: false,
        parseOptions: false,
      },
      sourceName: "",
      containerName: "",
      type: "",
      dataTypeName: "",
    },
    showEdit: true,
    isExpanded: false,
    activeField: "",
    checkFields: ["name", "dataTypeDetails", "parseOptions"],
    customValidity: {},
    missingRequiredFields: [],
  });

  const cmpWorking = useRef({});
  const psNavigationInputRef = useRef(null);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.afterScriptsLoaded(cmp);
  }, [props.recordId]);

  useEffect(() => {
    if (!props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent.action]);

  useEffect(() => {
    document.addEventListener(
      "mousedown",
      cmp.handleClickOutsideOfPsNavigationInput
    );

    return () => {
      document.removeEventListener(
        "mousedown",
        cmp.handleClickOutsideOfPsNavigationInput
      );
    };
  }, []);

  const cmp = {
    // --- KeyController.js ---
    init: function () {},

    afterScriptsLoaded: function () {
      PsRecord.getRecord(cmp);
    },

    handleReload: function () {
      try {
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleEdit: function () {
      try {
        PsRecord.setMode(cmp, "edit");
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleCancel: function () {
      try {
        cmp.set("customValidity", {});
        PsRecord.cancelRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleSubmit: function () {
      try {
        PsRecord.submitRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleDelete: function () {
      try {
        // if (!cmp.get('v.scriptsLoaded')) { return; }
        // Record.showToast(cmp, 'Not Available', '', 'info');
        // //helper.deleteRecord(cmp); // NB: this navigates to parent record after successfull delete
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleOverride: function (field, value) {
      try {
        // // navigationInput doesn't send the event, but since we have only 1, the absence of an event means a navigationInput was changed
        // var field = event ? event.getSource().get("v.name") : "dataTypeId";
        // if (["dataTypeId", "dataTypeDetails", "parseOptions"].includes(field)) {
        //   var record = cmp.get("v.record");
        //   record.scheduleReset = true; // NB: record is updated with cmp.set in helper.setOverride below
        // }

        if (["dataTypeId", "dataTypeDetails", "parseOptions"].includes(field)) {
          var record = cmp.get("record");
          record.scheduleReset = true; // NB: record is updated with cmp.set in helper.setOverride below
        }

        if (field === "dataTypeId") {
          record = { ...record, dataType: value, dataTypeId: value.id };
        } else {
          record = { ...record, [field + "Text"]: value };
        }

        // record = { ...record, [field + "Text"]: value };
        cmp.set("record", record);

        PsRecord.setOverride(cmp, field);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRevert: function (field) {
      try {
        // var source = event.getSource();
        // var field = source.get('v.name');
        // // revert field value
        // helper.revertOverride(cmp, field);
        // // additional revert logic
        // if (field === 'dataTypeId') {
        //     helper.revertDataType(cmp);
        // } else if (['dataTypeDetails', 'parseOptions'].includes(field)) {
        //     helper.revertJson(cmp, field);
        // }

        // var source = event.getSource();
        // var field = source.get("v.name");
        // revert field value

        PsRecord.revertOverride(cmp, field);
        // additional revert logic
        if (field === "dataTypeId") {
          cmp.revertDataType();
        } else if (["dataTypeDetails", "parseOptions"].includes(field)) {
          cmp.revertJson(field);
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    checkJsonInput: function (field, value) {
      try {
        // try {
        //     var source = event.getSource();
        //     var value = source.get('v.value');
        //     if (value) { JSON.parse(value); }
        //     source.setCustomValidity("");
        // } catch (err) {
        //     source.setCustomValidity("Invalid JSON");
        // }
        // source.showHelpMessageIfInvalid();

        let customValidity = cmp.get("customValidity");
        try {
          if (value) {
            JSON.parse(value);
          }

          cmp.set("customValidity", { ...customValidity, [field]: "" });
        } catch (err) {
          cmp.set("customValidity", {
            ...customValidity,
            [field]: "Invalid JSON",
          });
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- KeyHelper.js ---

    getDefaultRecord: function () {
      return {};
    },

    getOverrideFields: function () {
      return [
        "name",
        "accept",
        "dataTypeId",
        "parseOptions",
        "dataTypeDetails",
      ];
    },

    parseInputPlainText: function (record) {
      try {
        // valid JSON already checked during 'onblur' for textareas
        record.parseOptions = record.parseOptionsText
          ? JSON.parse(record.parseOptionsText)
          : null;
        record.dataTypeDetails = record.dataTypeDetailsText
          ? JSON.parse(record.dataTypeDetailsText)
          : null;
      } catch (err) {}
      return (({
        id,
        name,
        dataTypeId,
        parseOptions,
        dataTypeDetails,
        overridden,
        scheduleReset,
        accept,
      }) => ({
        id,
        name,
        dataTypeId,
        parseOptions,
        dataTypeDetails,
        overridden,
        scheduleReset,
        accept,
      }))(record);
    },

    parseResponse: function (response) {
      response = response.map(
        ({
          id,
          name,
          type,
          container,
          dataType,
          relevance,
          dataLastChangedOn,
          parseOptions,
          dataTypeDetails,
          numRows,
          numValues,
          robustDistinct,
          robustMin,
          robustMax,
          robustRange,
          overridden,
          scheduleReset,
          accept,
        }) => ({
          id,
          name,
          type,
          containerId: container.id,
          containerName: container.name,
          sourceId: container.source.id,
          sourceName: container.source.name,
          dataType,
          dataTypeName: dataType.name,
          dataTypeId: dataType.id,
          dataTypeSelected: Record.nameFromDetails(
            "types",
            "dataType",
            dataType.id
          ),
          relevance,
          dataLastChangedOn,
          parseOptions,
          dataTypeDetails,
          numRows,
          numValues,
          robustDistinct,
          robustMin,
          robustMax,
          robustRange,
          overridden,
          scheduleReset,
          accept,
        })
      );

      response.forEach((record) => {
        try {
          record.dataType.title = this.getTitle(
            this.getBreadCrumb("dataType", record.dataType)
          );
          record.dataTypeDetailsText = record.dataTypeDetails
            ? JSON.stringify(record.dataTypeDetails, null, 2)
            : null;
          record.parseOptionsText = record.parseOptions
            ? JSON.stringify(record.parseOptions, null, 2)
            : null;
        } catch (err) {
          console.error(err.stack);
        }
      });
      return response;
    },

    setParent: function (cmp, record) {
      var parentId = record.containerId || (record.container || {}).id;
      cmp.set("v.parentId", parentId);
      return parentId;
    },

    revertDataType: function () {
      var record = cmp.get("record") || {};
      var dataTypeId = record.dataTypeId;

      var onSuccess = function (response) {
        var dataType = response[0];
        dataType.title = cmp.getTitle(cmp.getBreadCrumb("dataType", dataType));
        Object.assign(record, {
          dataType,
          dataTypeName: dataType.name,
          dataTypeId: dataType.id,
          dataTypeSelected: Record.nameFromDetails(
            "types",
            "dataType",
            dataType.id
          ),
        });
        cmp.set("record", record);
      };

      var onError = function () {
        Object.assign(record, {
          dataType: null,
          dataTypeName: null,
          dataTypeId: null,
          dataTypeSelected: null,
        });
        cmp.set("record", record);
      };

      if (dataTypeId) {
        Record.getRecord(
          "core",
          "datatype",
          dataTypeId,
          {},
          "",
          "GET",
          onSuccess,
          onError
        );
      } else {
        onError(cmp, {});
      }
    },

    revertJson: function (field) {
      // // remove invalid JSON message when reverting from dataTypeDetails and parseOptions fields
      // var items = [].concat(cmp.find('checkField') || []);
      // items.forEach(item => {
      //     if (item.get('v.name') === field) {
      //         item.setCustomValidity("");
      //         item.showHelpMessageIfInvalid();
      //     }
      // });
      // // parse new JSON to text
      // var record = cmp.get('v.record');
      // record[field + 'Text'] = (record[field]) ? JSON.stringify(record[field], null, 2) : null;
      // cmp.set('v.record', record);

      //   // remove invalid JSON message when reverting from dataTypeDetails and parseOptions fields
      //   var items = [].concat(cmp.find("checkField") || []);
      //   items.forEach((item) => {
      //     if (item.get("name") === field) {
      //       item.setCustomValidity("");
      //       item.showHelpMessageIfInvalid();
      //     }
      //   });

      //   // parse new JSON to text
      //   var record = cmp.get("record");
      //   record[field + "Text"] = record[field]
      //     ? JSON.stringify(record[field], null, 2)
      //     : null;

      var record = cmp.get("record");
      record = {
        ...record,
        [field + "Text"]: record[field]
          ? JSON.stringify(record[field], null, 2)
          : null,
      };

      cmp.set("record", record);

      // remove errorText message
      cmp.checkJsonInput(field, "");
    },

    getBreadCrumb: function (object, record) {
      if (object === "dataType") {
        var dataRole = record.dataRole || {};
        return [
          { id: dataRole.id, name: dataRole.name },
          { id: record.id, name: record.name },
        ];
      }
      return [];
    },

    getTitle: function (breadcrumb) {
      var type = Record.TERMINOLOGY["dataType"] || "Unknown";
      breadcrumb = breadcrumb
        .map((item) => item.name)
        .filter((item) => item != null);
      var title = breadcrumb.length
        ? type + ": " + breadcrumb.join("->")
        : "loading...";
      return title;
    },

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    handleClickOutsideOfPsNavigationInput: function (event) {
      if (!psNavigationInputRef.current) {
        return;
      }
      cmp.set(
        "isExpanded",
        psNavigationInputRef.current.contains(event.target)
      );
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    checkForm: function () {
      try {
        const record = cmp.get("record");

        if (!record.name) {
          cmp.set("missingRequiredFields", ["record.name"]);
        }
        return !record.name;
      } catch (error) {
        console.error(error.stack);
      }
    },

    body: function () {
      return (
        <div className="slds-form slds-var-m-around_medium" role="list">
          <h3 className="slds-section-title--divider slds-var-m-top_medium">
            Field Details
          </h3>
          <div className="slds-form__row">
            {/* <!-- Name --> */}
            <NameInputWithUndoIcon cmpState={cmpState} cmp={cmp} />
          </div>

          <div className="slds-form__row">
            {/* <!-- Source --> */}
            <div
              className="slds-form__item slds-grid_vertical-align-center"
              role="listitem"
            >
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {(cmpState.mode === "new" ||
                  cmpState.mode === "edit" ||
                  cmpState.mode === "view") && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Source</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.sourceName}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <!-- Container --> */}
            <div
              className="slds-form__item slds-grid_vertical-align-center"
              role="listitem"
            >
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "new" ||
                cmpState.mode === "edit" ||
                cmpState.mode === "view" ? (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Object</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.containerName}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="slds-form__row">
            {/* <!-- Type --> */}
            <div
              className="slds-form__item slds-grid_vertical-align-center"
              role="listitem"
            >
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "new" ||
                cmpState.mode === "edit" ||
                cmpState.mode === "view" ? (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Type</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.type}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {/* <!-- Accept --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" ? (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">
                      Use in Pattern Discovery
                    </span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        <CheckAndCloseIcons
                          selectedItem={cmpState.record.accept}
                        />
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon
                          handleEdit={() => cmp.handleEdit(cmp)}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {cmpState.mode === "new" || cmpState.mode === "edit" ? (
                  <div verticalalign="start">
                    <label
                      className="slds-form-element__label"
                      htmlFor="toggle"
                    >
                      Use in pattern discovery
                    </label>
                    <div style={{ display: "flex", width: "14em" }}>
                      <label className="slds-checkbox_toggle slds-grid">
                        <input
                          type="checkbox"
                          checked={cmpState.record.accept || false}
                          onChange={() =>
                            PsRecord.handleToggleChange(cmp, "accept")
                          }
                        />
                        <span
                          id="checkbox-toggle-16"
                          className="slds-checkbox_faux_container"
                        >
                          <span className="slds-checkbox_faux"></span>
                          <span className="slds-checkbox_on">Yes</span>
                          <span className="slds-checkbox_off">No</span>
                        </span>
                      </label>{" "}
                      {cmpState.record.hasOverride?.accept && (
                        <div
                          style={{
                            marginTop: "-25px",
                          }}
                        >
                          <UndoButtonIcon
                            handleRevert={cmp.handleRevert}
                            field="accept"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {cmpState.mode !== "edit" && (
            <div className="slds-form__row">
              {/* <!-- DataLastChangedOn --> */}
              <div className="slds-form__item" role="listitem">
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}
                  {cmpState.mode === "view" && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">
                        Latest Data
                      </span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {/* <lightning:formattedDateTime value="{!v.record.dataLastChangedOn}" year="numeric" month="long" day="numeric" hour="2-digit" minute="2-digit" second="2-digit" /> */}
                          {formattedDateTime(cmpState.record.dataLastChangedOn)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* <!-- Relevance --> */}
              <div className="slds-form__item" role="listitem">
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}
                  {cmpState.mode === "view" && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">
                        Relevance
                      </span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {formattedNumber(cmpState.record.relevance)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <h3 className="slds-section-title--divider slds-var-m-top_medium">
            Data
          </h3>

          <div className="slds-form__row">
            {/* <!-- DataType --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Data Type</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.dataTypeName}
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon
                          handleEdit={() => cmp.handleEdit(cmp)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <div style={{ display: "flex" }}>
                    <div
                      flexibility="auto"
                      ref={psNavigationInputRef}
                      style={{ flexibility: "auto", width: "100%" }}
                    >
                      {/* <c:navigationInput label="Data Type" object="dataType" sections="['types']" selected="{!v.record.dataTypeSelected}" 
                        value="{!v.record.dataTypeId}" record="{!v.record.dataType}" onchange="{!c.handleOverride}"/> */}
                      <PsNavigationInput
                        label="Data Type"
                        object="dataType"
                        sections={["types"]}
                        selected={cmpState.record.dataTypeSelected}
                        value={cmpState.record.dataTypeId}
                        record={cmpState.record.dataType}
                        onChange={cmp.handleOverride}
                        isExpanded={cmpState.isExpanded}
                        activeField={cmpState.activeField}
                        setParentCmpState={setCmpState}
                        showRequiredFieldError={false}
                      />
                    </div>
                    {cmpState.record.hasOverride?.dataTypeId && (
                      <UndoButtonIcon
                        handleRevert={cmp.handleRevert}
                        field="dataTypeId"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* <!-- ScheduleReset --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Reprocess</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        <CheckAndCloseIcons
                          selectedItem={cmpState.record.scheduleReset}
                        />
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon
                          handleEdit={() => cmp.handleEdit(cmp)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <div>
                    <span className="slds-form-element__label slds-m-bottom_none">
                      Reprocess
                    </span>
                    <label className="slds-checkbox_toggle slds-grid">
                      <input
                        type="checkbox"
                        checked={cmpState.record.scheduleReset || false} // scheduleReset comes as null from the backend. This was added to avoid the error in the console.
                        onChange={() =>
                          PsRecord.handleToggleChange(cmp, "scheduleReset")
                        }
                      />
                      <span
                        id="checkbox-toggle-16"
                        className="slds-checkbox_faux_container"
                      >
                        <span className="slds-checkbox_faux"></span>
                        <span className="slds-checkbox_on">Yes</span>
                        <span className="slds-checkbox_off">No</span>
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="slds-form__row">
            {/* <!-- Parse Options --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">
                      Format Options
                    </span>
                    <div className="slds-form-element__control">
                      <div
                        className="slds-form-element__static"
                        style={{
                          height: "5em",
                          overflow: "auto",
                        }}
                      >
                        <pre
                          style={{
                            margin: "0.125rem 0 -0.3125rem 0",
                            fontSize: "13px",
                            fontFamily: "monospace",
                          }}
                        >
                          {cmpState.record.parseOptionsText}
                        </pre>
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon
                          handleEdit={() => cmp.handleEdit(cmp)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <div verticalalign="start" style={{ display: "flex" }}>
                    <div
                      flexibility="auto"
                      style={{
                        flexibility: "auto",
                        width: "100%",
                        fontFamily: "monospace",
                        fontSize: "13px",
                      }}
                    >
                      <Textarea
                        id="checkField"
                        name="parseOptions"
                        autocomplete="off"
                        label="Format Options"
                        value={cmpState.record.parseOptionsText || ""}
                        onChange={(e) =>
                          cmp.handleOverride("parseOptions", e.target.value)
                        }
                        onBlur={(e) =>
                          cmp.checkJsonInput("parseOptions", e.target.value)
                        }
                        errorText={cmpState.customValidity.parseOptions}
                      />
                    </div>

                    {cmpState.record.hasOverride?.parseOptions && (
                      <UndoButtonIcon
                        handleRevert={cmp.handleRevert}
                        field="parseOptions"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* <!-- Data Type Details --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">
                      Type Options
                    </span>
                    <div className="slds-form-element__control">
                      <div
                        className="slds-form-element__static"
                        style={{ height: "5em", overflow: "auto" }}
                      >
                        <pre
                          style={{
                            margin: "0.125rem 0 -0.3125rem 0",
                            fontFamily: "monospace",
                            fontSize: "13px",
                          }}
                        >
                          {cmpState.record.dataTypeDetailsText}
                        </pre>
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon
                          handleEdit={() => cmp.handleEdit(cmp)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <div verticalalign="start" style={{ display: "flex" }}>
                    <div
                      flexibility="auto"
                      style={{
                        flexibility: "auto",
                        width: "100%",
                        fontFamily: "monospace",
                        fontSize: "13px",
                      }}
                    >
                      <Textarea
                        id="checkField"
                        name="dataTypeDetails"
                        autocomplete="off"
                        label="Type Options"
                        value={cmpState.record.dataTypeDetailsText || ""}
                        onChange={(e) =>
                          cmp.handleOverride("dataTypeDetails", e.target.value)
                        }
                        onBlur={(e) =>
                          cmp.checkJsonInput("dataTypeDetails", e.target.value)
                        }
                        errorText={cmpState.customValidity.dataTypeDetails}
                      />
                    </div>
                    {cmpState.record.hasOverride?.dataTypeDetails && (
                      <UndoButtonIcon
                        handleRevert={cmp.handleRevert}
                        field="dataTypeDetails"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {(cmpState.mode === "init" || cmpState.mode === "view") && (
            <Statistics cmpState={cmpState} />
          )}
        </div>
      );
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },
  };

  return PsRecord.render(cmp, cmpState);
};

export default PsKey;
