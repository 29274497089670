import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import "./DataManagement.css";
import Record from "../../helpers/recordLayer.js";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";

import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree";
import SearchBox from "../../components/ps-search-box/SearchBox";
import PsSourceList from "../../components/ps-source-list/PsSourceList";
import PsSource from "../../components/ps-source/PsSource";
import PsConnector from "../../components/ps-connector/PsConnector";
import PsConnectorList from "../../components/ps-connector-list/PsConnectorList";
import ToastComponent from "../../components/toast-component";
import PsObjectList from "../../components/ps-object-list/PsObjectList";
import PsObject from "../../components/ps-object/PsObject";
import PsFieldList from "../../components/ps-field-list/PsFieldList";
import PsField from "../../components/ps-field/PsField";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";

function DataManagement(props) {
  const emptyToastState = {
    variant: "",
    heading: "",
    details: "",
  };

  const [cmpState, setCmpState] = useState({
    selectedItem: {},
    parentId: "",
    selectedObject: "connectors", //"sources",  // connectors/sources  , //TODO remove default
    searchText: "",
    searchLoading: false, //?

    setupDone: true, // false?

    rootId: "",
    connectorsId: "",
    sourcesId: null,
    sourceId: "",
    sourceFilter: {},
    connectorId: "",
    connectorFilter: {},
    objectId: "",
    objectFilter: {},
    fieldId: "",
    fieldFilter: {},

    parentToChildEvent: { action: "" },

    accountSettings: {},
    navigationLoading: false,
  });

  const [toastState, setToastState] = useState(emptyToastState);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const cmpWorking = useRef({});
  const leftRef = useRef(null);
  const searchdivRef = useRef(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.onPageReferenceChange();
  }, [searchParams]);

  const cmp = {
    get: (key) => {
      if (props[key]) return props[key];

      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    init: function () {},

    afterScriptsLoaded: function () {
      try {
        cmp.parsePageRef();
      } catch (err) {
        console.error(err.stack);
      }
    },

    //PumpController
    onPageReferenceChange: function () {
      try {
        // checkUrlMessage();
        cmp.parsePageRef();
        cmp.checkSetupStatus();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleNavigationEvent: function (event) {
      try {
        // navigate to different tab, e.g., when using the buttons in connector status section
        // var namespace = cmp.getType().split(':')[0];
        var tab = event.tab || "";

        if (tab.toLowerCase() === "explore") {
          navigate("/Explore"); ///just test
          return;
        }

        if (tab.toLowerCase() === "search") {
          navigate("/Search"); ///just test
          return;
        }

        // if no object is provided (e.g., when navigating to parentId), use the navigation tree to figure out where to go
        var id = event.id;
        var object = event.obj;
        if (id && !object) {
          var navigationTree = {}; //cmp.find("navigationTree");
          if (navigationTree) {
            // var name = navigationTree.nameFromId(id) || Record.nameFromDetails('overview', 'connectors', 'connectorList');
            var name = Record.nameFromDetails(
              "overview",
              "connectors",
              "connectorList"
            );
            cmp.navigateFromName(null, name);
          }
          return;
        }

        // scroll only
        var source = event.source;
        if (["change", "closeSearch"].includes(source)) {
          var scroll = event.scroll;
          var scroller = leftRef.current;
          var searchInput = searchdivRef.current;

          // update scroll position after rendering, so that rendered sizes are available
          if (searchInput && scroller && scroll != null) {
            var timer = setTimeout(() => {
              var top = searchInput.offsetTop + searchInput.offsetHeight;
              scroller.scrollTop = scroll * (scroller.scrollHeight - top);
            }, 0);
          }
        }

        if (event.obj === "connector") {
          cmp.set("connectorId", event.id);
        }

        // to clear the old recordlist when the filter changes
        cmp.clearQueryFilter(object);

        cmp.set("selectedObject", object);

        if (
          cmp.get("selectedObject") !== "connectors" &&
          event.id &&
          event.id !== "connectorList"
        ) {
          const name = "pump_" + event.obj + "_" + event.id;
          if (name !== searchParams.get("selected")) {
            cmp.navigateFromName(null, name);
          }
          return;
        }

        // navigate to specified record
        if (["record", "grid", "tree"].includes(source)) {
          cmp.navigateFromEvent(event);
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRefreshEvent: function (event) {
      try {
        event.stopPropagation();
      } catch (err) {
        console.error(err.stack);
      }
    },

    parsePageRef: function () {
      var selected =
        searchParams.get("selected") ||
        Record.nameFromDetails("overview", "connectors", "connectorList");
      var parsed = Record.parseName(selected);
      var config = parsed.config;
      var id = parsed.id;

      // each config has its own id and queryFilter variable, to prevent its component from reloading when changing the selection
      var selectedIdName = config + "Id";
      // cmpWorking.current[selectedIdName] = id;
      cmp.set(selectedIdName, id);

      // query filter
      var queryFilter = {};
      if (["source", "connector", "object", "field"].includes(config)) {
        var queryFilterName = config + "Filter";
        queryFilter[config + "Id"] = id;
        if (
          JSON.stringify(queryFilter) !==
          JSON.stringify(cmpWorking.current[queryFilterName])
        ) {
          // cmpWorking.current[queryFilterName] = queryFilter;
          cmp.set(queryFilterName, queryFilter);
        }
      }

      const parentId = selected.split("parent=")[1];
      if (parentId !== cmp.get("parentId")) {
        cmp.set("parentId", parentId);
      }

      // set the config to show _after_ all its settings have been set on the component, to prevent reloading in aftersript, as well as due to a change in filter
      // cmpWorking.current.selectedObject = config;
      cmp.set("selectedObject", config);

      // update the tree
      cmp.set("selectedItem", selected);
    },

    checkSetupStatus: function () {
      var doneStatus = "Authenticated";
      Record.getAccountSettings(cmp, doneStatus);
    },

    // show message toast, clear status and message from page navigation stack, and navigate to pageRef without success and message
    checkUrlMessage: function () {
      // var namespace = cmp.getType().split(':')[0];
      // var pageRef = cmp.get('v.pageReference');
      // var state = pageRef.state;
      var success = searchParams["success"]; //state[namespace + '__success'];
      var message = searchParams["message"]; //state[namespace + '__message'];

      if (success) {
        var isSuccess = success === "true";
        var showMessage = decodeURIComponent(
          (message || "").replace(/\+/g, "%20")
        );
        Record.showToast(
          isSuccess ? "Success" : "Error",
          showMessage,
          isSuccess ? "success" : "error"
        );
        // state = Object.assign({}, state); // shallow copy
        // delete state[namespace + '__success'];
        // delete state[namespace + '__message'];

        searchParams.delete("success");
        searchParams.delete("message");
        //this won't trigger navigation

        //setSearchParams(searchParams);
        //just do navigate instead? Might navigate to different tab?

        // navigate, while replacing the page reference in the stack, so that pressing back button won't generate the message again
        //cmp.find('navService').navigate({type: pageRef.type, attributes: pageRef.attributes, state: state}, true);

        navigate("/Explore"); ///just test, replace with navigation req above
      }
    },

    navigateFromEvent: function (event) {
      // if id is empty (i.e., when creating a new record), set the parent
      // var id = event.getParam("id");
      var id = event.id;
      var parentId = id ? undefined : event.parentId;

      // navigate
      var defaultSection = {
        root: "pump",
        sources: "pump",
        source: "pump",
        connectors: "pump",
        connector: "pump",
        // container: "pump",
        // containerItem: "pump",
        // link: "pump",
        // rightlink: "pump",
        // key: "pump",
        // filter: "pump",
        // chain: "pump",
        // rightChain: "pump",
      };
      var item = Record.itemFromEvent(event, defaultSection);

      if (event.module && !item.name.includes(event.module)) {
        item.name = event.module + item.name;
      }

      // Cancel create a new connector
      if (!id && !parentId && !event.obj && !event.module) {
        const selectedItem = cmp.get("selectedItem");

        item.name =
          selectedItem === "corepump_source___parent=source"
            ? "pump_sources_source"
            : "overview_connectors_connectorList"; // pump_sources_source
      }

      // Create a new
      else if (!id && !parentId) {
        item.name = !item.name.includes("parent")
          ? item.name + "_parent=" + cmp.get("connectorsId")
          : item.name;
      } else if (
        !event.record &&
        event.id &&
        cmp.get("selectedObject") === "source"
      ) {
        item.name = "pump_source_" + event.id;
      }

      if (
        event.id === "connectorList" &&
        event.obj === "connectors" &&
        event.parentId === "connectors" &&
        event.section === "overview"
      ) {
        item.name = "overview_connectors_connectorList";
        cmp.set("selectedItem", "overview_connectors_connectorList");
      }

      // navigate
      cmp.navigateFromName(parentId, item.name);
    },

    navigateFromName: function (parentId, name) {
      var selectedObject = cmp.get("selectedObject");
      var selectedId = selectedObject ? cmp.get(selectedObject + "Id") : null;
      var selectedParentId = cmp.get("parentId");
      var parsed = Record.parseName(name);
      var selected = searchParams.get("selected");

      if (
        parsed.id !== selectedId ||
        parsed.config !== selectedObject ||
        parentId !== selectedParentId ||
        selected !== name
      ) {
        let search = "?selected=" + name;
        if (parentId) {
          search = search + "__parent=" + parentId;
        }

        navigate({
          pathname: "/DataManagement",
          search,
        });
      }
    },

    // new functions

    clearQueryFilter: function (object) {
      const objects = [
        "field",
        "object",
        "connector",
        "source",
        "sources",
        "connectors",
      ].filter((item) => item !== object);

      objects.map((item) => {
        cmp.set(item + "Filter", {});
        cmp.set(item + "Id", "");
      });

      if (object === "sources") {
        cmp.set("sourceId", "");
      }

      if (object === "connectors") {
        cmp.set("connectorFilter", {});
        cmp.set("connectorId", "");
      }
    },
  };

  const handleDataCompEvent = (event) => {
    try {
      // const data = event.data;
      // if (data.action === "details") {
      //   //
      // } else if (data.action === "close") {
      //   //   navigatePattern(undefined);
      // }

      // update NavigatinTree:
      let recordChangedEvent = {
        ...event,
        parentId: cmp.get("parentId"),
        object: cmp.get("selectedObject"),
        sourceId: cmp.get("sourceId"),
        obj: event.record,
      };

      cmp.set("recordChangedEvent", recordChangedEvent);

      // After delete to navigate
      if (event.action === "delete") {
        let name = event.module + "_source_" + event.parentId;
        if (event.module === "core" || event.module === "pump") {
          name =
            event.parentId && event.parentId !== "source"
              ? "pump_source_" + event.parentId
              : "pump_sources_source";
        }

        cmp.navigateFromName(null, name);

        cmp.set("selectedItem", name);
        cmp.set("selectedObject", "source");
        cmp.set("connectorId", event.parentId);
        cmp.set("connectorsId", "connectorList");
      }

      // After creating a source, navigate to that source
      if (event.action === "create") {
        const name = "pump_source_" + event.id;
        cmp.navigateFromName(null, name);

        cmp.set("selectedItem", name);
        cmp.set("selectedObject", "source");
        cmp.set("sourceId", event.id);
        cmp.set("sourcesId", "source");
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleReload = () => {
    const parentToChildEvent = cmp.get("parentToChildEvent");
    cmp.set("parentToChildEvent", {
      ...parentToChildEvent,
      action: "reload",
    });
    cmp.set("navigationLoading", true);
  };

  const childToParent = (event) => {
    try {
      if (event.type === "navigation") {
        cmp.handleNavigationEvent(event);
      } else if (event.type === "dataCompEvent") {
        handleDataCompEvent(event);
      } else if (event.type === "reload") {
        handleReload(event);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleSearchTextChange = (value) => {
    cmp.set("searchText", value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    cmp.set("isSearchSubmitted", true);
  };

  return (
    <div className="DataManagement">
      <PsNavigationHeader view="list" childToParent={childToParent} />

      <div className="tab-content slds-p-around_medium">
        <div className="left slds-m-right_medium">
          {/* <!--NB: only using right-margin, because flex has no collapsing margins--> */}
          {/* <!-- navigation tree --> */}
          <article id="left" className="slds-card">
            <div
              className="slds-card__header"
              style={{
                margin: "-10px 0 -2px -5px",
              }}
            >
              <h2 className="slds-m-top_x-small slds-card__header-title slds-text-heading_small slds-truncate">
                Browse
              </h2>
            </div>
            <div
              ref={searchdivRef}
              className="slds-p-around_x-small"
              style={{
                marginBottom: "-10px",
              }}
            >
              <SearchBox
                searchText={cmpState.searchText}
                loading={cmpState.navigationLoading}
                handleSearchTextChange={handleSearchTextChange}
                handleSearchSubmit={handleSearchSubmit}
              />
            </div>

            <div
              ref={leftRef}
              className="slds-card__body"
              style={{
                overflowY: "auto",
                height: "calc(100vh - 16.5rem)",
              }}
            >
              <div className="slds-p-horizontal_x-small">
                {/* <c:navigationTree id="navigationTree" multiSelect="false" sections="['store']" selected="{!v.selectedName}" searchText="{!v.searchText}" isLoading="{!v.searchLoading}"/> */}
                <PsNavigationTree
                  multiSelect={false}
                  sections={["overview", "pump"]}
                  selected={cmpState.selectedItem}
                  searchText={cmpState.searchText}
                  isLoading={cmpState.navigationLoading}
                  parentCmp={cmp}
                  childToParent={childToParent}
                  parentToChildEvent={cmpState.parentToChildEvent}
                  recordChangedEvent={cmpState.recordChangedEvent}
                />
              </div>
            </div>
          </article>
        </div>

        {/* <!-- right panel view --> */}
        <div
          className={
            !cmpState.accountSettings.status ||
            ![
              "Authenticated",
              "Inventory Loaded",
              "Processing",
              "Onboarding",
              "Complete",
            ].includes(cmpState.accountSettings.status)
              ? "right right-noscroll"
              : "slds-scrollable right"
          }
        >
          {/* adding "right" breaks the scrolling */}
          {/* <div className="slds-scrollable right"> */}
          {/*<c:SetupStatus aura:id="setupStatus" doneStatus="Authenticated" showSpinner="false" setupDone="{!v.setupDone}" 
          title="Data Management" tagLine="Create and manage connected data sources."/>
           */}

          <PsSetupStatus
            id="setupStatus"
            doneStatus="Authenticated"
            showSpinner={false}
            setupDone={cmpState.setupDone}
            title="Data Management"
            tagLine="Create and manage connected data sources."
            parentToChildEvent={cmpState.parentToChildEvent}
            parentCmp={cmp}
            accountSettings={cmpState.accountSettings}
          />

          {cmpState.selectedObject === "connectors" && (
            <PsConnectorList
              queryFilter={{}}
              view="grid"
              parentId={cmpState.connectorId}
              maxRecords="12"
              childToParent={childToParent}
              parentCmp={cmp}
              parentToChildEvent={cmpState.parentToChildEvent}
              setToastState={setToastState}
            />
          )}
          {cmpState.selectedObject === "sources" && (
            <PsSourceList
              parentId={cmpState.sourcesId}
              maxRecords="12"
              queryFilter={{}}
              childToParent={childToParent}
              parentCmp={cmp}
              parentToChildEvent={cmpState.parentToChildEvent}
            />
          )}
          {cmpState.selectedObject === "root" &&
            cmpState.rootId === "connectors" && (
              <PsConnectorList
                queryFilter={{}}
                view="grid"
                parentId={cmpState.rootId}
                maxRecords="12"
                childToParent={childToParent}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
                setToastState={setToastState}
              />
            )}
          {cmpState.selectedObject === "root" &&
            cmpState.rootId === "sources" && (
              <PsSourceList
                parentId={cmpState.rootId}
                maxRecords="100"
                // queryFilter={cmpState.sourcesFilter}
                childToParent={childToParent}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
              />
            )}
          {cmpState.selectedObject === "source" && (
            // <!-- break height:100% chain -->
            <div>
              <PsSource
                recordId={cmpState.sourceId}
                parentId={cmpState.parentId}
                childToParent={childToParent}
                setToastState={setToastState}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
              />

              {cmpState.sourceFilter?.sourceId && (
                <PsConnectorList
                  queryFilter={cmpState.sourceFilter}
                  parentId={cmpState.sourceId}
                  maxRecords="100"
                  childToParent={childToParent}
                  parentCmp={cmp}
                  parentToChildEvent={cmpState.parentToChildEvent}
                  setToastState={setToastState}
                />
              )}
            </div>
          )}
          {cmpState.selectedObject === "connector" && (
            <div>
              <PsConnector
                recordId={cmpState.connectorId}
                parentId={cmpState.parentId}
                childToParent={childToParent}
                setToastState={setToastState}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
              />

              {cmpState.connectorId &&
                cmpState.connectorFilter?.connectorId && (
                  <PsObjectList
                    queryFilter={cmpState.connectorFilter}
                    parentId={cmpState.connectorId}
                    maxRecords="100"
                    childToParent={childToParent}
                    setToastState={setToastState}
                    parentCmp={cmp}
                    parentToChildEvent={cmpState.parentToChildEvent}
                  />
                )}
            </div>
          )}
          {cmpState.selectedObject === "object" && (
            <div>
              <PsObject
                recordId={cmpState.objectId}
                parentId={cmpState.parentId}
                childToParent={childToParent}
                setToastState={setToastState}
                parentCmp={cmp}
                parentToChildEvent={cmpState.parentToChildEvent}
              />
              {cmpState.objectId && cmpState.objectFilter?.objectId && (
                <PsFieldList
                  queryFilter={cmpState.objectFilter}
                  parentId={cmpState.objectId}
                  maxRecords="100"
                  childToParent={childToParent}
                  setToastState={setToastState}
                  parentCmp={cmp}
                  parentToChildEvent={cmpState.parentToChildEvent}
                />
              )}
            </div>
          )}
          {cmpState.selectedObject === "field" && (
            <PsField
              recordId={cmpState.fieldId}
              parentId={cmpState.parentId}
              childToParent={childToParent}
              setToastState={setToastState}
              parentCmp={cmp}
              parentToChildEvent={cmpState.parentToChildEvent}
            />
          )}
        </div>
      </div>
      {toastState.details ? (
        <ToastComponent
          close={() => setToastState(emptyToastState)}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </div>
  );
}

export default DataManagement;
