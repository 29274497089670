import { useState, useEffect, useRef } from "react";
import { Button } from "@salesforce/design-system-react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";

const PsSourceList = (props) => {
  const [cmpState, setCmpState] = useState({
    // <!-- interface -->
    recordLabel: "Source",
    recordLabelPlural: "Sources",
    // title:"", use folderTitle
    // header" type="Aura.Component[]"/> <!-- extra content at top-->
    showTitle: true,
    showFooter: false,
    tagLine: "Manage the data sources that Point Sigma analyzes.",
    emptyLine: "Start by creating a New Source",
    recordModule: "core",
    recordObject: "source",
    gridComponent: "PatternChart",
    gridComponents: [],
    // cardActions: [],
    // emptyCallToAction: [],
    gridItems: [],
    viewOptions: [
      { label: "Table", value: "table" },
      { label: "Grid", value: "grid" },
    ],
    changeView: false,
    showEdit: false, // TODO > value="{! v.queryFilter.id != 'liked' }"/>
    showLoadMore: true, // <!-- whether to show the 'Load More' button -->
    view: "table",
    // itemView: "grid", // <!-- send to each grid item -->
    isDragMode: true,
    // footer" type="Aura.Component[]"/> <!-- add modal dialogs and other extra content -->

    draggedStart: null,
    draggedIndex: null,

    // <!-- querying -->
    parentId: props.parentId || "",
    parentPrefix: "",
    // queryFilter:[], //use props
    orderBy: "relevance", //TODO this needs to use the pattern's order
    orderDirection: "asc",
    lastValue: "",
    lastId: "",
    hasMore: false,

    // <!-- records -->
    mode: "empty", //"init",  // <!-- init, empty, view, error -->
    loading: true,
    recordColumns: [],
    recordList: [],
    recordDefaultSortDirection: "asc",

    //PsSourceList specific
    showEmptyCallToAction: true,
    showCardActions: true,

    loadingMore: false,
  });

  const cmpWorking = useRef({});

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent.action]);

  const cmp = {
    // --- SourceListController.js ---

    init: function () {
      // PsRecordGrid.setRecordColumns(cmp);
      cmp.afterScriptsLoaded();
    },

    afterScriptsLoaded: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      var maxRecords = cmp.get("maxRecords");
      var numRecords = !maxRecords
        ? 0
        : Math.max(cmp.get("recordList").length, maxRecords);
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    notifyDataComp: function (row) {
      var setting = row.setting || {};
      const event = {
        type: "navigation",
        id: row.id,
        label: row.name,
        section: setting.section || "store",
        parentId: row.parentId || "",
        obj: setting.config || "source",
        breadcrumb: row.breadcrumb || [],
        record: row,
        source: row.source || "tree",
        scroll: row || null,
      };

      cmp.handleEvent(event);
    },

    handleRecordRowAction: function (row) {
      switch (row.action.name) {
        case "details":
          cmp.notifyDataComp(row);
          break;
        default:
          break;
      }
    },

    // fire event for navigation to record
    notifyNavigation: function (parentId, module, object, id = null, type) {
      const navigationEvent = {
        parentId,
        module,
        obj: object,
        id,
        source: "record",
        type,
      };
      cmp.handleEvent(navigationEvent);
    },

    handleNew: function () {
      var parentId = cmp.get("parentId");
      var recordModule = cmp.get("recordModule");
      var recordObject = cmp.get("recordObject");
      const type = "navigation";

      // navigate to 'null' id loads a 'new record' page
      cmp.notifyNavigation(parentId, recordModule, recordObject, null, type);
    },

    emptyCallToAction: function () {
      const content = `Create a new ${cmpState.recordLabel}`;
      return (
        <Button
          // id="ViewModeButton"
          label={content}
          title={content}
          onClick={() => cmp.handleNew()}
          disabled={cmpState.loading}
          variant="brand"
        />
      );
    },

    cardActions: function () {
      const mode = cmp.get("mode");
      return (
        <>
          {mode === "init" || mode === "view" ? (
            <Button
              // id="ViewModeButton"
              label="New"
              title={`Create a new ${cmpState.recordLabel}`}
              onClick={() => cmp.handleNew()}
              disabled={cmpState.loading}
            />
          ) : null}
        </>
      );
    },

    // --- SourceListHelper.js ---

    //This is Salesforce specific. need to cover to
    RECORD_COLUMNS: [
      {
        key: "name", //fieldName
        label: "Name", //label
        property: "name", //fieldName
        type: "link", //if type = button, this value is link
        action: "details", //name within typeAttributes
        sortable: true,
      },
      {
        key: "status", //fieldName
        label: "Status", //label
        property: "status", //fieldName
        type: "status", //if type = button, this value is link
        action: "text", //name within typeAttributes
        width: "100px",
        sortable: true,
      },
    ],

    parseResponse: function (response) {
      return response.map(({ id, name, status }) => ({ id, name, status }));
    },

    // --- New functions ---
    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },
    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },
  };

  return PsRecordGrid.render(cmp, cmpState);
};

export default PsSourceList;
