import { useEffect, useRef, useState } from "react";
import {
  Button,
  Icon,
  IconSettings,
  Input,
  Tooltip,
} from "@salesforce/design-system-react";
import Combobox from "@salesforce/design-system-react/components/combobox";

import PsRecord from "../ps-record/PsRecord";
import ProgressBarComponent from "../ui/ProgressBarComponent";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import EditButtonIcon from "../ui/EditButtonIcon";
import Record from "../../helpers/recordLayer";
import FreeText from "../ui/FreeText";
import DualListBox from "../ui/DualListBox";
import BetweenNumber from "../ui/BetweenNumber";
import BetweenDateTime from "../ui/BetweenDateTime";
import RelativeDateTime from "../ui/RelativeDateTime";
import PsNavigationInput from "../ps-navigation-input/PsNavigationInput";
import Modal from "../ui/Modal";

const PsMap = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Map",
    recordModule: "store",
    recordObject: "map",

    showEdit: true,

    // <!--Parameters -->
    recordValue: null, //{},
    newScopes: "",
    keyIds: {},

    // <!-- Helper and formatting fields -->
    isInit: false,
    isSaved: false,
    keyItem: null, //{},
    selectedKey: null, //{},
    keyFilter: null, //{},
    scope: "",
    bucketName: "",
    useInput: "",
    selectedPreset: "",
    selectedPresetLabel: "",
    freeTextValues: "",
    selectedValues: [],
    valuesText: [],
    minDate: null,
    maxDate: null,
    minDateAmount: 0,
    minDateUnit: "",
    minDateUnitLabel: "",
    maxDateAmount: 0,
    maxDateUnit: "",
    maxDateUnitLabel: "",
    minNumber: 0,
    maxNumber: 0,
    minDateDefault: null,
    maxDateDefault: null,
    minNumberDefault: 0,
    maxNumberDefault: 0,

    // <!-- options -->
    scopeOptions: [],
    dateTimeUnits: [],
    presetOptions: [],

    valuesOptionsLoaded: false,
    valuesOptions: [],

    //////
    loading: false,
    mode: "init",
    showDelete: true,

    record: {
      active: true,
      name: "",
      unmappedAsMissing: false,
    },
    isExpanded: true,
    activeField: "",
    selectedValue: null,
    selectedOptions: [],
    errorTexts: [],
    showDeleteConfirmDialog: false,
    deleteConfirmation: false,
    leftListBoxOptions: [],
    selectedDualListBoxItems: [],
    activeDualListBox: "",
    selectedFirstBoxItems: [],
    selectedSecondBoxItems: [],
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);
  const psNavigationInputRef = useRef(null);
  const dualListBoxRef = useRef(null);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };

    cmp.afterScriptsLoaded(cmp);
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      cmp.handleClickOutsideOfDualListBox(event);
      cmp.handleClickOutsideOfPsNavigationInput(event);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    // cmp.handleDualListBoxChange();
  }, [cmpState.selectedValues, cmpState.selectedOptions]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.handleReset();
  }, [
    props.recordId, // , props.parentId
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.handleParentIdChange();
    cmp.handleKeyIdsChange();
  }, [props.parentId, cmpState.keyIds]);

  // Combobox gives warning without id
  const scopeOptionsWithId =
    cmpState.scopeOptions && cmpState.scopeOptions.length > 0
      ? cmpState.scopeOptions.map((item) => ({
          ...item,
          id: item.label,
        }))
      : cmpState.scopeOptions;

  const cmp = {
    // --- MapController.js ---

    // init: function (cmp, event, helper) {},

    afterScriptsLoaded: function () {
      try {
        // helper.init(cmp);
        cmp.setLoad();
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleMapChange: function (cmp, event, helper) {
      try {
        if (!cmp.get("v.scriptsLoaded")) {
          return;
        }
        helper.setLoad(cmp);
        helper.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleParentIdChange: function () {
      try {
        cmp.setParent();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleKeyIdsChange: function () {
      try {
        cmp.setParent();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleReload: function () {
      try {
        cmp.setLoad();
        PsRecord.getRecord(cmp);

        cmp.set("selectedOptions", []);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleEdit: function () {
      try {
        PsRecord.setMode(cmp, "edit");
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleCancel: function () {
      try {
        cmp.set("selectedFirstBoxItems", []);
        cmp.set("selectedSecondBoxItems", []);
        cmp.setLoad();
        PsRecord.cancelRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleSubmit: function () {
      try {
        PsRecord.submitRecord(cmp);
        cmp.set("selectedFirstBoxItems", []);
        cmp.set("selectedSecondBoxItems", []);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleDelete: function () {
      try {
        PsRecord.deleteRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handlePresetChange: function (cmp, event, helper) {
      try {
        if (!cmp.get("v.scriptsLoaded")) {
          return;
        }
        Record.selectLoadedOption(
          cmp,
          "v.presetOptions",
          "v.selectedPreset",
          "v.selectedPresetLabel",
          false
        );
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleMinDateUnitChange: function (cmp, event, helper) {
      try {
        if (!cmp.get("v.scriptsLoaded")) {
          return;
        }
        Record.selectLoadedOption(
          cmp,
          "v.dateTimeUnits",
          "v.minDateUnit",
          "v.minDateUnitLabel",
          false
        );
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleMaxDateUnitChange: function (cmp, event, helper) {
      try {
        if (!cmp.get("v.scriptsLoaded")) {
          return;
        }
        Record.selectLoadedOption(
          cmp,
          "v.dateTimeUnits",
          "v.maxDateUnit",
          "v.maxDateUnitLabel",
          false
        );
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleKeySelect: function (record) {
      try {
        cmp.set("selectedKey", record);
        cmp.loadKeyDetails(true);
      } catch (err) {
        console.error(err.stack);
      }
    },

    mapJson: function () {
      try {
        var map = cmp.mapJsonHelper();
        return map;
      } catch (err) {
        console.error(err.stack);
      }
    },

    //Review: This function is not used
    mapValid: function (cmp, event, helper) {
      try {
        return !PsRecord.checkForm(cmp);
        // return Record.checkForm(cmp, ["checkField"], true);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleAddBucket: function () {
      try {
        cmp.addBucket();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleDualListBoxChange: function () {
      try {
        cmp.dualListBoxChange();
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- MapHelper.js ---

    // init: function (cmp) {},

    setLoad: function () {
      if (!cmp.get("recordValue") && !cmp.get("recordId")) {
        cmp.setParent();
      }
    },

    getDefaultRecord: function () {
      const recordName = cmp.get("selectedKey")?.name || "";
      return { active: true, name: recordName, unmappedAsMissing: false };
      //   return { active: true };
    },

    parseInputPlainText: function (record) {
      try {
        var record = cmp.mapJson();
        record = JSON.parse(JSON.stringify(record)); // deepcopy to prevent changing original record

        return record.id
          ? (({ id, name, settings, unmappedAsMissing, active }) => ({
              id,
              name,
              settings,
              unmappedAsMissing,
              active,
            }))(record)
          : (({
              type,
              name,
              scope,
              active,
              containerId,
              keyId,
              settings,
              unmappedAsMissing,
            }) => ({
              type,
              name,
              scope,
              active,
              containerId,
              keyId,
              settings,
              unmappedAsMissing,
            }))(record);
      } catch (error) {
        console.error(error);
      }
    },

    updateUI: function () {
      try {
        var record = cmp.get("record");

        var key = record.key;
        if (key) {
          key.title = this.getTitle(this.getBreadCrumb("key", key));
          cmp.set("selectedKey", key);
          cmp.set("keyItem", Record.nameFromDetails("keys", "key", key.id));
        } else {
          this.unloadKeyDetails(cmp);
        }
        var useInput = record.type === "Text" ? "MultiSelect" : "Not Supported"; // IMPROVEMENT: support other types
        cmp.set("useInput", useInput);
        var mappings = (record.settings || {}).map || [];
        if (useInput === "MultiSelect") {
          var options = [];
          var values = [];
          mappings.forEach((mapping) => {
            var bucketName = Object.keys(mapping)[0];
            var bucketItems = Object.values(mapping)[0] || [];
            var bucketValue =
              "POINT SIGMA BUCKET VALUE " + Math.random().toString();
            options.push({
              label: "*** " + bucketName + " ***",
              value: bucketValue,
              bucket: bucketName,
            });

            values.push(bucketValue);
            bucketItems.forEach((item) => {
              if (item === null) {
                options.push({
                  label: "--None--",
                  value: "POINT SIGMA EMPTY VALUE",
                });
                values.push("POINT SIGMA EMPTY VALUE");
              } else {
                options.push({ label: item, value: item });
                values.push(item);
              }
            });
          });

          cmp.set("selectedOptions", options);

          cmp.set("valuesOptions", options);
          cmp.set("selectedValues", values);
        }
        // put settings into input fields
        /*if (['FreeText', 'MultiSelect', 'ListValues'].includes(useInput)) {
            // populate both freetext and multiselect fields, because it is not clear yet which one will be used; select options are loaded later down the line
            try { cmp.set('v.selectedValues', settings.values); } catch (err) {}
            try { cmp.set('v.freeTextValues', Record.toCSV(settings.values)); } catch (err) {}
        } else if (useInput === 'BetweenDateTime') {
            try { cmp.set('v.minDate', settings.min); } catch (err) {}
            try { cmp.set('v.maxDate', settings.max); } catch (err) {}
        } else if (useInput === 'RelativeDateTime') {
            var minParts = (settings.min || '').split(' ');
            var maxParts = (settings.max || '').split(' ');
            try { cmp.set('v.minDateAmount', minParts[0]); cmp.set('v.minDateUnit', minParts[1]); } catch (err) {}
            try { cmp.set('v.maxDateAmount', maxParts[0]); cmp.set('v.maxDateUnit', maxParts[1]); } catch (err) {}
            Record.selectLoadedOption(cmp, 'v.dateTimeUnits', 'v.minDateUnit', 'v.minDateUnitLabel', false);
            Record.selectLoadedOption(cmp, 'v.dateTimeUnits', 'v.maxDateUnit', 'v.maxDateUnitLabel', false);
        } else if (useInput === 'BetweenNumber') {
            try { cmp.set('v.minNumber', settings.min); } catch (err) {}
            try { cmp.set('v.maxNumber', settings.max); } catch (err) {}
        }*/
        // new / existing record
        var isSaved = Boolean(record.id || record.floatingId);
        cmp.set("isSaved", isSaved);
        // scope
        var mapScopes = JSON.parse(JSON.stringify(Record.MAP_SCOPES)); // deepcopy to prevent changing underlying values
        if (!isSaved) {
          var newScopes = cmp.get("newScopes");
          mapScopes = mapScopes.filter(function (v) {
            return newScopes.includes(v.value);
          });
        }
        cmp.set("scopeOptions", mapScopes);
        cmp.set("scope", record.scope);
        Record.selectLoadedOption(cmp, "scopeOptions", "scope");
        cmp.loadKeyDetails(false);
      } catch (error) {
        console.error(error.stack);
      }
    },

    setParent: function () {
      var containerId = cmp.get("parentId");
      var keyIds = cmp.get("keyIds");
      if (containerId) {
        cmp.set("keyFilter", { keys: { key: { containerId: containerId } } });
      } else if (keyIds && keyIds.length) {
        cmp.set("keyFilter", { keys: { key: { Id: keyIds } } });
      } else {
        cmp.set("keyFilter", null);
      }
    },

    mapJsonHelper: function () {
      var record = cmp.get("record");
      var settings = {};
      var useInput = cmp.get("useInput");
      // parse input fields to settings
      if (useInput === "MultiSelect") {
        var mappings = cmp.getOptionsMap(false);
        settings.map = mappings && mappings.length ? mappings : undefined;
      } else if (useInput === "FreeText") {
        settings.values = Record.parseCSV(cmp.get("freeTextValues"));
      } else if (useInput === "BetweenDateTime") {
        settings.min = cmp.get("minDate");
        settings.max = cmp.get("maxDate");
      } else if (useInput === "RelativeDateTime") {
        var minAmount = cmp.get("minDateAmount");
        minAmount = minAmount || minAmount === 0 ? String(minAmount) : "";
        var maxAmount = cmp.get("maxDateAmount");
        maxAmount = maxAmount || maxAmount === 0 ? String(maxAmount) : "";
        var minUnit = cmp.get("minDateUnit");
        var maxUnit = cmp.get("maxDateUnit");
        settings.min = minAmount && minUnit ? minAmount + " " + minUnit : null;
        settings.max = maxAmount && maxUnit ? maxAmount + " " + maxUnit : null;
      } else if (useInput === "BetweenNumber") {
        var minNumber = cmp.get("minNumber");
        var maxNumber = cmp.get("maxNumber");
        // parse to float; NB: <lightning:input type="number"...> is not useful here, because it requires a 'step' setting, which we do not know
        settings.min =
          minNumber || minNumber === 0 ? parseFloat(minNumber) : null;
        settings.max =
          maxNumber || maxNumber === 0 ? parseFloat(maxNumber) : null;
      }
      // create / update map settings
      var scope = cmp.get("scope");
      // include the selected Key with the Map, so that other components (e.g., MapSet) can read its values without having to query the API again
      var key = cmp.get("selectedKey") || {};
      var container = key.container;
      record = (({
        id,
        floatingId,
        name,
        type,
        active,
        unmappedAsMissing,
      }) => ({
        id,
        floatingId,
        name,
        type,
        scope,
        containerId: container.id,
        container,
        keyId: key.id,
        key,
        settings,
        active,
        unmappedAsMissing,
      }))(record);
      return record;
    },

    loadKeyDetails: function (reset) {
      try {
        // load existing record, or open new record
        var key = cmp.get("selectedKey");
        if (key) {
          PsRecord.setLoading(cmp);
          var onSuccess = function (response) {
            var record = response[0];
            var type = (record.dataType || {}).format;
            record.title = cmp.getTitle(cmp.getBreadCrumb("key", record));

            cmp.set("record", { ...cmp.get("record"), type });
            cmp.set("selectedKey", record);
            if (reset) {
              try {
                cmp.set("record", { ...cmp.get("record"), name: record.name });
              } catch (err) {}
              try {
                cmp.set("selectedValues", null);
              } catch (err) {}
              try {
                cmp.set("valuesOptions", null);
                cmp.set("leftListBoxOptions", null);
              } catch (err) {}
            }
            var useInput = type === "Text" ? "MultiSelect" : "Not Supported"; // IMPROVEMENT: support other types
            cmp.set("useInput", useInput);
            if (type === "Text") {
              // options from the Key
              var values = record.values || [];
              var keyOptions = values.map(({ label }) => ({
                label: label,
                value: label,
              }));

              keyOptions.push({
                label: "--None--",
                value: "POINT SIGMA EMPTY VALUE",
              });
              const valuesOptions = cmp.get("valuesOptions");
              const leftListBoxOptions =
                keyOptions && keyOptions.length > 0
                  ? keyOptions.filter(
                      (keyOption) =>
                        !valuesOptions?.find(
                          (valueOption) => valueOption.value === keyOption.value
                        )
                    )
                  : [];

              cmp.set("leftListBoxOptions", leftListBoxOptions);

              // merge with options from the Map
              var options = keyOptions.concat(cmp.get("valuesOptions") || []);
              // dedupe options from Key and Map on bucket
              var optionsMap = options.reduce((obj, item) => {
                obj[item.value] = item;
                return obj;
              }, {});
              var options = Object.values(optionsMap);
              // options = Object.values(optionsMap);
              cmp.set("valuesOptions", options);

              // cmp.set("leftListBoxOptions", options);
              cmp.set("valuesOptionsLoaded", true);
              Record.selectLoadedOptions(
                cmp.get("valuesOptions"),
                cmp.get("selectedValues")
              );
              cmp.set("valuesText", cmp.getValuesText());
            }
            // // defaults
            // else if (type === 'DateTime') {
            //     var minDate = (record.robustMin) ? new Date(record.robustMin / 1000000).toISOString() : null;
            //     var maxDate = (record.robustMax) ? new Date(record.robustMax / 1000000).toISOString() : null;
            //     try { cmp.set('v.minDateDefault', minDate); } catch (err) { }
            //     try { cmp.set('v.maxDateDefault', maxDate); } catch (err) { }
            // } else if (type === 'Number') {
            //     try { cmp.set('v.minNumberDefault', record.robustMin); } catch (err) {}
            //     try { cmp.set('v.maxNumberDefault', record.robustMax); } catch (err) {}
            // }
            cmp.set("loading", false);
          };
          var onError = function (response) {
            cmp.unloadKeyDetails();
          };

          Record.getRecord(
            "store",
            "key",
            key.id,
            {},
            "",
            "GET",
            onSuccess,
            onError
          );
        } else {
          cmp.unloadKeyDetails();
        }
      } catch (error) {
        console.error(error.stack);
      }
    },

    unloadKeyDetails: function () {
      const record = cmp.get("record");
      cmp.set("record", { ...record, type: null });

      cmp.set("useInput", null);

      cmp.set("valuesOptions", [{ value: "", label: "Not Available" }]);
      cmp.set("leftListBoxOptions", [{ value: "", label: "Not Available" }]);
      cmp.set("valuesText", null);
      cmp.set("selectedValues", null);
      cmp.set("valuesOptionsLoaded", false);

      cmp.set("bucketName", "");

      cmp.set("freeTextValues", null);

      cmp.set("minDateUnit", null);
      cmp.set("maxDateUnit", null);
      cmp.set("minDateUnitLabel", null);
      cmp.set("maxDateUnitLabel", null);

      cmp.set("loading", false);
    },

    getBreadCrumb: function (object, record) {
      if (object === "key") {
        var container = record.container || {};
        var source = container.source || {};
        return [
          { id: source.id, name: source.name },
          { id: container.id, name: container.name },
          { id: record.id, name: record.name },
        ];
      }
      return [];
    },

    getTitle: function (breadcrumb) {
      return breadcrumb
        .map((item) => item.name)
        .filter((item) => item != null)
        .join("->");
    },

    addBucket: function () {
      try {
        // TODO: prevent duplicate bucket names
        var bucketName = cmp.get("bucketName");
        var bucketValue =
          "POINT SIGMA BUCKET VALUE " + Math.random().toString();

        var options =
          cmp.get("selectedOptions") || cmp.get("leftListBoxOptions") || [];

        options.push({
          label: "*** " + bucketName + " ***",
          value: bucketValue,
          bucket: bucketName,
        });

        cmp.set("valuesOptions", options);

        cmp.set("selectedOptions", options);

        var selectedValues = cmp.get("selectedValues") || [];
        selectedValues.push(bucketValue);
        cmp.set("selectedValues", selectedValues);
        Record.selectLoadedOptions(
          cmp.get("selectedOptions"),
          cmp.get("selectedValues")
        );
        cmp.set("valuesText", cmp.getValuesText());
        // empty bucket name
        cmp.set("bucketName", "");
      } catch (error) {
        console.error(error.stack);
      }
    },

    dualListBoxChange: function () {
      try {
        var options = cmp.get("valuesOptions") || [];
        var values = cmp.get("selectedValues") || [];

        options = options.filter((v) => !v.bucket || values.includes(v.value));

        cmp.set("valuesOptions", options);

        Record.selectLoadedOptions(
          cmp.get("valuesOptions"),
          cmp.get("selectedValues")
        );

        cmp.set("valuesText", cmp.getValuesText());
      } catch (error) {
        console.error(error);
      }
    },

    getOptionsMap: function (useLabels) {
      var values = cmp.get("selectedValues") || [];
      var options = cmp.get("valuesOptions") || [];

      var optionsMap = options.reduce((obj, item) => {
        obj[item.value] = item;
        return obj;
      }, {});

      var mappings = [];
      var currentValues = [];
      values.forEach((value) => {
        let item = optionsMap[value] || {};
        if (item.bucket) {
          currentValues = [];
          mappings.push({ [item.bucket]: currentValues });
        } else {
          if (useLabels) {
            currentValues.push(item.label);
          } else {
            currentValues.push(
              item.value === "POINT SIGMA EMPTY VALUE" ? null : item.value
            );
          }
        }
      });

      return mappings;
    },

    getValuesText: function () {
      var mappings = cmp.getOptionsMap(true);

      var valuesText = [];
      mappings.forEach((mapping) => {
        var bucketName = Object.keys(mapping)[0];
        var bucketItems = Object.values(mapping)[0] || [];
        valuesText.push({ bucket: bucketName, values: bucketItems.join(", ") });
      });

      return valuesText;
    },

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      const newEvent =
        event.action === "delete" || event.action === "update"
          ? { ...event, type: "dataCompEvent", record: cmp.get("record") }
          : event;

      if (!stopPropagation) {
        props.childToParent(newEvent);
      }
    },

    handleReset: function () {
      try {
        cmp.setLoad();
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleToggleChange: function (item) {
      const record = cmp.get("record");
      cmp.set("record", { ...record, [item]: !record[item] });
    },

    handleNameChange: function (e) {
      const record = cmp.get("record");
      cmp.set("record", { ...record, name: e.target.value });
    },

    handleChangeBucketName: function (value) {
      cmp.set("bucketName", value);
    },

    handleSelectScope: function (event, data) {},

    handleClickOutsideOfPsNavigationInput: function (event) {
      if (!psNavigationInputRef.current) {
        return;
      }
      cmp.set(
        "isExpanded",
        psNavigationInputRef.current.contains(event.target)
      );
    },

    handleClickOutsideOfDualListBox: function (event) {
      if (
        dualListBoxRef.current &&
        !dualListBoxRef.current.contains(event.target)
      ) {
        cmp.set("selectedFirstBoxItems", []);
        cmp.set("selectedSecondBoxItems", []);
      }
    },

    handleSelectedValuesChange: function (optionValue, process) {
      const selectedOptions = cmp.get("selectedOptions"); // right box options
      const selectedValues = cmp.get("selectedValues"); // right box options just values
      const valuesOptions = cmp.get("valuesOptions"); // bucket and with selectedValues
      const leftListBoxOptions = cmp.get("leftListBoxOptions"); // left box options

      const activeDualListBox = cmp.get("activeDualListBox");
      const selectedDualListBoxItems =
        activeDualListBox === "firstBox"
          ? cmp.get("selectedFirstBoxItems")
          : cmp.get("selectedSecondBoxItems"); // selectedItems values

      let selectedOption = valuesOptions.find(
        (valuesOption) => valuesOption.value === optionValue
      );

      // If a new bucket is added but not saved yet
      if (!selectedOption) {
        selectedOption = leftListBoxOptions.find(
          (valuesOption) => valuesOption.value === optionValue
        );
      }

      cmp.set("selectedValue", null);
      cmp.set("selectedFirstBoxItems", []);
      cmp.set("selectedSecondBoxItems", []);

      if (process === "optionsToSelected") {
        let updatedSelectedOptions = [];

        const updatedLeftListBoxOptions =
          leftListBoxOptions?.length > 0
            ? leftListBoxOptions.filter(
                (item) => !selectedDualListBoxItems.includes(item.value)
              )
            : [];

        cmp.set("leftListBoxOptions", updatedLeftListBoxOptions);

        const updatedSelectedValues = [
          ...selectedValues,
          ...selectedDualListBoxItems,
        ];
        cmp.set("selectedValues", updatedSelectedValues);

        if (selectedOptions?.length > 0) {
          const isLabelIncluded = selectedOptions.some((item) =>
            item.label.includes("**")
          );
          if (isLabelIncluded) {
            updatedSelectedOptions = [
              ...selectedOptions,
              ...selectedDualListBoxItems.map((item) => ({
                value: item,
                label: item,
              })),
            ];
            cmp.set("selectedOptions", updatedSelectedOptions);

            // update valuesOptions
            if (
              (valuesOptions?.length === 1 &&
                updatedSelectedOptions?.length > 1) ||
              valuesOptions?.length < updatedSelectedOptions?.length
            ) {
              cmp.set("valuesOptions", [
                ...updatedSelectedOptions,
                ...updatedLeftListBoxOptions,
              ]);
            }

            return;
          }
        }

        updatedSelectedOptions = valuesOptions.filter((item) =>
          selectedDualListBoxItems.includes(item.value)
        );

        cmp.set("selectedOptions", [
          ...selectedOptions,
          ...updatedSelectedOptions,
        ]);
      } else {
        // multiselectable
        const updatedSelectedValues =
          selectedValues?.length > 0
            ? selectedValues.filter(
                (item) => !selectedDualListBoxItems.includes(item)
              )
            : [];

        const updatedSelectedOptions = selectedOptions.filter(
          (item) => !selectedDualListBoxItems.includes(item.value)
        );

        const updatedLeftListBoxOptions = [
          ...leftListBoxOptions,
          ...valuesOptions.filter((item) =>
            selectedDualListBoxItems.includes(item.value)
          ),
        ].sort((a, b) => a.label.localeCompare(b.label));
        cmp.set("leftListBoxOptions", updatedLeftListBoxOptions);
        cmp.set("selectedOptions", updatedSelectedOptions);
        cmp.set("selectedValues", updatedSelectedValues);
      }
    },

    handleChangeSelectedValue: function (selected) {
      cmp.set("selectedValue", selected);

      const selectedOptionsValues = cmp
        .get("selectedOptions")
        .map((item) => item.value);

      // update active dualListBox
      let activeDualListBox = "firstBox";
      if (selectedOptionsValues.includes(selected)) {
        activeDualListBox = "secondBox";
      }
      cmp.set("activeDualListBox", activeDualListBox);

      if (activeDualListBox === "firstBox") {
        let selectedFirstBoxItems = cmp.get("selectedFirstBoxItems");

        const selectedValue = selectedFirstBoxItems.find(
          (item) => item === selected
        );

        if (selectedValue) {
          selectedFirstBoxItems = selectedFirstBoxItems.filter(
            (item) => item !== selected
          );
        } else {
          selectedFirstBoxItems = [...selectedFirstBoxItems, selected];
        }

        cmp.set("selectedFirstBoxItems", selectedFirstBoxItems);
        cmp.set("selectedSecondBoxItems", []);
      } else {
        let selectedSecondBoxItems = cmp.get("selectedSecondBoxItems");

        const selectedValue = selectedSecondBoxItems.find(
          (item) => item === selected
        );

        if (selectedValue) {
          selectedSecondBoxItems = selectedSecondBoxItems.filter(
            (item) => item !== selected
          );
        } else {
          selectedSecondBoxItems = [...selectedSecondBoxItems, selected];
        }
        cmp.set("selectedSecondBoxItems", selectedSecondBoxItems);
        cmp.set("selectedFirstBoxItems", []);
      }
    },

    handleSelectedValueUpOrDown: function (process) {
      try {
        // multiple selection:

        // const selectedDualListBoxItems = cmp.get("selectedSecondBoxItems");
        // let selectedOptions = cmp.get("selectedOptions");

        // if (selectedOptions.length === selectedDualListBoxItems.length) {
        //   return;
        // }

        // const selectedIndexes = selectedDualListBoxItems.map((selectedValue) =>
        //   selectedOptions.findIndex((item) => item.value === selectedValue)
        // );

        // selectedIndexes.forEach((index) => {
        //   const selectedOption = selectedOptions[index];

        //   if (selectedOption) {
        //     const currentIndex = selectedOptions.indexOf(selectedOption);

        //     if (
        //       process === "down" &&
        //       currentIndex < selectedOptions.length - 1
        //     ) {
        //       const temp = selectedOptions[currentIndex];
        //       selectedOptions[currentIndex] = selectedOptions[currentIndex + 1];
        //       selectedOptions[currentIndex + 1] = temp;
        //     }

        //     if (process === "up" && currentIndex > 0) {
        //       const temp = selectedOptions[currentIndex];
        //       selectedOptions[currentIndex] = selectedOptions[currentIndex - 1];
        //       selectedOptions[currentIndex - 1] = temp;
        //     }
        //   }
        // });

        // cmp.set("selectedOptions", selectedOptions);

        // single selection:
        const selectedOptions = cmp.get("selectedOptions");
        // const selectedValue = cmp.get("selectedValue");
        const selectedValue = cmp.get("selectedSecondBoxItems")[0];
        const selectedValues = cmp.get("selectedValues");

        const currentIndex = selectedOptions.findIndex(
          (item) => item.value === selectedValue
        );
        if (currentIndex === -1) {
          return;
        }
        const updatedSelectedOptions = [...selectedOptions];
        if (process === "up" && currentIndex > 0) {
          [
            updatedSelectedOptions[currentIndex - 1],
            updatedSelectedOptions[currentIndex],
          ] = [
            updatedSelectedOptions[currentIndex],
            updatedSelectedOptions[currentIndex - 1],
          ];
        }
        if (
          process === "down" &&
          currentIndex < updatedSelectedOptions.length - 1
        ) {
          [
            updatedSelectedOptions[currentIndex],
            updatedSelectedOptions[currentIndex + 1],
          ] = [
            updatedSelectedOptions[currentIndex + 1],
            updatedSelectedOptions[currentIndex],
          ];
        }
        cmp.set("selectedOptions", updatedSelectedOptions);

        const selectedValueIndex = selectedValues.findIndex(
          (value) => value === selectedValue
        );
        if (selectedValueIndex === -1) {
          return;
        }

        const updatedSelectedValues = [...selectedValues];
        if (process === "up" && selectedValueIndex > 0) {
          [
            updatedSelectedValues[selectedValueIndex - 1],
            updatedSelectedValues[selectedValueIndex],
          ] = [
            updatedSelectedValues[selectedValueIndex],
            updatedSelectedValues[selectedValueIndex - 1],
          ];
        }
        if (
          process === "down" &&
          selectedValueIndex < updatedSelectedValues.length - 1
        ) {
          [
            updatedSelectedValues[selectedValueIndex],
            updatedSelectedValues[selectedValueIndex + 1],
          ] = [
            updatedSelectedValues[selectedValueIndex + 1],
            updatedSelectedValues[selectedValueIndex],
          ];
        }
        cmp.set("selectedValues", updatedSelectedValues);

        cmp.handleDualListBoxChange();
      } catch (error) {
        console.error(error.stack);
      }
    },

    checkForm: function () {
      const checkDivs = document.querySelectorAll("[id^='check']");
      let errorTexts = [];
      let invalid = false;

      checkDivs.forEach((div) => {
        const inputOrElement = div.querySelector(
          "input, textarea, select, .combobox"
        );

        if (inputOrElement && inputOrElement.value.trim() === "") {
          errorTexts.push(div.id);
          invalid = true;
        }
      });
      cmp.set("errorTexts", errorTexts);
      return invalid;
    },

    handleGenerateMap: function () {
      try {
        cmp.set("loading", true);
        var record = cmp.get("record") || {};
        var selectedKey = cmp.get("selectedKey"); //TODO: temporary fix - need to review this in more detail
        const valuesOptions = cmp.get("valuesOptions");

        var onSuccess = function (response) {
          var updatedRecord = cmp.get("record");

          if (!response.settings || !response.settings.map) {
            console.error("No map returned from AI");
            console.error("response: ", response);
            return;
          }

          updatedRecord.settings = updatedRecord.settings || {};
          updatedRecord.settings.map = response.settings.map;

          cmp.set("record", updatedRecord);
          cmp.updateUI();
          cmp.set("loading", false);

          const selectedValues = cmp.get("selectedValues") || [];
          if (selectedValues.length < valuesOptions.length) {
            cmp.setToastState(
              "info",
              "Info",
              "Not all values could be assigned to the categories. You can manually assign the values, or create better matching categories and try again"
            );
          }
        };

        var onError = function (response) {
          cmp.set("loading", false);
          cmp.setToastState(
            "error",
            "Error",
            "An error occurred during the map with AI. You can manually assign the values, or create better matching categories and try again"
          );
        };

        var dataPlainText = cmp.parseInputPlainText(cmp, record);

        var bodyString = {
          ...record,
          name: dataPlainText.name,
          keyId: selectedKey.id,
          settings: dataPlainText.settings,
        };

        bodyString = JSON.stringify(bodyString);

        Record.getRecord(
          "store",
          "generative",
          "",
          {},
          bodyString,
          "PUT",
          onSuccess,
          onError
        );
      } catch (err) {
        console.error(err.stack);
        cmp.set("loading", false);
      }
    },

    isGenerateMapButtonDisabled: function () {
      const leftListBoxOptions = cmp.get("leftListBoxOptions");
      if (
        !leftListBoxOptions ||
        leftListBoxOptions.length === 0 ||
        (leftListBoxOptions.length === 1 &&
          leftListBoxOptions[0].label === "--None--")
      ) {
        return true;
      }
      return false;
    },

    body: function () {
      return (
        <div className="slds-form slds-var-m-around_medium" role="list">
          <h3 className="slds-section-title--divider slds-var-m-top_medium">
            Map Details
          </h3>
          <div className="slds-form__row">
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                <lightning-layout>
                  <div className="slds-grid slds-grid_vertical slds-wrap">
                    <div className="slds-col slds-size_1-of-1">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        {/* Active */}
                        <div style={{ flex: "0 0 50px" }}>
                          {cmpState.mode === "init" && <ProgressBarComponent />}
                          {cmpState.mode === "view" && (
                            <div
                              id="FormDiv"
                              className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                            >
                              <span className="slds-form-element__label">
                                Active
                              </span>
                              <div className="slds-form-element__control">
                                <div className="slds-form-element__static">
                                  <CheckAndCloseIcons
                                    selectedItem={cmpState.record.active}
                                  />
                                </div>
                                <div
                                  className="slds-button slds-button__icon slds-button__icon_hint"
                                  style={{ padding: "8px" }}
                                >
                                  <EditButtonIcon handleEdit={cmp.handleEdit} />
                                </div>
                              </div>
                            </div>
                          )}
                          {(cmpState.mode === "new" ||
                            cmpState.mode === "edit") && (
                            <>
                              <span className="slds-form-element__label slds-m-bottom_none">
                                Active
                              </span>
                              <label className="slds-checkbox_toggle slds-grid">
                                <input
                                  type="checkbox"
                                  checked={cmpState.record.active}
                                  onChange={() =>
                                    cmp.handleToggleChange("active")
                                  }
                                />
                                <span
                                  id="checkbox-toggle-16"
                                  className="slds-checkbox_faux_container"
                                >
                                  <span className="slds-checkbox_faux"></span>
                                </span>
                              </label>
                            </>
                          )}
                        </div>
                        {/* Name */}
                        <div
                          id="checkRecordName"
                          style={{
                            flex: "1",
                            marginLeft: "10px",
                          }}
                        >
                          {cmpState.mode === "view" && (
                            <div
                              id="FormDiv"
                              className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                            >
                              <span className="slds-form-element__label">
                                Name
                              </span>
                              <div className="slds-form-element__control">
                                <div className="slds-form-element__static">
                                  {cmpState.record.name}
                                </div>
                                <div className="slds-button slds-button__icon slds-button__icon_hint">
                                  <EditButtonIcon
                                    handleEdit={() => cmp.handleEdit(cmp)}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {(cmpState.mode === "new" ||
                            cmpState.mode === "edit") && (
                            <Input
                              label="Name"
                              value={cmpState.record.name}
                              onChange={(e) => cmp.handleNameChange(e)}
                              required
                              errorText={
                                !cmpState.record.name &&
                                cmpState.errorTexts.includes("checkRecordName")
                                  ? "Complete this field."
                                  : null
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </lightning-layout>
              </div>
            </div>
            {/* Scope */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Scope</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.scope}
                      </div>
                    </div>
                  </div>
                )}
                {/* <lightning:combobox aura:id="checkField" name="scope" label="Scope" value="{!v.scope}" options="{!v.scopeOptions}" 
disabled="{! v.isSaved }" required="true"/> */}

                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <Combobox
                    events={{
                      onSelect: (event, data) =>
                        cmp.handleSelectScope(event, data),
                    }}
                    labels={{
                      label: "Scope",
                      placeholder: "",
                    }}
                    menuPosition="relative"
                    options={scopeOptionsWithId}
                    selection={[
                      {
                        value: props.newScopes[0],
                        label: props.newScopes[0],
                        selected: true,
                        id: props.newScopes[0],
                      },
                    ]}
                    value={cmpState.scope}
                    variant="readonly"
                    disabled={cmpState.isSaved}
                    name="scope"
                    required={true}
                  />
                )}
              </div>
            </div>
          </div>

          <h3 className="slds-section-title--divider slds-var-m-top_medium">
            Settings
          </h3>

          <div className="slds-form__row">
            {/* <!-- Key --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Field</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.selectedKey.name}
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <div ref={psNavigationInputRef} id="checkPsNavigationInput">
                    {/* <c:navigationInput aura:id="checkField" label="Field" object="key" sections="['keys']" selected="{!v.keyItem}" 
                    record="{!v.selectedKey}" required="true" onchange="{!c.handleKeySelect}" disabled="{! v.isSaved }" filters="{!v.keyFilter}"/> */}

                    <PsNavigationInput
                      label="Field"
                      object="key"
                      sections={["keys"]}
                      selected={cmpState.keyItem}
                      value={cmpState.rightKeyId}
                      record={cmpState.selectedKey}
                      filters={cmpState.keyFilter}
                      onChange={cmp.handleKeySelect}
                      disabled={cmpState.isSaved}
                      required={true}
                      ////
                      isExpanded={cmpState.isExpanded}
                      activeField={cmpState.activeField}
                      setParentCmpState={setCmpState}
                      parentCmp={cmp}
                      showRequiredFieldError={
                        !cmpState.selectedKey?.name &&
                        cmpState.errorTexts.includes("checkPsNavigationInput")
                      }
                    />
                  </div>
                )}
              </div>
            </div>{" "}
          </div>

          {/* <div className="slds-form__row">
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                Test
                <Input />
                <ResponsiveDualListBox />
              </div>
            </div>
          </div> */}

          <div className="slds-form__row">
            {/* <!-- Not Supported --> */}
            {cmpState.useInput === "Not Supported" && (
              <div className="slds-form__item" role="listitem">
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">
                      Not currently supported
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* <!-- Freetext --> */}
            {cmpState.useInput === "FreeText" && (
              <FreeText cmpState={cmpState} />
            )}

            {/* <!-- MultiSelect --> */}
            {cmpState.useInput === "MultiSelect" && (
              <div className="slds-form__item" role="listitem">
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}

                  {cmpState.mode === "view" && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">Mapping</span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {/* <aura:iteration items="{!v.valuesText}" var="item">
                                            {!item.bucket} -> {!item.values}<br/>
                                        </aura:iteration> */}
                          {cmpState.valuesText.map((item, index) => (
                            <div key={index}>
                              {item.bucket} -&gt; {item.values}
                              <br />
                            </div>
                          ))}
                        </div>
                        <div className="slds-button slds-button__icon slds-button__icon_hint">
                          {/* <lightning:buttonIcon aura:id="EditRecord" iconName="utility:edit" variant="bare" alternativeText="Edit" onclick="{!c.handleEdit}" /> */}
                          <EditButtonIcon handleEdit={cmp.handleEdit} />
                        </div>
                      </div>
                    </div>
                  )}

                  {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                    <div ref={dualListBoxRef}>
                      {/* <lightning:duallistbox aura:id="checkField" name="selectedValues" label="Mapping" value="{!v.selectedValues}" 
                        fieldLevelHelp="Create buckets to rename or group values, and add items under each bucket. Remove a bucket by moving it from right to left. 
                        Edit a bucket name by creating a new one with the desired name and deleting the old bucket" options="{!v.valuesOptions}" onchange="{!c.handleDualListBoxChange}"/> */}

                      <DualListBox
                        filterState={cmpState}
                        handleSelectedValuesChange={
                          cmp.handleSelectedValuesChange
                        }
                        handleChangeSelectedValue={
                          cmp.handleChangeSelectedValue
                        }
                        handleSelectedValueUpOrDown={
                          cmp.handleSelectedValueUpOrDown
                        }
                        label="Mapping"
                        fieldLevelHelp="Create buckets to rename or group values, and add items under each bucket. Remove a bucket by moving it from right to left. Edit a bucket name by creating a new one with the desired name and deleting the old bucket"
                        valuesOptions={
                          cmpState.leftListBoxOptions?.length > 0
                            ? cmpState.leftListBoxOptions.filter(
                                (item) => !item.bucket
                              )
                            : []
                        }
                        selectedOptions={cmpState.selectedOptions}
                        selectedValue={cmpState.selectedValue}
                        selectedDualListBoxItems={
                          cmpState.selectedDualListBoxItems
                        }
                        selectedFirstBoxItems={cmpState.selectedFirstBoxItems}
                        selectedSecondBoxItems={cmpState.selectedSecondBoxItems}
                      />

                      {/* <lightning:layout verticalAlign="end">
                                <lightning:layoutItem size="6"></lightning:layoutItem>
                                <lightning:layoutItem size="5">
                                    <lightning:input aura:id="checkField" name="bucketName" label="New Bucket" autocomplete="off" fieldLevelHelp="Enter a new bucket name and press 'Add'" value="{!v.bucketName}"/>
                                </lightning:layoutItem>
                                <lightning:layoutItem size="1" padding="horizontal-medium">
                                    <lightning:button disabled="{! empty(v.bucketName) }" label="Add" title="Add a new bucket" onclick="{!c.handleAddBucket}" variant="brand"/>                                    
                                </lightning:layoutItem>
                            </lightning:layout> */}

                      <div
                        style={{
                          display: "flex",
                          float: "inline-end",
                          width: "50%",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <IconSettings iconPath="/assets/icons">
                            <Input
                              label="New Bucket"
                              autoComplete="off"
                              fieldLevelHelpTooltip={
                                <Tooltip
                                  id="field-level-help-tooltip"
                                  align="top left"
                                  content="Enter a new bucket name and press 'Add'"
                                />
                              }
                              value={cmpState.bucketName}
                              onChange={(event) =>
                                cmp.handleChangeBucketName(event.target.value)
                              }
                            />
                          </IconSettings>
                        </div>
                        <div
                          style={{
                            padding: "1.8em 0 0 0.7em",
                          }}
                        >
                          <Button
                            disabled={!cmpState.bucketName}
                            label="Add"
                            title="Add a new bucket"
                            onClick={cmp.handleAddBucket}
                            variant="brand"
                          />
                        </div>
                      </div>

                      {props.parentCmp.get("generativeAIMapEnabled") && (
                        <div
                          style={{
                            display: "flex",
                            float: "inline-end",
                            width: "50%",
                            paddingTop: "1.8em",
                          }}
                        >
                          <IconSettings iconPath="/assets/icons">
                            <button
                              disabled={cmp.isGenerateMapButtonDisabled()}
                              className="slds-button slds-button_neutral"
                              variant="base"
                              title="Generate map with AI"
                              onClick={cmp.handleGenerateMap}
                            >
                              <div style={{ padding: "0 0.625em 0.125em 0" }}>
                                <Icon
                                  assistiveText={{ label: "Bot" }}
                                  category="standard"
                                  name="bot"
                                  size="small"
                                />
                              </div>
                              Generate Map with AI
                            </button>
                          </IconSettings>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* <!-- Between Number --> */}
            {cmpState.useInput === "BetweenNumber" && (
              <BetweenNumber
                filterState={cmpState}
                // handleChangeMaxMinNumber={handleChangeMaxMinNumber}
              />
            )}

            {/* <!-- Between DateTime --> */}
            {cmpState.useInput === "BetweenDateTime" && (
              <BetweenDateTime
                filterState={cmpState}
                // handleChangeBetweenDateTime={handleChangeBetweenDateTime}
              />
            )}

            {/* <!-- Relative DateTime --> */}
            {cmpState.useInput === "RelativeDateTime" && (
              <RelativeDateTime
                filterState={cmpState}
                // handleMaxAndMinDateAmount={handleMaxAndMinDateAmount}
                // handleMinDateUnitChange={handleMinDateUnitChange}
                // handleMaxDateUnitChange={handleMaxDateUnitChange}
                // showRequiredFieldError={
                //   props.filterSetState.showRequiredFieldError
                // }
              />
            )}
          </div>

          <div className="slds-form__row">
            {/* <!-- Unmapped Values --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">
                      Unmapped values as missing
                    </span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {/* <aura:if isTrue="{!v.record.unmappedAsMissing}">
                                        <lightning:icon size="x-small" iconName="utility:check" alternativeText="Yes" title="Yes" />
                                        <aura:set attribute="else">
                                            <lightning:icon size="x-small" iconName="utility:close" alternativeText="No" title="No" />
                                        </aura:set>
                                    </aura:if> */}
                        <CheckAndCloseIcons
                          selectedItem={cmpState.record.unmappedAsMissing}
                        />
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon handleEdit={cmp.handleEdit} />
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <div>
                    <span className="slds-form-element__label slds-m-bottom_none">
                      Set unmapped values as missing
                    </span>
                    <label className="slds-checkbox_toggle slds-grid">
                      <input
                        type="checkbox"
                        checked={cmpState.record.unmappedAsMissing}
                        onChange={() =>
                          cmp.handleToggleChange("unmappedAsMissing")
                        }
                      />
                      <span
                        id="checkbox-toggle-16"
                        className="slds-checkbox_faux_container"
                      >
                        <span className="slds-checkbox_faux"></span>
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="slds-form__item" role="listitem"></div>
          </div>
        </div>
      );
    },
  };

  return (
    <>
      {cmpState.showDeleteConfirmDialog ? (
        <Modal
          apply={() => PsRecord.confirmDeleteRecord(cmp)}
          cancel={() => PsRecord.cancelDeleteRecord(cmp)}
          header="Confirmation"
          modalContent="Deleting this Record will also delete all its associated loaded data. Are you sure?"
          applyButtonContent="Delete"
        />
      ) : null}
      {PsRecord.render(cmp, cmpState)}
    </>
  );
};

export default PsMap;
