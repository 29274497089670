import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Spinner,
  Radio,
  RadioGroup,
  Textarea,
} from "@salesforce/design-system-react";
import { Tag, Input, Tooltip } from "antd";

import "./Search.css";
import SearchLoading from "../../components/ui/SearchLoading";
import Record from "../../helpers/recordLayer";
import PsSearchGrid from "../../components/ps-search-grid/PsSearchGrid";
import PsNavigationTree from "../../components/ps-navigation-tree/PsNavigationTree";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ToastComponent from "../../components/toast-component";
import PsPatternDetailedView from "../../components/ps-pattern-detailed-view/PsPatternDetailedView";
import PsNavigationHeader from "../../components/ps-navigation-header/PsNavigationHeader";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";
import useWindowSize from "../../hooks/useWindowSize";
import { truncateText } from "../../helpers";

const SearchPage = () => {
  const [cmpState, setCmpState] = useState({
    accountSettings: {},

    view: "search", // "list",
    queryFilter: {},
    pattern: null,
    showSearchResult: false,
    searchText: null,
    questionText: null,
    generativeAISearchEnabled: false,
    debugGenerativeAI: false,
    debugPrompt: null,
    debugRequest: null,
    debugResponse: null,

    // question input
    question: "",
    showSearchSection: false,
    searchLoading: false,
    selectedSuggestion: null,
    suggestionData: [],

    // search input
    showSearchBox: true,
    // searchTerms: [],
    searchPosition: {},
    setCaretAtNode: null,
    showNavigation: false,
    showSuggestions: false,

    // navigation tree
    navigationLoading: false,
    navigationSearch: null,
    navigationName: null,
    navigationRecord: {},

    onFocusSearchBox: false,
    searchBoxContent: "",

    parentToChildEvent: {},
  });

  const emptyToastState = {
    variant: "",
    heading: "",
    details: "",
  };

  const [toastState, setToastState] = useState(emptyToastState);

  const [searchParams, setSearchParams] = useSearchParams();
  const cmpWorking = useRef({});

  const searchBoxRef = useRef(null);
  const suggestionsRef = useRef(null);
  const navigationscroll = useRef(null);
  const isFirstRender = useRef(true);

  const [searchTerms, setSearchTerms] = useState([]); //array of search terms. Currently just strings, we'll have to change this to array of objects that have label, value, etc
  const [inputValue, setInputValue] = useState(""); //used by input components to capture inputValue
  const [editIndex, setEditIndex] = useState(-1); //index that tracks with tag is being edited. set it when clicking a tag. unset when clicking outside of tag
  const [treePosition, setTreePosition] = useState({
    //showNavigation
    //used for positioning of the navigation tree
    top: 0,
    left: 0,
    visible: false,
  });

  // custom hook to get window size
  const { width } = useWindowSize();

  const navigationTreeSection = useRef(null); //ref for the div that contains the navigation tree
  const tagsContainerRef = useRef(null); //ref for the container (div) of the searchTerms
  const prevInputValue = useRef("");
  const newTagInputRef = useRef(null); // Ref for Input element for adding new tag
  // Callback ref to get the actual DOM element, because we're using antd's Input component
  const setNewInputRef = (inputComponent) => {
    if (inputComponent) {
      newTagInputRef.current = inputComponent.input;
    }
  };

  const editInputRef = useRef(null); // Ref for Input element for editing existing searchTerms
  // Callback ref to get the actual DOM element, because we're using antd's Input component
  const setEditInputRef = (inputComponent) => {
    if (inputComponent) {
      editInputRef.current = inputComponent.input;
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  // ---------- useEffect ----------

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    init();
  }, []);

  useEffect(() => {
    // Determine which input field to focus based on whether a tag is being edited
    const inputRef =
      editIndex === -1 ? newTagInputRef.current : editInputRef.current;
    if (inputRef) {
      inputRef.focus();
      updateTreePosition(inputRef);
    }
  }, [searchTerms, editIndex]);

  // ---------- useEffect ----------

  //only run after init, if accountSettings change
  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    handleAccountSettingsChange();
  }, [cmpState.accountSettings]);

  useEffect(() => {
    updateTreeLeftPosition();
  }, [
    navigationTreeSection?.current,
    tagsContainerRef?.current,
    cmpState.navigationLoading,
    treePosition.left,
    width,
  ]);

  //only run after init, if searchParams change
  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    onPageReferenceChange();
  }, [searchParams]);

  // ---------- cmp get & set ----------
  const cmp = {
    get: (key) => {
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },
  };

  // ---------- From SearchTabController.js ----------
  const init = () => {
    try {
      cmpWorking.current = { ...cmpState };
      cmp.set("queryFilter", { query: "" });
      checkSetupStatus();
      parsePageRef();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const onPageReferenceChange = function () {
    try {
      parsePageRef();
      checkSetupStatus();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleDataCompEvent = (event) => {
    try {
      var data = event.data;
      if (data.action === "viewDetails") {
        var pattern = data.pattern;
        var patternId = (pattern || {}).id;
        var currentId = (cmpWorking.current.pattern || {}).id;
        cmp.set("pattern", pattern);

        if (patternId) {
          if (patternId !== currentId || cmpWorking.current.view !== "detail") {
            navigatePattern(patternId);
          }
        } else if (!cmpWorking.current.showSearchResult) {
          navigatePattern("result");
        }
      } else if (data.action === "close") {
        navigatePattern(undefined);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleAccountSettingsChange = () => {
    try {
      setGenerativeAISearchEnabled();
    } catch (err) {
      console.error(err.stack);
    }
  };

  //fires when you click select, below navigation tree
  const handleSelectNavigation = () => {
    try {
      if (cmpWorking.current.navigationRecord) {
        // parseSearchBox(true);

        var item = cmpWorking.current.navigationRecord;
        var inputValue = cmpWorking.current.navigationSearch;
        item.text = inputValue;
        item.name =
          "${" + item.config + ":" + item.id + ",name:" + item.label + "}";
        updateTags(item);
      } else {
        closeNavigation();
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleCancelNavigation = () => {
    try {
      handleInputConfirm();
      closeNavigation();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleShowSuggestions = () => {
    try {
      cmp.set("showSuggestions", true);
      //REVIEW not keeping track of selected suggestion during navigation, so we shouldn't have item selected in radio group?
      cmp.set("selectedSuggestion", 0);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleCancelSuggestions = () => {
    try {
      cmp.set("showSuggestions", false);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleNavigationEvent = (event) => {
    try {
      var source = event.source;

      // scroll only
      if (["change", "closeSearch"].includes(source)) {
        var scroll = event.scroll;
        var scroller = navigationscroll.current;
        // update scroll position after rendering, so that rendered sizes are available
        if (scroller && scroll != null) {
          var timer = setTimeout(function () {
            scroller.scrollTop = scroll * scroller.scrollHeight;
          }, 0);
        }
      }

      if (source === "tree") {
        setNavigationRecord(event);
      }

      if (source === "delete") {
        cmp.set("navigationRecord", null);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleStartSearch = () => {
    try {
      doSearch();
    } catch (err) {
      console.error(err.stack);
    }
  };

  //No longer needed. Check if it can be deleted
  // const handleCommitQuestion = (event) => {
  //   try {
  //     var question = cmp.get("question");
  //     if (!question || question === "") {
  //       doClear();
  //     }
  //   } catch (err) {
  //     console.error(err.stack);
  //   }
  // };

  const handleQuestionKeyUp = (event) => {
    try {
      if (event.which === 13) {
        doQuestion();
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  // Classic search
  const handleClearSearch = () => {
    try {
      var currentSearchText = cmp.get("searchText");
      if (currentSearchText && currentSearchText !== "") {
        setSearchTerms([]); //might not be necessary once the navigate below is working
        navigate("/Search", { forceRefresh: true });
      } else {
        setSearchTerms([]);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleToggleSearchDetails = () => {
    try {
      var showSearchSection = cmp.get("showSearchSection");
      cmp.set("showSearchSection", !showSearchSection);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleStartQuestion = () => {
    try {
      doQuestion();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleSelectSuggestion = (event) => {
    try {
      cmp.set("showSuggestions", false);
      var index = event.target.value;
      var suggestions = cmp.get("suggestionData");
      suggestions = suggestions.map((suggestion) => ({
        ...suggestion,
        checked: suggestion.value === index,
      }));
      cmp.set("suggestionData", suggestions);
      cmp.set("selectedSuggestion", index);
      doSuggestion(false);
    } catch (err) {
      console.error(err.stack);
    }
  };

  //SearchTabHelper.js
  const parsePageRef = () => {
    try {
      const patternId = new URLSearchParams(location.search).get("pattern");
      const questionText = new URLSearchParams(location.search).get("question");
      const searchText = new URLSearchParams(location.search).get("search");

      // determine view
      var pattern = cmp.get("pattern");
      var showSearchResult = patternId === "result";
      var hasSearchPattern = pattern && !pattern.id;
      var view =
        patternId && (!showSearchResult || hasSearchPattern)
          ? "detail"
          : "search";

      // update filters depending on the view
      // NB: this only updates filters if they actually changed to prevent unnecessary reloading of data in child components
      if (view === "search") {
        var currentSearchText = cmp.get("searchText");
        var currentQuestionText = cmp.get("questionText");
        var processQuestion =
          questionText && questionText !== currentQuestionText;

        if (questionText !== currentQuestionText) {
          // REVIEW > it's going in here when it shouldn't
          updateQuestion(questionText);
        }

        // At startup processQuestion currentSearchText values are null and searchText is empty
        if (
          !processQuestion &&
          (searchText || currentSearchText) &&
          searchText !== currentSearchText
        ) {
          // if (!processQuestion && searchText !== currentSearchText) {
          updateSearchText(searchText);
        }
      } else if (view === "detail") {
        if (patternId !== "result") {
          var currentId = (cmpWorking.pattern || {}).id;
          if (patternId !== currentId) {
            cmp.set("pattern", { id: patternId });
          }
        }
      }

      // set panel view mode
      // IMPROVEMENT: remove pattern=result from the url when reading it direclty from the URL (on browser refresh), to prevent subsequent navigation issues
      cmp.set("showSearchResult", showSearchResult && hasSearchPattern);
      cmp.set("view", view);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const setGenerativeAISearchEnabled = () => {
    try {
      var generativeAISearchEnabled = false;
      var debugGenerativeAI = false;
      var accountSettings = cmp.get("accountSettings") || {};

      if (
        ["Full", "Metadata", "Debug"].includes(
          (accountSettings.settings || {}).generativeAI
        ) &&
        ["Full", "Metadata", "Debug"].includes(
          (accountSettings.limits || {}).generativeAI
        ) &&
        accountSettings.settings.enableGenerativeAISearch
      ) {
        generativeAISearchEnabled = true;
        if (
          (accountSettings.settings || {}).generativeAI === "Debug" &&
          (accountSettings.limits || {}).generativeAI === "Debug"
        ) {
          debugGenerativeAI = true;
        }
      }

      //For testing
      // generativeAISearchEnabled = false;

      cmp.set("generativeAISearchEnabled", generativeAISearchEnabled);
      cmp.set("debugGenerativeAI", debugGenerativeAI);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const updateQuestion = (questionText) => {
    questionText = questionText || "";
    cmp.set("question", questionText);
    cmp.set("questionText", questionText);
    loadQuestion(questionText);
  };

  //called from parsePageRef to set seach text based on url input
  const updateSearchText = (searchText) => {
    searchText = searchText || "";
    var parts = searchText.split(new RegExp("(\\$\\{[^\\}]*\\})")); // /(\$\{[^\}]*\})/);
    var tagsUpdate = [];
    var filterText = "";
    parts.forEach((part) => {
      if (part === "" || part === " ") {
        tagsUpdate.push({ text: "" });
        return;
      } else if (part.startsWith("${")) {
        var parsed = part.match(/\$\{(.*)\,name:(.*)\}/);
        var name = parsed && parsed.length > 0 ? "${" + parsed[1] + "}" : part;
        var text = parsed && parsed.length > 1 ? parsed[2] : name;
        var item = { label: text };
        tagsUpdate.push(createSearchTerm(item, part));
        filterText += name;
      } else {
        if (part[0] === " ") {
          tagsUpdate.push({ text: "" });
        }
        tagsUpdate.push({ text: part.trim() });
        if (part[part.length - 1] === " ") {
          tagsUpdate.push({ text: "" });
        }
        filterText += part;
      }
    });
    setSearchTerms(tagsUpdate);
    cmp.set("searchText", searchText);
    cmp.set("queryFilter", { query: filterText });
  };

  const checkSetupStatus = () => {
    Record.checkSetupStatus(cmp);
  };

  const navigateQuestion = (questionText) => {
    // var pageref = cmp.get("pageReference");
    // var namespace = cmp.getType().split(":")[0];
    // var state = Object.assign({}, pageref.state, {
    //   [namespace + "__pattern"]: undefined,
    //   [namespace + "__question"]: questionText,
    // });
    // cmp.find("navService").navigate({
    //   type: pageref.type,
    //   attributes: pageref.attributes,
    //   state: state,
    // });

    //Should we set pattern to undefined?
    let search = questionText
      ? `?question=${encodeURIComponent(questionText)}`
      : "";

    navigate({
      pathname: "/Search",
      search: search,
      replace: false,
    });
  };

  //url navigation
  const navigateSearch = (searchText, replace) => {
    // let search = searchText ? `?search=${encodeURIComponent(searchText)}` : "";
    // navigate({
    //   pathname: "/Search",
    //   search: search,
    //   replace: replace,
    // });
    searchParams.set("search", searchText);
    setSearchParams(searchParams);
  };

  const navigatePattern = (patternId) => {
    const currentPattern = searchParams.get("pattern");
    if (patternId === undefined) {
      if (currentPattern !== null) {
        searchParams.delete("pattern");
        setSearchParams(searchParams, { replace: true });
      } else {
        //Force reload
        onPageReferenceChange();
      }
    } else {
      if (
        currentPattern !== patternId ||
        (patternId === "result" && currentPattern === "result")
      ) {
        searchParams.set("pattern", patternId);
        setSearchParams(searchParams, { replace: true });

        // After refershing the page on the detail view it will go back to search view, when you click the chart again you should go back to detail view
        if (patternId === "result" && currentPattern === "result") {
          cmp.set("view", "detail");
        }
      } else {
        //Force reload
        onPageReferenceChange();
      }
    }
  };

  const navigateClear = () => {
    // var pageref = cmp.get("pageReference");
    // cmp.find("navService").navigate({
    //   type: pageref.type,
    //   attributes: pageref.attributes,
    //   state: null,
    // });
  };

  const closeNavigation = () => {
    setTreePosition((prev) => ({ ...prev, visible: false }));

    cmp.set("navigationRecord", null);
    cmp.set("navigationName", null);
    cmp.set("navigationSearch", null);
    cmp.set("showNavigation", false);

    cmp.set("onFocusSearchBox", false);

    // after selected item loaded to maket searchBox blur
    // var searchBox = searchBoxRef.current;
    // if (searchBox) {
    //   searchBox.blur();
    // }
  };

  const setNavigationRecord = (event) => {
    // default sections used when navigating from an external component
    // IMPROVEMENT: add sections for aggregations, transforms, filters, etc.
    var defaultSections = {
      root: "types",
      dataRole: "types",
      dataType: "types",
      source: "data",
      container: "data",
      key: "data",
    };

    // create item
    var item = Record.itemFromEvent(event, defaultSections);
    cmp.set("navigationRecord", item);
  };

  // update settings for navigation dropdown after typing
  // IMPROVEMENT: build in some delay, so this is only parsed after user doesn't type for a few 100ms, same way is in NavigationTree
  const parseTypedNew = (text, forceNavigation) => {
    try {
      cmpWorking.current.navigationSearch = text;
      cmpWorking.current.navigationLoading = true;

      setCmpState((prev) => ({
        ...prev,
        navigationLoading: cmpWorking.current.navigationLoading,
        navigationSearch: text,
        // showNavigation,
      }));
    } catch (err) {
      console.error(err.stack);
    }
  };

  // set search position, and reposition navigation dropdown box
  const setSearchPosition = (index, start, end, searchBox, caretRect) => {
    // cmp.set("searchPosition", { index, start, end });
    // // IMPROVEMENT: position the dropdown at the start of the search word, rather than moving while typing
    // var navigation = navigationRef.current;
    // var searchboxRect = searchBox ? searchBox.getBoundingClientRect() : null;
    // if (navigation && caretRect && searchboxRect) {
    //   if (!searchboxRect) {
    //     return;
    //   }
    //   var left = caretRect.x - searchboxRect.x || 15;
    //   var top = caretRect.bottom - searchboxRect.top || 15;
    //   if (left > searchboxRect.width - 300) {
    //     navigation.style.left = "auto";
    //     navigation.style.right = 0;
    //   } else {
    //     navigation.style.left = left + "px";
    //     navigation.style.right = "auto";
    //   }
    //   navigation.style.top = top + "px";
    // }
  };

  const createSearchTerm = (item, name) => {
    var maxChars = 50;
    var label = item.label || "";
    // NB; slds-trunctate doesn't work on 'display: inline' elements, so we truncate manually
    var text =
      label.length <= maxChars
        ? label
        : label.slice(0, maxChars - 1) + "\u2026";

    text = text.split(": ")[1];
    var title = item.title || item.label;
    var tag = { text, title, name, item, label };
    return tag;
  };

  const doSearch = () => {
    var mySearchTerms = searchTerms || [];
    var stringTerms = mySearchTerms.reduce((obj, searchTerm) => {
      obj.push(
        searchTerm.name
          ? searchTerm.name
          : searchTerm.text === ""
          ? " "
          : searchTerm.text
      );
      return obj;
    }, []);

    var searchText = stringTerms.join("");

    // only navigate if the search text changed
    var currentSearchText = cmpWorking.current.searchText;
    if ((searchText || "") !== (currentSearchText || "")) {
      navigateSearch(searchText, false);
    }
  };

  const doSuggestion = (replace) => {
    var index = cmp.get("selectedSuggestion"); //why was this 1 based? more areas to correct?
    var suggestionData = cmp.get("suggestionData");
    var suggestion = suggestionData[index] || {};
    var searchText = suggestion.query;
    // only navigate if the search text changed
    var currentSearchText = cmp.get("searchText");
    if ((searchText || "") !== (currentSearchText || "")) {
      navigateSearch(searchText, replace);
    }
  };

  const doQuestion = () => {
    // navigate to the new question if it changed
    var question = cmpWorking.current.question;
    var currentQuestion = cmpWorking.current.questionText;

    if ((question || "") !== (currentQuestion || "")) {
      // only navigate if the question text changed
      navigateQuestion(question);
    } else {
      // load question without navigating
      loadQuestion(question);
    }
  };

  //Only called by handleCommitQuestion, which is no longer used
  // const doClear = () => {
  //   // navigate to clear the question and search text, if they are not already empty
  //   var questionText = cmp.get("questionText");
  //   var searchText = cmp.get("searchText");
  //   // only clear if either searchText or questionText is provided
  //   if (searchText || questionText) {
  //     cmp.set("debugRequest", "");
  //     cmp.set("debugResponse", "");
  //     navigateClear();
  //   }
  // };

  const loadQuestion = (question) => {
    // send question to API and store the results, then navigate to the suggestion (if available)
    var debug = cmp.get("debugGenerativeAI");

    if (question) {
      cmp.set("searchLoading", true);
      var onSuccess = function (response) {
        setDebug(response);
        setSuggestions(response);
        doSuggestion(true);
        cmp.set("searchLoading", false);
      };

      var onError = function (response) {
        unsetSuggestions();
        unsetDebug(cmp);
        if (response && response.type === "Timeout") {
          Record.showToast(
            "Service busy",
            "We cannot currently handle your request due to high server load. Please try again in a few moments",
            "error"
          );
        }
        cmp.set("searchLoading", false);
      };

      var body = {};
      if (debug) {
        body.debug = true;
        body.prompt = prepJson(cmp.get("debugPrompt"));
      }
      body.question = prepJson(question);
      Record.getRecords("relate", "interpret", body, onSuccess, onError, "PUT");
    } else {
      unsetSuggestions();
      unsetDebug();
      cmp.set("searchLoading", false);
    }
  };

  const prepJson = (text) => {
    // escape undesired JSON control characters before sending to the API
    text = JSON.stringify(text || "");
    text = text.substring(1, text.length - 1);
    return text;
  };

  const setDebug = (response) => {
    var debug = cmp.get("debugGenerativeAI");
    if (debug) {
      var msg = response.filter(function (i) {
        return i.type === "debug";
      });
      cmp.set("debugRequest", (msg[0] || {}).request);
      cmp.set("debugResponse", (msg[0] || {}).response);
    }
  };

  const unsetDebug = (response) => {
    var debug = cmp.get("debugGenerativeAI");
    if (debug) {
      cmp.set("debugRequest", "");
      cmp.set("debugResponse", "");
    }
  };

  const setSuggestions = (response) => {
    var queries = response.filter(function (i) {
      return i.type === "query";
    });

    queries = queries.map((query, index) => ({
      ...query,
      label: query.description,
      name: index, //index + 1,
      id: `item-${index}`, //index + 1, //not a number?
      key: query.query,
      labels: { label: query.description },
      value: "" + index,
      checked: false,
    }));

    if (!queries.length) {
      Record.showToast(
        cmp,
        "No results",
        "Unable to find any results for your question, try rephrasing your question in a different way.",
        "warning"
      );
    } else {
      queries[0].checked = true;
    }

    cmp.set("suggestionData", queries);
    cmp.set("selectedSuggestion", 0);
  };

  const unsetSuggestions = (response) => {
    cmp.set("suggestionData", []);
    cmp.set("selectedSuggestion", 0);
    doSuggestion(cmp, true);
  };

  //New functions

  //REVIEW why do we need this
  const handleDocumentClick = (event) => {
    let onFocusSearchBox = false;
    // to make search div active when click search div or select button
    if (
      (searchBoxRef.current && searchBoxRef.current.contains(event.target)) ||
      event.target.id === "selectButton"
    ) {
      onFocusSearchBox = true;
    }
    cmp.set("onFocusSearchBox", onFocusSearchBox);
  };

  const handleClearQuestion = () => {
    cmp.set("question", "");
  };

  const handleChangeQuestion = (value) => {
    cmp.set("question", value);
  };

  const handleReload = () => {
    cmp.set("parentToChildEvent", {
      action: "reload",
    });
  };

  const childToParent = (event) => {
    try {
      if (event.type === "navigation") {
        handleNavigationEvent(event);
      } else if (event.type === "dataCompEvent") {
        handleDataCompEvent(event);
      } else if (event.type === "reload") {
        handleReload(event);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // ---------- new search box functions ----------

  // Update the position of the navigation tree and hide/unhide it
  const updateTreePosition = (inputEl, hasSpecialInput) => {
    // Check if inputEl is a DOM element
    if (
      inputEl &&
      typeof inputEl.getBoundingClientRect === "function" &&
      (editIndex !== -1 || inputValue || hasSpecialInput)
    ) {
      const rect = inputEl.getBoundingClientRect();
      var generativeAISearchEnabled = cmp.get("generativeAISearchEnabled");
      var top = rect.bottom + window.scrollY - 150;
      var left = rect.left + window.scrollX - 10;
      if (generativeAISearchEnabled) {
        top -= 40;
      }

      setTreePosition({
        top: top,
        left: left,
        visible: true,
      });
    } else {
      setTreePosition((prev) => ({ ...prev, visible: false }));
    }
  };

  const updateTreeLeftPosition = () => {
    let left = treePosition.left;
    const defaultNavigationTreeSectionWidth = 425;
    const defaultTagsContainerWidth = 1610;

    // Get the actual width of the navigation tree section; if not available, use the default width
    const navigationTreeSectionWidth =
      navigationTreeSection.current?.offsetWidth ??
      defaultNavigationTreeSectionWidth;

    // Get the actual width of the tags container; if not available, use the default width
    const tagsContainerWidth =
      tagsContainerRef.current?.offsetWidth ?? defaultTagsContainerWidth;

    if (navigationTreeSectionWidth + left >= tagsContainerWidth) {
      left = tagsContainerWidth - navigationTreeSectionWidth;
    } else {
      let currentInput =
        editIndex === -1 ? newTagInputRef.current : editInputRef.current;

      if (
        currentInput &&
        typeof currentInput.getBoundingClientRect === "function" &&
        (editIndex !== -1 || inputValue)
      ) {
        const rect = currentInput.getBoundingClientRect();
        left = rect.left + window.scrollX - 10;
      }
    }
    setTreePosition((prev) => ({ ...prev, left }));
  };

  // Clicking enter or clicking outside of the input field will confirm the input
  // needed separate function, because we can't call handleInputConfirm() with no param from input element
  const handleInputConfirm = () => {
    updateTags();
  };

  ///new approach that can handle enter and navitgation tree input
  const updateTags = (selectedValue = null) => {
    // Reference to the navigation tree div, assuming you have a ref called navigationscroll
    // const navigationTreeDiv = navigationTreeSection.current;

    var newTag = selectedValue ? selectedValue : { text: inputValue };
    let newSearchTerms = [];

    if (selectedValue || inputValue.trim()) {
      newSearchTerms = [...searchTerms];
      if (editIndex === -1) {
        // To avoid adding two space tags next to each other
        if (
          newSearchTerms.length === 0 ||
          (newSearchTerms.length > 0 &&
            (newTag.text !== "" || newTag.label) &&
            (newSearchTerms[newSearchTerms.length - 1].text !== "" ||
              newSearchTerms[newSearchTerms.length - 1].label))
        ) {
          newSearchTerms.push({ text: "" }); // Add a space tag before the new tag
        }
        newSearchTerms.push(newTag);
      } else {
        // Replacing a space tag or editing an existing tag
        if (searchTerms[editIndex].text === "") {
          // If it's a space tag, ensure there's a space before and after the new tag
          newSearchTerms = [
            ...newSearchTerms.slice(0, editIndex),
            { text: "" },
            newTag,
            { text: "" },
            ...newSearchTerms.slice(editIndex + 1),
          ];
        } else {
          // Editing a non-space tag
          newSearchTerms[editIndex] = newTag;
        }
      }

      setSearchTerms(newSearchTerms);
    } else if (editIndex !== -1 && inputValue === "") {
      if (
        editIndex !== 0 &&
        (searchTerms[editIndex - 1].text === "" ||
          (searchTerms.length > editIndex + 1 &&
            searchTerms[editIndex + 1].text === ""))
      ) {
        if (
          searchTerms[editIndex - 1].text === "" &&
          searchTerms.length > editIndex + 1 &&
          searchTerms[editIndex + 1].text === ""
        ) {
          newSearchTerms = searchTerms.filter(
            (_, index) => index !== editIndex && index !== editIndex + 1
          );
        } else {
          //remove the tag
          newSearchTerms = searchTerms.filter(
            (_, index) => index !== editIndex
          );
        }
        setSearchTerms(newSearchTerms);
      }
    }

    setInputValue("");
    prevInputValue.current = "";

    //if we just submitted the last tag > setEditIndex(-1);
    //if we inserted a tag mid way, setEditIndex to the index of the next tag, which should be a space tag

    setEditIndex(-1); //this might not be right.. if you used backspace?
  };

  const handleTagClick = (index) => {
    setEditIndex(index);
    setInputValue(searchTerms[index].text);
    prevInputValue.current = searchTerms[index].text;

    parseTypedNew(searchTerms[index].text, true);

    // Calculate and set tag position
    const tagElement = tagsContainerRef.current?.querySelector(
      `[data-tag-index="${index}"]`
    );
    if (tagElement) {
      const rect = tagElement.getBoundingClientRect();
      setTreePosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        visible: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    setInputValue(value);
    prevInputValue.current = value;
    let currentInput =
      editIndex === -1 ? newTagInputRef.current : editInputRef.current;

    if (
      currentInput &&
      typeof currentInput.getBoundingClientRect === "function"
    ) {
      const hasSpecialInput = value === "#";
      updateTreePosition(currentInput, hasSpecialInput);
    }
    parseTypedNew(value, true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      handleCancelNavigation();
    } else if (e.key === "Backspace") {
      if (
        prevInputValue.current === "" &&
        searchTerms.length > 1 &&
        editIndex !== 0 &&
        editIndex !== 1
      ) {
        let selectedItem = editIndex === -1 ? searchTerms.length : editIndex;
        if (selectedItem > 0) {
          let newSearchTerms = searchTerms.filter(
            (tag, index) => index !== selectedItem - 1
          );
          setSearchTerms(newSearchTerms);
          if (editIndex !== -1) {
            selectedItem--;
            setEditIndex(selectedItem);
          }
        }
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (searchTerms.length === 0 || inputValue) {
        return;
      }

      handleStartSearch();
    }
  };

  const handleTagClose = (removedTag, removedIndex) => {
    let newSearchTerms = searchTerms.filter(
      (tag, index) => index !== removedIndex
    );

    // Adjust spaces after tag removal
    if (
      newSearchTerms[removedIndex - 1] &&
      newSearchTerms[removedIndex - 1].text === "" &&
      newSearchTerms[removedIndex] &&
      newSearchTerms[removedIndex].text === ""
    ) {
      // Remove only one of the two spaces
      newSearchTerms.splice(removedIndex, 1);
    } else {
      // Remove any single spaces adjacent to the removed tag
      newSearchTerms = newSearchTerms.filter(
        (tag, index, array) =>
          !(
            tag.text === "" &&
            ((array[index - 1] && array[index - 1].text === "") ||
              (array[index + 1] && array[index + 1].text === ""))
          )
      );
    }

    setSearchTerms(newSearchTerms);
  };

  return (
    <div className="Search">
      {/* <c:NavigationHeader view="{!v.view}"/> */}
      <PsNavigationHeader view={cmpState.view} childToParent={childToParent} />
      <div className="tab-content slds-p-around_medium">
        <div
          className={`${cmpState.view === "search" ? "top" : "slds-hide"}`}
          style={{ width: "100%" }}
        >
          {cmpState.generativeAISearchEnabled && (
            <div className="slds-grid slds-grid_vertical-align-start slds-m-bottom_x-small">
              <div
                className="slds-col"
                onKeyUp={handleQuestionKeyUp}
                // "{! c.handleQuestionKeyUp }"
              >
                {/* <lightning:input aura:id="question" type="search" autocomplete="off" variant="label-hidden" placeholder="Question..." value="{!v.question}" isLoading="{!v.searchLoading}" oncommit="{!c.handleCommitQuestion}"/> */}
                <div className="slds-form-element">
                  <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left-right slds-input-has-icon_group-right">
                    <svg
                      className="slds-icon slds-input__icon slds-input__icon_left slds-icon-text-default"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#search"></use>
                    </svg>
                    <input
                      id="question"
                      className="slds-input"
                      // value={searchText}
                      onChange={(e) => handleChangeQuestion(e.target.value)}
                      autoComplete="off"
                      type="search"
                      variant="label-hidden"
                      placeholder="Question..."
                      value={cmpState.question}
                      // onPressEnter={handleCommitQuestion} //Warning: Unknown event handler property `onPressEnter`. It will be ignored.
                      // isLoading={cmpState.searchLoading}
                      // onCommit="{!c.handleCommitQuestion}"
                    />
                    <div className="slds-input__icon-group slds-input__icon-group_right">
                      {cmpState.searchLoading ? <SearchLoading /> : null}
                      {cmpState.question ? (
                        <button
                          className="slds-button slds-button_icon slds-input__icon slds-input__icon_right"
                          title="Clear"
                          onClick={handleClearQuestion}
                        >
                          <svg
                            className="slds-button__icon slds-icon-text-light"
                            aria-hidden="true"
                          >
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clear"></use>
                          </svg>
                          <span className="slds-assistive-text">Clear</span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="slds-col slds-grow-none slds-p-left_xx-small">
                {/* <lightning:buttonIcon iconName="{! if(v.showSearchSection, 'utility:chevrondown', 'utility:chevronright') }" variant="border-filled" onclick="{! c.handleToggleSearchDetails }" alternativeText="Details" title="Details"/> */}
                <button
                  className="slds-button slds-button_icon slds-button_icon-border-filled"
                  title="Details"
                  // alternativeText="Details"
                  onClick={handleToggleSearchDetails}
                >
                  <svg className="slds-button__icon" aria-hidden="true">
                    <use
                      xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#${
                        cmpState.showSearchSection
                          ? "chevrondown"
                          : "chevronright"
                      }`}
                    ></use>
                  </svg>
                  <span className="slds-assistive-text">Details</span>
                </button>
              </div>
              <div className="slds-col slds-grow-none slds-p-left_x-small">
                {/* <lightning:button variant="brand" name="search" label="Search" onclick="{!c.handleStartQuestion}" className="search-button"></lightning:button> */}
                <Button
                  variant="brand"
                  name="search"
                  label="Search"
                  onClick={handleStartQuestion}
                  className="search-button"
                />
              </div>
            </div>
          )}

          {(cmpState.showSearchSection ||
            !cmpState.generativeAISearchEnabled) && (
            <>
              <div className="slds-grid slds-grid_vertical-align-start slds-m-bottom_small">
                {cmpState.showSearchBox && (
                  <div className="slds-col search-container">
                    <div
                      ref={tagsContainerRef}
                      style={{
                        display: "flex",
                        flexDirection: "row wrap", // Change here to allow wrapping
                        alignItems: "center",
                        padding: "5px",
                        border: "1px solid #d9d9d9",
                        borderRadius: "4px",
                        background: "#fff",
                        width: "100%",
                        flexWrap: "wrap", // Ensure elements can wrap onto the next line
                      }}
                    >
                      {searchTerms.map((tag, index) => (
                        <div
                          key={`${tag.text}-${index}`}
                          style={{ marginRight: 8, marginBottom: 5 }}
                          data-tag-index={index}
                        >
                          {editIndex === index ? (
                            <Input
                              ref={setEditInputRef}
                              size="small"
                              value={inputValue}
                              onChange={handleInputChange}
                              onPressEnter={handleInputConfirm}
                              onKeyDown={handleKeyDown}
                            />
                          ) : (
                            <>
                              {tag.label || tag.text ? (
                                <Tooltip title={tag.title || tag.text}>
                                  <Tag
                                    color={tag.label ? "green" : "blue"}
                                    closable
                                    onClose={() => handleTagClose(tag, index)}
                                    onClick={() => handleTagClick(index)}
                                  >
                                    {truncateText(tag.label || tag.text, 50)}
                                  </Tag>
                                </Tooltip>
                              ) : (
                                <>
                                  {(tag.text === "" ||
                                    (tag.text === "" && tag.label)) && (
                                    <Tag
                                      onClick={() => handleTagClick(index)}
                                      style={{
                                        cursor: "text",
                                        color: "transparent",
                                        border: "1px dashed #ccc",
                                      }}
                                    >
                                      ·
                                    </Tag>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                      {editIndex === -1 && (
                        <Input
                          ref={setNewInputRef}
                          size="small"
                          value={inputValue}
                          onChange={handleInputChange}
                          onPressEnter={handleInputConfirm}
                          onKeyDown={handleKeyDown}
                          placeholder="Type to add a search term"
                          style={{
                            flex: 1,
                            border: "none",
                            boxShadow: "none",
                            margin: 0,
                          }}
                        />
                      )}
                    </div>

                    {/* <div className="{! if(v.showNavigation, '', 'slds-hidden') }"> */}
                    {treePosition.visible && (
                      //Navigation Tree
                      <div>
                        <div
                          ref={navigationTreeSection}
                          // ref={navigationRef}
                          className="slds-box slds-theme_default slds-is-relative navigation"
                          style={{
                            // position: "absolute",
                            top: `${treePosition.top}px`,
                            left: `${treePosition.left}px`,
                          }}
                        >
                          <div
                            id="navigationscroll"
                            ref={navigationscroll}
                            className="slds-m-around_x-small slds-scrollable"
                            style={{ maxHeight: "50vh" }}
                          >
                            <PsNavigationTree
                              selected={cmpState.navigationName}
                              searchText={
                                cmpState.navigationSearch === "#"
                                  ? ""
                                  : cmpState.navigationSearch
                              }
                              isLoading={cmpState.navigationLoading}
                              multiSelect={false}
                              sections={[
                                "transforms",
                                "aggs",
                                "types",
                                "data",
                                "chains",
                                "filters",
                              ]}
                              parentCmp={cmp}
                              childToParent={childToParent}
                            />
                          </div>
                          <div className="slds-border_top"></div>
                          <div className="slds-grid slds-grid_align-end slds-m-around_x-small">
                            <div className="slds-col slds-p-left_x-small">
                              <Button
                                name="cancel"
                                label="Cancel"
                                onClick={() => handleCancelNavigation()}
                              />
                            </div>
                            <div className="slds-col slds-p-left_x-small">
                              <Button
                                id="selectButton"
                                variant="brand"
                                name="select"
                                label="Select"
                                onClick={handleSelectNavigation}
                                disabled={!cmpState.navigationRecord}
                              />
                            </div>
                          </div>
                          {cmpState.navigationLoading && (
                            <Spinner assistiveText={{ label: "Loading" }} />
                          )}
                        </div>
                        <div
                          className="backdrop"
                          onClick={() => handleCancelNavigation()}
                        ></div>
                      </div>
                    )}

                    {cmpState.showSuggestions && (
                      <>
                        <div
                          ref={suggestionsRef}
                          className="slds-box slds-theme_default slds-is-relative suggestions"
                        >
                          <RadioGroup
                            labels={{}}
                            onChange={handleSelectSuggestion}
                            name="suggestionsRadioGroup"
                          >
                            {cmpState.suggestionData.map((suggestion) => (
                              <Radio
                                key={suggestion.key}
                                id={suggestion.id}
                                labels={suggestion.labels}
                                value={suggestion.value}
                                checked={suggestion.checked}
                                variant="base"
                              />
                            ))}
                          </RadioGroup>
                        </div>
                        <div
                          className="backdrop"
                          onClick={handleCancelSuggestions}
                        ></div>
                      </>
                    )}
                  </div>
                )}

                {/* Update */}
                {cmpState.generativeAISearchEnabled && (
                  <>
                    <div className="slds-col slds-grow-none slds-p-left_xx-small">
                      <button
                        className="slds-button slds-button_icon slds-button_icon-border-filled"
                        title="Suggestions"
                        // alternativeText="Details"
                        onClick={handleShowSuggestions}
                        disabled={cmpState.suggestionData.lenght === 0}
                      >
                        <svg className="slds-button__icon" aria-hidden="true">
                          {/* Change this to change the icon based on if list is expanded or now */}
                          <use
                            xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#${
                              cmpState.showSearchSection
                                ? "chevrondown"
                                : "chevronright"
                            }`}
                          ></use>
                        </svg>
                        <span className="slds-assistive-text">Details</span>
                      </button>

                      {/* <lightning:buttonIcon iconName="utility:chevrondown" variant="border-filled" onclick="{! c.handleShowSuggestions }" alternativeText="Suggestions" title="Suggestions" disabled="{! empty(v.suggestionData)}" /> */}
                    </div>
                    <div className="slds-col slds-grow-none slds-p-left_x-small">
                      {/* <lightning:button name="update" label="Update" onclick="{!c.handleStartSearch}" className="search-button"></lightning:button> */}
                      <Button
                        variant="brand"
                        name="update"
                        label="Update"
                        onClick={handleStartSearch}
                        className="search-button"
                      />
                    </div>
                  </>
                )}

                {/* Clear and Search */}
                {!cmpState.generativeAISearchEnabled && (
                  <>
                    <div className="slds-col slds-grow-none slds-p-left_x-small">
                      {/* <lightning:button label="Clear" onclick="{!c.handleClearSearch}"></lightning:button> */}
                      <Button label="Clear" onClick={handleClearSearch} />
                    </div>
                    <div className="slds-col slds-grow-none slds-p-left_x-small">
                      {/* <lightning:button variant="brand" name="select" label="Search" onclick="{!c.handleStartSearch}"></lightning:button> */}
                      <Button
                        variant="brand"
                        name="search"
                        label="Search"
                        onClick={handleStartSearch}
                      />
                    </div>
                  </>
                )}
              </div>

              {cmpState.debugGenerativeAI && (
                <>
                  <Textarea
                    id="prompt"
                    label="GPT Prompt"
                    value={cmpState.debugPrompt}
                  />
                  <Textarea
                    id="request"
                    label="GPT Request"
                    value={cmpState.debugRequest}
                    readonly={true}
                  />
                  <Textarea
                    id="response"
                    label="GPT Response"
                    value={cmpState.debugResponse}
                    readonly={true}
                  />

                  {/* <lightning:textarea aura:id="prompt" label="GPT Prompt" value="{!v.debugPrompt}"/>
                <lightning:textarea aura:id="request" label="GPT Request" value="{!v.debugRequest}" readonly="true"/>
                <lightning:textarea aura:id="response" label="GPT Response" value="{!v.debugResponse}" readonly="true"/> */}
                </>
              )}
            </>
          )}

          <div // className="{! if(empty(v.queryFilter), 'slds-hide', 'results')}"
            className={`${cmpState.queryFilter ? "results" : "slds-hide"}`}
          >
            {/* <c:SetupStatus aura:id="setupStatus" accountSettings="{!v.accountSettings}" doneStatus="Complete" title="Search" tagLine="Find the insights you need by typing just a few keywords."/>
             */}

            <PsSetupStatus
              id="setupStatus"
              accountSettings={cmpState.accountSettings}
              doneStatus="Complete"
              title="Search"
              tagLine="Find the insights you need by typing just a few keywords."
              parentToChildEvent={cmpState.parentToChildEvent}
              parentCmp={cmp}
            />

            <PsSearchGrid
              view="grid"
              queryFilter={cmpState.queryFilter}
              maxRecords="12"
              childToParent={childToParent}
              setToastState={setToastState}
              parentToChildEvent={cmpState.parentToChildEvent}
              parentCmp={cmp}
            />
          </div>
        </div>

        {cmpState.view === "detail" && (
          <>
            <PsPatternDetailedView
              pattern={cmpState.pattern}
              accountSettings={cmpState.accountSettings}
              childToParent={childToParent}
              setToastState={setToastState}
              parentToChildEvent={cmpState.parentToChildEvent}
              parentCmp={cmp}
            />
          </>
        )}
      </div>
      {toastState.details ? (
        <ToastComponent
          close={() => setToastState(emptyToastState)}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </div>
  );
};

export default SearchPage;
