import { Dropdown, IconSettings } from "@salesforce/design-system-react";

const DropDownMenu = ({ handleAction, itemName }) => {
  let label = "Process Data";
  let value = "supply";

  if (itemName === "link" || itemName === "rightlink") {
    label = "Find Joins";
    value = "conform";
  }

  if (
    itemName === "chains" ||
    itemName === "chain" ||
    itemName === "rightchain"
  ) {
    label = "Find Paths";
    value = "tack";
  }

  return (
    <IconSettings iconPath="/assets/icons">
      <Dropdown
        assistiveText={{ icon: "More Options" }}
        iconCategory="utility"
        iconName="down"
        iconVariant="border-filled"
        onSelect={(selected) => handleAction(selected.value)}
        options={[
          {
            label: label,
            value: value,
            leftIcon: {
              category: "utility",
              name: "right",
            },
          },
        ]}
        width="xx-small"
        align="right"
        menuAlignment="right"
        className="slds-var-m-horizontal_xxx-small"
        alternativeText="Actions"
      />
    </IconSettings>
  );
};

export default DropDownMenu;
