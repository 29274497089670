import { useState, useEffect, useRef } from "react";

import Record from "../../helpers/recordLayer";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { RECORD_COLUMNS, parseResponse } from "./components/Helper";

const PsSearchGrid = (props) => {
  const [cmpState, setCmpState] = useState({
    // <!-- interface -->
    recordLabel: "Pattern", //"Record",
    recordLabelPlural: "Results", //"Records",
    // title:"",
    // header" type="Aura.Component[]"/> <!-- extra content at top-->
    showTitle: false, //true,
    showFooter: true,
    tagLine: "",
    emptyLine: "No results found for the specified criteria", //"Create a new Record",
    recordModule: "relate",
    recordObject: "search",
    gridComponent: "PatternChart", //"",
    gridComponents: [], //NEW
    cardActions: [],
    emptyCallToAction: [],
    gridItems: [],
    viewOptions: [
      { label: "Table", value: "table" },
      { label: "Grid", value: "grid" },
    ],
    changeView: true, // <!-- whether to render radiobuttons that change the default view -->
    showEdit: false, // <!-- whether to show the 'Edit' button -->
    showLoadMore: true, // <!-- whether to show the 'Load More' button -->
    view: props.view, // "table", // <!-- table vs grid --> // TODO: just use props. need to be able to change this from parent
    itemView: "grid", // <!-- send to each grid item -->
    isDragMode: true,
    // footer" type="Aura.Component[]"/> <!-- add modal dialogs and other extra content -->

    draggedStart: null,
    draggedIndex: null,

    // <!-- querying -->
    parentId: "",
    parentPrefix: "",
    // queryFilter:[], //use props
    orderBy: "relevance", //"name",
    orderDirection: "desc", //"asc",
    lastValue: "",
    lastId: "",
    maxRecords: 10, //<!-- number of records to load per API call, set to 0 to load all records -->
    hasMore: true,

    // <!-- records -->
    mode: "empty", //"init",  // <!-- init, empty, view, error -->
    loading: true,
    recordColumns: [],
    recordList: [],
    recordDefaultSortDirection: "asc",

    loadingMore: false,
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();

    // setCmpState({ ...cmpWorking.current });
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }

    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  //this will run on the first page load, because the queryFilter is intially empty and then Search.js processes the searchParams and updates the queryFilter
  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }

    cmpWorking.current = { ...cmpState };
    cmp.handleReset();
  }, [props.queryFilter]);

  const cmp = {
    // --- SearchGridController.js ---

    init: function () {
      PsRecordGrid.setRecordColumns(cmp);
      this.afterScriptsLoaded();
    },

    afterScriptsLoaded: function () {
      var numRecords = cmp.get("maxRecords");
      if (props.queryFilter && Object.keys(props).length > 0) {
        // The list is emptied before each new request:
        cmp.set("recordList", []);

        cmp.getRecords(numRecords);
      }
    },

    // called when refresh button is clicked
    // reload the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      var maxRecords = cmp.get("maxRecords");
      var numRecords = !maxRecords
        ? 0
        : Math.max(cmp.get("recordList").length, maxRecords);
      PsRecordGrid.reset(cmp);
      cmp.getRecords(numRecords);
    },

    //called when queryFilter changes
    handleReset: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.reset(cmp);
      cmp.getRecords(numRecords);
    },

    handleLoadMore: function () {
      var numRecords = cmp.get("maxRecords");
      cmp.getRecords(numRecords);
    },

    handleRecordRowAction: function (row) {
      const action = row.action;
      switch (action.name) {
        case "details":
          this.notifyDataComp(row);
          break;
        default:
          break;
      }
    },

    // --- SearchGridHelper.js ---

    RECORD_COLUMNS: RECORD_COLUMNS,

    parseResponse: (response) => parseResponse(response),

    // fire event for selecting a pattern
    notifyDataComp: function (row) {
      var event = new Event("dataCompEvent");
      event.data = { action: "viewDetails", pattern: row };
      handleEvent(event);
    },

    getRecords: function (numRecords) {
      if (!props.queryFilter || (Object.keys(props).length = 0)) {
        PsRecordGrid.setMode(cmp, "error");
        // setCmpState({ ...cmpWorking.current });
        return;
      }

      // IMPROVEMENT: backend should return no results for empty query
      if (!props.queryFilter.query) {
        cmp.set(
          "emptyLine",
          "Start typing to create the graphs you want to see"
        );
        PsRecordGrid.setMode(cmp, "empty");
        // setCmpState({ ...cmpWorking.current });
        return;
      }

      PsRecordGrid.setLoading(cmp);

      var onSuccess = function (response) {
        var records = cmp.parseResponse(response);
        var recordList = cmp.get("recordList");

        // if there are no further records available, show toast and disable the 'Load More' button
        // if (!records.length || records.length < numRecords) {
        //     if (recordList.length) {
        //         Record.showToast(cmp, 'No Further Records Available', 'You have reached the end.', 'info');
        //     }
        //     cmp.set('hasMore', false);
        // }
        cmp.set("hasMore", false); // IMPROVEMENT: enable pagination for search results in backend

        // set last row for pagination from raw API response (before it is processed by 'parseResponse')
        if (response.length) {
          // set last value and id
          var lastRow = response[response.length - 1];
          var orderBy = cmp.get("orderBy") || "name";
          cmp.set("lastValue", lastRow[orderBy]);
          cmp.set("lastId", lastRow.id);
        }

        // add items
        if (records.length) {
          // append items from records to recordList
          var start = recordList.length;
          recordList.push.apply(recordList, records);
          cmp.set("recordList", recordList);

          // create grid items (NB: these are created now, but initialized when on first render)
          if (cmp.get("gridComponent")) {
            PsRecordGrid.addGridItems(cmp, records, start);
          }
        }

        // set record mode depending on whether any records are loaded
        if (recordList.length) {
          PsRecordGrid.setMode(cmp, "view");
          // At this point, all values are correct and the grid should be rendered, but it doesn't render until I click on in the browser window
        } else {
          cmp.set("emptyLine", "No results found for the specified criteria");
          PsRecordGrid.setMode(cmp, "empty");
        }
        // Async flow. Doesn't work if we have done setCmpState already. cmp reference is broken maybe?
        // setCmpState({ ...cmpWorking.current });
      };

      var onError = function (returnValue) {
        if (returnValue.data.type === "NoSearchMatch") {
          cmp.set(
            "emptyLine",
            "No match for search term. Use the exact name of the data in search queries."
          );
          PsRecordGrid.setMode(cmp, "empty");
        } else {
          PsRecordGrid.setMode(cmp, "error");
        }
        // setCmpState({ ...cmpWorking.current });
      };

      var recordModule = cmp.get("recordModule");
      var recordObject = cmp.get("recordObject");
      var queryFilterLocal = Object.assign({}, props.queryFilter); // copy queryFilter from component to prevent changes
      queryFilterLocal.version = 1;

      // add pagination filters
      if (numRecords > 0) {
        queryFilterLocal.orderBy = cmp.get("orderBy"); // + ' ' + cmp.get('orderDirection').toUpperCase();
        //queryFilter.lastValue = cmp.get('lastValue');
        //queryFilter.lastId = cmp.get('lastId');
        queryFilterLocal.maxRecords = numRecords;
      }

      Record.getRecords(
        recordModule,
        recordObject,
        queryFilterLocal,
        onSuccess,
        onError,
        "PUT"
      );
    },

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    childToParent: (event) => {
      handleEvent(event);
    },

    footer: function () {
      return;
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },
  };

  const handleEvent = (event) => {
    let stopPropagation = false;

    if (!stopPropagation) {
      props.childToParent(event);
    }
  };

  return <>{PsRecordGrid.render(cmp, cmpState)}</>;
};

export default PsSearchGrid;
