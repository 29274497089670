import { useRef, useEffect, useState } from "react";
import {
  Button,
  Card,
  ProgressBar,
  Spinner,
} from "@salesforce/design-system-react";
import { useNavigate } from "react-router";

import "./PsSetupStatus.css";
import Record from "../../helpers/recordLayer";

const PsSetupStatus = (props) => {
  const [cmpState, setCmpState] = useState({
    accountSettings: {},

    setupDone: props.setupDone || true,

    setupStatus: "",
    tile: false,
    loading: true,
    progress: 0,
  });

  const cmpWorking = useRef({});

  const navigate = useNavigate();

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent.action]);

  const cmp = {
    // --- SetupStatusController.js ---
    init: function () {
      try {
        cmp.checkSetupStatus();
      } catch (err) {
        console.error(err.stack);
      }
    },

    refreshClick: function () {
      try {
        cmp.checkSetupStatus();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRefreshEvent: function () {
      try {
        if (!cmp.get("setupDone")) {
          cmp.checkSetupStatus();
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    exploreClick: function () {
      try {
        cmp.navigateToTab("Explore");
      } catch (err) {
        console.error(err.stack);
      }
    },

    searchClick: function () {
      try {
        cmp.navigateToTab("Search");
      } catch (err) {
        console.error(err.stack);
      }
    },

    dataManagementClick: function () {
      try {
        cmp.navigateToTab("DataManagement");
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- SetupStatusHelper.js ---

    checkSetupStatus: async function () {
      try {
        cmp.set("loading", true);
        var doneStatus = props.doneStatus;
        var accountSettings = await Record.getAccountSettings(cmp, doneStatus);
        accountSettings = { ...accountSettings, doneStatus };

        if (!accountSettings || Object.keys(accountSettings).length === 0) {
          accountSettings.status = "Error";
          accountSettings.progress = 0;
          accountSettings.done = false;
        }

        var setupStatus = accountSettings.status;

        if (
          ![
            "Authenticated",
            "Inventory Loaded",
            "Processing",
            "Onboarding",
            "Complete",
          ].includes(setupStatus)
        ) {
          setupStatus = "Error";
        }
        cmp.set("setupStatus", setupStatus);
        cmp.set("progress", accountSettings.progress);
        cmp.set("setupDone", accountSettings.done);

        // update ParentCmpState:
        if (props.setupDone !== undefined) {
          props.parentCmp.set("setupDone", accountSettings.done);
        }
        if (props.accountSettings !== undefined) {
          props.parentCmp.set("accountSettings", accountSettings);
        }

        cmp.set("accountSettings", accountSettings);
        cmp.set("loading", false);
      } catch (error) {
        console.error(error.stack);
        cmp.set("loading", false);
      }
    },

    navigateToTab: function (tabName) {
      navigate(`/${tabName}`);
    },

    // ---------- cmp get & set ----------

    get: (key) => {
      if (props.hasOwnProperty(key)) return props[key];

      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    // --- New functions ---
    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleRefreshEvent();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },
  };

  return (
    <div
      style={{
        marginBottom:
          props.title === "Data Management" &&
          cmpState.setupStatus !== "Error" &&
          !cmpState.setupDone
            ? "1em"
            : "",
      }}
    >
      {(cmpState.tile || (!cmpState.setupDone && cmpState.setupStatus)) && (
        <div>
          {/* <!-- can't set backdrop at root level, because background should not be black on initial load in spinner below --> */}
          <div
            className={`${
              cmpState.tile ||
              (props.title === "Data Management" &&
                cmpState.setupStatus !== "Error")
                ? ""
                : "setup-backdrop"
            }`}
          >
            <div
              className={`${
                cmpState.tile ||
                (props.title === "Data Management" &&
                  cmpState.setupStatus !== "Error")
                  ? ""
                  : "setup-modal"
              }`}
            >
              <Card
                heading={<b>{props.title}</b>}
                footer={
                  <div>
                    {/* <!-- button --> */}
                    {(cmpState.setupStatus === "Authenticated" ||
                      cmpState.setupStatus === "Inventory Loaded") && (
                      <button
                        className="slds-button slds-button_brand"
                        onClick={() => cmp.dataManagementClick()}
                      >
                        Manage Data
                      </button>
                    )}
                    {cmpState.setupStatus === "Complete" && (
                      <>
                        <button
                          className="slds-button slds-button_brand"
                          onClick={() => cmp.exploreClick()}
                        >
                          Start Exploring
                        </button>
                        <button
                          className="slds-button slds-button_brand"
                          onClick={() => cmp.searchClick()}
                        >
                          Search Now
                        </button>
                      </>
                    )}
                    {cmpState.setupStatus === "Onboarding" && (
                      <a
                        className="slds-button slds-button_brand"
                        href="https://calendly.com/point-sigma/call"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Book Onboarding Call
                      </a>
                    )}
                    {cmpState.setupStatus === "Error" && (
                      <a
                        className="slds-button slds-button_brand"
                        href="mailto:support@point-sigma.com"
                      >
                        Email Support
                      </a>
                    )}

                    <Button
                      label="Check Status"
                      variant="outline-brand"
                      onClick={() => cmp.refreshClick()}
                    />
                  </div>
                }
              >
                <div className="slds-p-around_medium">
                  <div
                    className={`${
                      cmpState.tile
                        ? "progress-text-left"
                        : "progress-text-center"
                    }`}
                  >
                    {props.tagLine}
                  </div>

                  {/* <!-- image --> */}
                  {!cmpState.tile && (
                    <div
                      className="slds-p-around_medium slds-illustration slds-illustration_large setup-image"
                      aria-hidden="true"
                    >
                      {(cmpState.setupStatus === "Authenticated" ||
                        cmpState.setupStatus === "Inventory Loaded") && (
                        <img
                          src="assets/icons/setup/setupconnect.png"
                          alt="setupconnect"
                        />
                      )}
                      {cmpState.setupStatus === "Processing" && (
                        <img
                          src="assets/icons/setup/setupprocessing.gif"
                          alt="setupprocessing"
                        />
                      )}

                      {(cmpState.setupStatus === "Onboarding" ||
                        cmpState.setupStatus === "Complete") && (
                        <img
                          src="assets/icons/setup/setupdone.png"
                          alt="setupdone"
                        />
                      )}

                      {cmpState.setupStatus === "Error" && (
                        <img
                          src="assets/icons/setup/setuperror.png"
                          alt="setuperror"
                        />
                      )}
                    </div>
                  )}

                  {/* <!-- progress bar --> */}
                  {cmpState.setupStatus === "Processing" && (
                    <div className="progress-center slds-p-bottom_small">
                      <div className="slds-text-color_weak">
                        <ProgressBar
                          value={cmpState.progress}
                          variant="circular"
                          size="large"
                        />
                      </div>
                    </div>
                  )}

                  {/* <!-- progress text --> */}
                  <div
                    className={
                      cmpState.tile
                        ? "progress-text-left"
                        : "progress-text-center"
                    }
                  >
                    {/* <!-- NB; this entire component is not shown at all in the 'Setup' tab--> */}
                    {cmpState.setupStatus === "Authenticated" && (
                      <>
                        Connect your data sources in the{" "}
                        <a onClick={() => cmp.dataManagementClick()}>
                          Data Management
                        </a>{" "}
                        tab.{" "}
                      </>
                    )}
                    {cmpState.setupStatus === "Inventory Loaded" && (
                      <>
                        Review the data inventory and continue to load data in
                        the{" "}
                        <a onClick={() => cmp.dataManagementClick()}>
                          Data Management
                        </a>{" "}
                        tab.
                      </>
                    )}
                    {cmpState.setupStatus === "Processing" && (
                      <>
                        Your data is being processed and scanned for patterns,
                        this may take a few moments.
                      </>
                    )}
                    {cmpState.setupStatus === "Onboarding" && (
                      <>
                        {" "}
                        Schedule your onboarding call by contacting us on
                        <a
                          href="mailto:onboarding@point-sigma.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          onboarding@point-sigma.com
                        </a>{" "}
                        or book online using the button below.
                      </>
                    )}
                    {cmpState.setupStatus === "Complete" && (
                      <>
                        {" "}
                        Discover insights in the{" "}
                        <a onClick={() => cmp.exploreClick()}>
                          Explore
                        </a> and <a onClick={() => cmp.searchClick()}>Search</a>{" "}
                        tabs. <br />
                        <br />
                        Point Sigma will continue to scan for more patterns, and
                        store new results when available.
                      </>
                    )}
                    {cmpState.setupStatus === "Error" && (
                      <>
                        An error occurred, please contact Point Sigma support on{" "}
                        <a
                          href="mailto:support@point-sigma.com"
                          target="_blank"
                        >
                          support@point-sigma.com
                        </a>
                        .
                      </>
                    )}
                  </div>
                </div>

                {/* <!-- spinner on top of modal --> */}
                {cmpState.loading && (
                  <Spinner assistiveText={{ label: "Loading" }} />
                )}
              </Card>
            </div>
          </div>
        </div>
      )}
      {/* <!-- initial loading spinner --> */}
      {!cmpState.setupStatus && props.showSpinner && cmpState.loading && (
        <Spinner assistiveText={{ label: "Loading" }} />
      )}
    </div>
  );
};

export default PsSetupStatus;
