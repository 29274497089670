import { useState, useEffect, useRef } from "react";
import Input from "@salesforce/design-system-react/components/input";

import PsNavigationTree from "../ps-navigation-tree/PsNavigationTree";
import Record from "../../helpers/recordLayer";
import SearchIcon from "../ui/SearchIcon";

const PsNavigationInput = (props) => {
  const [cmpState, setCmpState] = useState({
    searchText: "",
    isExpanded: props.isExpanded || false,
    navigationLoading: false,
    placeholder: "Search...",
    validity: {},
    object: props.object,
    selected: props.selected,
  });

  const cmpWorking = useRef({});
  const searchFieldRef = useRef(null);
  const navTreeRef = useRef(null);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    afterScriptsLoaded();
  }, []);

  useEffect(() => {
    if (cmpState.isExpanded && searchFieldRef.current) {
      handleSearchBlur();
    }

    // This process is done only during the first load:
    if (props.selected && !cmpWorking.current.selected) {
      cmpWorking.current.selected = props.selected;
      setCmpState((prev) => ({
        ...prev,
        selected: props.selected,
      }));
    }

    // TODO Can be made more general later
    if (props.setParentCmpState && !props.isExpanded) {
      cmp.set("isExpanded", false);
    }
  }, [props]);

  useEffect(() => {
    if (cmpState.isExpanded && searchFieldRef.current) {
      searchFieldRef.current.focus();
    }
  }, [cmpState.isExpanded]);

  const cmp = {
    name: "NavigationInput", //Trying to undo parent specific code in PsNavigationTree

    get: (key) => {
      if (props[key]) return props[key];

      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },
  };

  const checkValidity = () => {
    var valid = !props.required || props.record;
    if (valid) {
      cmpWorking.current.validity = { valid: true };
    } else {
      cmpWorking.current.validity = { valid: false, valueMissing: true };
    }
    return valid;
  };

  const afterScriptsLoaded = () => {
    try {
      checkValidity();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const focusSearch = () => {
    cmpWorking.current.isExpanded = true;
    cmpWorking.current.searchText = "";

    // TODO Can be made more general later
    if (props.setFilterState) {
      props.setFilterState((prev) => ({ ...prev, activeField: props.label }));
    }

    if (props.setParentCmpState) {
      props.setParentCmpState((prev) => ({
        ...prev,
        activeField: props.label,
      }));
    }

    setCmpState(cmpWorking.current);

    // set focus after the new search field is rendered
    // var timer = setTimeout(function () {
    //   $A.getCallback(function () {
    //     var inputs = [].concat(cmp.find("searchField") || []);
    //     inputs.forEach((input) => {
    //       input.focus();
    //     });
    //   })();
    // }, 0);
    if (searchFieldRef.current) {
      searchFieldRef.current.focus();
    }
  };

  const handleSearchClick = () => {
    try {
      focusSearch();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleSearchBlur = () => {
    if (!props.activeField) {
      cmp.set("isExpanded", false);
    }
  };

  const handleNavigationEvent = (event) => {
    try {
      // event.stopPropagation();
      var source = event.source;

      // scroll only
      if (["change", "closeSearch"].includes(source)) {
        var scroll = event.scroll;
        var scrollers = [].concat(navTreeRef.current || []);

        const searchInput = searchFieldRef.current;
        if (searchInput && scrollers && scroll != null) {
          scrollers.forEach((scroller) => {
            var timer = setTimeout(() => {
              var top = searchInput.offsetTop + searchInput.offsetHeight;
              scroller.scrollTop = scroll * (scroller.scrollHeight - top);
            }, 0);
          });
        }
      }

      // set selected and record, and copy breadcrumb to record title field
      if (source === "tree") {
        var object = event.obj;

        if (
          object === props.object // cmpWorking.current.object
        ) {
          var item = Record.itemFromEvent(event, {});
          var record = Object.assign({}, event.record || {}, {
            title: item.title,
          });

          cmpWorking.current = {
            ...cmpWorking.current,
            selected: item.name,
            value: record.id,
            // record,
            isExpanded: false,
          };
          setCmpState(cmpWorking.current);
          if (props.setFilterState) {
            props.setFilterState((prev) => ({
              ...prev,
              record: record,
              selected: item.name,
            }));
          }

          // TODO Can be made more general later
          if (props.object === "dataType") {
            props.setParentCmpState((prev) => ({
              ...prev,
              record: {
                ...prev.record,
                leftKey: record,
                leftKeySelected: item.name,
              },
            }));
          }

          if (
            props.object === "key" &&
            (props.label === "From Field" || props.label === "To Field")
          ) {
            props.setParentCmpState((prev) => ({
              ...prev,
              record: {
                ...prev.record,
                dataType: record,
                dataTypeSelected: item.name,
              },
            }));
          }

          if (props.onChange) {
            //TODO   Can be made more general later
            if (props.object === "dataType") {
              props.onChange("dataTypeId", record);
            } else {
              props.onChange(record);
            }

            //   $A.enqueueAction(onchange);
          } // IMPROVEMENT: this doesn't set the event context; see https://developer.salesforce.com/docs/atlas.en-us.lightning.meta/lightning/ref_attr_types_aura_action.htm for suggestions on how to improve
        } else {
          // clear search and focus
          focusSearch();
        }
      }
      checkValidity();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleSearchTextChange = (e) => {
    setCmpState((prev) => ({
      ...prev,
      navigationLoading: true,
      searchText: e.target.value,
    }));
  };

  const handleSearchTextClick = () => {
    // TODO Can be made more generic
    if (props.setFilterState) {
      props.setFilterState((prev) => ({
        ...prev,
        activeField: props.label,
      }));
    }
    props.setParentCmpState((prev) => ({
      ...prev,
      activeField: props.label,
    }));
  };

  const hasRecord = props.record && Object.keys(props.record).length !== 0;

  return (
    <div
      className="slds-form-element slds-form-element_stacked"
      style={{ marginBottom: "0" }}
    >
      {(!props.showRequiredFieldError || cmpState.isExpanded) && (
        <label className="slds-form-element__label">
          {props.required && (
            <abbr title="required" className="slds-required">
              *
            </abbr>
          )}
          {props.label}
        </label>
      )}

      {cmpState.isExpanded ? (
        <>
          <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left-right slds-input-has-icon_group-right">
            <SearchIcon />
            <div className="slds-form-element">
              <div className="slds-form-element__control">
                <input
                  ref={searchFieldRef}
                  type="text"
                  id="searchField"
                  placeholder={cmpState.placeholder}
                  required={props.required}
                  className="slds-input"
                  onChange={(e) => handleSearchTextChange(e)}
                  onClick={() => handleSearchTextClick()}
                  variant="base"
                  autoComplete="off"
                  value={cmpState.searchText}
                />
              </div>
            </div>
            <div className="slds-input__icon-group slds-input__icon-group_right">
              {cmpState.navigationLoading ? (
                <div
                  role="status"
                  className="slds-spinner slds-spinner_brand slds-spinner_x-small slds-input__spinner"
                >
                  <span className="slds-assistive-text">Loading</span>
                  <div className="slds-spinner__dot-a"></div>
                  <div className="slds-spinner__dot-b"></div>
                </div>
              ) : null}
              {cmpState.searchText ? (
                <button
                  className="slds-button slds-button_icon slds-input__icon slds-input__icon_right"
                  title="Clear"
                  onClick={() =>
                    setCmpState((prev) => ({
                      ...prev,
                      searchText: "",
                    }))
                  }
                >
                  <svg
                    className="slds-button__icon slds-icon-text-light"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clear"></use>
                  </svg>
                  <span className="slds-assistive-text">Clear</span>
                </button>
              ) : null}
            </div>
            {props.activeField === props.label && (
              <div
                className="dropdownwrapper"
                style={{
                  position: "relative",
                }}
              >
                <div
                  className="slds-box slds-theme_default dropdown"
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    zIndex: 999,
                    minWidth: "100%",
                    width: "auto",
                  }}
                >
                  <div
                    ref={navTreeRef}
                    id="dropdownscroll"
                    className="slds-p-around_small slds-scrollable"
                    style={{ maxHeight: "40vh", overflowY: "visible" }}
                  >
                    <PsNavigationTree
                      multiSelect={false}
                      sections={props.sections}
                      selected={cmpState.selected}
                      searchText={cmpState.searchText}
                      filters={props.filters}
                      navigationLoading={cmpState.navigationLoading}
                      parentCmp={cmp}
                      childToParent={handleNavigationEvent} //TODO use consistent approach for passing events
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="backdrop" onClick={handleSearchBlur}></div>
        </>
      ) : (
        <>
          {/* selected */}
          {hasRecord && (
            <div title={props.record.title}>
              <Input
                label={
                  !props.filterSetState?.showRequiredFieldError || !hasRecord
                    ? ""
                    : props.label
                }
                id="searchField"
                variant="base"
                autoComplete="off"
                type="text"
                value={props.record.name || ""}
                title={props.record.title}
                onFocus={handleSearchClick}
                disabled={props.disabled}
                required={props.required}
              />
            </div>
          )}
          {!hasRecord && (
            <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left-right slds-input-has-icon_group-right">
              <SearchIcon />
              <Input
                label={
                  (!props.filterSetState?.showRequiredFieldError ||
                    hasRecord) &&
                  !cmpState.isExpanded
                    ? ""
                    : props.label
                }
                id="searchField"
                variant="base"
                autoComplete="off"
                type="search"
                placeholder={cmpState.placeholder}
                required={props.required}
                onFocus={handleSearchClick}
                disabled={props.disabled}
                errorText={
                  props.showRequiredFieldError ? "Complete this field." : null
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PsNavigationInput;
