import { useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  IconSettings,
} from "@salesforce/design-system-react";
import { format } from "date-fns";
import Combobox from "@salesforce/design-system-react/components/combobox";

import "./PsObject.css";
import PsRecord from "../ps-record/PsRecord";
import Record from "../../helpers/recordLayer";
import ProgressBarComponent from "../ui/ProgressBarComponent";
import EditButtonIcon from "../ui/EditButtonIcon";
import CheckAndCloseIcons from "../ps-key/components/CheckAndCloseIcons";
import Modal from "../ui/Modal";
import DualListBox from "../ui/DualListBox";
import Restriction from "../../helpers/restriction";
import { formattedNumber, toastDetails } from "../../helpers";

const PsObject = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Object",
    recordModule: "pump",
    recordObject: "object",
    showDelete: true,

    ////
    loading: false,
    mode: "init",
    showEdit: true,
    showCardActions: true,

    record: {
      supportsSchedule: false,
      sourceIdentifier: "",
      restrictionLabels: "",
      sourceExists: false,
      connectorId: "",
      status: "",
      restrictionValues: [],
      restrictionRequired: [],
      runStatus: "",
    },

    showClearConfirmDialog: false,
    showDeleteConfirmDialog: false,
    clearConfirmation: false,
    deleteConfirmation: false,
    selectedValue: null,
    restrictionOptions: [],
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded(cmp);
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.handleReset();
  }, [props.recordId, props.parentId]);

  const cmp = {
    // --- ObjectController.js ---

    // init: function (cmp, event, helper) {},

    afterScriptsLoaded: function () {
      try {
        // if (!cmp.get("v.restrictionLoaded")) {
        //   return;
        // }
        cmp.init();
      } catch (err) {
        console.error(err.stack);
      }
    },

    afterRestrictionLoaded: function (cmp, event, helper) {
      try {
        cmp.set("v.restrictionLoaded", true);
        if (!cmp.get("v.scriptsLoaded")) {
          return;
        }
        helper.init(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleReload: function () {
      try {
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRefresh: function () {
      try {
        var refreshEvent = { type: "reload" };
        refreshEvent.data = { action: "reload", origin: "Object" };

        props.childToParent(refreshEvent);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleEdit: function () {
      try {
        PsRecord.setMode(cmp, "edit");
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleCancel: function () {
      try {
        PsRecord.cancelRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleSubmit: function () {
      try {
        PsRecord.submitRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleDelete: function () {
      try {
        var record = cmp.get("record") || {};
        if (!record.sourceExists || record.type === "CSV File") {
          // delete Object, then run store.supply
          var callback = function (success) {
            if (success) {
              cmp.actionSupply();
            }
          };
          PsRecord.deleteRecord(cmp, callback); // NB: this navigates to parent record after successfull delete
        } else {
          cmp.setToastState(
            "warning",
            "Not Available",
            'Objects can only be deleted when they are no longer present in the source.\nInstead, use the "Clear" action to remove the Object\'s data and set the Status to "Excluded" to stop loading new data.'
          );
        }
        return;
      } catch (err) {
        console.error(err.stack);
      } finally {
        cmp.set("deleteConfirmation", false);
      }
    },

    selectRestrictions: function () {
      try {
        // var options = event.getParam("value");
        // Record.setSelectedLabels(
        //   cmp,
        //   "v.restrictionOptions",
        //   options,
        //   "v.record.restrictionLabels"
        // );
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleLoad: function () {
      try {
        cmp.actionLoad(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleAction: function (action) {
      try {
        switch (action) {
          case "sync":
            cmp.actionSync();
            break;
          case "load":
            cmp.actionLoad();
            break;
          case "clear":
            cmp.actionClear();
            break;
          case "exclude-clear":
            cmp.actionExcludeClear();
            break;
          default:
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- ObjectHelper.js ---

    parseInputPlainText: function (record) {
      return (({ id, status }) => ({ id, status }))(record);
    },

    getDefaultRecord: function () {
      return {};
    },

    parseResponse: function (response) {
      // for status and setup section on Object page
      response.forEach((obj) => {
        obj.supportsCredentials =
          obj.connector.connectorType.supportedMethods.includes("Credentials");
        obj.supportsSchedule =
          obj.connector.connectorType.supportedMethods.includes("Schedule");
        obj.supportsUpload =
          obj.connector.connectorType.supportedMethods.includes("Upload");
      });

      return response.map(
        ({
          id,
          name,
          type,
          status,
          restrictions,
          firstRunOn,
          lastRunOn,
          sourceExists,
          sourceIdentifier,
          primaryKey,
          changeTracker,
          totalRecords,
          connector,
          supportsSchedule,
          supportsUpload,
          supportsCredentials,
        }) => ({
          id,
          name,
          type,
          status,
          restrictions,
          firstRunOn,
          lastRunOn,
          sourceExists,
          sourceIdentifier,
          primaryKey,
          changeTracker,
          totalRecords,
          connectorId: connector.id,
          runStatus: connector.runStatus,
          setupStatus: connector.setupStatus,
          supportsSchedule,
          supportsUpload,
          supportsCredentials,
        })
      );
    },

    updateUI: function () {
      var record = cmp.get("record");
      var restrictions = record.restrictions || [];
      Restriction.update(cmp, record, restrictions);
    },

    // update restrictions
    postSubmit: function (record) {
      Restriction.merge(cmp, "objectId", record);
    },

    init: function () {
      cmp.set("statusOptions", Object.values(Record.FIELD_STATUS));
      Restriction.loadRestrictionTypes(cmp);
      PsRecord.getRecord(cmp);
    },

    actionSync: function () {
      try {
        var previousMode = cmp.get("mode");
        PsRecord.setLoading(cmp);
        var objectId = cmp.get("recordId");
        const record = cmp.get("record");
        var connectorId = record.connectorId;
        var onError = function (response) {
          cmp.setToastState("error", "Error", toastDetails(response));
          PsRecord.setMode(cmp, previousMode);
        };

        var onSucces = function () {
          cmp.setToastState(
            "info",
            "Connector Started",
            "Object inventory data loading started"
          );

          // cmp.set("v.record.runStatus", "Running"); // doesn't get saved, but enables the 'Running' message box
          cmp.set("record", { ...record, runStatus: "Running" });
          PsRecord.setMode(cmp, previousMode);
        };
        Record.doAction(
          "pump",
          "connector",
          "sync",
          { id: connectorId, objectId },
          onSucces,
          onError
        );
      } catch (error) {
        console.error(error.stack);
      }
    },

    actionLoad: function () {
      try {
        var previousMode = cmp.get("mode");
        PsRecord.setLoading(cmp);
        var objectId = cmp.get("recordId");

        const record = cmp.get("record");
        var connectorId = record.connectorId;
        var onError = function (response) {
          cmp.setToastState("error", "Error", toastDetails(response));
          PsRecord.setMode(cmp, previousMode);
        };
        var onSucces = function () {
          cmp.setToastState(
            "info",
            "Connector Started",
            "Object data loading started"
          );

          // cmp.set("v.record.runStatus", "Running"); // doesn't get saved, but enables the 'Running' message box
          cmp.set("record", { ...record, runStatus: "Running" });
          PsRecord.setMode(cmp, previousMode);
        };
        Record.doAction(
          "pump",
          "connector",
          "load",
          { id: connectorId, objectId },
          onSucces,
          onError
        );
      } catch (error) {
        console.error(error.stack);
      }
    },

    actionClear: function () {
      try {
        var previousMode = cmp.get("mode");
        PsRecord.setLoading(cmp);
        var objectId = cmp.get("recordId");
        var record = cmp.get("record");
        var connectorId = record.connectorId;

        var onError = function (response) {
          cmp.setToastState("error", "Error", toastDetails(response));
          PsRecord.setMode(cmp, previousMode);
        };
        var onSucces = function () {
          cmp.setToastState(
            "info",
            "Connector Started",
            "Object clearing started"
          );

          // cmp.set("v.record.runStatus", "Running"); // doesn't get saved, but enables the 'Running' message box
          cmp.set("record", { ...record, runStatus: "Running" });
          PsRecord.setMode(cmp, previousMode);
        };
        Record.doAction(
          "pump",
          "connector",
          "clear",
          { id: connectorId, objectId },
          onSucces,
          onError
        );
      } catch (error) {
        console.error(error.stack);
      }
    },

    actionExcludeClear: function () {
      try {
        // confirmation
        if (!cmp.get("clearConfirmation")) {
          cmp.set("showClearConfirmDialog", true);
          return;
        }

        var self = this;
        var record = cmp.get("record");
        var callback = function (success) {
          if (success) {
            // cmp.set('v.record.runStatus', 'Running'); // doesn't get saved, but enables the 'Running' message box
            cmp.set("record", { ...record, runStatus: "Running" });
            self.actionClear(cmp);
          }
        };
        record.status = Record.OBJECT_STATUS.EXCLUDED.value;
        PsRecord.submitRecord(cmp, callback);
      } catch (error) {
        console.error(error.stack);
      } finally {
        cmp.set("clearConfirmation", false);
      }
    },

    actionSupply: function () {
      try {
        var previousMode = cmp.get("mode");
        PsRecord.setLoading(cmp);
        // var containerId = cmp.get('v.recordId');
        var onError = function (response) {
          cmp.setToastState("error", "Error", toastDetails(response));
          PsRecord.setMode(cmp, previousMode);
        };
        var onSucces = function () {
          // IMPROVEMENT: callback for doAction is never called (maybe because the component is already refreshed before this returns?)
          cmp.setToastState(
            "info",
            "Data Processing Started",
            "Started processing data, this may take a few moments"
          );

          PsRecord.setMode(cmp, previousMode);
        };
        Record.doAction("store", "data", "supply", {}, onSucces, onError);
      } catch (error) {
        console.error(error.stack);
      }
    },

    setParent: function (record) {
      var parentId = record.connectorId || (record.connector || {}).id;
      cmp.set("parentId", parentId);
      return parentId;
    },

    // --- New functions ---

    get: (key) => {
      if (props.hasOwnProperty(key)) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    // notifyNavigation: function (parentId, module, object, id) {
    //   let event = new Event("navigation");
    //   event.parentId = parentId;
    //   event.module = module;
    //   event.obj = object;
    //   event.id = id;
    //   event.source = "grid";

    //   cmp.handleEvent(event);
    // },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    handleReset: function () {
      try {
        PsRecord.getRecord(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    cardActions: function () {
      const disabled = cmpState.record.runStatus === "Running";
      return (
        <>
          {cmpState.mode === "view" && (
            <IconSettings iconPath="/assets/icons">
              <Dropdown
                assistiveText={{ icon: "More Options" }}
                iconCategory="utility"
                iconName="down"
                iconVariant="border-filled"
                onSelect={(selected) => cmp.handleAction(selected.value)}
                options={[
                  {
                    label: "Get Inventory",
                    value: "sync",
                    leftIcon: {
                      category: "utility",
                      name: "sync",
                    },
                    disabled: disabled,
                  },
                  {
                    label: "Load Data",
                    value: "load",
                    leftIcon: {
                      category: "utility",
                      name: "download",
                    },
                    disabled: disabled,
                  },
                  {
                    label: "Exclude and Clear",
                    value: "exclude-clear",
                    leftIcon: {
                      category: "utility",
                      name: "clear",
                    },
                    disabled: disabled,
                  },
                ]}
                width="xx-small"
                align="right"
                menuAlignment="right"
                className="slds-var-m-horizontal_xxx-small"
                alternativeText="Actions"
              />
            </IconSettings>
          )}
        </>
      );
    },

    cancelClearRecord: function () {
      cmp.set("clearConfirmation", false);
      cmp.set("showClearConfirmDialog", false);
    },

    confirmClearRecord: function () {
      cmp.set("clearConfirmation", true);
      cmp.set("showClearConfirmDialog", false);
      cmp.actionExcludeClear();
    },

    cancelDeleteRecord: function () {
      cmp.set("deleteConfirmation", false);
      cmp.set("showDeleteConfirmDialog", false);
    },

    confirmDeleteRecord: function () {
      cmp.set("deleteConfirmation", true);
      cmp.set("showDeleteConfirmDialog", false);
      cmp.handleDelete();
    },

    handleSelectStatus: function (event, data) {
      if (data.selection.length === 0) {
        return;
      }

      let record = cmp.get("record");
      record = { ...record, status: data.selection[0].value };
      cmp.set("record", record);
    },

    handleRestrictionValuesChange: function (value, process) {
      try {
        const record = cmp.get("record");
        const restrictionValues = record.restrictionValues;

        let updatedRestrictionValues;
        if (process === "optionsToSelected") {
          updatedRestrictionValues =
            restrictionValues && restrictionValues.includes(value)
              ? restrictionValues
              : [...(restrictionValues || []), value];
        } else {
          updatedRestrictionValues =
            restrictionValues && restrictionValues.length > 0
              ? restrictionValues.filter((item) => item !== value)
              : [];
        }
        cmp.set("record", {
          ...record,
          restrictionValues: updatedRestrictionValues,
        });
        cmp.set("selectedValue", null);
      } catch (error) {
        console.error(error.stack);
      }
    },

    handleSelectedValueUpOrDown: function (process) {
      //   try {
      //     const record = cmp.get("record");
      //     const restrictionValues = record.restrictionValues;
      //     const selectedValue = cmp.get("selectedValue");
      //     const currentIndex = restrictionValues.indexOf(selectedValue);
      //     if (currentIndex === -1) {
      //       return;
      //     }
      //     const updatedRestrictionValues = [...restrictionValues];
      //     if (process === "up" && currentIndex > 0) {
      //       const temp = updatedRestrictionValues[currentIndex];
      //       updatedRestrictionValues[currentIndex] =
      //         updatedRestrictionValues[currentIndex - 1];
      //       updatedRestrictionValues[currentIndex - 1] = temp;
      //     } else if (
      //       process === "down" &&
      //       currentIndex < updatedRestrictionValues.length - 1
      //     ) {
      //       const temp = updatedRestrictionValues[currentIndex];
      //       updatedRestrictionValues[currentIndex] =
      //         updatedRestrictionValues[currentIndex + 1];
      //       updatedRestrictionValues[currentIndex + 1] = temp;
      //     }
      //     cmp.set("record", {
      //       ...record,
      //       restrictionValues: updatedRestrictionValues,
      //     });
      //   } catch (error) {
      //     console.error(error.stack);
      //   }
    },

    handleChangeSelectedValue: function (selected) {
      cmp.set("selectedValue", selected);
    },

    body: function () {
      // Combobox gives warning without id
      const statusOptionsWithId =
        cmpState.statusOptions && cmpState.statusOptions.length > 0
          ? cmpState.statusOptions.map((item) => ({
              ...item,
              id: item.label,
            }))
          : cmpState.statusOptions;

      return (
        <div className="slds-form slds-var-m-around_medium" role="list">
          {/* <!-- Connector Running --> */}
          {cmpState.mode === "view" &&
            cmpState.record.runStatus === "Running" && (
              <>
                <h3 className="slds-section-title--divider slds-m-top_medium">
                  Connector Running
                </h3>
                <div className="message">
                  <p>The Connector is running.</p>
                  <p>
                    Use the "Check Status" or the refresh buttons to check
                    progress.
                  </p>
                  <Button
                    className="slds-m-vertical_small"
                    disabled={cmpState.loading}
                    label="Check Status"
                    title="Check Status"
                    onClick={cmp.handleRefresh}
                    variant="outline-brand"
                  />
                </div>
              </>
            )}

          {/* <!-- Load button--> */}
          {cmpState.mode === "view" &&
            cmpState.record.runStatus !== "Running" &&
            cmpState.mode === "view" &&
            !cmpState.record.totalRecords && (
              <>
                <h3 className="slds-section-title--divider slds-m-top_medium">
                  Next Steps
                </h3>
                <div className="message">
                  {cmpState.record.supportsSchedule && (
                    <p>
                      Review Fields to inlude or exclude. Set a download
                      schedule on the Connector, or start loading data for this
                      Object only.
                    </p>
                  )}
                  {!cmpState.record.supportsSchedule && (
                    <p>
                      Review Fields to inlude or exclude, and start loading data
                      for this Object only.
                    </p>
                  )}
                  <Button
                    className="slds-m-vertical_small"
                    disabled={cmpState.loading}
                    label="Load Data"
                    title="Load data from the Source"
                    onClick={cmp.handleLoad}
                    variant="outline-brand"
                  />
                </div>
              </>
            )}

          <h3 className="slds-section-title--divider slds-m-top_medium">
            Object Details
          </h3>

          <div className="slds-form__row">
            {/* <!-- Name --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {(cmpState.mode === "view" ||
                  cmpState.mode === "edit" ||
                  cmpState.mode === "new") && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Name</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.name}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <!-- Status --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">Status</span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.status}
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon handleEdit={cmp.handleEdit} />
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <Combobox
                    events={{
                      onSelect: (event, data) =>
                        cmp.handleSelectStatus(event, data),
                    }}
                    labels={{
                      label: "Status",
                      placeholder: "--Please Select--",
                    }}
                    menuPosition="relative"
                    options={statusOptionsWithId}
                    selection={[
                      statusOptionsWithId.find(
                        (option) => option.value === cmpState.record.status
                      ),
                    ]}
                    value={cmpState.record.status}
                    variant="readonly"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="slds-form__row">
            {/* <!-- Source Identifier --> */}
            {cmpState.mode !== "edit" && (
              <div className="slds-form__item" role="listitem">
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}
                  {cmpState.mode === "view" && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">
                        Source Identifier
                      </span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {cmpState.record.sourceIdentifier}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* <!-- Restrictions --> */}
            <div className="slds-form__item" role="listitem">
              <div className="slds-form-element slds-form-element_stacked">
                {cmpState.mode === "init" && <ProgressBarComponent />}
                {cmpState.mode === "view" && (
                  <div
                    id="FormDiv"
                    className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                  >
                    <span className="slds-form-element__label">
                      Restrictions
                    </span>
                    <div className="slds-form-element__control">
                      <div className="slds-form-element__static">
                        {cmpState.record.restrictionLabels}
                      </div>
                      <div className="slds-button slds-button__icon slds-button__icon_hint">
                        <EditButtonIcon handleEdit={cmp.handleEdit} />
                      </div>
                    </div>
                  </div>
                )}
                {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                  <>
                    {/* <lightning:dualListbox name="restrictions" label="Restrictions" options="{!v.restrictionOptions}" 
                    disableReordering="true" sourceLabel="Available" selectedLabel="Selected" value="{!v.record.restrictionValues}" 
                    requiredOptions="{!v.record.restrictionRequired}" onchange="{!c.selectRestrictions}" /> */}
                    <DualListBox
                      handleSelectedValuesChange={
                        cmp.handleRestrictionValuesChange
                      }
                      handleChangeSelectedValue={cmp.handleChangeSelectedValue}
                      handleSelectedValueUpOrDown={
                        cmp.handleSelectedValueUpOrDown
                      }
                      label="Restrictions"
                      options={cmpState.restrictionOptions}
                      disableReordering={true}
                      sourceLabel="Available"
                      selectedLabel="Selected"
                      value={cmpState.record.restrictionValues}
                      requiredOptions={cmpState.record.restrictionRequired}
                      fieldLevelHelp=""
                      valuesOptions={
                        cmpState.record.restrictionValues &&
                        cmpState.record.restrictionValues.length > 0
                          ? cmpState.restrictionOptions.filter(
                              (option) =>
                                !cmpState.record.restrictionValues.includes(
                                  option.value
                                )
                            )
                          : cmpState.restrictionOptions
                      }
                      selectedOptions={
                        cmpState.record.restrictionValues &&
                        cmpState.record.restrictionValues.length > 0
                          ? cmpState.record.restrictionValues
                              .map((selectedValue) => {
                                const option = cmpState.restrictionOptions.find(
                                  (opt) => opt.value === selectedValue
                                );
                                return option
                                  ? { value: option.value, label: option.label }
                                  : null;
                              })
                              .filter(Boolean)
                          : []
                      }
                      selectedValue={cmpState.selectedValue}
                      hasNoUpDownButton={true}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="slds-form__row">
            {/* <!-- Source Primary Key --> */}
            {cmpState.mode !== "edit" && (
              <div className="slds-form__item" role="listitem">
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}
                  {cmpState.mode === "view" && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">
                        Primary Key
                      </span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {cmpState.record.primaryKey}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* <!-- Source Change Tracker --> */}
            {cmpState.mode !== "edit" && (
              <div className="slds-form__item" role="listitem">
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}
                  {cmpState.mode === "view" && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">
                        Change Tracker
                      </span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {cmpState.record.changeTracker}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {cmpState.mode === "view" &&
            cmpState.record.totalRecords !== null &&
            cmpState.record.totalRecords !== "" &&
            cmpState.record.totalRecords !== undefined && (
              <>
                <h3 className="slds-section-title--divider slds-m-top_medium">
                  Status
                </h3>

                <div className="slds-form__row">
                  {/* <!-- Source Exists --> */}
                  <div className="slds-form__item" role="listitem">
                    <div className="slds-form-element slds-form-element_stacked">
                      {cmpState.mode === "init" && <ProgressBarComponent />}
                      {cmpState.mode === "view" && (
                        <div
                          id="FormDiv"
                          className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                        >
                          <span className="slds-form-element__label">
                            Found in Source
                          </span>
                          <div className="slds-form-element__control">
                            <div className="slds-form-element__static">
                              <CheckAndCloseIcons
                                selectedItem={cmpState.record.sourceExists}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <!-- TotalRecords --> */}
                  <div className="slds-form__item" role="listitem">
                    <div className="slds-form-element slds-form-element_stacked">
                      {cmpState.mode === "init" && <ProgressBarComponent />}
                      {cmpState.mode === "view" && (
                        <div
                          id="FormDiv"
                          className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                        >
                          <span className="slds-form-element__label">
                            Number of Records Loaded
                          </span>
                          <div className="slds-form-element__control">
                            <div className="slds-form-element__static">
                              {formattedNumber(cmpState.record.totalRecords)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="slds-form__row">
                  {/* <!-- First Loaded--> */}
                  <div className="slds-form__item" role="listitem">
                    <div className="slds-form-element slds-form-element_stacked">
                      {cmpState.mode === "init" && <ProgressBarComponent />}
                      {cmpState.mode === "view" && (
                        <div
                          id="FormDiv"
                          className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                        >
                          <span className="slds-form-element__label">
                            First Run On
                          </span>
                          <div className="slds-form-element__control">
                            {/* <div className="slds-form-element__static"><lightning:formattedDateTime value="{!v.record.firstRunOn}" year="numeric" month="long" day="numeric" hour="2-digit" minute="2-digit" second="2-digit" /></div> */}
                            {cmpState.record.firstRunOn &&
                              format(
                                new Date(cmpState.record.firstRunOn),
                                "d MMMM yyyy 'at' HH:mm:ss"
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <!-- Last Loaded --> */}
                  <div className="slds-form__item" role="listitem">
                    <div className="slds-form-element slds-form-element_stacked">
                      {cmpState.mode === "init" && <ProgressBarComponent />}
                      {cmpState.mode === "view" && (
                        <div
                          id="FormDiv"
                          className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                        >
                          <span className="slds-form-element__label">
                            Last Run On
                          </span>
                          <div className="slds-form-element__control">
                            {/* <div className="slds-form-element__static"><lightning:formattedDateTime value="{!v.record.lastRunOn}" year="numeric" month="long" day="numeric" hour="2-digit" minute="2-digit" second="2-digit" /></div> */}
                            {cmpState.record.lastRunOn &&
                              format(
                                new Date(cmpState.record.lastRunOn),
                                "d MMMM yyyy 'at' HH:mm:ss"
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
      );
    },
  };

  return (
    <>
      {cmpState.showClearConfirmDialog ? (
        <Modal
          apply={() => cmp.confirmClearRecord()}
          cancel={() => cmp.cancelClearRecord()}
          header="Confirmation"
          modalContent="Clearing an Object will delete all its loaded data. Are you sure?"
          applyButtonContent="OK"
        />
      ) : null}

      {cmpState.showDeleteConfirmDialog ? (
        <Modal
          apply={() => cmp.confirmDeleteRecord()}
          cancel={() => cmp.cancelDeleteRecord()}
          header="Confirmation"
          modalContent="Deleting this Record will also delete all its associated loaded data. Are you sure?"
          applyButtonContent="OK"
        />
      ) : null}
      {PsRecord.render(cmp, cmpState)}
    </>
  );
};

export default PsObject;
