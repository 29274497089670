import { useState } from "react";
import { Card } from "@salesforce/design-system-react";

import "./../Pages.css";
import ToastComponent from "../../components/toast-component";
import UserForm from "./components/UserForm";
import ConfirmationForm from "./components/ConfirmationForm";

const Signup = ({ childProps: { userHasAuthenticated } }) => {
  const emptyToastState = { variant: "", details: "" };

  const [toastState, setToastState] = useState(emptyToastState);
  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [confirmationCode, setConfirmationCode] = useState(""); //Why is this not just on ConfirmationForm?
  const [newUser, setNewUser] = useState(null);

  const closeToast = () => setToastState(emptyToastState);

  return (
    <div className="login-signup-container">
      <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
        {newUser ? (
          <ConfirmationForm
            confirmationCode={confirmationCode}
            setConfirmationCode={setConfirmationCode}
            setToastState={setToastState}
            userHasAuthenticated={userHasAuthenticated}
            user={user}
          />
        ) : (
          <UserForm
            setToastState={setToastState}
            user={user}
            setUser={setUser}
            setNewUser={setNewUser}
          />
        )}
      </Card>
      {toastState.details ? (
        <ToastComponent
          close={closeToast}
          details={toastState.details}
          variant={toastState.variant}
        />
      ) : null}
    </div>
  );
};

export default Signup;
