import Record from "../../../helpers/recordLayer";

export const RECORD_COLUMNS = [
  {
    key: "namePlain", //fieldName
    label: "Pattern", //label
    property: "namePlain", //fieldName
    type: "link", //if type = button, this value is link
    action: "details", //name within typeAttributes
  },
  {
    key: "relevance",
    label: "Relevance",
    property: "relevance",
    type: "percent",
  },
  {
    key: "keyName",
    label: "Field",
    property: "keyName",
    type: "text",
  },
  {
    key: "containerName",
    label: "Object",
    property: "containerName",
    type: "text",
  },
  {
    key: "sourceName",
    label: "Source",
    property: "sourceName",
    type: "text",
  },
];

export const parseResponse = (response) => {
  return response.map(
    ({
      id,
      compositionId,
      name,
      type,
      description,
      treeHash,
      relevance,
      relevanceIntrinsic,
      createdOn,
      lastRunOn,
      parameters,
      key,
      inputs,
      version,
    }) => ({
      // id: id || treeHash,
      id,
      compositionId,
      name,
      namePlain: Record.removeMarkup(name),
      nameMarkup: Record.markupToHtml(name),
      type,
      description,
      descriptionPlain: Record.removeMarkup(description),
      descriptionMarkup: Record.markupToHtml(description),
      treeHash,
      relevance,
      relevanceIntrinsic,
      createdOn,
      lastRunOn,
      parameters,
      key,
      keyId: key.id,
      keyName: key.name,
      containerName: key.container.name,
      sourceName: key.container.source.name,
      inputs,
      version,
    })
  );
};
