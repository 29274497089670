export const RECORD_COLUMNS = [
  { label: "Name", property: "name", type: "text", wrapText: false },
  {
    label: "Order",
    width: "80px",
    property: "argOrder",
    type: "number",
  },
  {
    label: "Field",
    type: "link",
    width: "100px",
    property: "keyName",
    action: "viewKey",
    title: "View Details",
  },
  {
    label: "Object",
    type: "link",
    width: "100px",
    property: "containerName",
    action: "viewContainer",
    title: "View Details",
  },
  {
    label: "Source",
    type: "link",
    width: "100px",
    property: "sourceName",
    action: "viewSource",
    title: "View Details",
  },
  {
    label: "# Distinct",
    width: "110px",
    property: "robustDistinct",
    type: "number",
  },
];

export const parseInputPlainTextHelper = (record) => {
  // TODO: revert overriden, then setting a new value doesn't work
  var data = record.id
    ? (({ id, name, accept, overridden }) => ({
        id,
        name,
        accept,
        overridden,
      }))(record) // select fields that can be updated
    : (({ name, type, accept, leftContainerId, rightContainerId, inputs }) => ({
        name,
        type,
        accept,
        leftContainerId,
        rightContainerId,
        inputs,
      }))(record); // select fields that can be created
  return data;
};

export const parseResponseHelper = (response) => {
  return response.map(
    ({
      id,
      name,
      accept,
      leftContainer,
      rightContainer,
      relationship,
      lastRunOn,
      coverage,
      robustDistinct,
      relevance,
      overridden,
      custom,
      inputs,
    }) => ({
      id,
      name,
      accept,
      leftContainerName: leftContainer.name,
      rightContainerName: rightContainer.name,
      relationship,
      lastRunOn,
      coverage,
      robustDistinct,
      relevance,
      overridden,
      custom,
      inputs,
    })
  );
};
