import { useState, useEffect, useRef } from "react";
import { Button } from "@salesforce/design-system-react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import Record from "../../helpers/recordLayer";

const PsConnectorList = (props) => {
  const [cmpState, setCmpState] = useState({
    // <!-- interface -->
    recordLabel: "Connector",
    recordLabelPlural: "Connectors",
    // title:"", use folderTitle
    // header" type="Aura.Component[]"/> <!-- extra content at top-->
    showTitle: true,
    showFooter: false,
    tagLine: "Connectors load your data into the Point Sigma analytics engine.",
    emptyLine: "Connect this Source using a new Connector",
    recordModule: "pump",
    recordObject: "connector",
    gridComponent: "ConnectorTile",
    gridComponents: [],
    // cardActions: [],
    // emptyCallToAction: [],
    gridItems: [],
    viewOptions: [
      { label: "Table", value: "table" },
      { label: "Grid", value: "grid" },
    ],
    changeView: false,
    showEdit: false, // TODO > value="{! v.queryFilter.id != 'liked' }"/>
    showLoadMore: true, // <!-- whether to show the 'Load More' button -->
    view: "table",
    // itemView: "grid", // <!-- send to each grid item -->
    isDragMode: true,
    // footer" type="Aura.Component[]"/> <!-- add modal dialogs and other extra content -->

    draggedStart: null,
    draggedIndex: null,

    // <!-- querying -->
    parentPrefix: "",
    orderBy: "name", //TODO this needs to use the pattern's order
    orderDirection: "asc",
    lastValue: null,
    lastId: null,
    hasMore: true,

    // <!-- records -->
    mode: "empty", //"init",  // <!-- init, empty, view, error -->
    loading: true,
    recordColumns: [],
    recordList: [],
    recordDefaultSortDirection: "asc",

    //PsSourceList specific
    showEmptyCallToAction: true,
    showCardActions: true,

    loadingMore: false,
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent.action]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }

    if (props.queryFilter && Object.keys(props.queryFilter).length > 0) {
      cmp.handleReset();
    }
  }, [props.queryFilter]);

  const cmp = {
    // --- Get/Set ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    // --- ConnectorListController.js ---

    init: function () {
      // PsRecordGrid.setRecordColumns(cmp);
      cmp.afterScriptsLoaded();
    },

    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      var maxRecords = cmp.get("maxRecords");
      var numRecords = !maxRecords
        ? 0
        : Math.max(cmp.get("recordList").length, maxRecords);
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleReset: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleLoadMore: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    afterScriptsLoaded: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleRecordRowAction: function (row) {
      switch (row.action.name) {
        case "details":
          //Review
          // PsRecordGrid.notifyNavigation(row);

          //this isn't doing anythign with row now??
          var parentId = cmp.get("parentId");
          var recordModule = cmp.get("recordModule");
          var recordObject = cmp.get("recordObject");
          const type = "navigation";
          PsRecordGrid.notifyNavigation(
            cmp,
            parentId,
            recordModule,
            recordObject,
            row.id,
            type
          );
          break;
        case "viewSource":
          PsRecordGrid.notifyNavigation(
            cmp,
            "sources",
            "pump",
            "source",
            row.source.id
          );
          break;
        default:
      }
    },

    handleNew: function () {
      var parentId = cmp.get("parentId");
      var recordModule = cmp.get("recordModule");
      var recordObject = cmp.get("recordObject");
      const type = "navigation";

      // navigate to 'null' id loads a 'new record' page
      // cmp.notifyNavigation(parentId, recordModule, recordObject, null, type);
      //(cmp, parentId, module, object, id = null) {
      //notifyNavigation: function (cmp, parentId, module, object, id = null, type) {
      PsRecordGrid.notifyNavigation(
        cmp,
        parentId,
        recordModule,
        recordObject,
        null,
        type
      );
    },

    // --- ConnectorListHelper.js ---

    RECORD_COLUMNS: [
      {
        key: "name", //fieldName
        label: "Name", //label
        property: "name", //fieldName
        type: "link", //if type = button, this value is link
        action: "details", //name within typeAttributes
        sortable: true,
      },
      {
        key: "sourceName", //fieldName
        label: "Source", //label
        property: "sourceName", //fieldName
        type: "link", //if type = button, this value is link
        action: "viewSource", //name within typeAttributes
        sortable: true,
      },
      {
        key: "connectorTypeName", //fieldName
        label: "Type", //label
        property: "connectorTypeName", //fieldName
        type: "text", //if type = button, this value is link
        sortable: false,
      },
      {
        key: "status", //fieldName
        label: "Status", //label
        property: "status", //fieldName
        type: "text", //if type = button, this value is link
        sortable: true,
      },
      {
        key: "schedule", //fieldName
        label: "Schedule", //label
        property: "schedule", //fieldName
        type: "text", //if type = button, this value is link
      },

      {
        key: "runEnd", //fieldName
        label: "Last Run Completed", //label
        property: "runEnd", //fieldName
        type: "formattedDate", //if type = button, this value is link
        sortable: true,
      },
      {
        key: "runStatus", //fieldName
        label: "Last Run Status", //label
        property: "runStatus", //fieldName
        type: "text", //if type = button, this value is link
        sortable: true,
      },

      //{ label: 'Next Scheduled Run', fieldName: 'nextScheduledRun', type: 'date', sortable: true, typeAttributes: {year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'} },
      //TODO - renenable
      // {
      //   label: "Last Run Completed",
      //   fixedWidth: 190,
      //   fieldName: "runEnd",
      //   type: "date",
      //   sortable: true,
      //   typeAttributes: {
      //     year: "numeric",
      //     month: "long",
      //     day: "2-digit",
      //     hour: "2-digit",
      //     minute: "2-digit",
      //     second: "2-digit",
      //   },
      // },
      // {
      //   label: "Last Run Status",
      //   fixedWidth: 170,
      //   fieldName: "runStatus",
      //   type: "text",
      //   sortable: true,
      // },
      // { label: 'Last Run Message', initialWidth: 160, fieldName: 'runMessage', type: 'text', sortable: false, wrapText: true  },
    ],

    parseResponse: function (response) {
      var results = response.map(
        ({
          id,
          name,
          status,
          schedule,
          source,
          nextScheduledRun,
          runEnd,
          runStatus,
          runMessage,
          connectorType,
        }) => ({
          id,
          name,
          status,
          schedule: Record.cronToHuman(schedule),
          source,
          sourceName: source.name,
          nextScheduledRun,
          runEnd,
          runStatus,
          runMessage,
          connectorType,
          connectorTypeName: connectorType.name,
        })
      );
      return results;
    },

    // --- Functions that used to be only on RecordGrid ---

    notifyDataComp: function (row) {
      var setting = row.setting || {};
      const event = {
        type: "navigation",
        id: row.id,
        label: row.name,
        section: setting.section || "pump",
        parentId: row.parentId || "",
        obj: setting.config || "connector",
        breadcrumb: row.breadcrumb || [],
        record: row,
        source: row.source || "tree",
        scroll: row || null,
      };

      cmp.handleEvent(event);
    },

    emptyCallToAction: function () {
      const content = `Create a new ${cmpState.recordLabel}`;
      return (
        <Button
          // id="ViewModeButton"
          label={content}
          title={content}
          onClick={() => cmp.handleNew()}
          disabled={cmpState.loading}
          variant="brand"
        />
      );
    },

    cardActions: function () {
      const mode = cmp.get("mode");
      return (
        <>
          {mode === "init" || mode === "view" ? (
            <Button
              // id="ViewModeButton"
              label="New"
              title={`Create a new ${cmpState.recordLabel}`}
              onClick={() => cmp.handleNew()}
              disabled={cmpState.loading}
            />
          ) : null}
        </>
      );
    },

    // --- New functions ---

    handleEvent: function (event) {
      let stopPropagation = false;

      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({
        variant,
        heading,
        details,
      });
    },
  };

  return PsRecordGrid.render(cmp, cmpState);
};

export default PsConnectorList;
