import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import Icon from "@salesforce/design-system-react/components/icon";

import Event from "../../helpers/event.js";
import "./PsNavigationHeader.css";

function PsNavigationHeader(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [cmpState, setCmpState] = useState({
    // selectedTab: window.location.pathname
    // view: "list", //list or detail
    showDataFilters: false,
  });

  const cmpWorking = useRef({});

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    //do some init stuff
    // onPageReferenceChange();

    setCmpState({ ...cmpWorking.current });
  }, [searchParams]); //runs on init and when search params are updated

  const onLinkClick = (path) => {
    cmpWorking.current = { ...cmpState };
    // cmpWorking.current.selectedTab = path
    setCmpState({ ...cmpWorking.current });
  };

  const handleCloseDetail = () => {
    try {
      var event = Event.createEvent("dataCompEvent");
      event.data = { action: "close" };
      props.childToParent(event);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handleDataFilters = () => {
    console.log("handleDataFilters");
  };

  const handleSupport = () => {
    console.log("handleSupport");
  };

  const handleReload = () => {
    try {
      // var refreshEvent = $A.get("e.c:RefreshEvent");
      // refreshEvent.setParams({
      //   data: { action: "reload", origin: "NavigationHeader" },
      // });
      // refreshEvent.fire();
      const refreshEvent = {
        data: { action: "reload", origin: "NavigationHeader" },
        type: "reload",
      };
      props.childToParent(refreshEvent);
    } catch (err) {
      console.error(err.stack);
    }
  };

  // This styling will be applied to a <NavLink> when the
  // route that it links to is currently selected.
  // let activeClassName = "slds-context-bar__item slds-is-active";
  // let inactiveClassName = "slds-context-bar__item";
  return (
    <div>
      <div id="navigationHeader" className="navigationHeader">
        <div className="navigationHeaderItem">
          {props.view === "detail" && (
            <div
              className="navigationHeaderButton slds-p-around_medium borderRight"
              onClick={handleCloseDetail}
            >
              <Icon
                assistiveText={{ label: "Back" }}
                category="utility"
                name="back"
                size="x-small"
                inverse
              />
              {/* <lightning:icon size="x-small" iconName="utility:back" variant="inverse" alternativeText="Back" title="Back" /> */}
              &nbsp;Back to Results
            </div>
          )}
        </div>

        <div className="navigationHeaderItem borderRight"></div>

        <div className="navigationHeaderItemRight">
          {cmpState.showDataFilters && props.view === "list" && (
            <div
              id="dataFiltersButton"
              className="navigationHeaderButton slds-p-around_medium borderRight"
              onClick={handleDataFilters}
            >
              <Icon
                assistiveText={{ label: "Filter" }}
                category="utility"
                name="filterList"
                size="x-small"
                inverse
              />
              {/* <lightning:icon size="x-small" iconName="utility:filterList" variant="inverse" alternativeText="Filter" title="Filter" /> */}
              &nbsp;Data Filters&nbsp;
              <Icon
                assistiveText={{ label: "Down" }}
                category="utility"
                name="chevrondown"
                size="x-small"
                inverse
              />
              {/* <lightning:icon aura:id="chevrondown" size="x-small" iconName="utility:chevrondown" variant="inverse" alternativeText="Down" title="Down" /> */}
            </div>
          )}

          {/* <div  id="supportButton" className="navigationHeaderButton slds-p-top_small slds-p-horizontal_medium" onClick={handleSupport}>
                <Icon
                    assistiveText={{ label: 'Support' }}
                    category="utility"
                    name="help"
                    size="x-small"
                    inverse
                  />      
              </div>
              <div className="navigationHeaderItem borderRight"></div> */}

          <div
            className="navigationHeaderButton slds-p-top_small slds-p-horizontal_medium"
            onClick={handleReload}
          >
            <Icon
              assistiveText={{ label: "Refresh" }}
              category="utility"
              name="refresh"
              size="x-small"
              inverse
            />
            {/* <lightning:icon size="x-small" iconName="utility:refresh" variant="inverse" alternativeText="Refresh" title="Refresh" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PsNavigationHeader;
