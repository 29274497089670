export const CARTESIAN_CHART_TYPES = [
  "bar",
  "horizontalBar",
  "line",
  "matrix",
  "scatter",
];
export const CIRCULAR_CHART_TYPES = ["doughnut", "pie", "polarArea", "radar"];
export const GEO_CHART_TYPES = ["choropleth"];

export const EMPTY_TOAST_STATE = {
  variant: "",
  heading: "",
  details: "",
};
