import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({
  childProps,
  redirectPath = '/login',
  children,
}) => {
  if (!childProps.isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;