import Button from "@salesforce/design-system-react/components/button";

import "../PsPatternChart";

const HeaderActionsIcons = ({
  selectedView,
  handleLikeClick,
  handleDislikeClick,
  handleSaveClick,
  handleViewDetails,
}) => {
  return (
    <div className="header-actions-icons">
      <Button
        assistiveText={{ icon: "Icon Bare Small" }}
        iconCategory="utility"
        iconName="like"
        iconSize="large"
        iconVariant="bare"
        onClick={handleLikeClick}
        variant="icon"
      />
      <Button
        assistiveText={{ icon: "Icon Bare Small" }}
        iconCategory="utility"
        iconName="dislike"
        iconSize="large"
        iconVariant="bare"
        onClick={handleDislikeClick}
        variant="icon"
        style={{ marginLeft: "-12px" }}
      />
      <Button
        assistiveText={{ icon: "Icon Bare Small" }}
        iconCategory="utility"
        iconName="save"
        iconSize="large"
        iconVariant="bare"
        onClick={handleSaveClick}
        variant="icon"
        style={{ marginLeft: "-12px" }}
      />
      {selectedView === "grid" && (
        <Button
          assistiveText={{ icon: "Icon Bare Small" }}
          iconCategory="utility"
          iconName="expand_alt"
          iconSize="large"
          iconVariant="bare"
          onClick={handleViewDetails}
          variant="icon"
          style={{ marginLeft: "-12px" }}
        />
      )}
    </div>
  );
};

export default HeaderActionsIcons;
