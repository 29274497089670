import { Input } from "@salesforce/design-system-react";
import { useEffect, useRef, useState } from "react";
import Combobox from "@salesforce/design-system-react/components/combobox";
import Checkbox from "@salesforce/design-system-react/components/checkbox";
import Tooltip from "@salesforce/design-system-react/components/tooltip";
import {
  IconSettings,
  Button,
  Dropdown,
} from "@salesforce/design-system-react";
import { format } from "date-fns";
import cloneDeep from "lodash/cloneDeep";

import "./PsConnector.css";
import PsRecord from "../ps-record/PsRecord";
import Record from "../../helpers/recordLayer";
import EditButtonIcon from "../ui/EditButtonIcon";
import ProgressBarComponent from "../ui/ProgressBarComponent";
import Modal from "../ui/Modal";
import { toastDetails } from "../../helpers";
import AuthenticationDetailsModalWindow from "./components/AuthenticationDetailsModalWindow";
import Files from "./components/Files";

const PsConnector = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Connector",
    recordModule: "pump",
    recordObject: "connector",

    showEdit: true,
    showCardActions: true,
    showDelete: true,
    isModal: false,

    // <!-- parent -->
    parentPrefix: "", // <!-- prefix to use when navigating using parentId -->

    // <!-- record -->
    loading: true,
    mode: "init", // <!-- init, new, view, edit, error -->
    recordValue: "", //  /> <!-- controlled by parent, parent is responsible for updating, DO NOT UPDATE from this componentd -->
    record: {},

    connectorType: {},
    selectedConnectMethod: {},
    defaultConnectMethod: {},

    // <!-- picklist -->
    externalSource: "",
    sourceOptions: [],
    sourceOptionsLoaded: false,
    sourceSelection: [],
    connectorTypeOptions: [],
    connectorTypeOptionsLoaded: false,
    connectorTypeSelection: [],
    statusOptions: Record.addIds(Object.values(Record.CONNECTOR_STATUS)),
    statusSelection: [],
    objectStatusOptions: Record.addIds(
      Object.values(Record.DEFAULT_OBJECT_STATUS)
    ),
    objectStatusSelection: [],
    fieldStatusOptions: Record.addIds(
      Object.values(Record.DEFAULT_FIELD_STATUS)
    ),
    fieldStatusSelection: [],
    scheduleOptions: Record.addIds(Object.values(Record.CONNECTOR_SCHEDULE)),
    scheduleSelection: [],
    connectOptions: [],
    connectSelection: [],
    selectedConnectOption: "",
    selectedConnectSelection: [],

    // <!-- Credentials Modal -->
    credentialsModalIsOpen: false,

    // <!-- file upload -->
    fileUploadProgress: 0,
    uploading: false,
    loadAllData: true,

    showDeleteConfirmDialog: false,
    deleteConfirmation: false,

    files: [],

    missingRequiredFields: [],
  });

  const cmpWorking = useRef({});
  const fileInputRef = useRef(null);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.afterScriptsLoaded();
  }, [props.recordId, props.parentId]);

  useEffect(() => {
    if (!props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent.action]);

  const cmp = {
    // --- xController.js ---

    init: function () {},

    afterScriptsLoaded: function () {
      cmp.set("statusOptions", Object.values(Record.ENVIRONMENT_STATUS));
      cmp.set(
        "objectStatusOptions",
        Object.values(Record.DEFAULT_OBJECT_STATUS)
      );
      cmp.set("fieldStatusOptions", Object.values(Record.DEFAULT_FIELD_STATUS));
      cmp.set("scheduleOptions", Object.values(Record.CONNECTOR_SCHEDULE));
      this.loadSources();
      this.loadConnectorTypes();
      PsRecord.getRecord(cmp);
    },

    handleReload: function () {
      cmp.loadSources();
      PsRecord.getRecord(cmp);
    },

    handleRefresh: function () {
      try {
        var refreshEvent = { type: "reload" };
        refreshEvent.data = { action: "reload", origin: "Connector" };
        props.childToParent(refreshEvent);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleSync: function () {
      try {
        cmp.actionSync();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleLoad: function () {
      try {
        cmp.actionLoad();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleGoToExplore: function () {
      try {
        let navigationEvent = new Event("navigation");
        navigationEvent.tab = "Explore";
        props.childToParent(navigationEvent);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleGoToSearch: function () {
      try {
        let navigationEvent = new Event("navigation");
        navigationEvent.tab = "Search";
        props.childToParent(navigationEvent);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleEdit: function () {
      cmp.loadSources();
      PsRecord.setMode(cmp, "edit");
    },

    handleCancel: function () {
      PsRecord.cancelRecord(cmp);
    },

    handleSubmit: function () {
      cmp.handleNoneValueForSchedule();
      PsRecord.submitRecord(cmp);
    },

    handleDelete: function () {
      PsRecord.deleteRecord(cmp); // NB: this navigates to parent record after successfull delete
    },

    selectSource: function (data) {
      try {
        var sourceId = data.selection[0].id;
        const record = cmp.get("record");
        cmp.set("record", {
          ...record,
          sourceId,
        });

        cmp.set("sourceSelection", data.selection);
        Record.setSelectedLabel(
          cmp,
          "sourceOptions",
          sourceId,
          "record.sourceName"
        );
        cmp.set("parentId", sourceId); // update parent so that item is added under the correct parent in the navigation tree
        cmp.removeFieldIfNotEmpty("sourceId");
      } catch (err) {
        console.error(err.stack);
      }
    },

    selectConnectorType: function (data) {
      try {
        var connectorTypeId = data.selection[0].id;
        cmp.set("record.connectorTypeId", connectorTypeId);
        cmp.set("connectorTypeSelection", data.selection);
        Record.setSelectedLabel(
          cmp,
          "connectorTypeOptions",
          connectorTypeId,
          "record.connectorTypeName"
        );
        cmp.setConnectorType(connectorTypeId);
        cmp.removeFieldIfNotEmpty("connectorTypeId");
      } catch (err) {
        console.error(err.stack);
      }
    },

    selectObjectStatus: function (data) {
      try {
        // cmp.set("defaultObjectStatus", data.selection[0].id);
        const record = cmp.get("record");
        cmp.set("record", {
          ...record,
          defaultObjectStatus: data.selection[0].value,
        });
        cmp.set("objectStatusSelection", data.selection);
      } catch (err) {
        console.error(err.stack);
      }
    },

    selectFieldStatus: function (data) {
      try {
        // cmp.set("defaultFieldStatus", data.selection[0].id);
        const record = cmp.get("record");
        cmp.set("record", {
          ...record,
          defaultFieldStatus: data.selection[0].value,
        });
        cmp.set("fieldStatusSelection", data.selection);
      } catch (err) {
        console.error(err.stack);
      }
    },

    selectStatus: function (data) {
      try {
        const record = cmp.get("record");
        cmp.set("record", { ...record, status: data.selection[0].value });
        cmp.set("statusSelection", data.selection);
      } catch (err) {
        console.error(err.stack);
      }
    },

    selectSchedule: function (data) {
      try {
        const record = cmp.get("record");
        cmp.set("record", { ...record, schedule: data.selection[0].value }); //in case of none, this is an empty string
        cmp.set("scheduleSelection", data.selection);
      } catch (err) {
        console.error(err.stack);
      }
    },

    selectConnect: function (data) {
      try {
        let value = data.selection[0].value;
        cmp.set("selectedConnectOption", value);
        cmp.set("connectSelection", data.selection);
        cmp.selectConnectMethod(value);
        cmp.removeFieldIfNotEmpty("selectedConnectOption");
      } catch (err) {
        console.error(err.stack);
      }
    },

    selectConnectMethodInput: function (data, index) {
      try {
        let id = data.selection[0].id;
        if (!id) {
          id = data.selection[0].value;
        }
        cmpWorking.current.selectedConnectMethod.inputs[index].value = id;

        //This could cause immutability issues
        // setCmpState(cmpWorking.current);

        //Safe way to update nested state
        setCmpState((prevState) => {
          // Copy the outer level
          const newState = { ...prevState };

          // Navigate to the selectedConnectMethod, ensuring to copy it to maintain immutability
          newState.selectedConnectMethod = {
            ...prevState.selectedConnectMethod,
          };

          // Copy the inputs array to avoid direct mutation
          newState.selectedConnectMethod.inputs = [
            ...prevState.selectedConnectMethod.inputs,
          ];

          // Now, update the specific index's value within the inputs array
          newState.selectedConnectMethod.inputs[index] = {
            ...prevState.selectedConnectMethod.inputs[index],
            value: id, // Update the value here
          };

          // Return the updated state
          return newState;
        });
      } catch (err) {
        console.error(err.stack);
      }
    },

    selectConnectMethodInputInput: function (data, index, nestedIndex) {
      try {
        let value = data.selection[0].value;

        cmpWorking.current.selectedConnectMethod.inputs[index].inputs[
          nestedIndex
        ].value = value;

        //This could cause immutability issues
        // setCmpState(cmpWorking.current);

        //Safe way to update nested state
        setCmpState((prevState) => {
          // Copy the outer level
          const newState = { ...prevState };

          // Navigate to the selectedConnectMethod, ensuring to copy it to maintain immutability
          newState.selectedConnectMethod = {
            ...prevState.selectedConnectMethod,
          };

          // Copy the inputs array to avoid direct mutation
          newState.selectedConnectMethod.inputs = [
            ...prevState.selectedConnectMethod.inputs,
          ];

          // Now, update the specific index's value within the inputs array
          newState.selectedConnectMethod.inputs[index].inputs[nestedIndex] = {
            ...prevState.selectedConnectMethod.inputs[index].inputs[
              nestedIndex
            ],
            value: value, // Update the value here
          };

          // Return the updated state
          return newState;
        });
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleAction: function (action) {
      try {
        switch (action) {
          case "validate":
            cmp.actionValidate();
            break;
          case "sync":
            cmp.actionSync();
            break;
          case "load":
            cmp.actionLoad();
            break;
          case "abort":
            cmp.actionAbort();
            break;
          case "clear":
            cmp.actionClear();
            break;
          default:
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    selectConnectMethod: function (selectedConnectOption) {
      try {
        // Already done in selectConnect
        // var selectedConnectOption = event.getSource().get("v.value");

        cmp.setConnectMethod(selectedConnectOption);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleStartConnect: function () {
      try {
        cmp.startConnect();
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleEditCredentials: function () {
      try {
        cmp.set("credentialsModalIsOpen", true);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleCancelCredentials: function () {
      try {
        cmp.unsetCredentials();
        cmp.set("credentialsModalIsOpen", false);
        cmp.set("missingRequiredFields", []);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleSaveCredentials: function () {
      try {
        if (cmp.checkForm()) {
          cmp.setToastState(
            "error",
            "Input Error",
            "Please update the invalid form entries and try again."
          );
          return;
        } else {
          cmp.saveCredentials();
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- xHelper.js ---

    getDefaultRecord: function () {
      // return { status: Record.ENVIRONMENT_STATUS.ACTIVE.value };

      var parentId = cmp.get("parentId");

      if (["connectors", "connectorList"].includes(parentId)) {
        parentId = null;
      }
      cmp.set("externalSource", parentId);
      return {
        name: "",
        sourceId: parentId,
        status: Record.CONNECTOR_STATUS.ACTIVE.value,
        defaultObjectStatus: Record.OBJECT_STATUS.INCLUDED.value,
        defaultFieldStatus: Record.FIELD_STATUS.INCLUDED.value,
        schedule: Record.CONNECTOR_SCHEDULE.NONE.value,
      };
    },

    parseResponse: function (response) {
      return response.map(
        ({
          id,
          name,
          status,
          setupStatus,
          schedule,
          source,
          connectorType,
          runStatus,
          nextScheduledRun,
          runStart,
          runEnd,
          runMessage,
          lastModifiedOn,
          concurrency,
          defaultObjectStatus,
          defaultFieldStatus,
        }) => ({
          id,
          name,
          status,
          setupStatus: this.mergeSetupStatus(setupStatus, runEnd),
          schedule: Record.cronToHuman(schedule),
          sourceId: source.id,
          sourceName: source.name,
          connectorTypeId: connectorType.id,
          connectorTypeName: connectorType.name,
          runStatus,
          nextScheduledRun,
          runStart,
          runEnd,
          runMessage,
          lastModifiedOn,
          concurrency,
          defaultObjectStatus,
          defaultFieldStatus,
        })
      );
    },

    mergeSetupStatus: function (connectorStatus, runEnd) {
      if (connectorStatus === "Data Loaded") {
        try {
          // for now, this just sets the status to 'Processing Done' an hour after data is last loaded
          var runEndParsed = Date.parse(runEnd);
          if (new Date() - runEndParsed > 60 * 60 * 1000) {
            return "Processing Done";
          }
        } catch (err) {}
      }
      return connectorStatus;
    },

    // This overrides the default PsRecord function
    parseInputPlainText: function (record) {
      var connectorType = cmp.get("connectorType") || {};

      // extract fields that can be created / updated
      var data = record.id
        ? (({ id, name, defaultObjectStatus, defaultFieldStatus }) => ({
            id,
            name,
            defaultObjectStatus,
            defaultFieldStatus,
          }))(record) // select fields that can be updated
        : (({ name, defaultObjectStatus, defaultFieldStatus, sourceId }) => ({
            name,
            defaultObjectStatus,
            defaultFieldStatus,
            sourceId,
            connectorTypeId: connectorType.id,
          }))(record); // select fields that can be created

      // method-specific fields
      if (connectorType.supportsSchedule) {
        Object.assign(
          data,
          (({ status, schedule, concurrency }) => ({
            status,
            schedule: Record.humanToCron(schedule),
            concurrency: parseInt(concurrency),
          }))(record)
        );
      }
      // IMPROVEMENT: implement fields for other supported methods
      if (connectorType.supportsUpload) {
      }
      return data;
    },

    // TODO: move this to separate saving function, and remove the whole encryption business to just connnector instead of all records
    parseInputToEncrypt: function (record) {
      var connectorType = cmp.get("connectorType") || {};
      var data = null;
      if (connectorType.supportsCredentials) {
        var connectMethod = cmp.get("selectedConnectMethod") || {};
        var methodConfig = connectMethod.inputs || [];
        var inputs = methodConfig.reduce((obj1, item1) => {
          if (item1.type === "section") {
            var obj2 = {};
            var option = item1.value;
            var inputs = item1.inputs || [];
            inputs.forEach((item2) => {
              if (option === null || option === item2.option) {
                obj2[item2.name] = item2.value || item2.default;
              }
            });
            obj1[item1.name] = obj2;
          } else if (item1.type === "list") {
            obj1[item1.name] = Record.parseCSV(item1.value) || item1.default;
          } else if (item1.type === "number") {
            obj1[item1.name] =
              item1.value != null && item1.value !== ""
                ? parseFloat(item1.value)
                : item1.default;
          } else {
            obj1[item1.name] = item1.value || item1.default;
          }
          return obj1;
        }, {});
        var hascredentials =
          Object.values(inputs).join("").match(/^ *$/) === null; // check if any connection parameter is provided, ignoring empty strings
        inputs.method = connectMethod.value;
        data = hascredentials ? inputs : null;
      }
      return data;
    },

    updateUI: function () {
      this.setConnectorType(cmp.get("record.connectorTypeId"));
    },

    loadConnectorTypes: function () {
      var onSuccess = function (response) {
        response.forEach((ct) => {
          ct.label = ct.name; // for combobox
          ct.value = ct.id; // for combobox
          ct.supportsCredentials = ct.supportedMethods.includes("Credentials");
          ct.supportsSchedule = ct.supportedMethods.includes("Schedule");
          ct.supportsUpload = ct.supportedMethods.includes("Upload");
          ct.supportsConcurrency = ct.supportedMethods.includes("Concurrency");
        });
        cmp.set("connectorTypeOptions", response);
        cmp.set("connectorTypeOptionsLoaded", true);
        if (!response.length) {
          cmp.setToastState(
            "error",
            "No Connector Types Found",
            "Please contact Point Sigma Support"
          );
        }

        // picklist items post-processsing in case this runs after loading the record
        var connectorTypeId = Record.selectLoadedOption(
          cmp,
          "connectorTypeOptions",
          "record.connectorTypeId",
          "record.connectorTypeName"
        );
        cmp.setConnectorType(connectorTypeId);
      };

      Record.getRecords(
        "pump",
        "connectortype",
        { orderBy: "name ASC" },
        onSuccess
      );
    },

    loadSources: function () {
      var onSuccess = function (response) {
        response.forEach((env) => {
          env.label = env.name;
          env.value = env.id;
        }); // for combobox

        cmp.set("sourceOptions", response);
        cmp.set("sourceOptionsLoaded", true);

        if (!response.length) {
          cmp.setToastState(
            "error",
            "No Sources Found",
            "First create a Source before creating a Connector"
          );
        }

        // picklist items post-processsing in case this runs after loading the record
        Record.selectLoadedOption(
          cmp,
          "sourceOptions",
          "record.sourceId",
          "record.sourceName"
        );
      };

      Record.getRecords("core", "source", {}, onSuccess);
    },

    unsetCredentials: function () {
      var connectOptions = cmp.get("connectOptions") || [];
      connectOptions.forEach((am) => {
        var inputs = am.inputs || [];
        inputs.forEach((v) => {
          delete v.value;
        });
      });
    },

    setConnectorType: function (connectorTypeId) {
      if (!cmp.get("connectorTypeOptionsLoaded")) {
        return;
      }

      var connectorTypeOptions = cmp.get("connectorTypeOptions") || [];
      var connectorType =
        connectorTypeOptions.find((ct) => ct.value === connectorTypeId) || {};
      cmp.set("connectorType", connectorType);

      var methodDetails = (connectorType || {}).methodDetails || {};
      var methods = methodDetails.methods || [];
      cmp.set("connectOptions", methods);

      var connectMethod = methods.find((am) => am.default) || {};
      cmp.set("defaultConnectMethod", connectMethod);

      var selectedConnectOption = Record.selectLoadedOption(
        cmp,
        "connectOptions",
        "selectedConnectOption",
        null,
        true
      );
      var connectOptions = cmp.get("connectOptions") || [];
      connectMethod =
        connectOptions.find((am) => am.value === selectedConnectOption) || {};
      cmp.set("selectedConnectMethod", connectMethod);
    },

    setConnectMethod: function (selectedConnectOption) {
      this.unsetCredentials();
      var connectOptions = cmp.get("connectOptions") || [];
      var connectMethod =
        connectOptions.find((am) => am.value === selectedConnectOption) || {};

      cmp.set("selectedConnectMethod", connectMethod);
      cmp.set("missingRequiredFields", []);
    },

    actionValidate: function () {
      var previousMode = cmp.get("mode");
      PsRecord.setLoading(cmp);

      var connectorId = cmp.get("recordId");

      // after completing validation action, reload the record, and check the setupStatus
      var callback = function (success) {
        var record = cmp.get("record") || {};
        if (success && !record.runMessage && record.setupStatus) {
          cmp.setToastState("success", "Success", "Successfully connected");
        } else {
          var message = record.runMessage || "Unexpected error";
          cmp.setToastState("error", "Error", message);
        }
        PsRecord.setMode(cmp, previousMode);
      };

      var onError = function () {
        PsRecord.getRecord(cmp, callback);
      }; // standard toast message may be shown by Record.getRecord
      var onSuccess = function () {
        PsRecord.getRecord(cmp, callback);
      };
      Record.doAction(
        "pump",
        "connector",
        "validate",
        { id: connectorId },
        onSuccess,
        onError
      );
    },

    actionSync: function () {
      var previousMode = cmp.get("mode");
      PsRecord.setLoading(cmp);

      var connectorId = cmp.get("recordId");
      var onError = function () {
        PsRecord.setMode(cmp, previousMode);
      };
      var onSuccess = function () {
        cmp.setToastState(
          "info",
          "Connector Started",
          "Loading data inventory"
        );
        cmp.set("record.runStatus", "Running"); // doesn't get saved, but enables the 'Running' message box
        PsRecord.setMode(cmp, previousMode);
      };
      Record.doAction(
        "pump",
        "connector",
        "sync",
        { id: connectorId },
        onSuccess,
        onError
      );
    },

    actionLoad: function () {
      var previousMode = cmp.get("mode");
      PsRecord.setLoading(cmp);

      var connectorId = cmp.get("recordId");
      var onError = function (response) {
        cmp.setToastState("error", "Error", toastDetails(response));
        PsRecord.setMode(cmp, previousMode);
      };
      var onSuccess = function () {
        cmp.setToastState("info", "Connector Started", "Loading data");

        const record = cmp.get("record");
        cmp.set("record", { ...record, runStatus: "Running" });
        PsRecord.setMode(cmp, previousMode);
      };

      // parse input, and extract fields that can be created / updated
      Record.doAction(
        "pump",
        "connector",
        "load",
        { id: connectorId },
        onSuccess,
        onError
      );
    },

    actionAbort: function () {
      var previousMode = cmp.get("mode");
      PsRecord.setLoading(cmp);

      var connectorId = cmp.get("recordId");

      var onError = function (response) {
        PsRecord.setMode(cmp, previousMode);
        cmp.setToastState("error", "Error", toastDetails(response));
      };
      var onSuccess = function () {
        cmp.setToastState("info", "Connector Aborted", "Connector aborted");

        // cmp.set("record.runStatus", "Aborting"); // doesn't get saved, but shows the correct status
        const record = cmp.get("record");
        cmp.set("record", { ...record, runStatus: "Aborting" });
        PsRecord.setMode(cmp, previousMode);
      };

      Record.doAction(
        "pump",
        "connector",
        "abort",
        { id: connectorId },
        onSuccess,
        onError
      );
    },

    actionClear: function () {
      // confirmation
      if (
        !window.confirm(
          "Are you sure you want to delete all data from this Connector?"
        )
      ) {
        return;
      }

      var previousMode = cmp.get("mode");
      PsRecord.setLoading(cmp);

      var connectorId = cmp.get("recordId");
      var onError = function () {
        PsRecord.setMode(cmp, previousMode);
      };
      var onSuccess = function () {
        cmp.setToastState("info", "Connector Started", "Clearing data");
        cmp.set("record.runStatus", "Running"); // doesn't get saved, but enables the 'Running' message box
        PsRecord.setMode(cmp, previousMode);
      };

      Record.doAction(
        "pump",
        "connector",
        "clear",
        { id: connectorId },
        onSuccess,
        onError
      );
    },

    uploadFiles: function (files) {
      var connectorId = cmp.get("recordId");
      var onSuccess = function (response) {
        try {
          var link = response[0];
          var progress = new Array(files.length);
          var done = false;
          progress.fill(0.0);

          files.forEach((file, index) => {
            cmp.uploadFile(link, file, index, progress, done);
          });
        } catch (err) {
          cmp.setToastState("error", "Upload Unsuccessful", err);
          cmp.set("uploading", false);
        }
      };
      var onError = function (response) {
        cmp.set("uploading", false);
      };

      cmp.set("fileUploadProgress", 0);
      cmp.set("uploading", true);

      // getUploadLink: function (
      //   module,
      //   folder,
      //   onSuccess = null,
      //   onError = null
      // ) {

      Record.getUploadLink("pump", connectorId, onSuccess, onError);
    },

    uploadFile: function (link, file, index, progress, done) {
      var success = false; //940?
      var url = link.url;
      var fields = link.fields;
      var xhr = new XMLHttpRequest();
      var formData = new FormData();
      var self = this;
      var totalProgress = 0;
      xhr.open("POST", url, true);
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

      xhr.upload.addEventListener("progress", function (e) {
        progress[index] = e.loaded / e.total || 1;
        totalProgress =
          progress.reduce((total, item) => {
            return total + item;
          }, 0) / progress.length;
        cmp.set("fileUploadProgress", 100 * totalProgress);
      });

      xhr.addEventListener("readystatechange", function (e) {
        if (xhr.readyState === 4) {
          if (xhr.status === 201) {
            success = true;
          } else {
            var message =
              xhr.responseXML.getElementsByTagName("Message")[0].innerHTML;
            cmp.setToastState(
              "error",
              "Upload Failed",
              'Could not upload "' + file.name + '"; ' + message
            );
          }

          // last upload finished
          progress[index] = 1;
          if (!done && progress.every((x) => x === 1)) {
            done = true; // prevent other threads (for very small files) to start the connector twice
            cmp.set("uploading", false);

            if (cmp.get("loadAllData")) {
              cmp.actionLoad();
            } else {
              cmp.actionSync();
            }
          }
        }
      });

      formData.append("key", fields["key"]);
      formData.append("success_action_status", fields["success_action_status"]);
      formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
      formData.append("x-amz-credential", fields["x-amz-credential"]);
      formData.append("x-amz-date", fields["x-amz-date"]);
      formData.append("policy", fields["policy"]);
      formData.append("x-amz-signature", fields["x-amz-signature"]);
      formData.append("file", file);
      xhr.send(formData);
    },

    startConnect: function () {
      var connectMethod = cmp.get("defaultConnectMethod") || {};
      if (
        connectMethod.value === "oauth2" ||
        connectMethod.value === "OAuth Credentials"
      ) {
        this.initiateOauthConnection(connectMethod);
      } else if (connectMethod.value === "upload") {
        var files = cmp.get("files");
        if (files.length === 0) {
          cmp.setToastState(
            "warning",
            "Select a file ",
            "Please select a file to upload."
          );
          return;
        }
        this.uploadFiles(files);
      } else {
        cmp.set("credentialsModalIsOpen", true);
      }
    },

    saveCredentials: function () {
      cmp.set("credentialsModalIsOpen", false);
      cmp.set("missingRequiredFields", []);
      var connectMethod = cmp.get("selectedConnectMethod") || {};

      //In the past, I had set a few connectors to oauth2, only the ones for which I had configered apps and where I added the right settings
      //OAuth Credentials
      if (
        connectMethod.value === "OAuth Credentials" ||
        connectMethod.value === "oauth2" ||
        (connectMethod.supportsOAuth && connectMethod.value === "default")
      ) {
        //First submit the record, then initiate the OAuth flow
        //TODO - make this more efficient
        var callback = function (success) {
          cmp.initiateOauthConnection(connectMethod);
          //TODO should be async?
          cmp.actionValidate();
        };
        cmp.handleNoneValueForSchedule();
        PsRecord.submitRecord(cmp, callback);
      } else {
        var callback = function (success) {
          cmp.actionValidate();
        };
        cmp.handleNoneValueForSchedule();
        PsRecord.submitRecord(cmp, callback);
      }
    },

    initiateOauthConnection: function (connectMethod) {
      try {
        var previousMode = cmp.get("mode");
        PsRecord.setLoading(cmp);

        var record = cmp.get("record") || {};

        var connectorType = cmp.get("connectorType") || {};
        var config = {
          type: connectorType.name,
          method: connectMethod.value,
          save_as: "connector",
          record_id: record.id,
          return_url: window.location.href,
        };
        var methodConfig = connectMethod.inputs || [];

        //TODO - we need the ability to pass the custom domain to the api
        //This doesn't work, need to see how we deal with custom urls
        if (connectMethod.value === "SF") {
          // replace 'domain' in 'login_url' and 'token_url' if not on the page
          var domain = methodConfig.find((item) => item.name === "domain");
          if (domain.value) {
            domain.value = domain.value
              .replace("http://", "")
              .replace("https://", "");
            domain.value = domain.value.replace(
              ".lightning.force.com",
              ".my.salesforce.com"
            );
            domain.value = domain.value.replace(
              ".force.com",
              ".my.salesforce.com"
            );
          }
          var loginUrl = methodConfig.find((item) => item.name === "login_url");
          var tokenUrl = methodConfig.find((item) => item.name === "token_url");
          if (domain && domain.value && loginUrl && !loginUrl.type) {
            loginUrl.value = (loginUrl.default || "").replace(
              domain.default,
              domain.value
            );
          }
          if (domain && domain.value && loginUrl && !tokenUrl.type) {
            tokenUrl.value = (tokenUrl.default || "").replace(
              domain.default,
              domain.value
            );
          }
        }

        // set methodConfig to config
        methodConfig.reduce((obj, item) => {
          obj[item.name] = item.value || item.default;
          return obj;
        }, config);

        var onError = function (res) {
          const error = res.error || "";
          cmp.setToastState(
            "error",
            "Authentication Error",
            "Unable to start authentication\n" + error
          );
          PsRecord.setMode(cmp, previousMode);
        };

        var onSuccess = function (response) {
          var actionResponse = response[0];
          if (actionResponse.details && actionResponse.details.loginUrl) {
            window.open(actionResponse.details.loginUrl, "_top");
          } else {
            const error = actionResponse.error || "";
            cmp.setToastState(
              "error",
              "Authentication Error",
              "Unable to start authentication\n" + error
            );
            PsRecord.setMode(cmp, previousMode);
          }
        };

        // Call  Initiate OAuth api here
        // Connect/Pump/Connector/Actions/Connector - Action - Initiate OAuth
        Record.doAction(
          "pump",
          "connector",
          "initiate_oauth",
          { id: record.id, returnUrl: window.location.href },
          onSuccess,
          onError
        );
      } catch (error) {
        console.error(error.stack);
      }

      //Old flow - this used to go via Apex

      // var action = cmp.get("c.initiateConnection");
      // action.setParams({ config });
      // action.setCallback(this, function (response) {
      //   try {
      //     if (response.getState() === "SUCCESS") {
      //       var res = response.getReturnValue() || {};
      //       if (!res.error && res.url) {
      //         // didn't find a way to stay in the same window with Lightning navigation service
      //         // cmp.find('navService').navigate({ type: "standard__webPage",  attributes: { url: res.url } });
      //         window.open(res.url, "_top");
      //       } else {
      //         Record.showToast(
      //           cmp,
      //           "Authentication Error",
      //           "Unable to start authentication\n" + res.error,
      //           "error"
      //         );
      //         setMode( previousMode);
      //       }
      //     } else {
      //       Record.showToast(
      //         "Authentication Error",
      //         "Unable to start authentication, please contact Point Sigma support",
      //         "error"
      //       );
      //       setMode( previousMode);
      //     }
      //   } catch (err) {
      //     console.error(err.stack);
      //   }
      // });
      // $A.enqueueAction(action);
    },

    // setParent: function (cmp, record) {
    //   var parentId = "source";
    //   cmp.set("parentId", parentId);
    //   return parentId;
    // },

    setParent: function (record) {
      var parentId = record.sourceId || (record.source || {}).id;
      cmp.set("parentId", parentId);
      return parentId;
    },

    // --- New functions ---

    setToastState: function (variant, heading, details) {
      props.setToastState({
        variant,
        heading,
        details,
      });
    },

    // handleNameChange: function (value) {
    //   let record = cmp.get("record");
    //   record = { ...record, name: value };
    //   cmp.set("record", record);
    // },

    // handleSelectStatus: function (event, data) {
    //   if (data.selection.length === 0) {
    //     return;
    //   }

    //   let record = cmp.get("record");
    //   record = { ...record, status: data.selection[0].value };
    //   cmp.set("record", record);
    // },

    // handleSelectRestrict: function (event, data) {
    //   if (data.selection.length === 0) {
    //     return;
    //   }

    //   let record = cmp.get("record");
    //   record = { ...record, restrict: data.selection[0].value };
    //   cmp.set("record", record);
    // },

    handleEvent: function (event) {
      let stopPropagation = false;
      const newEvent =
        event.action === "delete" || event.action === "update"
          ? { ...event, type: "dataCompEvent", record: cmp.get("record") }
          : event;

      if (!stopPropagation) {
        props.childToParent(newEvent);
      }
    },

    // const handleNameChange = (e) => {
    //   const newValue = e.target.value;
    //   cmpWorking.current.record.name = newValue;

    //   setCmpState((prev) => ({
    //     ...prev,
    //     record: { ...prev.record, name: newValue },
    //   }));
    // };

    handleChange: (e) => {
      const { name, value } = e.target;
      cmpWorking.current.record[name] = value;
      setCmpState((prev) => ({
        ...prev,
        record: { ...prev.record, [name]: value },
      }));
    },

    handleRecordChange: (e) => {
      const { name, value } = e.target;

      cmpWorking.current.record[name] = value;
      setCmpState((prev) => ({
        ...prev,
        record: { ...prev.record, [name]: value },
      }));

      // Remove the field from the missingRequiredFields array if it is no longer empty
      if (value) {
        cmp.removeFieldIfNotEmpty(name);
      }
    },

    handleChangeSelectedConnectMethod: (e, indexes, name, type) => {
      try {
        const index = indexes[0];
        const nestedIndex = indexes[1];
        const newValue =
          type === "checkbox" ? e.target.checked : e.target.value;

        if (nestedIndex) {
          cmpWorking.current.selectedConnectMethod.inputs[index].inputs[
            nestedIndex
          ].value = newValue;
        } else {
          cmpWorking.current.selectedConnectMethod.inputs[index].value =
            newValue;
        }

        //Safe way to update nested state
        setCmpState((prevState) => {
          // Copy the outer level
          const newState = { ...prevState };

          // Navigate to the selectedConnectMethod, ensuring to copy it to maintain immutability
          newState.selectedConnectMethod = {
            ...prevState.selectedConnectMethod,
          };

          // Copy the inputs array to avoid direct mutation
          newState.selectedConnectMethod.inputs = [
            ...prevState.selectedConnectMethod.inputs,
          ];

          // Now, update the specific index's value within the inputs array
          newState.selectedConnectMethod.inputs =
            cmpWorking.current.selectedConnectMethod.inputs;

          // Return the updated state
          return newState;
        });

        if (newValue) {
          cmp.removeFieldIfNotEmpty(name);
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    nestedGet: (obj, keys) => {
      const keyArray = keys.split(".");
      let result = obj;
      for (const key of keyArray) {
        result = result[key];
        if (result === undefined) return undefined; // Property not found
      }
      return result;
    },

    //nested set cmpWorking
    nestedSet: (obj, keys, value) => {
      const keyArray = keys.split(".");
      let current = obj;
      for (let i = 0; i < keyArray.length - 1; i++) {
        const key = keyArray[i];
        if (!current[key] || typeof current[key] !== "object") {
          current[key] = {};
        }
        current = current[key];
      }
      current[keyArray[keyArray.length - 1]] = value;
    },

    handleFileChange: (event) => {
      const fileList = event.target.files;
      const filesArray = Array.from(fileList);
      cmp.set("files", filesArray);
      cmp.startConnect();
    },

    handleDropFiles: (e) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      cmp.handleFileChange({
        target: { files },
      });
    },

    get: (key) => {
      if (!key) return;

      if (props[key]) return props[key];
      if (key.includes(".")) {
        return cmp.nestedGet(cmpWorking.current, key);
      }
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      if (!key) return;

      if (key.includes(".")) {
        cmp.nestedSet(cmpWorking.current, key, value);
      } else {
        cmpWorking.current[key] = value;
      }

      if (key.includes(".")) {
        const firstKeyPart = key.split(".")[0];
        setCmpState((prev) => ({
          ...prev,
          [firstKeyPart]: cloneDeep(cmpWorking.current[firstKeyPart]),
        }));
      } else {
        setCmpState((prev) => ({ ...prev, [key]: value }));
      }
    },

    checkForm: function () {
      try {
        const elements = cmp.findElementsWithCheckFieldId();
        const keysWithoutValue = [];
        elements.forEach((element) => {
          Object.keys(element).forEach((key) => {
            if (!element[key]) {
              keysWithoutValue.push(key);
            }
          });
        });
        cmp.set("missingRequiredFields", keysWithoutValue);

        return keysWithoutValue.length > 0;
      } catch (error) {
        console.error(error.stack);
      }
    },

    removeFieldIfNotEmpty: function (name) {
      try {
        let missingRequiredFields = cmp.get("missingRequiredFields");
        if (name.includes("record")) {
          missingRequiredFields = missingRequiredFields.filter(
            (field) => field !== `record.${name}`
          );
        } else {
          missingRequiredFields = missingRequiredFields.filter(
            (field) => field !== name
          );
        }

        cmp.set("missingRequiredFields", missingRequiredFields);
      } catch (error) {
        console.error(error.stack);
      }
    },

    findElementsWithCheckFieldId: function () {
      try {
        const elementsWithCheckFieldId = [];

        document.querySelectorAll('[id^="checkField-"]').forEach((element) => {
          const id = element.id.replace("checkField-", "");
          const value =
            element.type === "checkbox"
              ? element.checked
              : element.value || element.innerText || element.textContent;
          elementsWithCheckFieldId.push({ [id]: value });
        });

        return elementsWithCheckFieldId;
      } catch (error) {
        console.error(error.stack);
      }
    },

    handleNoneValueForSchedule: function () {
      let record = cmp.get("record");
      if (record.schedule === "--None--") {
        record.schedule = "";
      }
      cmp.set("record", record);
    },

    cardActions: function () {
      const disabled =
        cmpState.record.runStatus === "Running" ||
        cmpState.record.runStatus === "Aborting";

      return (
        <>
          {cmpState.mode === "view" && (
            <IconSettings iconPath="/assets/icons">
              <Dropdown
                assistiveText={{ icon: "More Options" }}
                iconCategory="utility"
                iconName="down"
                iconVariant="border-filled"
                onSelect={(selected) => cmp.handleAction(selected.value)}
                options={[
                  {
                    label: "Check Connection",
                    value: "validate",
                    leftIcon: {
                      category: "utility",
                      name: "key",
                    },
                    disabled: disabled,
                  },
                  {
                    label: "Get Inventory",
                    value: "sync",
                    leftIcon: {
                      category: "utility",
                      name: "sync",
                    },
                    disabled: disabled,
                  },
                  {
                    label: "Load Data",
                    value: "load",
                    leftIcon: {
                      category: "utility",
                      name: "download",
                    },
                    disabled: disabled,
                  },
                  {
                    label: "Abort",
                    value: "abort",
                    leftIcon: {
                      category: "utility",
                      name: "stop",
                    },
                  },
                  {
                    label: "Clear Data",
                    value: "clear",
                    leftIcon: {
                      category: "utility",
                      name: "clear",
                    },
                    disabled: disabled,
                  },
                ]}
                width="xx-small"
                align="right"
                menuAlignment="right"
                className="slds-var-m-horizontal_xxx-small"
                alternativeText="Actions"
              />
            </IconSettings>
          )}
        </>
      );
    },

    body: function () {
      // Combobox gives warning without id
      const statusOptionsWithId =
        cmpState.statusOptions && cmpState.statusOptions.length > 0
          ? cmpState.statusOptions.map((item) => ({
              ...item,
              id: item.label,
            }))
          : cmpState.statusOptions;

      return (
        <IconSettings iconPath="/assets/icons">
          <div
            className="PsConnector slds-form slds-m-around_medium"
            role="list"
          >
            {/* <!-- Connector in progress --> */}
            {cmpState.mode === "view" &&
              (cmpState.record.runStatus === "Running" ||
                cmpState.record.runStatus === "Aborting") && (
                <div>
                  <h3 className="slds-section-title--divider slds-m-top_medium">
                    Connector Running
                  </h3>
                  <div className="message">
                    <p className="slds-p-bottom_x-small">
                      The Connector is running.
                    </p>
                    <p className="slds-p-bottom_x-small">
                      Use the "Check Status" or the refresh buttons to check
                      progress.
                    </p>
                    <Button
                      label="Check Status"
                      title="Check Status"
                      onClick={cmp.handleRefresh}
                      disabled={cmpState.loading}
                      variant="outline-brand"
                    />
                  </div>
                </div>
              )}
            {/* <!-- Connector setup flow --> */}
            {cmpState.mode === "view" &&
              cmpState.record.setupStatus &&
              cmpState.record.runStatus !== "Running" &&
              cmpState.record.runStatus !== "Aborting" && (
                <div>
                  <h3 className="slds-section-title--divider slds-m-top_medium">
                    Next Steps
                  </h3>
                  {/* <ui:message title="" severity="info" closable="false"> */}

                  {cmpState.record.setupStatus === "Connection Validated" && (
                    <div className="message">
                      <p className="slds-p-bottom_x-small">
                        Start by loading the data inventory from the source.
                      </p>
                      <p className="slds-p-bottom_x-small">
                        Once the inventory is loaded, review which Objects and
                        Fields to include, and start loading data.
                      </p>
                      <Button
                        label="Load Inventory"
                        title="Load the data inventory from the source"
                        onClick={cmp.handleSync}
                        disabled={cmpState.loading}
                        variant="outline-brand"
                      />
                    </div>
                  )}

                  {cmpState.record.setupStatus === "Aborted" && (
                    <div className="message">
                      <p className="slds-p-bottom_x-small">
                        Connector was aborted.
                      </p>
                      <p className="slds-p-bottom_x-small">
                        Review which Objects and Fields to inlude or exclude.
                        Set a download schedule, or start loading data.
                      </p>
                      <Button
                        label="Load Data"
                        title="Load data from the source"
                        onClick={cmp.handleLoad}
                        disabled={cmpState.loading}
                        variant="outline-brand"
                      />
                    </div>
                  )}

                  {cmpState.record.setupStatus === "Inventory Loaded" && (
                    <div className="message">
                      <p className="slds-p-bottom_x-small">
                        Inventory successfully loaded.
                      </p>
                      {cmpState.record.supportsSchedule && (
                        <p className="slds-p-bottom_x-small">
                          Review which Objects and Fields to inlude or exclude.
                          Set a download schedule, or start loading data.
                        </p>
                      )}
                      {!cmpState.record.supportsSchedule && (
                        <p className="slds-p-bottom_x-small">
                          Review Objects and Fields to inlude or exclude, and
                          start loading data.
                        </p>
                      )}

                      <Button
                        label="Load Data"
                        title="Load data from the source"
                        onClick={cmp.handleLoad}
                        disabled={cmpState.loading}
                        variant="outline-brand"
                      />
                    </div>
                  )}

                  {cmpState.record.setupStatus === "Data Loaded" && (
                    <div className="message">
                      <p className="slds-p-bottom_x-small">
                        Your data is being processed and scanned for patterns,
                        this may take a few moments.
                      </p>
                      <p className="slds-p-bottom_x-small">
                        Discover results so far in the{" "}
                        <a href="/" onClick={cmp.handleGoToExplore}>
                          Explore
                        </a>{" "}
                        and{" "}
                        <a href="/" onClick={cmp.handleGoToExplore}>
                          Search
                        </a>{" "}
                        tabs.
                      </p>
                      <Button
                        label="Check Status"
                        title="Check Status"
                        onClick={cmp.handleRefresh}
                        disabled={cmpState.loading}
                        variant="outline-brand"
                      />
                    </div>
                  )}

                  {cmpState.record.setupStatus === "Processing Done" && (
                    <div className="message">
                      <p className="slds-p-bottom_x-small">
                        Finished processing your data.
                      </p>
                      <p className="slds-p-bottom_x-small">
                        Discover results in the{" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            cmp.handleGoToExplore();
                          }}
                        >
                          Explore
                        </a>{" "}
                        and{" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            cmp.handleGoToSearch();
                          }}
                        >
                          Search
                        </a>{" "}
                        tabs.
                      </p>
                      {cmpState.connectorType.supportsUpload && (
                        <p className="slds-p-bottom_x-small">
                          Upload more files in the "Upload Files" section, then
                          use the "Load Data" button to load the new data.
                        </p>
                      )}
                      {cmpState.connectorType.supportsCredentials && (
                        <div>
                          {!cmpState.record.schedule && (
                            <p className="slds-p-bottom_x-small">
                              Set a schedule to load new data at regular
                              intervals, or use the "Load Data" button to load
                              the latest data now.
                            </p>
                          )}
                          {cmpState.record.schedule && (
                            <p className="slds-p-bottom_x-small">
                              Use the "Load Data" button to load the latest
                              data.
                            </p>
                          )}
                        </div>
                      )}
                      <Button
                        label="Load Data"
                        title="Load data from the source"
                        onClick={cmp.handleLoad}
                        disabled={cmpState.loading}
                        variant="outline-brand"
                      />
                    </div>
                  )}
                  {/* </ui:message> */}
                </div>
              )}
            {/* <!-- Authentication section --> */}
            {cmpState.mode === "view" &&
              cmpState.connectorType.supportsCredentials &&
              cmpState.record.runStatus !== "Running" && (
                <div>
                  <h3 className="slds-section-title--divider slds-m-top_medium">
                    Connector Setup
                  </h3>
                  <div className="slds-form__row">
                    <div className="slds-form__item" role="listitem">
                      <div className="slds-form-element slds-form-element_stacked">
                        {cmpState.record.setupStatus && (
                          <span className="slds-form-element__label">
                            Successfully connected.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="slds-form__row">
                    <div className="slds-form__item" role="listitem">
                      <div className="slds-form-element slds-form-element_stacked">
                        <div className="slds-form-element__control">
                          {cmpState.defaultConnectMethod.button &&
                            !cmpState.record.setupStatus && (
                              <Button
                                label={cmpState.defaultConnectMethod.button}
                                title={cmpState.defaultConnectMethod.button}
                                onClick={cmp.handleStartConnect}
                                disabled={cmpState.loading}
                                variant="brand"
                              />
                            )}
                          <Button
                            label="Setup..."
                            title="Set connection details"
                            onClick={cmp.handleEditCredentials}
                            disabled={cmpState.loading}
                            variant="outline-brand"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {/* <!-- Upload section (supportsUpload only) --> */}
            {cmpState.mode === "view" &&
              cmpState.connectorType.supportsUpload &&
              cmpState.record.runStatus !== "Running" && (
                <div>
                  <h3 className="slds-section-title--divider slds-m-top_medium">
                    Upload Files
                  </h3>
                  <div className="slds-form__row">
                    <div
                      className="slds-form__item slds-grid_vertical-align-center"
                      role="listitem"
                    >
                      <div className="slds-form-element slds-form-element_stacked">
                        <div
                          className={
                            cmpState.uploading
                              ? "slds-hide"
                              : "slds-show_inline"
                          }
                        >
                          {/* // <!-- show extra input fields for the default connector --> */}
                          {/* Iteration */}
                          {cmpState.defaultConnectMethod.inputs.map(
                            (item, index) => {
                              return (
                                // extra div might break the row part
                                <div
                                  key={"defaultConnectMethod_inputs_" + index}
                                >
                                  {item.type && (
                                    <div className="slds-form__row">
                                      <div
                                        className="slds-form__item slds-grid_vertical-align-center"
                                        role="listitem"
                                      >
                                        <div className="slds-p-top_x-small">
                                          <div className="slds-form-element slds-form-element_stacked ">
                                            <div className="slds-text-color_weak">
                                              {item.label}
                                            </div>
                                            <div
                                              className="slds-box slds-box_x-small"
                                              onDragOver={(e) =>
                                                e.preventDefault()
                                              }
                                              onDrop={(e) =>
                                                cmp.handleDropFiles(e)
                                              }
                                            >
                                              <div style={{ display: "flex" }}>
                                                <button
                                                  className="slds-button slds-button_outline-brand"
                                                  onClick={() =>
                                                    fileInputRef.current.click()
                                                  }
                                                  onDragOver={(e) =>
                                                    e.preventDefault()
                                                  }
                                                  onDrop={(e) =>
                                                    cmp.handleDropFiles(e)
                                                  }
                                                >
                                                  <svg
                                                    className="slds-button__icon slds-button__icon_left"
                                                    aria-hidden="true"
                                                  >
                                                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#upload"></use>
                                                  </svg>
                                                  Click to Upload or drop files
                                                  <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    multiple
                                                    onChange={
                                                      cmp.handleFileChange
                                                    }
                                                    id="fileUpload-input"
                                                    placeholder={item.default}
                                                    name="file"
                                                    label={item.label}
                                                    // fieldLevelHelp={item.help}
                                                    value={item.value}
                                                    style={{ display: "none" }}
                                                  />
                                                </button>
                                                {cmpState.files.length > 0 && (
                                                  <Files cmpState={cmpState} />
                                                )}
                                              </div>
                                            </div>
                                          </div>

                                          {/* TODO accept="{!item.accept} multiple="{!item.multiple}   */}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                          <div className="slds-form__row">
                            <div
                              className="slds-form__item slds-grid_vertical-align-center"
                              role="listitem"
                            >
                              <Checkbox
                                assistiveText={{
                                  label: "Default",
                                }}
                                id="checkField_loadAllData"
                                labels={{
                                  label: "Load all data",
                                }}
                                onChange={(e) => {
                                  cmp.set("loadAllData", !cmpState.loadAllData);
                                }}
                                checked={cmpState.loadAllData}
                              />
                              <Tooltip
                                id="base"
                                align="top left"
                                content="Load all data after uploading. Uncheck to read the file's inventory only, then select Objects and Fields to include."
                                variant="learnMore"
                                dialogClassName="dialog-classname"
                              />
                            </div>
                          </div>
                        </div>
                        {cmpState.uploading && (
                          <div>
                            <p>&nbsp;</p>
                            <p>Uploading...</p>
                            <ProgressBarComponent />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {/* <!-- Connector details --> */}
            <h3 className="slds-section-title--divider slds-m-top_medium">
              Connector Details
            </h3>
            <div className="slds-form__row">
              {/* <!-- Name --> */}
              <div className="slds-form__item" role="listitem">
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}
                  {cmpState.mode === "view" && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">Name</span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {cmpState.record.name}
                        </div>
                        <div className="slds-button slds-button__icon slds-button__icon_hint">
                          <EditButtonIcon handleEdit={cmp.handleEdit} />
                        </div>
                      </div>
                    </div>
                  )}

                  {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                    <Input
                      id="checkField-record.name"
                      name="name"
                      onChange={this.handleRecordChange}
                      label="Name"
                      value={cmpState.record.name}
                      autoComplete="off"
                      required={true}
                      errorText={
                        cmpState.missingRequiredFields.includes(
                          "record.name"
                        ) && !cmpState.record.name
                          ? "Complete this field."
                          : null
                      }
                    />
                  )}
                </div>
              </div>

              {/* <!-- Source --> */}
              <div
                className="slds-form__item slds-grid_vertical-align-center"
                role="listitem"
              >
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}
                  {((cmpState.mode === "new" && cmpState.externalSource) ||
                    cmpState.mode === "view" ||
                    cmpState.mode === "edit") && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">Source</span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {cmpState.record.sourceName}
                        </div>
                      </div>
                    </div>
                  )}
                  {cmpState.mode === "new" && !cmpState.externalSource && (
                    <Combobox
                      id="checkField-record.sourceId"
                      events={{
                        onSelect: (event, data) => {
                          this.selectSource(data);
                        },
                      }}
                      labels={{
                        label: "Source",
                        placeholderReadOnly: "--Please Select --",
                      }}
                      options={cmpState.sourceOptions}
                      selection={cmpState.sourceSelection}
                      value={cmpState.record.sourceId} //input value? set to blank value in example
                      variant="readonly"
                      required={true}
                      errorText={
                        cmpState.missingRequiredFields.includes(
                          "record.sourceId"
                        ) && !cmpState.record.sourceId
                          ? "Complete this field."
                          : null
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="slds-form__row">
              {/* <!-- Type --> */}
              <div
                className="slds-form__item slds-grid_vertical-align-center"
                role="listitem"
              >
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}
                  {(cmpState.mode === "view" || cmpState.mode === "edit") && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">
                        Connector Type
                      </span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {cmpState.record.connectorTypeName}
                        </div>
                      </div>
                    </div>
                  )}
                  {cmpState.mode === "new" && (
                    <Combobox
                      id="checkField-record.connectorTypeId"
                      events={{
                        onSelect: (event, data) => {
                          cmp.selectConnectorType(data);
                        },
                      }}
                      labels={{
                        label: "Connector",
                        placeholderReadOnly: "--Please Select --",
                      }}
                      options={cmpState.connectorTypeOptions}
                      selection={[
                        cmpState.connectorTypeSelection.find(
                          (option) =>
                            option.value === cmpState.record.connectorTypeId
                        ),
                      ]}
                      value={cmpState.record.connectorTypeId}
                      variant="readonly"
                      required={true}
                      errorText={
                        cmpState.missingRequiredFields.includes(
                          "record.connectorTypeId"
                        ) && !cmpState.record.connectorTypeId
                          ? "Complete this field."
                          : null
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="slds-form__row">
              {/* <!-- Default Object Status --> */}
              <div
                className="slds-form__item slds-grid_vertical-align-center"
                role="listitem"
              >
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}
                  {cmpState.mode === "view" && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">
                        Default Object Status
                      </span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {cmpState.record.defaultObjectStatus}
                        </div>
                        <div className="slds-button slds-button__icon slds-button__icon_hint">
                          <EditButtonIcon handleEdit={cmp.handleEdit} />
                        </div>
                      </div>
                    </div>
                  )}
                  {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                    <Combobox
                      id="checkField-defaultObjectStatus"
                      events={{
                        onSelect: (event, data) => {
                          cmp.selectObjectStatus(data);
                        },
                      }}
                      labels={{
                        label:
                          "Default status for Objects added to the inventory:",
                        placeholderReadOnly: "--Please Select --",
                      }}
                      options={cmpState.objectStatusOptions}
                      // selection={cmpState.objectStatusSelection} // For this to work, selection must also be set when the record is set at the beginning.
                      selection={[
                        cmpState.objectStatusOptions.find(
                          (option) =>
                            option.value === cmpState.record.defaultObjectStatus
                        ),
                      ]}
                      value={cmpState.record.defaultObjectStatus}
                      variant="readonly"
                    />
                  )}
                </div>
              </div>

              {/* <!-- Default Field Status --> */}
              <div
                className="slds-form__item slds-grid_vertical-align-center"
                role="listitem"
              >
                <div className="slds-form-element slds-form-element_stacked">
                  {cmpState.mode === "init" && <ProgressBarComponent />}
                  {cmpState.mode === "view" && (
                    <div
                      id="FormDiv"
                      className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                    >
                      <span className="slds-form-element__label">
                        Default Field Status
                      </span>
                      <div className="slds-form-element__control">
                        <div className="slds-form-element__static">
                          {cmpState.record.defaultFieldStatus}
                        </div>
                        <div className="slds-button slds-button__icon slds-button__icon_hint">
                          <EditButtonIcon handleEdit={cmp.handleEdit} />
                        </div>
                      </div>
                    </div>
                  )}
                  {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                    <div>
                      <Combobox
                        id="checkField-defaultFieldStatus"
                        events={{
                          onSelect: (event, data) => {
                            cmp.selectFieldStatus(data);
                          },
                        }}
                        labels={{
                          label:
                            "Default status for Fields added to the inventory:",
                          placeholderReadOnly: "--Please Select --",
                        }}
                        options={cmpState.fieldStatusOptions}
                        // selection={cmpState.fieldStatusSelection} // For this to work, selection must also be set when the record is set at the beginning.
                        selection={[
                          cmpState.fieldStatusOptions.find(
                            (option) =>
                              option.value ===
                              cmpState.record.defaultFieldStatus
                          ),
                        ]}
                        value={cmpState.record.defaultFieldStatus}
                        variant="readonly"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <!-- Status section (only when runStatus is set) --> */}
            {cmpState.mode === "view" && cmpState.record.runStatus && (
              <h3 className="slds-section-title--divider slds-m-top_medium">
                Status
              </h3>
            )}
            {/* Issue with row being inside another div, so I need to put them in separate conditional blocks */}
            {cmpState.mode === "view" && cmpState.record.runStatus && (
              <div className="slds-form__row">
                {/* <!-- Last Run Status --> */}
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {cmpState.mode === "view" && (
                      <div
                        id="FormDiv"
                        className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                      >
                        <span className="slds-form-element__label">
                          Last Run Status
                        </span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {cmpState.record.runStatus}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* <!-- Last Run Completed --> */}
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {cmpState.mode === "view" && (
                      <div
                        id="FormDiv"
                        className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                      >
                        <span className="slds-form-element__label">
                          Next Scheduled Run
                        </span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {cmpState.record.nextScheduledRun &&
                              format(
                                new Date(cmpState.record.nextScheduledRun),
                                "d MMMM yyyy 'at' HH:mm:ss"
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {cmpState.mode === "view" && cmpState.record.runStatus && (
              <div className="slds-form__row">
                {/* <!-- Last Run Started--> */}
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {cmpState.mode === "view" && (
                      <div
                        id="FormDiv"
                        className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                      >
                        <span className="slds-form-element__label">
                          Last Run Started
                        </span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {cmpState.record.runStart &&
                              format(
                                new Date(cmpState.record.runStart),
                                "d MMMM yyyy 'at' HH:mm:ss"
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* <!-- Last Run Completed --> */}
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {cmpState.mode === "view" && (
                      <div
                        id="FormDiv"
                        className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                      >
                        <span className="slds-form-element__label">
                          Last Run Completed
                        </span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {cmpState.record.runEnd &&
                              format(
                                new Date(cmpState.record.runEnd),
                                "d MMMM yyyy 'at' HH:mm:ss"
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {cmpState.mode === "view" && cmpState.record.runStatus && (
              <div className="slds-form__row">
                {/* <!-- Last Run Message --> */}
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {cmpState.mode === "view" && (
                      <div
                        id="FormDiv"
                        className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                      >
                        <span className="slds-form-element__label">
                          Last Run Message
                        </span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {cmpState.record.runMessage}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* <!-- Download Settings Section --> */}
            {(cmpState.connectorType.supportsSchedule ||
              cmpState.connectorType.supportsConcurrency) && (
              <h3 className="slds-section-title--divider slds-m-top_medium">
                Download Settings
              </h3>
            )}
            {cmpState.connectorType.supportsSchedule && (
              <div className="slds-form__row">
                {/* <!-- Status --> */}
                <div
                  className="slds-form__item slds-grid_vertical-align-center"
                  role="listitem"
                >
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {cmpState.mode === "view" && (
                      <div
                        id="FormDiv"
                        className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                      >
                        <span className="slds-form-element__label">Status</span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {cmpState.record.status}
                          </div>
                          <div className="slds-button slds-button__icon slds-button__icon_hint">
                            <EditButtonIcon handleEdit={cmp.handleEdit} />
                          </div>
                        </div>
                      </div>
                    )}
                    {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                      <Combobox
                        id="checkField-status"
                        events={{
                          onSelect: (event, data) => {
                            cmp.selectStatus(data);
                          },
                        }}
                        labels={{
                          label: "Status",
                          placeholderReadOnly: "--Please Select --",
                        }}
                        options={statusOptionsWithId}
                        selection={[
                          statusOptionsWithId.find(
                            (option) => option.value === cmpState.record.status
                          ),
                        ]}
                        value={cmpState.record.status}
                        variant="readonly"
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {cmpState.connectorType.supportsSchedule && (
              <div className="slds-form__row">
                {/* <!-- Schedule --> */}
                <div
                  className="slds-form__item slds-grid_vertical-align-center"
                  role="listitem"
                >
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {cmpState.mode === "view" && (
                      <div
                        id="FormDiv"
                        className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                      >
                        <span className="slds-form-element__label">
                          Schedule
                        </span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {cmpState.record.schedule}
                          </div>
                          <div className="slds-button slds-button__icon slds-button__icon_hint">
                            <EditButtonIcon handleEdit={cmp.handleEdit} />
                          </div>
                        </div>
                      </div>
                    )}
                    {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                      <Combobox
                        events={{
                          onSelect: (event, data) => {
                            cmp.selectSchedule(data);
                          },
                        }}
                        labels={{
                          label: "How often should data be loaded?",
                          placeholderReadOnly: "--Please Select --",
                        }}
                        options={cmpState.scheduleOptions}
                        selection={
                          cmpState.record.schedule === ""
                            ? [cmpState.scheduleOptions[0]]
                            : [
                                cmpState.scheduleOptions.find(
                                  (item) =>
                                    item.value === cmpState.record.schedule
                                ),
                              ]
                        }
                        value={cmpState.record.schedule}
                        variant="readonly"
                      />
                    )}
                  </div>
                </div>

                {/* <!-- Placeholder (can put custom cron expression in the future ) --> */}
                <div
                  className="slds-form__item slds-grid_vertical-align-center"
                  role="listitem"
                >
                  <div className="slds-form-element slds-form-element_stacked"></div>
                </div>
              </div>
            )}
            {cmpState.connectorType.supportsConcurrency && (
              <div className="slds-form__row">
                {/* <!-- Concurrency --> */}
                <div
                  className="slds-form__item slds-grid_vertical-align-center"
                  role="listitem"
                >
                  <div className="slds-form-element slds-form-element_stacked">
                    {cmpState.mode === "init" && <ProgressBarComponent />}
                    {cmpState.mode === "view" && (
                      <div
                        id="FormDiv"
                        className="slds-form-element_edit slds-form-element_readonly slds-form-element_stacked slds-hint-parent"
                      >
                        <span className="slds-form-element__label">
                          Concurrency
                        </span>
                        <div className="slds-form-element__control">
                          <div className="slds-form-element__static">
                            {cmpState.record.concurrency}
                          </div>
                          <div className="slds-button slds-button__icon slds-button__icon_hint">
                            <EditButtonIcon handleEdit={cmp.handleEdit} />
                          </div>
                        </div>
                      </div>
                    )}
                    {(cmpState.mode === "new" || cmpState.mode === "edit") && (
                      <div>
                        <Input
                          id="concurrency"
                          type="number"
                          name="concurrency"
                          label="Concurrency"
                          fieldLevelHelp="How many Objects may be loaded concurrently? Leave empty to disable concurrent loading, or set to 0 to use the maximum concurrency for your account."
                          value={cmpState.record.concurrency}
                          onChange={this.handleRecordChange}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* <!-- Placeholder --> */}
                <div
                  className="slds-form__item slds-grid_vertical-align-center"
                  role="listitem"
                >
                  <div className="slds-form-element slds-form-element_stacked"></div>
                </div>
              </div>
            )}
            {/* <!-- Authentication Details Modal Window --> */}
            {cmpState.credentialsModalIsOpen && (
              <AuthenticationDetailsModalWindow cmpState={cmpState} cmp={cmp} />
            )}
          </div>
        </IconSettings>
      );
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },
  };

  return (
    <>
      {cmpState.showDeleteConfirmDialog ? (
        <Modal
          apply={() => PsRecord.confirmDeleteRecord(cmp)}
          cancel={() => PsRecord.cancelDeleteRecord(cmp)}
          header="Confirmation"
          modalContent="Deleting this Record will also delete all its associated loaded data. Are you sure?"
          applyButtonContent="Delete"
        />
      ) : null}
      {PsRecord.render(cmp, cmpState)}
    </>
  );
};

export default PsConnector;
