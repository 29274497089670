import "@blueprintjs/table/lib/css/table.css";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Table2, Column, Cell } from "@blueprintjs/table";
import {
  HotkeysProvider,
  Menu,
  MenuItem,
  Classes,
  MenuDivider,
} from "@blueprintjs/core";

const DataTable = ({
  columns,
  data,
  onheaderaction,
  isEditable,
  handleTableResize,
}) => {
  const convertArrayToObjects = (dataArray, columnLabels) => {
    return dataArray.map((item) => {
      const convertedItem = { id: item[0]?.toString() };

      columnLabels.forEach((column, index) => {
        convertedItem[column.label] = item[index + 1]?.toString();
      });

      return convertedItem;
    });
  };

  const records = convertArrayToObjects(data, columns);

  const cellRenderer = (rowIndex, colLabel) => {
    return <Cell> {records[rowIndex][colLabel]}</Cell>;
  };

  const onSortAction = (colLabel, sortDirection) => {
    console.log("onSortAction", colLabel, sortDirection);
  };

  const renderMenu = (column) => {
    if (!isEditable) {
      return;
    }

    return (
      <Menu className={Classes.ELEVATION_1}>
        {column.actions.map((item) => (
          <MenuItem
            icon="edit"
            key={item.label}
            text={item.label}
            onClick={() => onheaderaction(item)}
          />
        ))}
        {/* <MenuDivider />
        <MenuItem
          icon="sort-asc"
          text="Sort Asc"
          onClick={() => onSortAction(column.label, "asc")}
        />
        <MenuItem
          icon="sort-desc"
          text="Sort Desc"
          onClick={() => onSortAction(column.label, "desc")}
        /> */}
      </Menu>
    );
  };

  const handleColumnWidthChanged = (index, width) => {
    const widths = columns.map((col, colIndex) => {
      if (colIndex === index) {
        return width;
      } else {
        return col.columnWidth || null;
      }
    });

    handleTableResize(widths);
  };

  return (
    <>
      <HotkeysProvider>
        <Table2
          numRows={data.length}
          enableColumnResizing={isEditable}
          columnWidths={columns.map(
            (col) => col.columnWidth || col.initialWidth || 150
          )}
          onColumnWidthChanged={(index, width) =>
            handleColumnWidthChanged(index, width)
          }
        >
          {columns.map((col) => {
            return (
              <Column
                key={col.label}
                name={col.label}
                cellRenderer={(rowIndex) => cellRenderer(rowIndex, col.label)}
                menuRenderer={() => renderMenu(col)}
              />
            );
          })}
        </Table2>
      </HotkeysProvider>
    </>
  );
};

export default DataTable;
