import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "@salesforce/design-system-react/components/button";
import Card from "@salesforce/design-system-react/components/card";

import "./Home.css";
import Record from "../../helpers/recordLayer";
import PsSetupStatus from "../../components/ps-setup-status/PsSetupStatus";

function Home() {
  const [cmpState, setCmpState] = useState({
    setupDone: true,
    loading: "",
    hasAdminPermission: true,

    parentToChildEvent: { action: "" },
  });

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const cmpWorking = useRef({});

  // Todo: React Hook useEffect has a missing dependency: 'cmpState'. Either include it or remove the dependency array
  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    if (searchParams.get("selectedObject")) {
      cmp.set("selectedObject", searchParams.get("selectedObject"));
    }
    onPageReferenceChange();

    setCmpState({ ...cmpWorking.current });
  }, [searchParams]); //runs on init and when search params are updated

  const cmp = {
    get: (key) => {
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },
  };

  const checkSetupStatus = () => {
    var doneStatus = "Complete";
    Record.getAccountSettings(cmp, doneStatus);
  };

  const onPageReferenceChange = () => {
    try {
      // checkUrlMessage();
      // parsePageRef();
      checkSetupStatus();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const checkLoading = function () {};
  const doneLoading = function () {};

  const exploreClick = function () {
    navigate("/Explore", { replace: false });
  };

  const searchClick = function () {
    navigate("/Search", { replace: false });
  };

  const savedInsightsClick = function () {
    navigate("/SavedInsights", { replace: false });
  };

  const setupClick = function () {
    navigate("/Setup", { replace: false });
  };

  const manageData = function () {
    navigate("/DataManagement", { replace: false });
  };

  return (
    <div className="Home">
      {/* <!-- prevent scrolling before setup is complete --> */}
      {/* <div style={cmpState.setupDone ? 'overflow: auto' : 'overflow: hidden'}>  */}
      <div
        style={{
          position: "relative",
        }}
        className={
          cmpState.setupDone ? "slds-scrollable left" : "right right-noscroll"
        }
      >
        {/* <div> */}
        {/* <c:SetupStatus aura:id="setupStatus" doneStatus="Complete" setupDone="{!cmpState.setupDone}" title="Home" tagLine="Welcome to Point Sigma."/> */}
        <PsSetupStatus
          id="setupStatus"
          doneStatus="Complete"
          setupDone={cmpState.setupDone}
          title="Home"
          tagLine="Welcome to Point Sigma."
          parentToChildEvent={cmpState.parentToChildEvent}
          parentCmp={cmp}
        />{" "}
        {cmpState.loading && (
          <Card
            id="x"
            className="slds-card_boundary"
            footer={
              <div>
                {cmpState.loading === "progress" && (
                  <Button label="Check Status" onClick={checkLoading} />
                )}

                {cmpState.loading === "done" && (
                  // brand
                  <Button label="Continue" onClick={doneLoading} />
                )}
              </div>
            }
          >
            <div className="slds-p-horizontal_medium">
              {/* <!-- image --> */}
              <div
                className="slds-p-around_medium slds-illustration slds-illustration_large"
                aria-hidden="true"
              >
                {/* <!-- using slds-hide instead of aura-if to prevent flickering while loading the next image--> */}
                <img
                  className={cmpState.loading === "progress" ? "" : "slds-hide"}
                  src="/assets/images/preview/loading.gif"
                  alt="loading"
                />
                <img
                  className={cmpState.loading === "done" ? "" : "slds-hide"}
                  src="/assets/images/preview/loading.gif"
                  alt="loading"
                />
              </div>
              <div className="progress-center slds-p-bottom_medium">
                <div className="slds-text-color_weak">
                  <h1 className="slds-text-heading_medium slds-p-bottom_x-small">
                    {cmpState.loading === "progress" && (
                      <p>Analyzing data with Artificial Curiosity...</p>
                    )}
                    {cmpState.loading === "done" && <p>Processing Complete!</p>}
                  </h1>
                </div>
              </div>

              {/* <!-- progress --> */}
              <div className="progress-center slds-p-bottom_small">
                <div className="slds-text-color_weak card-content">
                  {cmpState.loading === "progress" && (
                    // <lightning:progressBar value="60" variant="circular" size="large"/>
                    <p>
                      Your data is being processed and scanned for patterns,
                      this may take a few moments.
                    </p>
                  )}
                  {cmpState.loading === "done" && (
                    // <lightning:progressBar value="100" variant="circular" size="large"/>
                    <p>
                      Click{" "}
                      <a href="/" onClick={doneLoading}>
                        Continue
                      </a>{" "}
                      to discover insights and answers in your data.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Card>
        )}
        {!cmpState.loading && (
          <div className="slds-grid slds-wrap slds-gutters_direct-x-small slds-grid_align-center">
            <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
              <Card
                id="x"
                heading={<b>Explore</b>}
                className="slds-card_boundary"
                footer={
                  <Button
                    label={<b>Start Exploring</b>}
                    name="Start Exploring"
                    onClick={() => exploreClick()}
                    variant="brand"
                  />
                }
              >
                <div className="card-content slds-p-around_medium">
                  <p className="card-text">
                    Explore discovered patterns using simple navigation.
                  </p>
                  <div className="slds-p-vertical_medium slds-text-align_center">
                    <div className="gif-hover-active" onClick={exploreClick}>
                      {/* <img src={"/assets/images/connectors/" +  props.record.connectorType.image}  className="illustration-medium" alt={ props.record.connectorType.image} /> */}
                      <img
                        src="/assets/images/previews/explore.png"
                        alt="explore"
                        className="static"
                      />
                      <img
                        src="/assets/images/previews/explore.gif"
                        alt="explore"
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
              <Card
                id="x"
                heading={<b>Search</b>}
                className="slds-card_boundary"
                footer={
                  <Button
                    label={<b>Search Now</b>}
                    name="Start Exploring"
                    onClick={() => searchClick()}
                    variant="brand"
                  />
                }
              >
                <div className="card-content slds-p-around_medium">
                  <p className="card-text">
                    Find the insights you need by typing just a few keywords.
                  </p>
                  <div className="slds-p-vertical_medium slds-text-align_center">
                    <div className="gif-hover-active" onClick={searchClick}>
                      <img
                        src="/assets/images/previews/search.png"
                        alt="explore"
                        className="static"
                      />
                      <img
                        src="/assets/images/previews/search.gif"
                        alt="explore"
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
              <Card
                id="x"
                heading={<b>Saved Insights</b>}
                className="slds-card_boundary"
                footer={
                  <Button
                    label={<b>Show Saved Insights</b>}
                    name="Show Saved Insights"
                    onClick={() => savedInsightsClick()}
                    variant="brand"
                  />
                }
              >
                <div className="card-content slds-p-around_medium">
                  <p className="card-text">
                    View saved and liked insights, and organize insights as
                    dashboards.
                  </p>
                  <div className="slds-p-vertical_medium slds-text-align_center">
                    <div
                      className="gif-hover-active"
                      onClick={savedInsightsClick}
                    >
                      <img
                        src="/assets/images/previews/saved.png"
                        alt="explore"
                        className="static"
                      />
                      <img
                        src="/assets/images/previews/saved.gif"
                        alt="explore"
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            {/* {cmpState.hasAdminPermission && (
              <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
                <Card
                  id="x"
                  heading={<b>Setup</b>}
                  className="slds-card_boundary"
                  footer={
                    <Button
                      label={<b>Setup</b>}
                      name="Setup"
                      onClick={() => setupClick()}
                      variant="brand"
                    />
                  }
                >
                  <div className="card-content slds-p-around_medium">
                    <p className="card-text">
                      Authenticate Point Sigma and manage your encryption key.
                    </p>
                  </div>
                </Card>
              </div>
            )} */}

            {cmpState.hasAdminPermission && (
              <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
                <Card
                  id="x"
                  heading={<b>Data Management</b>}
                  className="slds-card_boundary"
                  footer={
                    <Button
                      label={<b>Manage Data</b>}
                      name="Manage Data"
                      onClick={() => manageData()}
                      variant="brand"
                    />
                  }
                >
                  <div className="card-content slds-p-around_medium">
                    <p className="card-text">
                      Create and manage connected data sources.
                      <br />
                    </p>
                  </div>
                </Card>
              </div>
            )}

            {cmpState.hasAdminPermission && (
              <div className="slds-col slds-var-m-bottom_medium slds-size_1-of-1 slds-small-size_2-of-3 slds-medium-size_1-of-2 slds-large-size_1-of-3">
                <Card
                  id="x"
                  heading={<b>Contact Us</b>}
                  className="slds-card_boundary"
                  footer={
                    <a
                      href="https://calendly.com/point-sigma/call"
                      className="slds-button slds-button_brand"
                      onClick={() => console.log("Book a Call...")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b>Book a Call</b>
                    </a>
                  }
                >
                  <div className="card-content slds-p-around_medium">
                    <p className="card-text">
                      Contact support on{" "}
                      <a
                        href="mailto:support@point-sigma.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@point-sigma.com
                      </a>{" "}
                      or book a call to speak with our team.
                      <br />
                    </p>
                  </div>
                </Card>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
