import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const tabs = [
  { path: "/", title: "Home" },
  { path: "/Explore", title: "Explore" },
  { path: "/Search", title: "Search" },
  { path: "/SavedInsights", title: "Saved Insights" },
  // { path: "/Setup", title: "Setup" },
  { path: "/DataManagement", title: "Data Management" },
  { path: "/StoreManagement", title: "Store Management" },
];

const PsGlobalNavigationBar = () => {
  const [selectedTab, setSelectedTab] = useState("/");

  const location = useLocation();

  useEffect(() => {
    setSelectedTab(location.pathname);
  }, [location.pathname]);

  const onLinkClick = (path) => {
    setSelectedTab(path);
  };

  const activeClassName = "slds-context-bar__item slds-is-active";
  const inactiveClassName = "slds-context-bar__item";

  return (
    <div
      className="slds-context-bar"
      style={{
        position: "fixed",
        top: "50px",
        width: "100%",
        zIndex: "1",
      }}
    >
      <div className="slds-context-bar__primary">
        <div className="slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click slds-no-hover">
          <span className="slds-context-bar__label-action slds-context-bar__app-name">
            <span className="slds-truncate" title="Point Sigma">
              Point Sigma
            </span>
          </span>
        </div>
      </div>
      <nav className="slds-context-bar__secondary" role="navigation">
        {tabs.map((tab) => (
          <li
            key={tab.path}
            className={
              selectedTab === tab.path ? activeClassName : inactiveClassName
            }
            onClick={() => onLinkClick(tab.path)}
          >
            <Link
              to={tab.path}
              className="slds-context-bar__label-action"
              style={{ textDecoration: "none" }}
            >
              <span className="slds-truncate" title={tab.title}>
                {tab.title}
              </span>
            </Link>
          </li>
        ))}
      </nav>
    </div>
  );
};

export default PsGlobalNavigationBar;
