import { useEffect, useRef, useState } from "react";
import { Button } from "@salesforce/design-system-react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";

const PsFilterList = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Filter",
    recordLabelPlural: "Filters",
    emptyLine: "No Filters found",
    recordModule: "store",
    recordObject: "filter",
    changeView: false,

    //////
    loading: false,
    orderBy: "name",
    orderDirection: "desc",
    recordList: [],
    recordColumns: [],
    view: "table",
    showTitle: true,
    hasMore: false,
    mode: "init",
    showLoadMore: true,
    showCardActions: true,
    showEmptyCallToAction: true,

    loadingMore: false,
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };

    cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.handleReset();
  }, [props.parentId, props.queryFilter]);

  const cmp = {
    // --- FilterListController.js ---

    init: function () {
      try {
        PsRecordGrid.setRecordColumns(cmp);
      } catch (err) {
        console.error(err.stack);
      }
    },

    afterScriptsLoaded: function () {
      try {
        var numRecords = cmp.get("maxRecords");
        PsRecordGrid.getRecords(cmp, numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      try {
        var maxRecords = cmp.get("maxRecords");
        var numRecords = !maxRecords
          ? 0
          : Math.max(cmp.get("recordList").length, maxRecords);
        PsRecordGrid.reset(cmp);
        PsRecordGrid.getRecords(cmp, numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleReset: function () {
      try {
        var numRecords = cmp.get("maxRecords");
        PsRecordGrid.reset(cmp);
        PsRecordGrid.getRecords(cmp, numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleLoadMore: function (cmp, event, helper) {
      try {
        var numRecords = cmp.get("v.maxRecords");
        helper.getRecords(cmp, numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleNew: function () {
      try {
        var parentNav =
          (cmp.get("parentPrefix") || "") + (cmp.get("parentId") || "");
        var recordModule = cmp.get("recordModule");
        var recordObject = cmp.get("recordObject");

        PsRecordGrid.notifyNavigation(
          cmp,
          parentNav,
          recordModule,
          recordObject,
          null
        );
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleRecordRowAction: function (row) {
      try {
        const action = row.action.name;
        var parentId = cmp.get("parentId");
        var recordModule = cmp.get("recordModule");
        var recordObject = cmp.get("recordObject");
        switch (action) {
          case "details":
            PsRecordGrid.notifyNavigation(
              cmp,
              parentId,
              recordModule,
              recordObject,
              row.id
            );
            break;
          default:
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- FilterListHelper.js ---

    RECORD_COLUMNS: [
      {
        label: "Name",
        type: "link",
        property: "name",
        key: "name",
        sortable: true,
        action: "details",
        title: "View Details",
      },
      {
        label: "# Distinct",
        width: "110px",
        property: "robustDistinct",
        key: "robustDistinct",
        type: "number",
      },
      {
        label: "Relevance",
        width: "110px",
        property: "relevance",
        key: "relevance",
        type: "number",
        sortable: true,
      },
      {
        label: "Latest Data",
        width: "200px",
        property: "dataLastChangedOn",
        key: "dataLastChangedOn",
        type: "formattedDate",
      },
    ],

    parseResponse: function (response) {
      return response.map(
        ({
          id,
          name,
          container,
          robustDistinct,
          relevance,
          dataLastChangedOn,
        }) => ({
          id,
          name,
          source: container.source.name,
          container: container.name,
          robustDistinct,
          relevance,
          dataLastChangedOn,
        })
      );
    },

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    cardActions: function () {
      return (
        <>
          {cmpState.mode === "init" || cmpState.mode === "view" ? (
            <Button
              // id="ViewModeButton"
              disabled={cmpState.loading}
              label="New"
              title={"Create a new " + cmpState.recordLabel}
              onClick={() => cmp.handleNew()}
            />
          ) : null}
        </>
      );
    },

    emptyCallToAction: function () {
      return (
        <Button
          // id="ViewModeButton"
          disabled={cmpState.loading}
          label="Create Filter"
          title={!"Create a new " + cmpState.recordLabel}
          onClick={() => cmp.handleNew()}
          variant="brand"
        />
      );
    },
  };

  return PsRecordGrid.render(cmp, cmpState);
};
export default PsFilterList;
