import { useEffect } from "react";
import { IconSettings, Toast } from "@salesforce/design-system-react";
import ToastContainer from "@salesforce/design-system-react/components/toast/container";

const ToastComponent = ({ close, details, variant, heading, duration }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      close();
    }, duration || 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <IconSettings iconPath="/assets/icons">
      <ToastContainer>
        <Toast
          labels={{
            heading: heading || "",
            details: details,
          }}
          variant={variant}
          onRequestClose={() => close()}
        />
      </ToastContainer>
    </IconSettings>
  );
};

export default ToastComponent;
