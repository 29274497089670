import { useState, useEffect, useRef } from "react";

import Record from "../../helpers/recordLayer";

// import { RECORD_COLUMNS, parseResponse } from "./components/Helper";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";

const PsFolderList = (props) => {
  const [cmpState, setCmpState] = useState({
    // <!-- interface -->
    recordLabel: "Folder", //"Record",
    recordLabelPlural: "Folders", //"Records",
    // title:"",
    // header" type="Aura.Component[]"/> <!-- extra content at top-->
    showTitle: false, //true,
    showFooter: true,
    tagLine: "Select a folder to view saved insights",
    emptyLine: "No records found for the specified criteria", //"Create a new Record",
    recordModule: "relate",
    recordObject: "folder",
    gridComponent: "PatternChart", //"",
    gridComponents: [], //NEW
    cardActions: [],
    emptyCallToAction: [],
    gridItems: [],
    viewOptions: [
      { label: "Table", value: "table" },
      { label: "Grid", value: "grid" },
    ],
    changeView: false, // <!-- whether to render radiobuttons that change the default view -->
    showEdit: false, // <!-- whether to show the 'Edit' button -->
    showLoadMore: true, // <!-- whether to show the 'Load More' button -->
    view: props.view, // "table", // <!-- table vs grid --> // TODO: just use props. need to be able to change this from parent
    itemView: "grid", // <!-- send to each grid item -->
    isDragMode: true,
    // footer" type="Aura.Component[]"/> <!-- add modal dialogs and other extra content -->

    draggedStart: null,
    draggedIndex: null,

    // <!-- querying -->
    parentId: "",
    parentPrefix: "",
    // queryFilter:[], //use props
    orderBy: "name",
    orderDirection: "asc", //"asc",
    lastValue: "",
    lastId: "",
    maxRecords: 0, //<!-- number of records to load per API call, set to 0 to load all records --> <!-- Disable 'Load More' button -->
    hasMore: true,

    // <!-- records -->
    mode: "empty", //"init",  // <!-- init, empty, view, error -->
    loading: true,
    recordColumns: [],
    recordList: [],
    recordDefaultSortDirection: "asc",

    showDeleteConfirmDialog: false,

    loadingMore: false,
  });

  const cmpWorking = useRef({});

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
  }, [props.queryFilter]);

  const cmp = {
    get: (key) => {
      if (props[key]) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    //Functions

    // render: () => {
    //   setCmpState({ ...cmpWorking.current });
    // },

    childToParent: (event) => {
      //handleEvent(event);
    },

    // Controller
    init: function () {
      this.setRecordColumns();
      this.afterScriptsLoaded();
    },

    afterScriptsLoaded: function () {
      this.setRecordObject();
      if (props.queryFilter && Object.keys(props).length > 0) {
        this.getRecords();
      }
    },

    //called when queryFilter changes
    handleReset: function () {
      this.setRecordObject();
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.reset(cmp);
      this.getRecords(numRecords);
    },

    // called when refresh button is clicked
    // reload the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      var maxRecords = cmp.get("maxRecords");
      var numRecords = !maxRecords
        ? 0
        : Math.max(cmp.get("recordList").length, maxRecords);
      PsRecordGrid.reset(cmp);
      this.getRecords(numRecords);
    },

    handleAction: function (cmp, event) {
      switch (event.getParam("value")) {
        case "Edit":
          //helper.handleEdit(cmp, event);
          break;
        case "Delete":
          cmp.set("showDeleteConfirmDialog", true);
          break;
        default:
      }
    },

    handleRecordRowAction: function (event) {
      var args = event.getParam("arguments");
      var wrappedEvent = args.event ? args.event : event;
      var action = wrappedEvent.getParam("action");
      var row = wrappedEvent.getParam("row");
      var parentId = cmp.get("parentId");
      var recordModule = cmp.get("recordModule");
      var recordObject = cmp.get("recordObject");
      switch (action.name) {
        case "details":
          this.notifyNavigation(
            cmp,
            parentId,
            recordModule,
            recordObject,
            row.id
          );
          break;
        default:
          break;
      }
    },

    handleConfirmDialogDelete: function () {
      this.deleteFolder();
      cmp.set("showDeleteConfirmDialog", false);
    },

    handleConfirmDialogCancel: function () {
      cmp.set("showDeleteConfirmDialog", false);
    },

    //Helper

    RECORD_COLUMNS: [
      {
        key: "name", //fieldName
        label: "Folder Name", //label
        property: "name", //fieldName
        type: "link", //if type = button, this value is link
        action: "details", //name within typeAttributes
      },
      {
        key: "description", //fieldName
        label: "Description", //label
        property: "description", //fieldName
        type: "link", //if type = button, this value is link
        action: "text", //name within typeAttributes
        // initialWidth: 250,
      },
    ],

    setRecordColumns: function () {
      cmp.set("recordColumns", this.RECORD_COLUMNS);
    },

    setRecordObject: function () {
      var folderItemId = cmp.get("parentId");
      var recordObject = ["mySavedInsights", "sharedInsights"].includes(
        folderItemId
      )
        ? "groupFolder"
        : "itemFolder";
      cmp.set("recordObject", recordObject);
    },

    getRecords: function (cmp, numRecords) {
      //TDOD: implement getting folders
    },

    deleteFolder: function (cmp, event) {
      // var recordModule = cmp.get("v.recordModule");
      // var recordObject = cmp.get("v.recordObject");
      // var folderId = cmp.get("v.parentId");
      // var action = cmp.get("c.deleteFolder");
      // action.setParams({ folderId });
      // action.setCallback(this, function (response) {
      //   if (!cmp.isValid()) {
      //     return;
      //   }
      //   var state = response.getState();
      //   if (state === "SUCCESS") {
      //     Record.showToast(
      //       cmp,
      //       "Folder Deleted",
      //       "Folder was deleted successfully",
      //       "success"
      //     );
      //     this.notifyNavigation(cmp, null, "folder", "topFolder", "liked"); // IMPROVEMENT: get the parentId from the deleted item, and navigate to the parent instead
      //     this.notifyChanged(
      //       cmp,
      //       "delete",
      //       null,
      //       recordModule,
      //       recordObject,
      //       folderId,
      //       {}
      //     );
      //   } else if (state === "ERROR") {
      //     Record.showToast(
      //       cmp,
      //       "Folder Delete Error",
      //       "An error occurred while trying to delete the folder",
      //       "error"
      //     );
      //   }
      // });
      // $A.enqueueAction(action);
    },

    //ADDED

    //We might need this
    parseResponse: function (response) {
      return response.map(
        ({
          id,
          compositionId,
          name,
          type,
          description,
          treeHash,
          relevance,
          relevanceIntrinsic,
          createdOn,
          lastRunOn,
          parameters,
          key,
          inputs,
          version,
        }) => ({
          id,
          compositionId,
          name,
          namePlain: Record.removeMarkup(name),
          nameMarkup: Record.markupToHtml(name),
          type,
          description,
          descriptionPlain: Record.removeMarkup(description),
          descriptionMarkup: Record.markupToHtml(description),
          treeHash,
          relevance,
          relevanceIntrinsic,
          createdOn,
          lastRunOn,
          parameters,
          key,
          keyId: key.id,
          keyName: key.name,
          containerName: key.container.name,
          sourceName: key.container.source.name,
          inputs,
          version,
        })
      );
    },

    footer: function () {
      return;

      // <!--Confirm Modal-->
      // <aura:if isTrue="{!v.showDeleteConfirmDialog}">
      //     <!--Modal Box Start-->
      //     <div role="dialog" class="slds-modal slds-fade-in-open ">
      //         <div class="slds-modal__container">
      //             <!--Modal Box Header Start-->
      //             <header class="slds-modal__header">
      //                 <lightning:buttonIcon iconName="utility:close" onclick="{! c.handleConfirmDialogCancel }" alternativeText="close" variant="bare-inverse" class="slds-modal__close"/>
      //                 <h2 class="slds-text-heading--medium">Confirmation</h2>
      //             </header>
      //             <!--Modal Box Header End-->

      //             <!--Modal Box Content Start-->
      //             <div class="slds-modal__content slds-p-around--medium">
      //                 <center><b>Are you sure you want to delete this folder?</b></center>
      //             </div>
      //             <!--Modal Box Content End-->

      //             <!--Modal Box Button Start-->
      //             <footer class="slds-modal__footer">
      //                 <lightning:button name='Cancel' label='Cancel' onclick='{!c.handleConfirmDialogCancel}'/>
      //                 <lightning:button variant="brand" name='Delete' label='Delete' onclick='{!c.handleConfirmDialogDelete}'/>
      //             </footer>
      //             <!--Modal Box Button End-->
      //         </div>
      //     </div>
      //     <div class="slds-backdrop slds-backdrop--open"></div>
      // </aura:if>
    },
  };

  // ADD THIS
  {
    /* <aura:set attribute="cardActions">
<aura:if isTrue="{! and(or(v.mode === 'init', or(v.mode === 'view', v.mode === 'empty')), v.parentId != 'mySavedInsights', v.parentId != 'sharedInsights', v.parentId != 'liked') }">
    <lightning:buttonMenu menuAlignment="auto" onselect="{!c.handleAction}" aura:id="cardMenu">
        <lightning:menuItem value="Delete" label="Delete" />
    </lightning:buttonMenu>
</aura:if>
</aura:set>

<aura:set attribute="footer">
<!--Confirm Modal-->
<aura:if isTrue="{!v.showDeleteConfirmDialog}">
    <!--Modal Box Start-->
    <div role="dialog" class="slds-modal slds-fade-in-open ">
        <div className="slds-modal__container">
            <!--Modal Box Header Start-->
            <header class="slds-modal__header">
                <lightning:buttonIcon iconName="utility:close" onclick="{! c.handleConfirmDialogCancel }" alternativeText="close" variant="bare-inverse" class="slds-modal__close"/>
                <h2 class="slds-text-heading--medium">Confirmation</h2>
            </header>
            <!--Modal Box Header End-->

            <!--Modal Box Content Start-->
            <div className="slds-modal__content slds-p-around--medium">
                <center><b>Are you sure you want to delete this folder?</b></center>
            </div>
            <!--Modal Box Content End-->

            <!--Modal Box Button Start-->
            <footer class="slds-modal__footer">
                <lightning:button name='Cancel' label='Cancel' onclick='{!c.handleConfirmDialogCancel}'/>
                <lightning:button variant="brand" name='Delete' label='Delete' onclick='{!c.handleConfirmDialogDelete}'/>
            </footer>
            <!--Modal Box Button End-->
        </div>
    </div>
    <div className="slds-backdrop slds-backdrop--open"></div>
</aura:if>
</aura:set> */
  }

  return PsRecordGrid.render(cmp, cmpState);
};

export default PsFolderList;
