// New version
import { useState, useEffect, useRef } from "react";

import Record from "../../helpers/recordLayer";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { RECORD_COLUMNS } from "./components/Helper";

const PsPatternGrid = (props) => {
  const [cmpState, setCmpState] = useState({
    // <!-- interface -->
    recordLabel: "Pattern", //"Record",
    recordLabelPlural: "Patterns", //"Records",
    // title:"",
    // header" type="Aura.Component[]"/> <!-- extra content at top-->
    showTitle: false, //true,
    showFooter: true,
    tagLine: "",
    emptyLine: "No results found for the specified criteria", //"Create a new Record",
    recordModule: "relate",
    recordObject: "pattern",
    gridComponent: "PatternChart", //"",
    gridComponents: [], //NEW
    cardActions: [],
    emptyCallToAction: [],
    gridItems: [],
    viewOptions: [
      { label: "Table", value: "table" },
      { label: "Grid", value: "grid" },
    ],
    changeView: true, // <!-- whether to render radiobuttons that change the default view -->
    showEdit: false, // <!-- whether to show the 'Edit' button -->
    showLoadMore: true, // <!-- whether to show the 'Load More' button -->
    view: props.view, // "table", // <!-- table vs grid --> // TODO: just use props. need to be able to change this from parent
    itemView: "grid", // <!-- send to each grid item -->
    isDragMode: true,
    // footer" type="Aura.Component[]"/> <!-- add modal dialogs and other extra content -->

    draggedStart: null,
    draggedIndex: null,

    // <!-- querying -->
    parentId: "",
    parentPrefix: "",
    // queryFilter:[], //use props
    orderBy: "relevance", //"name",
    orderDirection: "desc", //"asc",
    lastValue: "",
    lastId: "",
    maxRecords: 10, //<!-- number of records to load per API call, set to 0 to load all records -->
    hasMore: true,

    // <!-- records -->
    mode: "empty", //"init",  // <!-- init, empty, view, error -->
    loading: true,
    recordColumns: [],
    recordList: [],
    recordDefaultSortDirection: "asc",

    loadingMore: false,
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.handleReset();
  }, [props.queryFilter]);

  const cmp = {
    // --- PatternGridController.js ---

    init: function () {
      PsRecordGrid.setRecordColumns(cmp);
    },

    afterScriptsLoaded: function () {
      var numRecords = cmp.get("maxRecords");
      if (props.queryFilter && Object.keys(props).length > 0) {
        PsRecordGrid.getRecords(cmp, numRecords);
      }
    },

    // called when refresh button is clicked
    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      var maxRecords = cmp.get("maxRecords");
      var numRecords = !maxRecords
        ? 0
        : Math.max(cmp.get("recordList").length, maxRecords);
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    //called when queryFilter changes
    handleReset: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleLoadMore: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleRecordRowAction: function (row) {
      // var args = event.getParam("arguments");
      // var wrappedEvent = args.event ? args.event : event;
      // var action = wrappedEvent.getParam("action");
      // var row = wrappedEvent.getParam("row");

      switch (row.action.name) {
        case "details":
          this.notifyDataComp(row);
          break;
        // case "viewSource":
        //   helper.notifyNavigation(
        //     cmp,
        //     "root",
        //     "core",
        //     "source",
        //     row.key.container.source.id
        //   );
        //   break;
        // case "viewContainer":
        //   helper.notifyNavigation(
        //     cmp,
        //     row.key.container.source.id,
        //     "store",
        //     "container",
        //     row.key.container.id
        //   );
        //   break;
        // case "viewKey":
        //   helper.notifyNavigation(
        //     cmp,
        //     row.key.container.id,
        //     "store",
        //     "key",
        //     row.key.id
        //   );
        //   break;
        default:
          break;
      }
    },

    // --- PatternGridHelper.js ---

    RECORD_COLUMNS: RECORD_COLUMNS,

    parseResponse: function (response) {
      return response.map(
        ({
          id,
          compositionId,
          name,
          description,
          treeHash,
          relevance,
          createdOn,
          lastRunOn,
          key,
          version,
        }) => ({
          id,
          compositionId,
          name,
          namePlain: Record.removeMarkup(name),
          nameMarkup: Record.markupToHtml(name),
          description,
          descriptionPlain: Record.removeMarkup(description),
          descriptionMarkup: Record.markupToHtml(description),
          treeHash,
          relevance,
          createdOn,
          lastRunOn,
          key,
          keyId: key.id,
          keyName: key.name,
          containerName: key.container.name,
          sourceName: key.container.source.name,
          version,
        })
      );
    },

    // fire event for selecting a pattern
    notifyDataComp: function (row) {
      var event = new Event("dataCompEvent");
      event.data = { action: "viewDetails", pattern: row };
      handleEvent(event);
    },

    // --- New functions ---

    get: (key) => {
      if (props[key]) return props[key];

      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    footer: function () {
      return;
    },

    childToParent: (event) => {
      handleEvent(event);
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },
  };

  const handleEvent = (event) => {
    let stopPropagation = false;

    if (!stopPropagation) {
      props.childToParent(event);
    }
  };

  return PsRecordGrid.render(cmp, cmpState);
};

export default PsPatternGrid;
