import { useState, useEffect, useRef } from "react";

import "./PsCompositionGrid.css";
import Record from "../../helpers/recordLayer.js";
import Event from "../../helpers/event.js";
import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import { RECORD_COLUMNS } from "../ps-pattern-grid/components/Helper";

function PsCompositionGrid(props) {
  const [cmpState, setCmpState] = useState({
    // <!-- interface -->
    recordLabel: "Pattern", //"Record",
    recordLabelPlural: "Patterns", //"Records",
    // title:"",
    // header" type="Aura.Component[]"/> <!-- extra content at top-->
    showTitle: true,
    showFooter: true,
    tagLine:
      "Patterns show insights detected in your data. Click a Pattern to see more details, edit the graph, or organize it into folders.",
    emptyLine: "No results found for the specified criteria", //"Create a new Record",
    recordModule: "relate",
    recordObject: "composition",
    gridComponent: "PatternChart",
    gridComponents: [], //NEW
    cardActions: [],
    emptyCallToAction: [],
    gridItems: [],
    viewOptions: [
      { label: "Table", value: "table" },
      { label: "Grid", value: "grid" },
    ],
    changeView: true, // <!-- whether to render radiobuttons that change the default view -->
    showEdit: false, // <!-- whether to show the 'Edit' button -->
    showLoadMore: true, // <!-- whether to show the 'Load More' button -->
    view: props.view, // "table", // <!-- table vs grid --> // TODO: just use props. need to be able to change this from parent
    itemView: "grid", // <!-- send to each grid item -->
    isDragMode: true,
    // footer" type="Aura.Component[]"/> <!-- add modal dialogs and other extra content -->

    draggedStart: null,
    draggedIndex: null,

    // <!-- querying -->
    parentId: props.parentId || "",
    parentPrefix: "",
    // queryFilter:[], //use props
    orderBy: "relevance", //"name",
    orderDirection: "desc",
    lastValue: "",
    lastId: "",
    maxRecords: props.maxRecords || 10, //<!-- number of records to load per API call, set to 0 to load all records -->
    hasMore: true,

    // <!-- records -->
    mode: "empty", //"init",  // <!-- init, empty, view, error -->
    loading: true,
    recordColumns: [],
    recordList: [],
    recordDefaultSortDirection: "asc",

    loadingMore: false,
  });
  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent || !props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    cmp.handleReset();
  }, [props.queryFilter]);

  const cmp = {
    // --- CompositionGridController.js ---

    init: function () {
      PsRecordGrid.setRecordColumns(cmp);
    },

    afterScriptsLoaded: function () {
      var numRecords = cmp.get("maxRecords");
      if (props.queryFilter && Object.keys(props).length > 0) {
        PsRecordGrid.getRecords(cmp, numRecords);
      }
    },

    // called when queryFilter changes
    handleReset: function () {
      try {
        var numRecords = cmp.get("maxRecords");
        PsRecordGrid.reset(cmp);
        PsRecordGrid.getRecords(cmp, numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      try {
        var maxRecords = cmp.get("maxRecords");
        var numRecords = !maxRecords
          ? 0
          : Math.max(cmp.get("recordList").length, maxRecords);
        PsRecordGrid.reset(cmp);
        PsRecordGrid.getRecords(cmp, numRecords);
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleLoadMore: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    notifyNavigation: function (parentId, module, object, id) {
      var event = Event.createEvent("navigation");
      event.parentId = parentId;
      event.module = module;
      event.obj = object;
      event.id = id;
      event.source = "grid";

      props.childToParent(event);
    },

    handleRecordRowAction: function (row) {
      try {
        switch (row.action.name) {
          case "details":
            this.notifyDataComp(row);
            break;
          case "viewSource":
            this.notifyNavigation(
              "root",
              "core",
              "source",
              row.key.container.source.id
            );
            break;
          case "viewContainer":
            this.notifyNavigation(
              row.key.container.source.id,
              "store",
              "container",
              row.key.container.id
            );
            break;
          case "viewKey":
            this.notifyNavigation(
              row.key.container.id,
              "store",
              "key",
              row.key.id
            );
            break;
          default:
        }
      } catch (err) {
        console.error(err.stack);
      }
    },

    handleGoToSearch: function () {
      try {
        var navigationEvent = Event.createEvent("navigation");
        navigationEvent.tab = "Search";
        props.childToParent(navigationEvent);
      } catch (err) {
        console.error(err.stack);
      }
    },

    // --- CompositionGridHelper.js ---

    RECORD_COLUMNS: RECORD_COLUMNS,

    parseResponse: function (response) {
      return response.map(
        ({
          id,
          patternId,
          name,
          description,
          treeHash,
          relevance,
          createdOn,
          lastRunOn,
          key,
          version,
        }) => ({
          compositionId: id,
          id: patternId,
          name,
          namePlain: Record.removeMarkup(name),
          nameMarkup: Record.markupToHtml(name),
          description,
          descriptionPlain: Record.removeMarkup(description),
          descriptionMarkup: Record.markupToHtml(description),
          treeHash,
          relevance,
          createdOn,
          lastRunOn,
          key,
          keyId: key.id,
          keyName: key.name,
          containerName: key.container.name,
          sourceName: key.container.source.name,
          version,
        })
      );
    },

    // fire event for selecting a pattern
    notifyDataComp: function (row) {
      var event = Event.createEvent("dataCompEvent");
      event.data = { action: "viewDetails", pattern: row };
      props.childToParent(event);
    },

    // --- New functions ---

    get: (key) => {
      if (props.hasOwnProperty(key)) return props[key];

      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    childToParent: (event) => {
      handleEvent(event);
    },

    footer: function (cmpState) {
      return (
        <div>
          {!cmpState.loading && cmpState.mode === "view" && (
            <div>
              {cmpState.hasMore && (
                <div>
                  {/* <ui:message class="slds-m-around_x-small" title="" severity="info" closable="false">
                    Don't see what you are looking for? Adjust filters, or edit suggested graphs.
                  </ui:message>             */}
                </div>
              )}
              {!cmpState.hasMore && (
                <div>
                  {/* <ui:message class="slds-m-around_x-small" title="" severity="info" closable="false">
                      Don't see what you are looking for? Adjust filters, edit suggested graphs, or use <a onclick="{!c.handleGoToSearch}">Search</a> to build your own graphs.
                  </ui:message> */}
                </div>
              )}
            </div>
          )}
        </div>
      );
    },

    render: () => {
      setCmpState({ ...cmpWorking.current });
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },
  };

  const handleEvent = (event) => {
    let stopPropagation = false;

    if (!stopPropagation) {
      props.childToParent(event);
    }
  };

  return PsRecordGrid.render(cmp, cmpState);
}

export default PsCompositionGrid;
