import { Auth } from "aws-amplify";

export function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
}

export const checkCurrentSession = async () => {
  try {
    const issCurrentSession = await Auth.currentSession();
    return issCurrentSession;
  } catch (error) {
    console.error("Error checking current session:", error);
    return null;
  }
};

export const getLocalDateTime = function (utcDatetime) {
  try {
    // utcDatetime 2022-09-12T13:09:46.000Z
    const utcDate = new Date(utcDatetime);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    // Convert to local timezone, automatically takes the local timezone of the system
    const formattedLocalDatetime = localDate.toISOString().slice(0, 23); // Format to '2022-09-12T13:09:46.000'
    // formattedLocalDatetime 2022-09-12T14:09:46.000 //BST, so this did work

    return formattedLocalDatetime;
  } catch (err) {
    console.error(err.stack);
  }
  return null;
};

export const getUTCDateTime = function (localDatetime) {
  try {
    // localDatetime 2022-09-12T14:09:46.000 //BST, so this did work

    const localDate = new Date(localDatetime);
    const formattedUtcDatetime = localDate.toISOString();

    // const utcDate = new Date(localDate.getTime() + (localDate.getTimezoneOffset() * 60000)); // Convert to UTC, automatically takes the local timezone of the system
    // const formattedUtcDatetime = utcDate.toISOString().slice(0, 23); // Format to '2022-09-12T13:09:46.000Z'

    // formattedUtcDatetime 2022-09-12T13:09:46.000Z

    return formattedUtcDatetime;
  } catch (err) {
    console.error(err.stack);
  }
  return null;
};

export function arrayToQueryString(obj) {
  const queryString = Object.entries(obj)
    .map(([key, value]) =>
      Array.isArray(value)
        ? value.map((val) => `${key}=${val}`).join("&")
        : `${key}=${value}`
    )
    .join("&");
  return queryString;
}

export function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export function toastDetails(response) {
  return (
    <>
      {response?.data?.message && <>{response.data.message}</>}
      <br />
      {response?.data?.reference && <>{response.data.reference}</>}
      <br />
      {response?.data?.suggestion && <>{response.data.suggestion}</>}
    </>
  );
}

export function truncateText(text, maxLength) {
  return text.length <= maxLength ? text : text.substring(0, maxLength) + "...";
}

export const auth = (regionNumber) => {
  return {
    region: process.env[`REACT_APP_COGNITO_REGION_${regionNumber}`],
    userPoolId:
      process.env[`REACT_APP_COGNITO_USER_POOL_ID_REGION_${regionNumber}`],
    userPoolWebClientId:
      process.env[`REACT_APP_COGNITO_APP_CLIENT_ID_REGION_${regionNumber}`],
    mandatorySignIn: true,
  };
};

export const endpoints = (regionNumber) => {
  return [
    {
      name: "Application",
      endpoint:
        "https://" +
        process.env[`REACT_APP_COGNITO_REGION_${regionNumber}`] +
        "." +
        process.env.REACT_APP_API_URL,
      region: process.env[`REACT_APP_COGNITO_REGION_${regionNumber}`],
    },
  ];
};

export const formattedBlueText = (content) => {
  const matches = content.split(/(<\/?b>)/);

  const parsedContentArray = [];
  let isBlue = false;
  matches.forEach((match) => {
    if (match === "<b>") {
      isBlue = true;
    } else if (match === "</b>") {
      isBlue = false;
    } else if (match.trim() !== "") {
      parsedContentArray.push({ text: match, isBlue });
    }
  });

  return (
    <>
      {parsedContentArray.map((part, index) => (
        <span key={index} style={{ color: part.isBlue ? "#009edb" : "black" }}>
          {part.text}
        </span>
      ))}
    </>
  );
};

export const formattedNumber = (number) => {
  return <>{number ? <span>{number.toLocaleString()}</span> : number}</>;
};
