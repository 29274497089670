import Icon from "@salesforce/design-system-react/components/icon";
import { Badge } from "@salesforce/design-system-react";

import "../PsClobalHeader.css";

const HeaderProfileCustomContent = ({ childProps }) => {
  const region =
    process.env[`REACT_APP_COGNITO_REGION_LABEL_${childProps.user.region}`];

  const regionNumberCount = parseInt(
    process.env.REACT_APP_COGNITO_REGION_NUMBER_COUNT,
    10
  );

  return (
    <div className="header-profile-custom-content">
      {/* <div
        className="header-profile-custom-content-item"
        onClick={() => console.log("Settings")}
      >
        <div className="header-profile-custom-content-item-icon">
          <Icon
            assistiveText={{ label: "settings" }}
            category="utility"
            colorVariant="default"
            name="settings"
            size="x-small"
          />
        </div>
        Settings
      </div> */}
      {regionNumberCount > 1 && (
        <div style={{ margin: "-10px 0 10px -2px" }}>
          <Badge
            id="badge-base-example-default"
            content={<div>Region: {region}</div>}
            icon={<Icon category="utility" name="checkin" size="xx-small" />}
          />
        </div>
      )}
      <div className="logout" onClick={childProps.handleLogout}>
        <div className="header-profile-custom-content-item-icon">
          <Icon
            assistiveText={{ label: "logout" }}
            category="utility"
            colorVariant="default"
            name="logout"
            size="x-small"
          />
        </div>
        Log Out
      </div>
    </div>
  );
};
export default HeaderProfileCustomContent;
