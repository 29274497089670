import GlobalHeader from "@salesforce/design-system-react/components/global-header";
import GlobalHeaderHelp from "@salesforce/design-system-react/components/global-header/help";
import GlobalHeaderProfile from "@salesforce/design-system-react/components/global-header/profile";
import Popover from "@salesforce/design-system-react/components/popover";
import Avatar from "@salesforce/design-system-react/components/avatar";

import "./PsClobalHeader.css";
import HeaderProfileCustomContent from "./components/HeaderProfileCustomContent";

const ipsum =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec bibendum fermentum eros, vel porta metus dignissim vitae. Fusce finibus sed magna vitae tempus. Suspendisse condimentum, arcu eu viverra vulputate, mauris odio dictum velit, in dictum lorem augue id augue. Proin nec leo convallis, aliquet mi ut, interdum nunc.";

const PsGlobalHeader = ({ childProps }) => {
  const { isAuthenticated, user } = childProps;

  const avatar = <Avatar variant="user" label={user.username} size="medium" />;

  return (
    <div className="ps-global-header">
      <GlobalHeader
        logoSrc="/assets/images/PsLogo.png"
        onSkipToContent={() => {
          alert(">>> Skip to Content Clicked");
        }}
        onSkipToNav={() => {
          alert(">>> Skip to Nav Clicked");
        }}
      >
        {/* {isAuthenticated ? (
          <GlobalHeaderHelp
            popover={
              <Popover
                ariaLabelledby="help-heading"
                body={
                  <div>
                    <h2 className="slds-text-heading_small" id="help-heading">
                      Help and Training
                    </h2>
                    {ipsum}
                  </div>
                }
                id="header-help-popover-id"
              />
            }
          />
        ) : null} */}
        {isAuthenticated ? (
          <GlobalHeaderProfile
            popover={
              <Popover
                body={
                  <div>
                    <div className="header-profile-popover-body-avatar-user-info">
                      <div>{avatar}</div>
                      <div className="user-info">
                        <p className="user-name">{user.username}</p>
                        <p
                          className="user-email slds-truncate"
                          style={{ width: "250px" }}
                          title={user.email}
                        >
                          {user.email}
                        </p>
                      </div>
                    </div>
                    <HeaderProfileCustomContent childProps={childProps} />
                  </div>
                }
                id="header-profile-popover-id"
                ariaLabelledby="profile-heading"
                heading=""
              />
            }
            userName=""
            avatar={avatar}
          />
        ) : null}
      </GlobalHeader>
    </div>
  );
};

export default PsGlobalHeader;
