import { useState } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import {
  GENERAL_ERROR,
  INVALID_EMAIL,
  SIGNUP,
} from "../../../constants/ToastMessages";
import { isValidEmail } from "../../../helpers";
import "../../Pages.css";

const UserForm = ({ user, setUser, setToastState, setNewUser }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { username, email, password, confirmPassword } = user;

  const handleUserInputChange = (e, item) => {
    setUser((prevUser) => ({ ...prevUser, [item]: e.target.value }));
    setToastState({ variant: "", details: "" });
  };

  const handleSubmitUserForm = async (event) => {
    try {
      event.preventDefault();
      setToastState({ variant: "", details: "" });

      if (!isValidEmail(email)) {
        setToastState({
          variant: "warning",
          details: INVALID_EMAIL,
        });
        return;
      }
      if (password !== confirmPassword) {
        setToastState({
          variant: "warning",
          details: SIGNUP.PASSWORD_MISMATCH,
        });
        return;
      }

      setIsLoading(true);
      const newUser = await Auth.signUp({
        username,
        password,
        attributes: { email },
      });
      if (newUser) {
        setNewUser(newUser);
        setToastState({
          variant: "success",
          details: SIGNUP.SUCCESS,
        });
      } else {
        setToastState({
          variant: "error",
          details: GENERAL_ERROR,
        });
      }
    } catch (err) {
      console.error(err.stack);
      setToastState({
        variant: "error",
        details: err.message || GENERAL_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmitUserForm}>
      <div className="user-inputs">
        <Input
          type="text"
          label="Username"
          value={username}
          onChange={(e) => handleUserInputChange(e, "username")}
        />
      </div>
      <div className="user-inputs">
        <Input
          type="text"
          label="Email"
          value={email}
          onChange={(e) => handleUserInputChange(e, "email")}
        />
      </div>
      <div className="user-inputs">
        <Input
          type="password"
          label="Password"
          value={password}
          onChange={(e) => handleUserInputChange(e, "password")}
        />
      </div>
      <div className="user-inputs">
        <Input
          type="password"
          label="Confirm password"
          value={confirmPassword}
          onChange={(e) => handleUserInputChange(e, "confirmPassword")}
        />
      </div>
      <div className="login-signup-button">
        <Button
          type="submit"
          label={<>Sign up {isLoading ? <Spinner size="small" /> : null}</>}
          variant="brand"
          disabled={
            isLoading || !username || !password || !confirmPassword || !email
          }
          style={{ width: "100%" }}
        />
      </div>
      <nav className="login-signup-link">
        Have an account? <Link to="/login">Log in</Link>
      </nav>
    </form>
  );
};

export default UserForm;
