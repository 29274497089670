import { useState } from "react";
import { Button, Input, Spinner } from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import { GENERAL_ERROR } from "../../../constants/ToastMessages";
import "../../Pages.css";

const ConfirmationForm = ({
  confirmationCode,
  setConfirmationCode,
  setToastState,
  userHasAuthenticated,
  user,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { username, password } = user;

  const navigate = useNavigate();

  const handleConfirmationCodeChange = (e) => {
    setConfirmationCode(e.target.value);
    setToastState({ variant: "", details: "" });
  };

  const handleConfirmationSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const confirmationResult = await Auth.confirmSignUp(
        username,
        confirmationCode
      );
      const user = await Auth.signIn(username, password);
      if (confirmationResult && user) {
        userHasAuthenticated(true);
        navigate("/", { replace: true });
        return;
      }
    } catch (err) {
      console.error(err.stack);
      setToastState({
        variant: "error",
        details: err.message || GENERAL_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleConfirmationSubmit}>
      <div className="user-inputs">
        <Input
          type="tel"
          label="Confirmation Code"
          value={confirmationCode}
          onChange={(e) => handleConfirmationCodeChange(e)}
        />
      </div>
      <p style={{ textAlign: "center" }}>
        Please check your email for the code.
      </p>
      <div className="login-signup-link">
        <Button
          type="submit"
          label={<>Verify {isLoading ? <Spinner size="small" /> : null}</>}
          variant="brand"
          disabled={isLoading || !confirmationCode}
          style={{ width: "100%" }}
        />
      </div>
    </form>
  );
};

export default ConfirmationForm;
