import { useState } from "react";
import { Button, Input, Spinner, Card } from "@salesforce/design-system-react";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import "../Pages.css";
import ToastComponent from "../../components/toast-component";
import { GENERAL_ERROR, LOGIN } from "../../constants/ToastMessages";

const ForgotPassword = ({ childProps: { userHasAuthenticated } }) => {
  const emptyToastState = { variant: "", details: "" };

  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [toastState, setToastState] = useState(emptyToastState);
  // const [mode,setMode] = useState('login'); //login, resetpassword, newpassword - would we need confirm password mode too?

  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setToastState(emptyToastState);
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setToastState(emptyToastState);
      setIsLoading(true);

      const data = await Auth.forgotPassword(username);
      setToastState({
        variant: "success",
        details:
          "Reset requested. Please check your email for further instructions.",
      });

      navigate("/login?mode=resetPassword");
    } catch (err) {
      console.error(err.stack);
      setToastState({
        variant: "error",
        details: err.message || GENERAL_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeToast = () => setToastState(emptyToastState);

  //IMPROVEMENT: autocomplete="new-password" / autocomplete="current-password"
  // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
  return (
    <div className="login-signup-container">
      <form onSubmit={handleSubmit}>
        <Card heading={<p className="login-signup-heading">Point Sigma</p>}>
          <div className="user-inputs">
            <Input
              type="text"
              label="Username"
              value={username}
              onChange={handleUsernameChange}
            />
          </div>
          <div className="login-signup-button">
            <Button
              type="submit"
              label={
                <>
                  Reset my password{isLoading ? <Spinner size="small" /> : null}
                </>
              }
              variant="brand"
              disabled={isLoading || !username}
              style={{ width: "100%" }}
            />
          </div>
        </Card>
      </form>
      {toastState.details ? (
        <ToastComponent
          close={closeToast}
          details={toastState.details}
          variant={toastState.variant}
        />
      ) : null}
    </div>
  );
};

export default ForgotPassword;
