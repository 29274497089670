import {
  IconSettings,
  Input,
  Modal,
  Spinner,
  Tooltip,
} from "@salesforce/design-system-react";
import Button from "@salesforce/design-system-react/components/button";
import { useEffect, useRef, useState } from "react";

import Record from "../../helpers/recordLayer.js";
import ToastComponent from "../toast-component/index.js";
import { filterJson } from "../ps-filter/components/Helper.js";
import { PsFilter } from "../ps-filter/PsFilter.js";

// PROPS FROM PARENT
// <FilterSet pattern={cmpState.processed} handleEvent={handleEvent} />
// so props.pattern contains processed. Pattern doesn't get updated in this component, so we can use use it as props

const FilterSet = (props) => {
  const [filterSetState, setFilterSetState] = useState({
    //Parameters
    filters: [],

    // FEEDBACK
    // records get set in 'setRecords' function
    records: [],

    //Selected Filter
    listOrder: null,
    selectedFilter: null,
    selectedContainerId: null,

    //User Interface
    filterModalIsOpen: false,
    loading: false,
    showApplyButton: false,

    //scripts
    scriptsLoaded: false,

    //
    showRequiredFieldError: false,
  });

  const emptyToastState = {
    variant: "",
    heading: "",
    details: "",
  };
  const [toastState, setToastState] = useState(emptyToastState);

  const cmpWorking = useRef({});
  const filterCmpRef = useRef(null);

  useEffect(() => {
    cmpWorking.current = { ...filterSetState };
    parsePattern();
  }, []);

  useEffect(() => {
    cmpWorking.current = { ...filterSetState };
    if (filterSetState.filters?.length > 0) {
      filtersToRecords(filterSetState.filters);
    }
  }, [filterSetState.filters]);

  useEffect(() => {
    cmpWorking.current = { ...filterSetState };
    handlePatternChange();
  }, [props.pattern]);

  const cmp = {
    get: (key) => {
      if (props[key]) return props[key];

      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setFilterSetState((prev) => ({ ...prev, [key]: value }));
    },
  };

  //////
  // const init = () => {};

  // const isEmpty = () => {};

  const setRecords = (records, changed) => {
    // cmp.set('v.records', records); // rerenders filters
    // cmp.set('v.showApplyButton', changed); // show/hide the apply button
    cmpWorking.current.records = records;
    cmpWorking.current.showApplyButton = changed;
    setFilterSetState((prev) => ({
      ...prev,
      records,
      showApplyButton: changed,
    }));
  };

  const setLoading = () => {
    cmpWorking.current.loading = true;
    setFilterSetState((prev) => ({
      ...prev,
      loading: true,
    }));
  };

  const unsetLoading = () => {
    cmpWorking.current.loading = false;
    setFilterSetState((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  const parseRecord = (record, listOrder) => {
    try {
      // listOrder
      record.listOrder = listOrder;

      // can revert
      record.reset =
        record.floatingId &&
        ["Session", "Project", "DashBoard", "Global"].includes(record.scope);

      // key values (only available for filters retrieved from Filter modal)
      var settings = record.settings || {};
      var selectedValues = settings.values;
      var valuesOptions = (record.filterKey || {}).values;
      var selectedValue =
        selectedValues && selectedValues.length === 1
          ? selectedValues[0]
          : null;
      var labelsOptions = [];
      if (valuesOptions) {
        valuesOptions = valuesOptions.map(({ label }) => ({
          label,
          value: label,
        })); // API expects labels, not values
        valuesOptions = JSON.parse(JSON.stringify(valuesOptions)); // take a deepcopy, to prevent coupling picklists for the same Keys or presets
        valuesOptions.forEach((item) => {
          item.selected = item.value === selectedValue;
        });
        valuesOptions.unshift({ label: "--None--", value: "" });
        labelsOptions = valuesOptions.map(({ label }) => label);
      }

      // operator
      var type = record.type;
      var alias = settings.alias;
      var operator = record.operator;

      // settings.alias overrides the operator
      if (alias) {
        operator = alias;
      }
      var options = Record.FILTER_TYPES[type] || [];
      var option = options.find((i) => i.value === operator) || {};
      var useInput = option.useInput;

      if (useInput === "ListValues") {
        useInput =
          valuesOptions && valuesOptions.length ? "MultiSelect" : "FreeText";
      }

      // values
      if (useInput === "FreeText") {
        record.useInput = "FreeText";
        record.operator = option.label + ": ";
        record.freeTextValues = Record.toCSV(selectedValues);
      } else if (useInput === "MultiSelect") {
        if (!selectedValues || selectedValues.length <= 1) {
          // single-select for up to 1 item selected
          record.useInput = "SingleSelect";
          record.operator = option.label;
          record.selected = selectedValue;
          record.valuesOptions = valuesOptions;
        } else {
          // multiselect not yet supported
          // var selectedLabels = selectedValues.reduce((obj, item) => {
          //   if (labelsOptions.includes(item)) {
          //     obj.push(item);
          //   }
          //   return obj;
          // }, []);
          var selectedLabels = selectedValues.reduce((arr, item) => {
            if (item && item && labelsOptions.includes(item)) {
              arr.push(item);
            }
            return arr;
          }, []);

          record.useInput = null;
          record.operator = option.label + ": ";
          record.values =
            selectedLabels && selectedLabels.length
              ? Record.toCSV(selectedLabels)
              : "[Empty]";
        }
      } else if (useInput === "BetweenNumber") {
        record.useInput = null;
        if (settings.min === null && settings.max === null) {
          record.operator = "";
          record.values = "";
        } else if (settings.min === null || !settings.min) {
          record.operator =
            settings.includeMax === null || settings.includeMax ? "≤" : "< ";
          record.values = settings.max;
        } else if (settings.max === null || !settings.max) {
          record.operator =
            settings.includeMin === null || settings.includeMin ? "≥ " : "> ";
          record.values = settings.min;
        } else {
          record.operator = "Between ";
          record.values = settings.min + " and " + settings.max;
        }
      } else if (useInput === "BetweenDateTime") {
        record.useInput = null;
        if (settings.min === null && settings.max === null) {
          record.operator = "";
          record.values = "";
        } else if (settings.min === null) {
          record.operator = "Until: ";
          record.values = settings.max;
        } else if (settings.max === null) {
          record.operator = "From: ";
          record.values = settings.min;
        } else {
          record.operator = "Between ";
          //   record.values =
          //     $A.localizationService.formatDateTime(settings.min) +
          //     " and " +
          //     $A.localizationService.formatDateTime(settings.max);
          const formattedMinDate = new Date(settings.min).toLocaleString();
          const formattedMaxDate = new Date(settings.max).toLocaleString();
          record.values = `${formattedMinDate} and ${formattedMaxDate}`;
        }
      } else if (useInput === "RelativeDateTime") {
        record.useInput = null;
        if (settings.min === null && settings.max === null) {
          record.operator = "";
          record.values = "";
        } else if (settings.min === null) {
          record.operator = "Until ";
          record.values = Record.intervalToHuman(settings.max);
        } else if (settings.max === null) {
          record.operator = "Starting ";
          record.values = Record.intervalToHuman(settings.min);
        } else {
          record.operator = "Between ";
          record.values =
            Record.intervalToHuman(settings.min) +
            " and " +
            Record.intervalToHuman(settings.max);
        }
      } else if (useInput === "Preset") {
        var preset = settings.preset;
        var presets = record.presets;
        var presetDefaults = JSON.parse(
          JSON.stringify(Record.FILTER_PRESETS[type])
        ); // deepclone to prevent changing original values
        var presetOptions = presets || presetDefaults || [];
        presetOptions.forEach((item) => {
          item.selected = item.value === preset;
        });
        record.useInput = "Preset";
        record.operator = "";
        record.values = "";
        record.selected = preset;
        record.valuesOptions = presetOptions;
      } else if (useInput === "Missing") {
        record.operator =
          operator === "Missing" ? "Is Missing" : "Is Not Missing";
        record.values = "";
      } else {
        record.operator = null;
        record.values = null;
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const parseRecords = (records) => {
    for (var i = 0; i < records.length; i++) {
      parseRecord(records[i], i);
    }
    setRecords(records, false);
    unsetLoading();
  };

  // IMRPROVEMEN; check if there is a standard way of doing this
  const append = (obj, attrib, value) => {
    var arr = obj[attrib];
    if (arr) {
      arr.push(value);
    } else {
      obj[attrib] = [value];
    }
  };

  const filtersToRecords = (filters) => {
    try {
      // extract keyIds from filter spec
      var records = [];
      var record, nodes, keyId;
      var keyIdToRecord = {};

      // copy filters to records; records are used in the UI so underlying filters are not affected
      for (let filter of filters) {
        // check filter.keyId and filter.key.id
        record = JSON.parse(JSON.stringify(filter));
        records.push(record);
      }

      // exctract keyIds, and map them to their original records
      for (let record of records) {
        nodes = Record.flatten(record, "inputs");
        for (let node of nodes) {
          keyId = node.keyId || (node.key || {}).id;
          if (keyId) {
            if (keyId) {
              append(keyIdToRecord, keyId, record);
            }
          }
        }
      }

      // query Keys, and store them to records
      var keyIds = Object.keys(keyIdToRecord);
      if (keyIds.length) {
        var onSuccess = function (response) {
          for (let result of response) {
            for (let record of keyIdToRecord[result.id] || []) {
              record.filterKey = result;
            }
          }
          parseRecords(records);
        };

        var onError = function (response) {
          parseRecords(records);
        };

        Record.getRecords(
          "store",
          "values",
          { id: keyIds },
          onSuccess,
          onError
        );
      } else {
        parseRecords(records);
      }
    } catch (err) {
      console.error(err.stack);
    }
  };

  const parsePattern = () => {
    try {
      var FLT_IND = 4;
      var pattern = props.pattern || {};
      var nodes = pattern.inputs || [];
      var filters = [];
      setLoading();

      // extract Filters
      var filter;
      nodes.forEach((node) => {
        if (node.argOrder === FLT_IND) {
          filter = JSON.parse(JSON.stringify(node.filter || {})); // deepcopy, to prevent changing the original filter in the pattern
          // DeepSigma loads a record from the database if an 'id' is provided, so we remove it and keep track of the original 'id' in 'floatingId'
          if (filter.id) {
            filter.floatingId = filter.id;
            filter.id = undefined;
          }
          filters.push(filter);
        }
      });

      // save and create ui records
      cmpWorking.current.filters = filters;
      setFilterSetState((prev) => ({
        ...prev,
        filters,
      }));
      filtersToRecords(filters);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const handlePatternChange = () => {
    try {
      parsePattern();
    } catch (err) {
      console.error(err.stack);
    }
  };

  const editFilter = (listOrder) => {
    try {
      // var listOrder = event.getSource().get("v.name");
      var filters = cmpWorking.current.filters || [];
      var filter = filters[listOrder];
      var pattern = props.pattern;

      //cmpWorking was not being set here. Is there a risk that new state was not being picked up by cmpWorking?
      cmpWorking.current.listOrder = listOrder;
      cmpWorking.current.selectedFilter = filter;
      cmpWorking.current.selectedContainerId = (
        ((pattern || {}).key || {}).container || {}
      ).id;

      setFilterSetState((prev) => ({
        ...prev,
        listOrder,
        selectedFilter: filter,
        selectedContainerId: (((pattern || {}).key || {}).container || {}).id,
        filterModalIsOpen: true,
      }));
    } catch (err) {
      console.error(err.stack);
    }
  };

  const deleteFilter = (listOrder) => {
    // update ui
    var records = cmpWorking.current.records || [];
    records[listOrder] = null;
    setRecords(records, true);

    // update filter
    var filters = cmpWorking.current.filters || [];
    filters[listOrder] = null;
    cmpWorking.current.filters = filters;
    setFilterSetState((prev) => ({ ...prev, filters }));
  };

  const toggleFilterActive = (listOrder) => {
    // update UI
    var records = cmpWorking.current.records || [];
    var record = records[listOrder];
    record.active = !record.active;
    setRecords(records, true);

    // update filter
    var filters = cmpWorking.current.filters || [];
    var filter = filters[listOrder];
    filter.active = record.active;
    cmpWorking.current.filters = filters;
    setFilterSetState((prev) => ({ ...prev, filters }));
  };

  const updateFilterValue = (listOrder, value) => {
    // update ui
    var records = cmpWorking.current.records || [];
    var record = records[listOrder];
    var useInput = record?.useInput;
    var settings = record.settings || {};
    var presetOption;
    if (useInput === "SingleSelect") {
      settings.values = value ? [value] : null;
      record.valuesOptions.forEach((item) => {
        item.selected = item.value === value;
      });
    } else if (useInput === "FreeText") {
      var newValues = Record.parseCSV(value);
      if (JSON.stringify(settings.values) === JSON.stringify(newValues)) {
        // no change
        return;
      }
      settings.values = newValues;
    } else if (useInput === "Preset") {
      presetOption = record.valuesOptions.find((i) => i.value === value) || {};
      Object.assign(settings, presetOption.settings || {});
      settings.preset = value;
      record.valuesOptions.forEach((item) => {
        item.selected = item.value === value;
      });
    }
    setRecords(records, true);

    // update filters
    var filters = cmpWorking.current.filters || [];
    var filter = filters[listOrder];
    filter.settings = record.settings;
    if (presetOption) {
      filter.operator = presetOption.operator;
    }
    cmpWorking.current.filters = filters;
    setFilterSetState((prev) => ({ ...prev, filters }));
  };

  const helperUpdateFilterValue = (item, value, listOrder) => {
    try {
      // var listOrder = event.getSource().get("v.name");
      // var value = event.getSource().get("v.value");
      // helper.updateFilterValue(cmp, listOrder, value);

      // var listOrder = event.getSource().get("v.name");
      // var value = event.getSource().get("v.value");

      cmpWorking.current.records = cmpWorking.current.records.map(
        (record, index) =>
          index === listOrder ? { ...record, [item]: value } : record
      );

      setFilterSetState((prev) => ({
        ...prev,
        records: cmpWorking.current.records,
      }));

      updateFilterValue(listOrder, value);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const newFilter = () => {
    try {
      var pattern = props.pattern;
      cmpWorking.current.listOrder = null;
      cmpWorking.current.selectedFilter = null;
      cmpWorking.current.selectedContainerId = (
        ((pattern || {}).key || {}).container || {}
      ).id;
      cmpWorking.current.filterModalIsOpen = true;
      setFilterSetState((prev) => ({
        ...prev,
        listOrder: null,
        selectedFilter: null,
        selectedContainerId: (((pattern || {}).key || {}).container || {}).id,
        filterModalIsOpen: true,
      }));
    } catch (err) {
      console.error(err.stack);
    }
  };

  const cancelFilter = () => {
    try {
      cmpWorking.current.listOrder = null;
      cmpWorking.current.filterModalIsOpen = false;
      cmpWorking.current.showRequiredFieldError = false;
      setFilterSetState((prev) => ({
        ...prev,
        listOrder: null,
        filterModalIsOpen: false,
        showRequiredFieldError: false,
      }));
      setToastState(emptyToastState);
    } catch (err) {
      console.error(err.stack);
    }
  };

  // this saveFilter is coming from filterSetController
  const saveFilter = () => {
    try {
      const filterState = filterCmpRef.current.getFilterCmpState();
      const filterCmp = filterCmpRef.current.getCmp();
      const selectedFilter = filterState;
      const operator = filterState.operator;

      if (
        !selectedFilter.record.name ||
        !operator ||
        operator === "Not Available" ||
        !selectedFilter.selectedKey ||
        (operator === "RelativeDateTime" &&
          ((selectedFilter.maxDateAmount && !selectedFilter.maxDateUnit) ||
            (selectedFilter.maxDateUnit && !selectedFilter.maxDateAmount) ||
            (selectedFilter.minDateAmount && !selectedFilter.minDateUnit) ||
            (selectedFilter.minDateUnit && !selectedFilter.minDateAmount))) ||
        (operator && operator.toLowerCase().includes("preset")) &
          !selectedFilter.selectedPreset
      ) {
        setToastState({
          variant: "error",
          heading: "Input Error",
          details: "Please update the invalid form entries and try again.",
        });
        cmpWorking.current.showRequiredFieldError = true;
        setFilterSetState((prev) => ({
          ...prev,
          showRequiredFieldError: true,
        }));
        return;
      }

      // var filterCmp = cmp.find("filterModal");
      // if (!filterCmp.checkValid()) {
      //   return;
      // }
      // var filter = filterCmp.getJson();
      // var listOrder = cmp.get("v.listOrder");
      // cmp.set("v.filterModalIsOpen", false);
      // helper.saveFilter(filter, listOrder);
      // cmp.set("v.listOrder", null);

      const filter = filterJson(filterCmp);

      const listOrder = filterSetState.listOrder;

      saveFilterHelper(filter, listOrder);

      cmpWorking.current.filterModalIsOpen = false;
      cmpWorking.current.listOrder = null;

      setFilterSetState((prev) => ({
        ...prev,
        filterModalIsOpen: false,
        listOrder: null,
      }));
    } catch (err) {
      console.error(err.stack);
    }
  };

  // this saveFilter is coming from filterSetHelper
  const saveFilterHelper = (filter, listOrder) => {
    try {
      // deepcopy and parse
      var record = JSON.parse(JSON.stringify(filter));
      delete filter.filterKey;
      parseRecord(record, listOrder);
      var filters = cmpWorking.current.filters || [];
      var records = cmpWorking.current.records || [];
      if (listOrder === null) {
        record.listOrder = records.length;
        filters.push(filter);
        records.push(record);
      } else {
        filters[listOrder] = filter;
        records[listOrder] = record;
      }
      cmpWorking.current.filters = filters;
      setFilterSetState((prev) => ({ ...prev, filters }));
      setRecords(records, true);
    } catch (err) {
      console.error(err.stack);
    } finally {
      cancelFilter();
    }
  };

  const applyFilters = () => {
    try {
      // var pattern = helper.applyFilters(cmp);
      var pattern = helperApplyFilters();
      // var dataCompEvent = cmp.getEvent("dataCompEvent");
      // dataCompEvent.setParams({ data: { action: "update", pattern: pattern } });
      // dataCompEvent.fire();
      const event = {
        data: { action: "update", pattern: pattern },
        type: "dataCompEvent",
      };
      props.handleEvent(event);
    } catch (err) {
      console.error(err.stack);
    }
  };

  const helperApplyFilters = () => {
    try {
      var FLT_IND = 4;
      var pattern = props.pattern || {};
      var nodes = pattern.inputs || [];
      var records = cmpWorking.current.records;
      var filters = cmpWorking.current.filters;

      // first, update existing filters (same order as they are extracted in parsePattern)
      var listOrder = 0;
      nodes.forEach((node) => {
        if (node.argOrder === FLT_IND) {
          node.filter = filters[listOrder];
          listOrder++;
        }
      });

      // next, add new filters inside a Where Node
      if (listOrder < filters.length) {
        for (var i = listOrder; i < filters.length; i++) {
          nodes.push({
            type: "Where",
            argOrder: FLT_IND,
            rootOrder: FLT_IND,
            filter: filters[i],
            inputs: [],
          });
        }
      }

      // remove Where nodes with deleted filters
      nodes = nodes.filter((node) => node.argOrder !== FLT_IND || node.filter);

      // force recalculation of previously calculated intervals in 'Refine' Nodes
      nodes.forEach((node) => {
        if (node.type === "Refine" && node.parameters) {
          delete node.parameters.interval;
        }
      });

      // update filters so removed items and listOrder stay in sync even if pattern doesn't return correctly
      pattern.inputs = nodes;

      records = records.filter((item) => item);
      filters = filters.filter((item) => item);

      cmpWorking.current.filters = filters;
      cmpWorking.current.records = records;
      setFilterSetState((prev) => ({
        ...prev,
        records,
        filters,
      }));
      return pattern;
    } catch (err) {
      console.error(err.stack);
    }
  };

  return (
    <>
      <div className="slds-is-relative">
        {filterSetState.loading && (
          <Spinner assistiveText={{ label: "Loading" }} />
        )}
        <div
          className="slds-scrollable--y slds-m-bottom_x-small"
          style={{ maxHeight: "200px" }}
        >
          {filterSetState.records.length > 0 &&
            filterSetState.records.map((record, index) =>
              record === null ? null : (
                <div key={index} className="slds-m-vertical_x-small">
                  <lightning-layout
                    verticalAlign="center"
                    style={{ display: "flex", marginBottom: "15px" }}
                  >
                    <div
                      // alignmentBump="right"
                      className="slds-truncate slds-has-flexi-truncate"
                      style={{ maxWidth: "35em" }}
                    >
                      {record?.name || null}
                      {!record?.useInput && (
                        <div>
                          {record?.operator} {record?.values}
                        </div>
                      )}
                    </div>
                    <div style={{ float: "right", marginRight: "-10px" }}>
                      <button
                        className={`slds-button slds-button_icon ${
                          record?.active
                            ? "slds-button_icon-brand"
                            : "slds-button_icon-border"
                        }`}
                        title={record?.active ? "Active" : "Inactive"}
                        onClick={() => toggleFilterActive(record.listOrder)}
                      >
                        <svg className="slds-button__icon" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#filterList"></use>
                        </svg>
                        <span className="slds-assistive-text">Search</span>
                      </button>
                      <Button
                        assistiveText={{ icon: "Icon Border medium" }}
                        iconCategory="utility"
                        iconName="edit"
                        iconVariant="border"
                        variant="icon"
                        title="Edit"
                        onClick={() => editFilter(record.listOrder)}
                      />
                      <Button
                        className="slds-m-right_small"
                        assistiveText={{ icon: "Icon Border medium" }}
                        iconCategory="utility"
                        iconName="delete"
                        iconVariant="border"
                        variant="icon"
                        disabled={record?.reset}
                        title={
                          record?.reset
                            ? `Cannot delete filter with ${record?.scope} scope`
                            : "Delete"
                        }
                        onClick={
                          record?.reset
                            ? () =>
                                console.log(
                                  `Cannot delete filter with ${record?.scope} scope`
                                )
                            : () => deleteFilter(record.listOrder)
                        }
                      />
                    </div>
                  </lightning-layout>
                  <div className="slds-truncate">
                    {/* Preset */}
                    {record?.useInput === "Preset" && (
                      <div className="slds-form-element slds-form-element_stacked">
                        <div className="slds-form-element">
                          <div className="slds-form-element__control">
                            <div className="slds-select_container">
                              <select
                                className="slds-select"
                                id="select-01"
                                onChange={(e) =>
                                  helperUpdateFilterValue(
                                    "selected",
                                    e.target.value,
                                    record.listOrder
                                  )
                                }
                                value={record.selected || ""}
                              >
                                {record?.valuesOptions.map((item, idx) => (
                                  <option key={idx} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* FreeText */}
                    {record?.useInput === "FreeText" && (
                      <div className="slds-col slds-size--12-of-12 slds-form-element slds-form-element_stacked">
                        {/* <lightning:input
                          aura:id="checkField"
                          name="{! record.listOrder }"
                          label="{! record.operator }"
                          autocomplete="off"
                          fieldLevelHelp='Enter one or more values separated by commas. Enclose values with commas in double quotes. Example: orange, "pear, apple", banana'
                          value="{!record.freeTextValues}"
                          onblur="{! c.updateFilterValue }"
                        /> */}

                        {/* <div className="slds-form-element">
                          <label
                            class="slds-form-element__label"
                            for="text-input-id-46"
                          >
                            Input Label
                          </label>
                          <div className="slds-form-element__icon">
                            <button
                              class="slds-button slds-button_icon"
                              aria-describedby="help"
                            >
                              <svg class="slds-button__icon" aria-hidden="true">
                                <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#info"></use>
                              </svg>
                              <span class="slds-assistive-text">Help</span>
                            </button>
                            <div
                              class="slds-popover slds-popover_tooltip slds-nubbin_bottom-left"
                              role="tooltip"
                              id="help"
                              style={{
                                position: "absolute",
                                top: "-45px",
                                left: "-15px",
                                width: "170px",
                              }}
                            >
                              <div className="slds-popover__body">
                                Some helpful information.
                              </div>
                            </div>
                          </div>
                          <div className="slds-form-element__control">
                            <input
                              type="text"
                              id="text-input-id-46"
                              placeholder="Placeholder text…"
                              class="slds-input"
                            />
                          </div>
                        </div> */}

                        <Input
                          type="text"
                          name={record?.listOrder.toString()}
                          value={record?.freeTextValues || ""}
                          label={record.operator}
                          fieldLevelHelpTooltip={
                            <Tooltip
                              id="field-level-help-tooltip"
                              align="top left"
                              content='Enter one or more values separated by commas. Enclose values with commas in double quotes. Example: orange, "pear, apple", banana'
                            />
                          }
                          onChange={(e) =>
                            helperUpdateFilterValue(
                              "freeTextValues",
                              e.target.value,
                              record.listOrder
                            )
                          }
                          onBlur={(e) => helperUpdateFilterValue(e)}
                          autocomplete="off"
                        />
                      </div>
                    )}

                    {/* SingleSelect */}
                    {record?.useInput === "SingleSelect" && (
                      <div className="slds-col slds-size--12-of-12 slds-form-element slds-form-element_stacked">
                        <div className="slds-form-element">
                          <label
                            className="slds-form-element__label"
                            htmlFor="select-01"
                          >
                            {record?.operator}
                          </label>
                          <div className="slds-form-element__control">
                            <div className="slds-select_container">
                              <select
                                className="slds-select"
                                name={record?.listOrder}
                                value={record.selected || ""}
                                id="select-01"
                                onChange={(e) =>
                                  helperUpdateFilterValue(
                                    "selected",
                                    e.target.value,
                                    record.listOrder
                                  )
                                }
                              >
                                {record?.valuesOptions.map((item, idx) => (
                                  <option key={idx} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    )}
                    {/* {record?.useInput === "SingleSelect" && (
                    <div className="slds-col slds-size--12-of-12 slds-form-element slds-form-element_stacked">
                      single select...
                      <select
                        name={record?.listOrder}
                        value={record?.selected}
                        onChange={(e) =>
                          updateFilterValue(record.listOrder, e.target.value)
                        }
                      >
                        {record?.valuesOptions.map((item, idx) => (
                          <option
                            key={idx}
                            value={item.value}
                            selected={item.selected}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select>
                      <div className="slds-form-element">
                        <label
                          className="slds-form-element__label"
                          for="select-01"
                        >
                          Select Label
                        </label>
                        <div className="slds-form-element__control">
                          <div className="slds-select_container">
                            <select className="slds-select" id="select-01">
                              <option value="">Select…</option>
                              <option>Option One</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
                    {/* {!record?.useInput && (
                      <div>
                        {record?.operator} {record?.values}
                      </div>
                    )} */}
                  </div>
                  {/* <div className="slds-form-element slds-form-element_stacked">
                    <div className="slds-m-vertical_x-small">
                      <div className="slds-form-element slds-form-element_stacked">
                        <div className="slds-m-vertical_x-small">
                          <div className="slds-truncate">{record?.name}</div>
                          <div className="slds-form-element__control">
                            {record.active ? (
                              <button
                                onClick={() =>
                                  alert("toggleFilterActive(record.listOrder)")
                                }
                              >
                                Active
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  alert("toggleFilterActive(record.listOrder)")
                                }
                              >
                                Inactive
                              </button>
                            )}
                            <button
                              onClick={() =>
                                alert("editFilter(record.listOrder)")
                              }
                            >
                              Edit
                            </button>
                            {record.reset ? (
                              <button disabled={true}>
                                Cannot delete filter with {record.scope} scope
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  alert("deleteFilter(record.listOrder)")
                                }
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      Preset
                      {record?.useInput === "Preset" && (
                        <div className="slds-form-element slds-form-element_stacked">
                          <select
                            name={record?.listOrder}
                            value={record?.selected}
                            onChange={(e) =>
                              updateFilterValue(
                                record.listOrder,
                                e.target.value
                              )
                            }
                          >
                            {record?.valuesOptions.map((item, idx) => (
                              <option
                                key={idx}
                                value={item.value}
                                selected={item.selected}
                              >
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      FreeText
                      {record?.useInput === "FreeText" && (
                        <div className="slds-form-element slds-form-element_stacked">
                          <input
                            type="text"
                            name={record?.listOrder}
                            value={record?.freeTextValues}
                            //onBlur={(e) => updateFilterValue(record.listOrder, e.target.value)}
                          />
                        </div>
                      )}
                      SingleSelect
                      {record?.useInput === "SingleSelect" && (
                        <div className="slds-form-element slds-form-element_stacked">
                          <select
                            name={record?.listOrder}
                            value={record?.selected}
                            onChange={(e) =>
                              updateFilterValue(
                                record.listOrder,
                                e.target.value
                              )
                            }
                          >
                            {record?.valuesOptions.map((item, idx) => (
                              <option
                                key={idx}
                                value={item.value}
                                selected={item.selected}
                              >
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      {!record?.useInput && (
                        <div>
                          {record?.operator} {record?.values}
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
              )
            )}
        </div>
        <div className="slds-grid slds-gutters_xx-small">
          <div className="slds-col slds-size_1-of-2">
            <button
              type="submit"
              className="slds-button slds-button_neutral slds-button_stretch"
              onClick={newFilter}
              label="New..."
              title="New Filter"
            >
              New...
            </button>
          </div>
          {filterSetState.showApplyButton && (
            <div className="slds-col slds-size_1-of-2">
              {/* <!-- still show the button if all filters are marked for deletion, because the pattern may change when removing a Filter --> */}
              <button
                type="submit"
                className="slds-button slds-button_brand slds-button_stretch"
                onClick={applyFilters}
                label="Apply Filters"
                title="Apply Filters"
              >
                Apply Filters
              </button>
            </div>
          )}
        </div>
      </div>
      {/* <!-- Filters Modal Window --> */}
      {filterSetState.filterModalIsOpen ? (
        <IconSettings iconPath="/assets/icons">
          <Modal
            isOpen={filterSetState.filterModalIsOpen}
            footer={[
              <Button key="Cancel" label="Cancel" onClick={cancelFilter} />,
              <Button
                key="Save"
                label="Save"
                variant="brand"
                onClick={saveFilter}
              />,
            ]}
            onRequestClose={cancelFilter}
            heading="Filter"
          >
            <div
              className="slds-modal__content slds-p-around_medium"
              id="modal-content-id-filter"
            >
              <PsFilter
                recordValue={filterSetState.selectedFilter}
                parentId={filterSetState.selectedContainerId}
                newScopes={["Pattern"]}
                isModal={true}
                ref={filterCmpRef}
                filterSetState={filterSetState}
                showRequiredFieldError={filterSetState.showRequiredFieldError}
              />
            </div>
          </Modal>
        </IconSettings>
      ) : null}
      {toastState.details ? (
        <ToastComponent
          close={() => setToastState(emptyToastState)}
          details={toastState.details}
          variant={toastState.variant}
          heading={toastState.heading}
        />
      ) : null}
    </>
  );
};

export default FilterSet;
