import { useRef, useState, useEffect } from "react";
import { Button } from "@salesforce/design-system-react";

import PsRecordGrid from "../ps-record-grid/PsRecordGrid";
import Record from "../../helpers/recordLayer";
import { toastDetails } from "../../helpers";

const PsFieldList = (props) => {
  const [cmpState, setCmpState] = useState({
    recordLabel: "Field",
    recordLabelPlural: "Fields",
    emptyLine: "Load the data inventory from the source file or system",
    recordModule: "pump",
    recordObject: "field",
    changeView: false,

    //////
    loading: false,
    orderBy: "name",
    orderDirection: "asc",
    recordList: [],
    recordColumns: [],
    view: "table",
    showTitle: true,
    hasMore: true,
    mode: "init",
    showLoadMore: true,
    showEmptyCallToAction: true,

    loadingMore: false,
  });

  const cmpWorking = useRef({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    cmpWorking.current = { ...cmpState };
    cmp.init();
    cmp.afterScriptsLoaded();
  }, []);

  useEffect(() => {
    if (!props.parentToChildEvent.action) {
      return;
    }
    cmp.handleParentToChildEvent(props.parentToChildEvent);
  }, [props.parentToChildEvent.action]);

  useEffect(() => {
    if (isFirstRender.current) {
      // last useEffect set it to false
      isFirstRender.current = false;
      return;
    }
    if (props.queryFilter?.objectId) {
      cmp.handleReset();
    }
  }, [props.queryFilter?.objectId]);

  const cmp = {
    // --- FieldListController.js ---

    init: function () {
      PsRecordGrid.setRecordColumns(cmp);
    },

    afterScriptsLoaded: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    // load the same number of items as are already in the list, or if no list was loaded, load maxRecords
    handleReload: function () {
      var maxRecords = cmp.get("maxRecords");
      var numRecords = !maxRecords
        ? 0
        : Math.max(cmp.get("recordList").length, maxRecords);
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleReset: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.reset(cmp);
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleLoadMore: function () {
      var numRecords = cmp.get("maxRecords");
      PsRecordGrid.getRecords(cmp, numRecords);
    },

    handleObjectSync: function () {
      cmp.actionSyncObject();
    },

    handleRecordRowAction: function (row) {
      const action = row.action.name;
      var parentId = cmp.get("parentId");
      var recordModule = cmp.get("recordModule");
      var recordObject = cmp.get("recordObject");
      switch (action) {
        case "details":
          PsRecordGrid.notifyNavigation(
            cmp,
            parentId,
            recordModule,
            recordObject,
            row.id
          );
          break;
        default:
      }
    },

    // --- FieldListHelper.js ---

    RECORD_COLUMNS: [
      {
        label: "Name",
        width: "300px",
        type: "link",
        property: "name",
        key: "name",
        sortable: true,
        title: "View Details",
        action: "details",
      },
      {
        label: "Source Identifier",
        width: "175px",
        property: "sourceIdentifier",
        key: "sourceIdentifier",
        type: "text",
        cellAttributes: { alignment: "left" },
      },
      {
        label: "Status",
        width: "100px",
        property: "status",
        key: "status",
        type: "text",
        sortable: true,
        cellAttributes: { alignment: "left" },
      },
      {
        label: "# Records Loaded",
        width: "175px",
        property: "totalRecords",
        key: "totalRecords",
        type: "number",
        sortable: true,
      }, // IMPROVEMENT: number formatting with thousands separators?
      {
        label: "Found in Source",
        width: "125px",
        property: "sourceExists",
        key: "sourceExists",
        type: "boolean",
      },
      {
        label: "Restrictions",
        width: "125px",
        property: "restrictions",
        key: "restrictions",
        type: "text",
      },
      //IMPROVEMENT:  set 'Include' and 'Exclude' action dynamically, based on the field status
    ],

    parseResponse: function (response) {
      return response.map(
        ({
          id,
          name,
          sourceIdentifier,
          status,
          totalRecords,
          sourceExists,
          lastRunOn,
          restrictions,
        }) => ({
          id,
          name,
          sourceIdentifier,
          status,
          totalRecords,
          sourceExists,
          lastRunOn,
          restrictions: this.parseRestrictionLabels(restrictions),
        })
      );
    },

    // comma-separated list of Restriction Type names for active retrictions
    parseRestrictionLabels: function (restrictions) {
      var restrictionType;
      restrictions = restrictions || [];
      return restrictions
        .reduce((o, i) => {
          if (i.status !== Record.RESTRICTION_STATUS.INACTIVE.value) {
            restrictionType = i.restrictionType || {};
            o.push(restrictionType.name);
          }
          return o;
        }, [])
        .join(", ");
    },

    actionSyncObject: function () {
      try {
        var previousMode = cmp.get("mode");
        PsRecordGrid.setLoading(cmp);
        var objectId = cmp.get("parentId");
        var onErrorSync = function (response) {
          cmp.setToastState("error", "Error", toastDetails(response));
          PsRecordGrid.setMode(cmp, previousMode);
        };
        var onSuccesSync = function () {
          cmp.setToastState(
            "info",
            "Connector Started",
            "Loading data inventory"
          );

          PsRecordGrid.setMode(cmp, previousMode);
        };
        var onErrorRecord = function (response) {
          cmp.setToastState("error", "Error", toastDetails(response));
          PsRecordGrid.setMode(cmp, previousMode);
        };
        var onSuccessRecord = function (response) {
          var record = response[0];
          var connectorId = record.connectorId || (record.connector || {}).id;
          Record.doAction(
            "pump",
            "connector",
            "sync",
            { id: connectorId, objectId },
            onSuccesSync,
            onErrorSync
          );
        };
        Record.getRecord(
          "pump",
          "object",
          objectId,
          {},
          onSuccessRecord,
          onErrorRecord
        );
      } catch (error) {
        console.error(error.stack);
      }
    },

    // --- New functions ---

    get: (key) => {
      if (props.hasOwnProperty(key)) return props[key];
      return cmpWorking.current[key];
    },

    set: (key, value) => {
      cmpWorking.current[key] = value;
      setCmpState((prev) => ({ ...prev, [key]: value }));
    },

    setToastState: function (variant, heading, details) {
      props.setToastState({ variant, heading, details });
    },

    handleParentToChildEvent: (event) => {
      if (event.action === "reload") {
        cmp.handleReload();
        props.parentCmp.set("parentToChildEvent", {});
      }
    },

    handleEvent: function (event) {
      let stopPropagation = false;

      if (!stopPropagation) {
        props.childToParent(event);
      }
    },

    emptyCallToAction: function () {
      return (
        <Button
          // id="ViewModeButton"
          disabled={cmpState.loading}
          label="Load Inventory"
          title="Load the data inventory from the source file or system"
          onClick={cmp.handleObjectSync}
          variant="brand"
        />
      );
    },
  };

  return PsRecordGrid.render(cmp, cmpState);
};

export default PsFieldList;
