import { useEffect, useState } from "react";
import {
  Button,
  IconSettings,
  Input,
  Spinner,
} from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import Combobox from "@salesforce/design-system-react/components/combobox";
import Cookies from "js-cookie";

import { GENERAL_ERROR, LOGIN } from "../../../constants/ToastMessages";
import "../../Pages.css";

const LoginForm = ({
  user,
  setUser,
  setToastState,
  userHasAuthenticated,
  setMode,
  setNewUser,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { username, password, region } = user;

  useEffect(() => {
    if (regions().length === 1) {
      setUser((prevUser) => ({ ...prevUser, region: regions()[0].value }));
    }
  }, []);

  const regions = () => {
    const regionNumberCount = parseInt(
      process.env.REACT_APP_COGNITO_REGION_NUMBER_COUNT,
      10
    );
    const regionOptions = Array.from({ length: regionNumberCount }, (v, i) => {
      const id = (i + 1).toString();
      const label = process.env[`REACT_APP_COGNITO_REGION_LABEL_${id}`];
      const value = id;
      return { id, label, value };
    });

    return regionOptions;
  };

  const navigate = useNavigate();

  const handleUserInputChange = (e, item) => {
    setUser((prevUser) => ({ ...prevUser, [item]: e.target.value }));
    setToastState({ variant: "", details: "" });
  };

  const handleSubmitLoginForm = async (event) => {
    try {
      event.preventDefault();
      setToastState({ variant: "", details: "" });
      setIsLoading(true);

      var user = await Auth.signIn(username, password);

      if (user) {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setNewUser(user);
          setMode("newpassword");
          return;
        }
        setUser((prev) => ({
          ...prev,
          ...user,
          username: user.username,
          email: user.attributes.email,
        }));

        // set region in cookies
        Cookies.set("region", region);

        // CHECK: this is not done in ConfirmationForm? is that not setting email?
        userHasAuthenticated(true); //also used in ConfirmationForm
        setToastState({ variant: "success", details: LOGIN.SUCCESS });
        navigate("/", { replace: true });
        return;
      }
      setToastState({
        variant: "error",
        details: GENERAL_ERROR,
      });
    } catch (err) {
      if (err.code === "PasswordResetRequiredException") {
        setMode("resetpassword");
        return;
      }
      console.error(err.stack);
      setToastState({
        variant: "error",
        details: err.message || GENERAL_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const selectRegion = (data) => {
    setUser((prevUser) => ({ ...prevUser, region: data.selection[0].value }));
    setToastState({ variant: "", details: "" });
  };

  return (
    <form onSubmit={handleSubmitLoginForm}>
      {regions().length > 1 && (
        <div className="user-inputs">
          <IconSettings iconPath="/assets/icons">
            <Combobox
              events={{
                onSelect: (event, data) => {
                  selectRegion(data);
                },
              }}
              labels={{
                label: "Region",
                placeholderReadOnly: "Select Region",
              }}
              options={regions()}
              selection={[regions().find((option) => option.value === region)]}
              value={region}
              variant="readonly"
            />
          </IconSettings>
        </div>
      )}
      <div className="user-inputs">
        <Input
          type="text"
          label="Username"
          value={username}
          onChange={(e) => handleUserInputChange(e, "username")}
        />
      </div>
      <div className="user-inputs">
        <Input
          type="password"
          label="Password"
          value={password}
          onChange={(e) => handleUserInputChange(e, "password")}
        />
      </div>
      <div className="login-signup-button">
        <Button
          type="submit"
          label={<>Log in {isLoading ? <Spinner size="small" /> : null}</>}
          variant="brand"
          disabled={isLoading || !username || !password || !region}
          style={{ width: "100%" }}
        />
      </div>
      <nav className="login-signup-link">
        {/*  <p>Don’t have an account? <Link to="/signup">Sign up</Link></p>         */}
        <p>
          Forgot your password? <Link to="/forgotPassword">Reset here</Link>
        </p>
      </nav>
    </form>
  );
};

export default LoginForm;
