import { v4 as uuidv4 } from "uuid";
import { Auth, API } from "aws-amplify";
import { useNavigate } from "react-router";
import { arrayToQueryString } from ".";

const Record = {
  ENVIRONMENT_STATUS: {
    ACTIVE: {
      value: "Active",
      label: "Active",
      description: "Connectors can load inventory and data",
    },
    INACTIVE: {
      value: "Inactive",
      label: "Inactive",
      description: "Connectors won't contact the Source",
    },
  },

  SOURCE_RESTRICT: {
    NONE: { value: "", label: "--None--" },
    SUPPLEMENTARY: {
      value: "Supplementary",
      label: "Supplementary",
      description:
        "Only combine with other Sources; don't find Patterns within this Source",
    },
    ISOLATED: {
      value: "Isolated",
      label: "Isolated",
      description: "Don't combine with other Sources",
    },
  },

  CONNECTOR_STATUS: {
    ACTIVE: {
      value: "Active",
      label: "Active",
      description: "Download new data from the Source",
    },
    INACTIVE: {
      value: "Inactive",
      label: "Inactive",
      description: "Don't contact the Source, but keep data downloaded so far",
    },
  },

  RESTRICTION_STATUS: {
    ACTIVE: { value: "Active", label: "Active" },
    INACTIVE: { value: "Inactive", label: "Inactive" },
  },

  OBJECT_STATUS: {
    INCLUDED: { value: "Included", label: "Included" },
    EXCLUDED: { value: "Excluded", label: "Excluded" },
  },

  FIELD_STATUS: {
    INCLUDED: { value: "Included", label: "Included" },
    EXCLUDED: { value: "Excluded", label: "Excluded" },
  },

  DEFAULT_OBJECT_STATUS: {
    INCLUDED: {
      value: "Included",
      label: "Included",
      description: "Automatically load new Objects",
    },
    EXCLUDED: {
      value: "Excluded",
      label: "Excluded",
      description: "Let me choose which Objects to load",
    },
  },

  DEFAULT_FIELD_STATUS: {
    INCLUDED: {
      value: "Included",
      label: "Included",
      description: "Automatically load new Fields",
    },
    EXCLUDED: {
      value: "Excluded",
      label: "Excluded",
      description: "Let me choose which Fields to load",
    },
  },

  CONNECTOR_SCHEDULE: {
    NONE: { value: "--None--", label: "--None--" },
    //MINUTE: {value: 'Every Minute', label: 'Every Minute'}, // only used for testing
    HOUR: { value: "Hourly", label: "Hourly" },
    DAY: { value: "Daily", label: "Daily" },
    WEEK: { value: "Weekly", label: "Weekly" },
    MONTH: { value: "Monthly", label: "Monthly" },
  },

  FILTER_TYPES: {
    Text: [
      {
        value: "Includes",
        label: "Is In",
        useInput: "ListValues",
        default: true,
      },
      { value: "Excludes", label: "Is Not In", useInput: "ListValues" },
      { value: "Contains", label: "Contains", useInput: "FreeText" },
      { value: "Lacks", label: "Lacks", useInput: "FreeText" },
      { value: "Missing", label: "Missing", useInput: "Missing" },
      { value: "NotMissing", label: "Not Missing", useInput: "Missing" },
      {
        value: "PresetText",
        label: "Preset",
        useInput: "Preset",
        default: true,
      },
    ],
    Number: [
      {
        value: "Between",
        label: "Between",
        useInput: "BetweenNumber",
        default: true,
      },
      { value: "Missing", label: "Missing", useInput: "Missing" },
      { value: "NotMissing", label: "Not Missing", useInput: "Missing" },
      {
        value: "PresetNumber",
        label: "Preset",
        useInput: "Preset",
        default: true,
      },
    ],
    DateTime: [
      {
        value: "PresetDateTime",
        label: "Preset",
        useInput: "Preset",
        default: true,
      },
      {
        value: "RelativeDateTime",
        label: "Relative",
        operator: "Between",
        useInput: "RelativeDateTime",
      },
      {
        value: "BetweenDateTime",
        label: "Between",
        operator: "Between",
        useInput: "BetweenDateTime",
      },
      { value: "Missing", label: "Missing", useInput: "Missing" },
      { value: "NotMissing", label: "Not Missing", useInput: "Missing" },
    ],
  },

  FILTER_PRESETS: {
    Text: [
      {
        value: "AllValues",
        label: "All",
        operator: "Includes",
        description: "All values are included",
        settings: { values: null },
      },
      {
        value: "LoggedInUserId",
        label: "Logged In User Id",
        description: "Filter by logged in user id",
        operator: "Includes",
        settings: { values: null },
      },
      {
        value: "LoggedInUserValue",
        label: "Logged In User Value",
        description: "Filter by user field (defined in App Builder)",
        operator: "Includes",
        settings: { values: null },
      },
      {
        value: "EmbeddedRecordValue",
        label: "Embedded Record Value",
        description: "Filter by embedded record field (defined in App Builder)",
        operator: "Includes",
        settings: { values: null },
      },
    ],
    Number: [
      {
        value: "AllValues",
        label: "All",
        operator: "Between",
        settings: { min: null, max: null },
      },
    ],
    DateTime: [
      {
        value: "AllTime",
        label: "All Time",
        operator: "Between",
        settings: { min: null, max: null },
      },
      {
        value: "Today",
        label: "Today",
        operator: "Between",
        settings: { min: "0 days", max: "1 days" },
      },
      {
        value: "Last7Days",
        label: "Last 7 Days",
        operator: "Between",
        settings: { min: "-7 days", max: "0 days" },
      },
      {
        value: "Next7Days",
        label: "Next 7 Days",
        operator: "Between",
        settings: { min: "1 days", max: "8 days" },
      },
      {
        value: "LastWeek",
        label: "Last Week",
        operator: "Between",
        settings: { min: "-1 weeks", max: "0 weeks" },
      },
      {
        value: "ThisWeek",
        label: "This Week",
        operator: "Between",
        settings: { min: "0 weeks", max: "1 weeks" },
      },
      {
        value: "NextWeek",
        label: "Next Week",
        operator: "Between",
        settings: { min: "1 weeks", max: "2 weeks" },
      },
      {
        value: "Last30Days",
        label: "Last 30 Days",
        operator: "Between",
        settings: { min: "-30 days", max: "0 days" },
      },
      {
        value: "Next30Days",
        label: "Next 30 Days",
        operator: "Between",
        settings: { min: "1 days", max: "31 days" },
      },
      {
        value: "LastMonth",
        label: "Last Month",
        operator: "Between",
        settings: { min: "-1 months", max: "0 months" },
      },
      {
        value: "ThisMonth",
        label: "This Month",
        operator: "Between",
        settings: { min: "0 months", max: "1 months" },
      },
      {
        value: "NextMonth",
        label: "Next Month",
        operator: "Between",
        settings: { min: "1 months", max: "2 months" },
      },
      {
        value: "Last90Days",
        label: "Last 90 Days",
        operator: "Between",
        settings: { min: "-90 days", max: "0 days" },
      },
      {
        value: "Next90Days",
        label: "Next 90 Days",
        operator: "Between",
        settings: { min: "1 days", max: "91 days" },
      },
      {
        value: "LastQuarter",
        label: "Last Quarter",
        operator: "Between",
        settings: { min: "-1 quarters", max: "0 quarters" },
      },
      {
        value: "ThisQuarter",
        label: "This Quarter",
        operator: "Between",
        settings: { min: "0 quarters", max: "1 quarters" },
      },
      {
        value: "NextQuarter",
        label: "Next Quarter",
        operator: "Between",
        settings: { min: "1 quarters", max: "2 quarters" },
      },
      {
        value: "Last Year",
        label: "Last Year",
        operator: "Between",
        settings: { min: "-1 years", max: "0 years" },
      },
      {
        value: "This Year",
        label: "This Year",
        operator: "Between",
        settings: { min: "0 years", max: "1 years" },
      },
      {
        value: "Next Year",
        label: "Next Year",
        operator: "Between",
        settings: { min: "1 years", max: "2 years" },
      },
    ],
  },

  DATETIME_UNITS: [
    { label: "--None--", value: "" },
    { label: "Seconds", value: "seconds" },
    { label: "Minutes", value: "minutes" },
    { label: "Hours", value: "hours" },
    { label: "Days", value: "days" },
    { label: "Weeks", value: "weeks" },
    { label: "Months", value: "months" },
    { label: "Quarters", value: "quarters" },
    { label: "Years", value: "years" },
  ],

  DATETIME_ROLLUP: [
    { value: "N", label: "Nanosecond" },
    { value: "U", label: "Microsecond" },
    { value: "L", label: "Millisecond" },
    { value: "S", label: "Second" },
    { value: "T", label: "Minute" },
    { value: "H", label: "Hour" },
    { value: "D", label: "Day" },
    { value: "W", label: "Week" },
    { value: "M", label: "Month" },
    { value: "Q", label: "Quarter" },
    { value: "Y", label: "Year" },
    { value: "10Y", label: "Decade" },
    { value: "100Y", label: "Century" },
    { value: "", label: "Automatic" },
  ],

  FILTER_SCOPES: [
    {
      label: "Pattern",
      value: "Pattern",
      description: "Apply to this pattern only",
    },
    {
      label: "Outliers",
      value: "Outliers",
      description: "Automatic outlier filter",
    },
    {
      label: "Session",
      value: "Session",
      description: "Apply within this session",
    },
    {
      label: "Dashboard",
      value: "Dashboard",
      description: "Apply within this dashboard",
    },
    {
      label: "Project",
      value: "Project",
      description: "Apply within this project",
    },
    { label: "Global", value: "Global", description: "Apply to all patterns" },
  ],

  MAP_SCOPES: [
    {
      label: "Pattern",
      value: "Pattern",
      description: "Apply to this pattern only",
    },
    {
      label: "Session",
      value: "Session",
      description: "Apply within this session",
    },
    {
      label: "Dashboard",
      value: "Dashboard",
      description: "Apply within this dashboard",
    },
    {
      label: "Project",
      value: "Project",
      description: "Apply within this project",
    },
    { label: "Global", value: "Global", description: "Apply to all patterns" },
  ],

  SILENT_API_ERRORS: [
    "NotConnected",
    "NoSearchMatch",
    "EmptyPlotError",
    "PlotError",
    "Timeout",
  ],

  // IMPROVMENT: instead of defining this here separately, use the 'name' attributes in NavigationTree specification
  TERMINOLOGY: {
    source: "Source",
    container: "Object",
    key: "Field",
    dataType: "Data Type",
    dataRole: "Data Role",
    filter: "Filter",
    link: "Join",
    chain: "Path",
    agg: "Aggregation",
    transform: "Transformation",
  },

  /*
  =========================================
     ApiWrapper
  =========================================
*/

  makeCallout: function (myCalloutParameters, onSuccess, onError) {
    Auth.currentSession().then((res) => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      // to start Chrome without CORS checks:
      // open /Applications/Google\ Chrome.app --args --user-data-dir="/var/tmp/chrome-dev-disabled-security" --disable-web-security --disable-site-isolation-trials
      let headerBearerToken = `Bearer ` + jwt;

      const apiName = "Application";
      const path = myCalloutParameters.path; //+ '?mistake'; //'/relate/pattern';

      if (myCalloutParameters.method === "GET") {
        const myInit = {
          headers: { Authorization: headerBearerToken },
          response: true,
          queryStringParameters: {
            // maxRecords: 2
          },
        };

        API.get(apiName, path, myInit)
          .then((response) => {
            //can do more with the fact that we have error handling in here
            var parsedResponse = this.parseResponse(response, true);

            this.onRecordCallback(
              myCalloutParameters,
              parsedResponse,
              onSuccess,
              onError
            );
          })
          .catch((error) => {
            this.onRecordCallback(
              myCalloutParameters,
              error.response,
              onSuccess,
              onError
            );
            //onError.call();
          });
      } else if (myCalloutParameters.method === "PUT") {
        const myInit = {
          headers: { Authorization: headerBearerToken },
          response: true,
          body: JSON.parse(myCalloutParameters.body),
          // queryStringParameters: {
          //   maxRecords: 2
          // },
        };

        API.put(apiName, path, myInit)
          .then((response) => {
            //can do more with the fact that we have error handling in here
            var parsedResponse = this.parseResponse(response, true);

            this.onRecordCallback(
              myCalloutParameters,
              parsedResponse,
              onSuccess,
              onError
            );
          })
          .catch((error) => {
            this.onRecordCallback(
              myCalloutParameters,
              error.response,
              onSuccess,
              onError
            );
            //onError.call();
          });
      } else if (myCalloutParameters.method === "POST") {
        const myInit = {
          headers: { Authorization: headerBearerToken },
          response: true,
          body: JSON.parse(myCalloutParameters.body),
        };

        API.post(apiName, path, myInit)
          .then((response) => {
            var parsedResponse = this.parseResponse(response, true);

            this.onRecordCallback(
              myCalloutParameters,
              parsedResponse,
              onSuccess,
              onError
            );
          })
          .catch((error) => {
            this.onRecordCallback(
              myCalloutParameters,
              error.response,
              onSuccess,
              onError
            );
            //onError.call();
          });
      } else if (myCalloutParameters.method === "DELETE") {
        const myInit = {
          headers: { Authorization: headerBearerToken },
          response: true,
          body: "",
        };

        API.del(apiName, path, myInit)
          .then((response) => {
            var parsedResponse = this.parseResponse(response, true);

            this.onRecordCallback(
              myCalloutParameters,
              parsedResponse,
              onSuccess,
              onError
            );
          })
          .catch((error) => {
            this.onRecordCallback(
              myCalloutParameters,
              error.response,
              onSuccess,
              onError
            );
            //onError.call();
          });
      } else if (myCalloutParameters.method === "PATCH") {
        const myInit = {
          headers: { Authorization: headerBearerToken },
          response: true,
          body: JSON.parse(myCalloutParameters.body),
        };

        API.patch(apiName, path, myInit)
          .then((response) => {
            var parsedResponse = this.parseResponse(response, true);

            this.onRecordCallback(
              myCalloutParameters,
              parsedResponse,
              onSuccess,
              onError
            );
          })
          .catch((error) => {
            this.onRecordCallback(
              myCalloutParameters,
              error.response,
              onSuccess,
              onError
            );
            //onError.call();
          });
      }
    });
  },

  encodeQueryData: function (data) {
    const ret = [];
    for (let d in data) {
      if (data[d]) {
        ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
      }
    }
    return ret.join("&");
  },

  // parses API response
  //parseResponse : function(HttpResponse response, Boolean failsWhenEmpty) {
  parseResponse: function (response, failsWhenEmpty) {
    var isEmpty = false;
    var result = {
      success: false,
      status: "",
    };
    var resultObject = null;

    if (!response) {
      result.type = "NotConnected";
      result.message = "Unable to connect to API";
      result.suggestion = "Authenticate Point Sigma in the Setup tab";
      return result;
    }

    try {
      //result.json = response.getBody();
      result.data = response.data;
      // resultObject = JSON.deserializeUntyped(result.json);
      resultObject = response.data;
    } catch (e) {
      console.error("Unexpected API response format");
      result.message = "Unexpected API response format";
    }

    // parse response JSON
    var statusCode = response.status;
    if (statusCode === 200) {
      try {
        // if response code is 200, the response body is a JSON array
        // isEmpty = ((List<Object>) resultObject).isEmpty();
        if (!resultObject) {
          isEmpty = true;
        }

        if (isEmpty && failsWhenEmpty) {
          result.message = "Record(s) not found";
        } else {
          result.success = true;
        }
      } catch (e) {
        result.message = "Unexpected API response content";
      }
    } else {
      try {
        // if the response code is _not_ 200, the response body is a JSON object (i.e., key-value map)
        var errorObject = resultObject;
        result.type = errorObject.type;
        result.message = errorObject.message;
        result.reference = errorObject.reference;
        result.suggestion = errorObject.suggestion;
        if (result.message && result.message === "Endpoint request timed out") {
          result.type = "Timeout";
        }
      } catch (e) {
        result.message = "Unexpected API error message format";
      }
    }
    return result;
  },

  /*
  =========================================
     Records API
  =========================================
  */

  getRecords: function (
    module,
    object,
    filters = {},
    onSuccess = null,
    onError = null,
    operation = "GET"
  ) {
    let path = "/" + module + "/" + object;
    let inputBody = "";

    if (operation === "PUT") {
      inputBody = this.filtersMapToInputBody(filters);
    } else {
      if (filters && Object.keys(filters).length !== 0) {
        if (filters.id && filters.id.length > 0) {
          path += "?" + arrayToQueryString(filters);
        } else {
          path += "?" + this.encodeQueryData(filters);
        }
      }
    }

    //makeCallout
    const myCalloutParameters = {
      path: path,
      method: operation,
      body: inputBody,
    };
    this.makeCallout(myCalloutParameters, onSuccess, onError);
  },

  filtersMapToInputBody: function (filters) {
    let inputBody = "";

    const keyList = Object.keys(filters);

    if (keyList.length > 0) {
      inputBody = "{ ";
      for (let i = 0; i < keyList.length; i++) {
        const key = keyList[i];
        if (key === "maxRecords") {
          inputBody += `"${key}": ${filters[key]} `;
        } else {
          inputBody += `"${key}": "${filters[key]}" `;
        }
        if (i < keyList.length - 1) {
          inputBody += ", "; // add comma, unless last item
        }
      }
      inputBody += " }";
    } else {
      inputBody = "{}";
    }
    return inputBody;
  },

  getRecord: function (
    module,
    object,
    recordId,
    filters = {},
    inputBody = "",
    operation = "GET",
    onSuccess = null,
    onError = null
  ) {
    //need to do callout directly, check apex code, especially for inputBody
    let path = "/" + module + "/" + object + "/" + recordId;
    if (operation === "PUT" && module === "plot") {
      path = "/plot";
    }

    if (filters !== null && Object.keys(filters).length > 0) {
      path += "?" + this.encodeQueryData(filters);
    }

    const myCalloutParameters = {
      path: path,
      method: operation,
      body: inputBody,
    };
    this.makeCallout(myCalloutParameters, onSuccess, onError);
  },

  // creates record returning record ID immediately
  createRecord: function (
    module,
    object,
    inputBody = "",
    onSuccess = null,
    onError = null
  ) {
    let path = "/" + module + "/" + object;

    const myCalloutParameters = {
      path,
      method: "POST",
      body: inputBody,
    };
    this.makeCallout(myCalloutParameters, onSuccess, onError);
  },

  // update record
  updateRecord: function (
    module,
    object,
    inputBody,
    recordId,
    onSuccess = null,
    onError = null
  ) {
    let path = "/" + module + "/" + object + "/" + recordId;

    const myCalloutParameters = {
      path,
      method: "PATCH",
      body: inputBody,
    };
    this.makeCallout(myCalloutParameters, onSuccess, onError);
  },

  // submitRecord: function (
  //   cmp,
  //   helper,
  //   module,
  //   object,
  //   data,
  //   onSuccess = null,
  //   onError = null
  // ) {
  //   // update record if `id` is set, or create a new record otherwise
  //   var recordId = data.id;
  //   var action;
  //   if (recordId) {
  //     action = cmp.get("c.updateRecord");
  //     action.setParams({
  //       module: module,
  //       obj: object,
  //       recordId: recordId,
  //       value: data,
  //     });
  //   } else {
  //     action = cmp.get("c.createRecord");
  //     action.setParams({ module: module, obj: object, value: data });
  //   }
  //   var params = action.getParams();
  //   action.setCallback(Record, function (response) {
  //     Record.onRecordCallback(
  //       cmp,
  //       helper,
  //       params,
  //       response,
  //       onSuccess,
  //       onError
  //     );
  //   });
  //   // $A.enqueueAction(action);
  // },

  submitRecord: function (
    module,
    object,
    data,
    onSuccess = null,
    onError = null
  ) {
    var recordId = data.id;

    if (recordId) {
      this.updateRecord(
        module,
        object,
        JSON.stringify(data),
        recordId,
        onSuccess,
        onError
      );
    } else {
      this.createRecord(
        module,
        object,
        JSON.stringify([data]),
        onSuccess,
        onError
      );
    }
  },

  deleteRecord: function (
    module,
    object,
    recordId,
    onSuccess = null,
    onError = null
  ) {
    let path = "/" + module + "/" + object + "/" + recordId;

    const myCalloutParameters = {
      path,
      method: "DELETE",
      body: "",
    };
    this.makeCallout(myCalloutParameters, onSuccess, onError);
  },

  doAction: function (
    module,
    obj,
    action,
    args,
    onSuccess = null,
    onError = null
  ) {
    let path = "/" + module + "/" + obj + "/action";
    const inputBodyObject = {
      action,
      ...args,
    };

    const myCalloutParameters = {
      path,
      method: "POST",
      body: JSON.stringify(inputBodyObject),
    };
    this.makeCallout(myCalloutParameters, onSuccess, onError);
  },

  // intializes action on module and object; NB: actions run asynchronously and the API responds before the action starts
  //   public static ApiResultWrapper doAction(String module, String obj, String action, Map<String, String> args) {
  //     Map<String, String> body = new Map<String, String>{'action'=>action};
  //     body.putAll(args);
  //     CalloutParameters myCalloutParameters = new CalloutParameters();
  //     myCalloutParameters.path = '/' + module  + '/' + obj + '/action';
  //     myCalloutParameters.method = 'POST';
  //     myCalloutParameters.body = JSON.serialize(body);
  //     HttpResponse response = makeCallout(myCalloutParameters);
  //     return parseResponse(response, true);
  // }

  getUploadLink: function (module, folder, onSuccess = null, onError = null) {
    let path = "/" + module + "/upload?id=" + folder;

    const myCalloutParameters = {
      path: path,
      method: "GET",
    };
    this.makeCallout(myCalloutParameters, onSuccess, onError);
  },

  //OLD SF implementation
  // getUploadLink: function (
  //   cmp,
  //   helper,
  //   module,
  //   folder,
  //   onSuccess = null,
  //   onError = null
  // ) {
  //   var action = cmp.get("c.getUploadLink");
  //   action.setParams({ module: module, folder: folder });
  //   var params = action.getParams();
  //   action.setCallback(Record, function (response) {
  //     Record.onRecordCallback(
  //       cmp,
  //       helper,
  //       params,
  //       response,
  //       onSuccess,
  //       onError
  //     );
  //   });
  //   // $A.enqueueAction(action);
  // },

  // intializes action on module and object; NB: actions run asynchronously and the API responds before the action starts
  //   public static ApiResultWrapper getUploadLink(String module, String folder) {
  //     PageReference path = new PageReference( '/' + module + '/upload');
  //     path.getParameters().put('id', folder);
  //     CalloutParameters myCalloutParameters = new CalloutParameters();
  //     myCalloutParameters.path = path.getUrl();
  //     myCalloutParameters.method = 'GET';
  //     HttpResponse response = makeCallout(myCalloutParameters);
  //     return parseResponse(response, true);
  // }

  //   public class ApiResultWrapper{
  //     @AuraEnabled
  //     Public Boolean success = false;
  //     Public String status = '';
  //     @AuraEnabled
  //     public String type;
  //     @AuraEnabled
  //     public String message;
  //     @AuraEnabled
  //     public String reference;
  //     @AuraEnabled
  //     public String suggestion;
  //     @AuraEnabled
  //     public String json;
  // }

  //IN SF, 2 levels of callouts

  // In SF, 2 levels of myCallouts
  // JS > SF
  // SF > AWS
  onRecordCallback: function (
    myCalloutParameters,
    returnValue,
    onSuccess,
    onError
  ) {
    try {
      // if (!cmp.isValid()) { return; } // does the component exist in this context
      if (returnValue && returnValue.success) {
        if (onSuccess) {
          onSuccess(returnValue.data);
        }
      } else {
        var toastMessage = returnValue.message || "No response from server";
        if (returnValue.suggestion)
          toastMessage += "\n" + returnValue.suggestion;
        if (returnValue.reference) toastMessage += "\n" + returnValue.reference;

        //TODO
        if (!this.SILENT_API_ERRORS.includes(returnValue.type)) {
          this.showToast("Error", toastMessage, "error");
        }

        // API error on console helps finding log references
        console.error("API error response:");
        console.error(JSON.parse(JSON.stringify(myCalloutParameters)));
        console.error(returnValue);

        if (onError) {
          // window.Record = this;
          // onError.call(helper, cmp, returnValue || []);
          onError(returnValue || {});
        }
      }
    } catch (err) {
      console.error(err.stack);
    }
  },

  // IMPROVEMENT: this is not a very good way to generate a UUID, and we can update to use standard javascript UUID generator once that becomes available (https://github.com/tc39/proposal-uuid)
  // from: https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
  uuidv4: function () {
    return uuidv4();

    // return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    //     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    // );
  },

  flatten: function (record, attrib) {
    // flatten Object inputs on the specified attribute
    const flat = (record[attrib] || []).slice(); // shallow copy
    var i = 0;
    while (i < flat.length) {
      if (Array.isArray(flat[i][attrib])) {
        flat.push(...flat[i][attrib]);
      }
      i++;
    }
    return flat;
  },

  /*
  =========================================
     sorting
  =========================================
*/

  sortByString: function (field, reverse) {
    var dir = reverse ? -1 : 1;
    return function (a, b) {
      a = a[field];
      b = b[field];
      if (a === null) {
        return dir;
      }
      if (b === null) {
        return -1 * dir;
      }
      return dir * a.toLowerCase().localeCompare(b.toLowerCase());
    };
  },

  sortByOther: function (field, reverse) {
    var dir = reverse ? -1 : 1;
    return function (a, b) {
      a = a[field];
      b = b[field];
      return dir * ((a > b) - (b > a));
    };
  },

  // returns the sort function for the data type
  sortFunction: function (records, field) {
    if (
      Array.isArray(records) &&
      records.length &&
      typeof records[0][field] === "string"
    ) {
      return Record.sortByString;
    } else {
      return Record.sortByOther;
    }
  },

  /*
  =========================================
     UI
  =========================================
*/
  showToast: function (title, message, variant, sticky = false) {
    // var notifLib = cmp.find('notifLib');
    // if (!notifLib) {
    //     console.error('The component does not include "notifLib"');
    // }
    // var mode = (sticky) ? 'sticky' : 'dismissable';
    // if (Array.isArray(message)) {
    //     var messageTemplate = message[0];
    //     var messageData = message[1];
    //     cmp.find('notifLib').showToast({title, message: messageTemplate, messageData, variant, mode});
    // } else {
    //     cmp.find('notifLib').showToast({title, message, variant, mode});
    // }
  },

  //challenge that id's have to be unique for regular html ids, whereas auraid's can be duplicated
  setElementAttribute: function (id, attribute, value) {
    try {
      var elements = document.querySelectorAll("[id=" + id + "]");
      elements.forEach((element) => {
        element.setAttribute(attribute, value);
      });
      // const elem = document.getElementById(id);
      // if(elem){elem.setAttribute(attribute, value);}
    } catch (err) {
      console.error("setElementAttribute failed");
      console.error(err);
    }
  },

  //TODO > this is SF code. Convert to react
  checkForm: function (cmp, auraIds, focusFirst = true) {
    return true;

    // check inputs
    var components = [];
    auraIds.forEach((auraId) => {
      // cmp.find returns a single item if there is only one, we always require a list here
      components.push(...[].concat(cmp.find(auraId) || []));
    });

    // check validity
    var allValid = components.reduce(function (validSoFar, inputCmp) {
      inputCmp.showHelpMessageIfInvalid();
      var valid = (inputCmp.get("v.validity") || {}).valid;
      if (!valid && validSoFar && focusFirst) {
        inputCmp.focus();
      } // set focus to the first invalid item; IMPROVEMENT: not sure what 'first' means in this context, we need the invalidated input field that is the highest on the page
      return validSoFar && valid;
    }, true);

    if (!allValid) {
      Record.showToast(
        cmp,
        "Input Error",
        "Please update the invalid form entries and try again.",
        "error"
      );
    }

    return allValid;
  },

  /*
  =========================================
     Formatting
  =========================================
*/

  // API markup to html
  markupToHtml: function (text) {
    if (text) {
      return text.replace(/\[(.*?)\]/g, "<b>$1</b>");
    } else {
      return "";
    }
  },

  // remove API markup
  removeMarkup: function (text) {
    if (text) {
      return text.replace(/\[(.*?)\]/g, "$1");
    } else {
      return "";
    }
  },

  // very basic cron to human-readable conversion, assuming expression is filled from left to right
  cronToHuman: function (cron) {
    if (!cron) {
      return "";
    }
    var cronParts = [
      "Every Minute",
      "Hourly",
      "Daily",
      "Monthly",
      "Weekly",
      "Yearly",
    ];
    var cronSplit = cron.split(" ");
    if (!["?", "*"].includes(cronSplit[4])) {
      return cronParts[4]; // Weekly
    } else {
      return cronParts[cronSplit.indexOf("*")] || "";
    }
  },

  // IMPROVEMENT: we now randomly set a datetime within the selected frequency as a cron expression, and change that every time the connector is saved. The user may also set their own CRON expression over the API, which we would then overwrite here when saving the connector. Build functionality to keep the selected cron expression if it is not changed, e.g., by dynamically setting the picklist, and updating the picklist values with the chosen record.
  humanToCron: function (human) {
    if (!human) {
      return "";
    }
    //Random number between min (inclusive) and max (exclusive)
    var getRandomInt = function (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    var CRON_MAP = {
      "Every Minute": "* * * * ? *",
      Hourly: getRandomInt(0, 59) + " * * * ? *",
      Daily: getRandomInt(0, 59) + " " + getRandomInt(0, 23) + " * * ? *",
      Monthly:
        getRandomInt(0, 59) +
        " " +
        getRandomInt(0, 23) +
        " " +
        getRandomInt(1, 28) +
        " * ? *",
      Weekly:
        getRandomInt(0, 59) +
        " " +
        getRandomInt(0, 23) +
        " ? * " +
        getRandomInt(0, 6) +
        " *",
      Yearly:
        getRandomInt(0, 59) +
        " " +
        getRandomInt(0, 23) +
        " " +
        getRandomInt(1, 28) +
        " " +
        getRandomInt(1, 12) +
        " ? *",
    };
    return CRON_MAP[human];
  },

  // parse comma-separated
  parseCSV: function (text) {
    if (!text) {
      return null;
    }
    // adjusted from https://stackoverflow.com/questions/1757065/java-splitting-a-comma-separated-string-but-ignoring-commas-in-quotes
    //   var results = text.split(/,\s*(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g);
    var results = text.split(/,\s*(?=(?:[^"]*"[^"]*")*[^"]*$)/g);
    results = results.map((item) => {
      item = item.trim();
      return item.replace(/^"+|"+$/g, "");
    }); // trim spaces and double quotes;
    return results;
  },

  // array to CSV
  toCSV: function (list) {
    if (!list) {
      return null;
    }
    var results = list.reduce((lst, item) => {
      if (item.includes(",")) {
        // put quotes around items with commas inside
        item = '"' + item + '"';
      } else {
        // trim quotes from items with no commas inside
        item = item.replace(/^"+|"+$/g, "");
      }
      lst.push(item);
      return lst;
    }, []);
    return results.join(", ");
  },

  capitalizeWords: function (string) {
    return string.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  },

  intervalToHuman: function (interval) {
    // IMPROVEMENT: remove 's' when value=1; use 'this [unit]' when value=0
    return interval.startsWith("-")
      ? interval.slice(1) + " ago"
      : interval + " from now";
  },
  /*
  =========================================
     picklists
  =========================================
*/

  // post-processing after loading picklist items
  selectLoadedOption: function (
    cmp,
    optionsAttribute,
    selectedValueAttribute,
    selectedLabelAttribute = null,
    setDefault = false
  ) {
    var options = cmp.get(optionsAttribute) || [];
    var selectedValue = cmp.get(selectedValueAttribute);

    // clear the selectedValue if it is not in the options
    if (selectedValue && !options.find((i) => i.value === selectedValue)) {
      selectedValue = null;
    }

    // if there is only one item in the options, select it
    if (options.length === 1) {
      selectedValue = options[0].value;
    }

    // no option was selected, see if there is a default option, and select it
    if (!selectedValue && setDefault) {
      selectedValue = (options.find((i) => i.default) || {}).value;
    }

    // mark the relevant item as selected in the options items
    options.forEach((i) => {
      i.selected = i.value === selectedValue;
    });
    var selectedItem = options.find((i) => i.value === selectedValue) || {};

    // update the component
    try {
      cmp.set(optionsAttribute, options);
    } catch (err) {}
    try {
      cmp.set(selectedValueAttribute, selectedValue);
    } catch (err) {}
    try {
      cmp.set(selectedLabelAttribute, selectedItem.label);
    } catch (err) {}

    return selectedValue;
  },

  // post-processing after loading multi-select picklist items
  selectLoadedOptions: function (
    optionsAttribute,
    selectedValuesAttribute,
    selectedLabelsAttribute = null
  ) {
    var options = optionsAttribute || [];
    var optionsMap = options.reduce((obj, item) => {
      obj[item.value] = item;
      return obj;
    }, {});
    var selectedValues = selectedValuesAttribute || [];

    // clear selectedValues that are not not in the options
    selectedValues = selectedValues.reduce((obj, item) => {
      if (optionsMap[item]) {
        obj.push(item);
      }
      return obj;
    }, []);

    // set labels
    var selectedLabels = selectedValues.reduce((obj, item) => {
      obj.push(optionsMap[item].label);
      return obj;
    }, []);

    // update the component
    try {
      selectedValuesAttribute = selectedValues;
    } catch (err) {}
    try {
      selectedLabelsAttribute = selectedLabels.join(", ");
    } catch (err) {}

    return selectedValues;
  },

  // set label for selected piclist item
  setSelectedLabel: function (
    cmp,
    optionsAttribute,
    selectedValue,
    labelAttribute
  ) {
    var options = cmp.get(optionsAttribute) || [];
    var item = options.find((i) => i.value === selectedValue) || {};

    //REVIEW: cmp.set doesn't work with record.sourceName
    cmp.set(labelAttribute, item.label);
  },

  // set labels for selection in multi-select picklist
  setSelectedLabels: function (
    optionsAttribute,
    selectedValues,
    labelAttribute
  ) {
    var options = optionsAttribute || [];
    var valueToLabelMap = options.reduce((obj, item) => {
      obj[item.value] = item.label;
      return obj;
    }, {});
    var selectedLabels = selectedValues.reduce((obj, value) => {
      obj.push(valueToLabelMap[value]);
      return obj;
    }, []);
    labelAttribute = selectedLabels.join(", ");
  },

  /*
  =========================================
     Navigation tree
  =========================================
*/

  // navigation tree item from name
  parseName: function (name) {
    if (typeof name !== "string") {
      return {};
    }

    var parts = name.split("_");
    return {
      name,
      section: parts[0] || null,
      config: parts[1] || null,
      id: parts[2] || null,
    };
  },

  // navigation tree item from name
  nameFromDetails: function (section, config, id) {
    return [section || null, config || null, id || null]
      .map((v) => (v === null ? "" : v))
      .join("_");
  },

  // create navigation tree item from navigation event, setting default section in case the section is not specified
  itemFromEvent: function (event, objectSectionMap) {
    var object = event.obj;
    var id = event.id;
    var section = event.section || objectSectionMap[object];
    var breadcrumb = event.breadcrumb || [];
    var hasDetails = event.label != null;
    var label = event.label || "loading...";
    var titleBreadCrumb = breadcrumb.slice(1).map((item) => item.name);
    var type = this.TERMINOLOGY[object] || "Unknown";
    var title = titleBreadCrumb.length
      ? type + ": " + titleBreadCrumb.join("->")
      : "loading...";
    var name = this.nameFromDetails(section, object, id);
    var item = { name, section, config: object, id };
    Object.assign(item, { label: type + ": " + label, title, hasDetails });
    return item;
  },

  // update navigation tree item from record, or create new if not exists
  itemFromRecord: function (item, record, expanded = false) {
    if (item) {
      // update any changed values
      Object.assign(item, { label: record.name, argOrder: record.argOrder });
      return item;
    } else {
      // create new list item from record
      return (({ id, name, argOrder }) => ({
        name: id,
        label: name,
        expanded: expanded,
        argOrder,
        items: [],
        test: "asdf",
      }))(record);
    }
  },

  // keep separate mapping between record and navigation tree item
  mapRecord: function (itemMap, parentId, module, object, record) {
    var id = record.id;
    var loaded = (itemMap[id] || {}).loaded;
    var breadcrumb = Record.breadcrumb(itemMap, parentId);
    var rootId = (breadcrumb ? breadcrumb[0] : {}).id;
    return {
      id,
      name: record.name,
      module,
      object,
      rootId,
      parentId,
      loaded,
      breadcrumb,
    };
  },

  // recursively goes through items tree to find the item with the specified name
  findItem: function (itemTree, name) {
    var selectedItem;
    itemTree.some(function (item) {
      if (item.name === name) {
        selectedItem = item;
        return true;
      }
      var items = item.items;
      if (items && items.length) {
        selectedItem = Record.findItem(items, name);
        return selectedItem !== undefined;
      }
      return null; //Array.prototype.some() expects a value to be returned
    });
    return selectedItem;
  },

  // recursively goes through items tree to find the parent of the item with the specified name
  findParent: function (itemTree, name) {
    var selectedItem;
    itemTree.some(function (item) {
      var items = item.items;
      if (items && items.length) {
        if (items.find((i) => i.name === name)) {
          selectedItem = item;
          return true;
        }
        selectedItem = Record.findParent(items, name);
        return selectedItem !== undefined;
      }
      return null; //Array.prototype.some() expects a value to be returned
    });
    return selectedItem;
  },

  // extracts breadcrumb from tree structure as flat list
  breadcrumb: function (itemMap, id) {
    if (id) {
      var record = itemMap[id] || {};
      var result = Record.breadcrumb(itemMap, record.parentId);
      result.push(record);
      return result;
    }
    return [];
  },

  // create item when browsing through navigation tree programmatically
  parseItem: function (itemMap, module, object, record, parentItem) {
    var items = parentItem.items || [];
    var thisItem = items.find((item) => item.name === record.id);

    // create item if not exists
    if (!thisItem) {
      // remove placeholder item
      if (items.length === 1 && items[0].name === undefined) {
        items = [];
      }

      // create and add item to parent
      thisItem = Record.itemFromRecord(null, record, true);
      items.push(thisItem);
      parentItem.items = items;

      // construct and add map item
      itemMap[record.id] = this.mapRecord(
        itemMap,
        parentItem.name,
        module,
        object,
        record
      );
    }
    return thisItem;
  },

  updateItems: function (
    cmp,
    action,
    itemTreeName,
    itemMapName,
    parentId,
    module,
    object,
    id,
    data
  ) {
    // action: 'list', 'create', 'read', 'update', 'delete'
    // data: record or list of records (in case action === 'list') having at least 'id' and 'name' fields
    // object: object of the children
    var itemTree = cmp.get("v." + itemTreeName);
    var itemMap = cmp.get("v." + itemMapName);
    var parent = {};
    if (parentId) {
      parent = Record.findItem(itemTree, parentId) || {};
    } else if (id) {
      parent = Record.findParent(itemTree, id) || {};
      parentId = parent.name;
    }
    var items = parent.items || [];
    var itemLookup = items.reduce((obj, item) => {
      obj[item.name] = item;
      return obj;
    }, {});

    if (action === "list") {
      // update items with list of loaded records
      items = data.reduce((obj, record) => {
        obj.push(this.itemFromRecord(itemLookup[record.id], record));
        itemMap[record.id] = this.mapRecord(
          itemMap,
          parentId,
          module,
          object,
          record
        );
        return obj;
      }, []);

      // update parent
      parent.expanded = true;
      itemMap[parentId].loaded = true;

      // set any removed items to 'undefined' in itemMap
      var newItems = items.reduce((obj, item) => {
        obj[item.name] = item;
        return obj;
      }, {});
      Object.keys(itemLookup).forEach((item) => {
        if (!newItems[item]) {
          itemMap[item] = undefined;
        }
      });
    } else {
      // remove placeholder item
      if (items.length === 1 && items[0].name === undefined) {
        items = [];
      }

      // delete, add or update single record
      if (action === "delete") {
        var index = items.findIndex((item) => item.name === id);
        if (index > -1) {
          items.splice(index, 1);
        }
        if (itemMap[id]) {
          itemMap[id] = undefined;
        }
      } else {
        var item = this.itemFromRecord(itemLookup[id], data); // this already updates any existing item
        if (!itemLookup[id]) {
          items.push(item);
        } // add new item if not yet in list
        itemMap[id] = this.mapRecord(itemMap, parentId, module, object, data);
      }
    }

    // If there are no items, add a non-selectable '--None--' child item instead, to indicate that the items were loaded
    // Setting 'disabled=true' to make the item becomes non-selectable; NB: some kind of Salesforce-related javascript error is generated.
    // Alternatively; "items = [ {} ];" to show the expanded arrow without any child items.
    if (!items.length) {
      items = [{ name: "", label: " ", metatext: "--None--", disabled: true }];
    } else {
      // sort
      var sortField =
        items && items.length && items[0].argOrder != null
          ? "argOrder"
          : "label";
      var sortFunction = Record.sortFunction(items, sortField);
      items.sort(sortFunction(sortField, false));
    }

    // store updated items with parent
    parent.items = items;
    cmp.set("v." + itemTreeName, itemTree);

    // store itemMap
    cmp.set("v." + itemMapName, itemMap);
  },

  //React combobox requires "id", instead of "value"
  addIds: function (array) {
    array.map((item) => {
      item.id = item.value;
    });
    return array;
  },

  checkSetupStatus: function (cmp) {
    var doneStatus = "Complete";
    this.getAccountSettings(cmp, doneStatus);
  },

  getAccountSettings: async function (cmp, doneStatus) {
    // List<String> statusList = new List<String>{'Not Authenticated', 'Authenticated', 'Inventory Loaded', 'Processing', 'Onboarding', 'Complete'};
    // Map<String, String> statusMap = new Map<String, String> {'Authenticated'=>'Authenticated', 'Inventory Loaded'=>'Inventory Loaded', 'Data Loaded'=>'Processing', 'Data Interpreted'=>'Processing', 'Data Connected'=>'Processing', 'Objects Connected'=>'Processing', 'No Results'=>'Onboarding', 'Patterns Created'=>'Onboarding', 'Relevance Model Trained'=>'Onboarding', 'Complete'=>'Complete'};
    // Map<String, Integer> progressMap = new Map<String, Integer> {'Authenticated'=>0, 'Inventory Loaded'=>10, 'Data Loaded'=>20, 'Data Interpreted'=>30, 'Data Connected'=>40, 'Objects Connected'=>60, 'No Results'=>100, 'Patterns Created'=>80, 'Relevance Model Trained'=>100, 'Complete'=>100};
    const statusList = [
      "Not Authenticated",
      "Authenticated",
      "Inventory Loaded",
      "Processing",
      "Onboarding",
      "Complete",
    ];
    const statusMap = {
      Authenticated: "Authenticated",
      "Inventory Loaded": "Inventory Loaded",
      "Data Loaded": "Processing",
      "Data Interpreted": "Processing",
      "Data Connected": "Processing",
      "Objects Connected": "Processing",
      "No Results": "Onboarding",
      "Patterns Created": "Onboarding",
      "Relevance Model Trained": "Onboarding",
      Complete: "Complete",
    };
    const progressMap = {
      Authenticated: 0,
      "Inventory Loaded": 10,
      "Data Loaded": 20,
      "Data Interpreted": 30,
      "Data Connected": 40,
      "Objects Connected": 60,
      "No Results": 100,
      "Patterns Created": 80,
      "Relevance Model Trained": 100,
      Complete: 100,
    };

    // Map<String,Object> accountSettings = new Map<String,Object>();
    // String status = 'Not Authenticated';
    // Integer progress = 0;
    let accountSettings = new Map();
    let status = "Not Authenticated";
    let progress = 0;

    // String authenticationStatus = ApiWrapper.getAuthenticationStatus();
    // const authenticationStatus = getAuthenticationStatus();

    accountSettings = await this.getAccountSettingsFromBackend(cmp);

    // String accountSetupStatus = (String) accountSettings.get('accountSetupStatus');
    const accountSetupStatus = accountSettings.accountSetupStatus;

    if (accountSetupStatus && accountSetupStatus.trim().length > 0) {
      status = statusMap[accountSetupStatus];
      progress = progressMap[accountSetupStatus];
      if (!status || status.trim() === "") {
        status = "Error";
      }
    } else {
      status = "Authenticated";
    }

    // check if done
    // Integer statusIndex = statusList.indexOf(status);
    // Integer doneIndex = statusList.indexOf(doneStatus);
    // Boolean done = (doneIndex >= 0) && (statusIndex >= doneIndex);
    const statusIndex = statusList.indexOf(status);
    const doneIndex = statusList.indexOf(doneStatus);

    // REVIEW Whichever doneIndex and statusIndex should be greater or equal?
    const done = doneIndex >= 0 && statusIndex >= doneIndex;
    // const done = doneIndex >= 0 && doneIndex >= statusIndex;

    accountSettings.status = status;
    accountSettings.done = done;
    accountSettings.progress = progress;

    cmp.set("accountSettings", accountSettings);
    // cmp.set("setupDone", accountSettings.done);

    return accountSettings;
  },

  getAccountSettingsFromBackend: function (cmp) {
    return new Promise((resolve, reject) => {
      try {
        var onSuccess = function (response) {
          if (response?.length > 0) {
            const record = response[0];
            const accountSettings = {
              accountSetupStatus: record.setupStatus,
              settings: record.settings,
              limits: record.limits,
            };

            cmp.set("accountSettings", accountSettings);
            resolve(accountSettings);
          } else {
            reject({});
          }
        };
        var onError = function (response) {
          reject({});
        };

        Record.getRecords("core", "account", {}, onSuccess, onError);
      } catch (err) {
        console.error(err.stack);
        reject({});
      }
    });
  },
};

export default Record;
