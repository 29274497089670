import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Auth, Amplify } from "aws-amplify";
import Cookies from "js-cookie";

import "./App.css";
import Home from "./pages/home/Home";
import Explore from "./pages/explore/Explore";
import Search from "./pages/search/Search";
import SavedInsights from "./pages/saved-insights/SavedInsights";
import DataManagement from "./pages/data-management/DataManagement";
import NotFound from "./pages/not-found/NotFound";
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import Login from "./pages/login";
import Signup from "./pages/signup";
import ForgotPassword from "./pages/forgot-password";
import PsGlobalHeader from "./components/ps-global-header";
import Store from "./pages/store/Store";
import PsGlobalNavigationBar from "./components/ps-global-navigation-bar/PsGlobalNavigationBar";
import { auth, endpoints } from "./helpers";
// import Setup from "./pages/setup/Setup";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    region: "",
  });

  const navigate = useNavigate();

  const regionFromCookies = Cookies.get("region");

  useEffect(() => {
    checkAuth();

    if (regionFromCookies !== user.region) {
      setUser((prev) => ({ ...prev, region: regionFromCookies }));
    }

    // every 5 minutes will check it;
    const sessionCheckInterval = setInterval(checkAuth, 300000);
    return () => {
      clearInterval(sessionCheckInterval);
    };
  }, []);

  const userHasAuthenticated = (authenticated) => {
    setIsAuthenticated(authenticated);
  };

  const handleLogout = async () => {
    await Auth.signOut();
    userHasAuthenticated(false);
    Cookies.remove("region");
    navigate("/login", { replace: true });
  };

  const checkAuth = async () => {
    try {
      const isCurrentSession = await Auth.currentSession();
      if (isCurrentSession) {
        setUser((prev) => ({
          ...prev,
          username: isCurrentSession.accessToken.payload.username,
          email: isCurrentSession.idToken.payload.email,
        }));
        userHasAuthenticated(true);
      } else {
        handleLogout();
      }
    } catch (err) {
      if (err !== "No current user") {
        //log so that I can understand how to catch it in case of refresh token has expired
      }
    } finally {
      setIsAuthenticating(false);
    }
  };

  const childProps = {
    isAuthenticated,
    userHasAuthenticated,
    handleLogout,
    user,
    setUser,
  };

  Amplify.configure({
    Auth: auth(user.region || regionFromCookies),
    API: {
      endpoints: endpoints(user.region || regionFromCookies),
    },
  });

  return isAuthenticating ? null : (
    <div className="App">
      <PsGlobalHeader childProps={childProps} />
      {isAuthenticated && <PsGlobalNavigationBar />}
      <Routes>
        <Route
          path=""
          element={
            <ProtectedRoute childProps={childProps}>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="explore"
          element={
            <ProtectedRoute childProps={childProps}>
              <Explore />
            </ProtectedRoute>
          }
        />
        <Route
          path="search"
          element={
            <ProtectedRoute childProps={childProps}>
              <Search />
            </ProtectedRoute>
          }
        />
        <Route
          path="savedInsights"
          element={
            <ProtectedRoute childProps={childProps}>
              <SavedInsights />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="setup"
          element={
            <ProtectedRoute childProps={childProps}>
              <Setup />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="storeManagement"
          element={
            <ProtectedRoute childProps={childProps}>
              <Store />
            </ProtectedRoute>
          }
        />
        <Route
          path="dataManagement"
          element={
            <ProtectedRoute childProps={childProps}>
              <DataManagement />
            </ProtectedRoute>
          }
        />
        <Route path="login" element={<Login childProps={childProps} />} />
        <Route path="signup" element={<Signup childProps={childProps} />} />
        <Route
          path="forgotPassword"
          element={<ForgotPassword childProps={childProps} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
